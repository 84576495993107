import classNames from 'classnames';
import React from 'react';

import * as utils from 'common/helpers/utils';
import * as Constants from './Constants';
import Chart from './ChartViewModel';
import { MAX_DATASETS_LENGTH_TO_SHOW } from './Constants';

class StackedMultiLineChart extends React.Component {
  // todo migrate to Chartjs4
  render() {
    if (!this.props.data) {
      return null;
    }

    return (
      <Chart
        title={this.props.title}
        helpText={this.props.helpText}
        chartConfig={this.chartConfig}
        className={classNames(this.props.className, 'StackedMultiLineChart')}
        hideLegend={
          this.props.hideLegend ||
          this.chartConfig.data.datasets.length > MAX_DATASETS_LENGTH_TO_SHOW
        }
        height={this.props.height}
        shipperSubscriptions={this.props.shipperSubscriptions}
      />
    );
  }

  get chartConfig() {
    const colorPallete = Constants.getColorPallete(this.props.data.datasets.length);

    return {
      type: 'line',
      data: {
        labels: this.props.data.labels,
        datasets: this.props.data.datasets.map((dataset, i) => {
          return Object.assign(
            {
              fill: false,
              lineTension: 0,
              backgroundColor: colorPallete[i],
              borderColor: colorPallete[i],
              pointBackgroundColor: colorPallete[i],
              pointHoverBackgroundColor: colorPallete[i],
              pointHoverBorderColor: utils.hexToRGBA(colorPallete[i], 0.3),
              spanGaps: false,
            },
            Constants.POINT_CONFIG,
            dataset
          );
        }),
      },
      options: {
        hover: {
          mode: 'x-axis',
        },
        tooltips: {
          mode: 'x-axis',
          callbacks: {
            // Add a little more space between the label color swatch and the
            // label text.
            label: (tooltipItem, data) => {
              var itemValue = tooltipItem.yLabel,
                dataset = data.datasets[tooltipItem.datasetIndex];

              if (this.props.yAxisTooltipsFmt) {
                itemValue = this.props.yAxisTooltipsFmt(itemValue);
              }

              var tooltip = ' ' + dataset.label + ': ' + itemValue;

              if (this.props.yAxisTooltipsShowPerc) {
                var datasetValues = data.datasets.map((dataset) => {
                  return dataset.data[tooltipItem.index];
                });

                var itemPerc = tooltipItem.yLabel / utils.sum(datasetValues);

                if (this.props.yAxisTooltipsPercFmt) {
                  itemPerc = this.props.yAxisTooltipsPercFmt(itemPerc);
                }
                tooltip += ' (' + itemPerc + ')';
              }

              return tooltip;
            },
          },
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: this.props.xAxisLabel,
              },
              gridLines: {
                color: 'transparent',
                zeroLineColor: Constants.LIGHT_BLUE,
              },
            },
          ],
          yAxes: [
            {
              // This is required to making this line chart stacked.
              stacked: true,

              scaleLabel: {
                display: true,
                labelString: this.props.yAxisLabel,
              },
              gridLines: {
                zeroLineColor: Constants.LIGHT_BLUE,
              },
              ticks: {
                beginAtZero: true,
                callback: (value) => {
                  if (this.props.yAxisTicksFmt) {
                    value = this.props.yAxisTicksFmt(value);
                  }
                  return value;
                },
                max: this.props.yAxisTicksMax,
                stepSize: this.props.yAxisTicksStepSize,
              },
            },
          ],
        },
      },
    };
  }
}

export default StackedMultiLineChart;
