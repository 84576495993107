import React from 'react';
import * as ajax from 'common/helpers/ajax';
import autobind from 'react-autobind';
import Box from '@mui/material/Box';
import Tips from '../../common/components/Tips/Tips';
import Button from '../../common/components/Button';
import Stack from '@mui/material/Stack';
import ReactSelect from 'react-select-plus';
import { enqueueSnackbar } from 'notistack';

const FINANCIAL_CALENDAR_OPTIONS = [
  {
    label: 'Standard',
    value: 'STANDARD',
  },
  {
    label: '4-5-4',
    value: 'NRF_4_5_4',
  },
  {
    label: '5-4-4',
    value: 'FY5253_5_4_4',
  },
];

class FinancialCalendar extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      financialCalendar: FINANCIAL_CALENDAR_OPTIONS[0].value,
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    ajax.getJSON(
      '/api/financial-calendar',
      (response) => {
        this.setState({ financialCalendar: response.financialCalendar });
      },
      (response) => {
        console.log(response);
      }
    );
  }

  async updateFinancialCalendar(value) {
    ajax.putJSON(
      '/api/financial-calendar',
      { financialCalendar: value },
      (response) => {
        this.setState({ financialCalendar: response.financialCalendar });
        enqueueSnackbar('Success! Your Financial Calendar has been updated.', {
          variant: 'success',
        });
      },
      (response) => {
        console.log(response);
        enqueueSnackbar(
          'Something went wrong! Your Financial Calendar has not been updated.',
          {
            variant: 'error',
          }
        );
      }
    );
  }

  render() {
    return (
      <section className="FinancialCalendar">
        <Box sx={{ paddingBottom: '16px' }}>
          <Tips>
            Please select a financial calendar that will be mapped to reports that use it
            for data grouping
          </Tips>
        </Box>

        <form
          onSubmit={(event) => {
            event.preventDefault();
            event.stopPropagation();
            this.updateFinancialCalendar(this.state.financialCalendar);
          }}
          style={{ maxWidth: '240px' }}
        >
          <Stack spacing={3}>
            <ReactSelect
              clearable={false}
              value={this.state.financialCalendar}
              options={FINANCIAL_CALENDAR_OPTIONS}
              onChange={({ value }) => {
                this.setState({ financialCalendar: value });
              }}
            />

            <Button
              type="submit"
              size="large"
              variant="contained"
              isLoading={this.props.submitting}
              disabled={this.props.pristine || this.props.submitting}
            >
              Save financial calendar
            </Button>
          </Stack>
        </form>
      </section>
    );
  }
}

export default FinancialCalendar;
