import { useCallback } from 'react';
import { CALCULATION_A, CALCULATION_B, CALCULATION_COMPARE } from './constants';

// Custom react hook for indicating selected calculation
export const useCalculation = (selectedCalculationIndex) => {
  const hasSelected = useCallback(
    (calculationIndex) => calculationIndex === selectedCalculationIndex,
    [selectedCalculationIndex]
  );

  hasSelected.isA = hasSelected(CALCULATION_A);
  hasSelected.isB = hasSelected(CALCULATION_B);
  hasSelected.isCompare = hasSelected(CALCULATION_COMPARE);

  return hasSelected;
};
