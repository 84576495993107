'use strict';

import React, { useState } from 'react';
import SingleDatePicker from 'react-dates/lib/components/SingleDatePicker';

const SingleDatePickerFormik = ({ date, setDate, setFieldTouched, ...props }) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <SingleDatePicker
      id="single_date_picker"
      date={date || null}
      hideKeyboardShortcutsPanel={true}
      focused={isFocused}
      numberOfMonths={1}
      onDateChange={(date) => {
        setDate('date', date);
      }}
      onFocusChange={({ focused }) => {
        if (!focused) setFieldTouched('date', true);
        setIsFocused(focused);
      }}
      {...props}
    />
  );
};

export default SingleDatePickerFormik;
