'use strict';

import { action as toggleFilterSidebar } from 'redux-burger-menu';
import { connect } from 'react-redux';
import autobind from 'react-autobind';
import React from 'react';
import Tips from 'common/components/Tips/Tips';

/**
 * Displays an error message.
 */
class NoShipmentsContainer extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  render() {
    return (
      <Tips className="NoShipmentsContainer alert alert-danger">
        <strong>No shipments matched the specified filters.</strong>{' '}
        <a href="#" onClick={this.toggleSidebar}>
          Please update
        </a>{' '}
        the selected filters and try again.
      </Tips>
    );
  }

  toggleSidebar(e) {
    e.preventDefault();
    this.props.dispatch(toggleFilterSidebar(true, 'Dashboard.FilterSidebar'));
  }
}

export default connect()(NoShipmentsContainer);
