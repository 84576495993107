import React from 'react';
import TextField from '@mui/material/TextField';

export const TextFieldFormik = ({ field, form, shrink = true, ...otherProps }) => {
  const hasError = !!form.errors[field.name];
  const hasTouched = !!form.touched[field.name];
  return (
    <TextField
      variant="standard"
      error={hasError && hasTouched}
      helperText={hasError && hasTouched ? form.errors[field.name] : null}
      InputLabelProps={
        shrink
          ? {
              shrink: true,
            }
          : null
      }
      {...field}
      {...otherProps}
      value={field.value === null ? '' : field.value}
    />
  );
};
