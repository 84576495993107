'use strict';

import React, { Fragment } from 'react';
import autobind from 'react-autobind';
import { FormGroup, FormLabel } from '@mui/material';
import { connect } from 'react-redux';
import {
  reduxForm,
  formValueSelector,
  arrayRemoveAll,
  arrayPush,
  change,
  Field,
} from 'redux-form';

import * as actions from '../store/actions';
import Button from 'common/components/Button';
import HelpIcon from 'common/components/HelpIcon';
import ReduxFormCheckbox from 'common/components/ReduxFormCheckbox';
import ReduxFormSelect from 'common/components/ReduxFormSelect';
import ReduxFormReportsSelect from 'common/components/ReduxFormReportsSelect';

import ReportFormHelpers from './ReportFormHelpers';
import { getOperationVendors, getVendors } from 'common/helpers/utils';
import { locationLevel, locationTypes } from 'common/helpers/locationTypes';
import LocationsSelect from 'common/components/LocationsSelect';
import UploadBlock from 'common/components/UploadTrackingField/UploadBlock';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ReduxFormSFMultiSelect from '../../broadside/components/ReduxFormSFMultiSelect';

class RunReportForm extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentWillMount() {
    if (this.props.reports.length === 0) {
      this.props.dispatch(actions.fetchReports());
    }
    if (this.props.operationVendorAccountNumberMappings.length === 0) {
      this.props.dispatch(actions.fetchOperationVendorAccountNumberMappings());
    }
  }

  componentDidUpdate(prevProps) {
    const {
      reportLocationType,
      startDate,
      endDate,
      vendor,
      selectedReport,
      locationLevel,
      removeFieldArray,
    } = this.props;

    const locationsOrigin = this.props[`locations_origin`];
    const locationsDestination = this.props[`locations_destination`];

    if (reportLocationType && startDate && endDate) {
      if (
        reportLocationType !== prevProps.reportLocationType ||
        startDate !== prevProps.startDate ||
        endDate !== prevProps.endDate ||
        vendor !== prevProps.vendor ||
        locationLevel !== prevProps.locationLevel
      ) {
        if (locationsOrigin) removeFieldArray('locations_origin');
        if (locationsDestination) removeFieldArray('locations_destination');

        if (
          reportLocationType === 'origin' ||
          reportLocationType === 'origin-destination'
        ) {
          this.props.addValueToFieldArray(`locations_origin`, {
            city: '',
            country: '',
            state: '',
          });
        }
        if (
          reportLocationType === 'destination' ||
          reportLocationType === 'origin-destination'
        ) {
          this.props.addValueToFieldArray(`locations_destination`, {
            city: '',
            country: '',
            state: '',
          });
        }

        const data = {
          start_date: startDate,
          end_date: endDate,
        };
        if (vendor) data.vendor = vendor;
        if (locationLevel) data.location_grouping = locationLevel;
        if (reportLocationType !== 'origin-destination')
          this.props.dispatch(actions.getReportLocations(reportLocationType, data));
        else if (reportLocationType === 'origin-destination') {
          this.props.dispatch(actions.getReportLocations('origin', data));
          this.props.dispatch(actions.getReportLocations('destination', data));
        }
      }
    }
    if (selectedReport && selectedReport !== prevProps.selectedReport) {
      const { removeFieldArray, changeField } = this.props;

      if (selectedReport.additionalOptions) {
        changeField('additional_options', selectedReport.additionalOptions);
      }

      if (!selectedReport.additionalOptions.includes('location_filtering')) {
        if (locationsOrigin) removeFieldArray('locations_origin');
        if (locationsDestination) removeFieldArray('locations_destination');

        this.props.dispatch({
          type: 'LOCATION_GET_ORIGIN',
          payload: null,
        });
        this.props.dispatch({
          type: 'LOCATION_GET_DESTINATION',
          payload: null,
        });
        changeField('reportLocationType', null);
        changeField('upload_radio_value', null);
        changeField('trackingNumbers', null);
        changeField('invoiceNumbers', null);
        changeField('files', null);
      } else {
        const customReportChangeField = () => {
          if (!this.props.upload_radio_value) {
            changeField('upload_radio_value', 'file');
          }
          changeField('reportLocationType', null);
        };

        if (selectedReport?.additionalOptions?.includes('tracking_number_filtering')) {
          customReportChangeField();
          changeField('invoiceNumbers', null);
        } else if (
          selectedReport?.additionalOptions?.includes('invoice_number_filtering')
        ) {
          customReportChangeField();
          changeField('trackingNumbers', null);
        } else {
          changeField('upload_radio_value', null);
          changeField('trackingNumbers', null);
          changeField('files', null);
        }
      }

      if (
        selectedReport?.isOperationReport !== prevProps.selectedReport?.isOperationReport
      ) {
        changeField('vendor', null);
        changeField('vendorAccountNumbers', null);
      }
    }
    if (locationLevel !== prevProps.locationLevel) {
      this.props.dispatch({
        type: 'LOCATION_GET_ORIGIN',
        payload: null,
      });
      this.props.dispatch({
        type: 'LOCATION_GET_DESTINATION',
        payload: null,
      });
      if (locationsOrigin) removeFieldArray('locations_origin');
      if (locationsDestination) removeFieldArray('locations_destination');

      if (
        reportLocationType === 'origin' ||
        reportLocationType === 'origin-destination'
      ) {
        this.props.addValueToFieldArray(`locations_origin`, {
          city: '',
          country: '',
          state: '',
        });
      }
      if (
        reportLocationType === 'destination' ||
        reportLocationType === 'origin-destination'
      ) {
        this.props.addValueToFieldArray(`locations_destination`, {
          city: '',
          country: '',
          state: '',
        });
      }
    }
  }

  onChangeRadioValue(value) {
    this.props.onUploadRadioValue('upload_radio_value', value);
  }

  checkPostalCodeLength(str) {
    if (!str) {
      return false;
    }
    if (str.length === 0) {
      return false;
    }
    return str.length < 3;
  }

  render() {
    const isShowSubreport =
      this.props.selectedReport &&
      this.props.selectedReport.additionalOptions.includes('location_filtering');

    const isSelectedReportIncludeTrackingNumber =
      this.props.selectedReport?.additionalOptions?.includes('tracking_number_filtering');
    const isSelectedReportIncludeInvoiceNumber =
      this.props.selectedReport?.additionalOptions?.includes('invoice_number_filtering');

    const isShowPostalCode = this.props.selectedReport?.additionalOptions?.includes(
      'postal_code_filtering'
    );

    const reportLocationType = this.props.reportLocationType;

    const selectLocationType = (value) => {
      const removeField =
        value === 'destination'
          ? 'locations_origin'
          : value === 'origin'
          ? 'locations_destination'
          : null;
      if (removeField) this.props.removeFieldArray(removeField);
      if (value === 'destination' || value === 'origin') {
        const field = this.props[`locations_${value}`];
        if (!field || (field && field.length === 0))
          this.props.addValueToFieldArray(`locations_${value}`, {
            city: '',
            country: '',
            state: '',
          });
      } else if (value === 'origin-destination') {
        const locationsOrigin = this.props[`locations_origin`];
        const locationsDestination = this.props[`locations_destination`];
        if (!locationsOrigin || (locationsOrigin && locationsOrigin.length === 0))
          this.props.addValueToFieldArray(`locations_origin`, {
            city: '',
            country: '',
            state: '',
          });
        if (
          !locationsDestination ||
          (locationsDestination && locationsDestination.length === 0)
        )
          this.props.addValueToFieldArray(`locations_destination`, {
            city: '',
            country: '',
            state: '',
          });
      }
    };

    return (
      <form
        className="RunReportForm ReportingForm defaultColumnFormStyle"
        onSubmit={this.props.handleSubmit}
      >
        <FormGroup className={'RunReport_reportType formGroup'}>
          <FormLabel className={'RunReport_reportType_label'}>Report type</FormLabel>
          <ReduxFormReportsSelect
            name="reportCanName"
            placeholder="Select a report type"
            reports={this.props.reports}
            selectedReport={this.props.selectedReport}
            formName={'Reporting.RunReportForm'}
          />
        </FormGroup>

        {ReportFormHelpers.getDateRangeFormGroupEl(this.props.selectedReport)}

        {isShowSubreport && (
          <Fragment>
            <FormGroup className={'small_group formGroup'}>
              <FormLabel>Report subtype</FormLabel>
              <ReduxFormSelect
                name="reportLocationType"
                placeholder="Select a report subtype"
                options={locationTypes}
                clearable={false}
                onChange={selectLocationType}
              />
            </FormGroup>
          </Fragment>
        )}

        <FormGroup className={'small_group formGroup'}>
          <FormLabel>Vendor</FormLabel>
          {this.props.selectedReport?.isOperationReport ? (
            <ReduxFormSelect
              name="vendor"
              placeholder="Select vendor"
              options={getOperationVendors(
                this.props.operationVendorAccountNumberMappings
              )}
              clearable={true}
            />
          ) : (
            <ReduxFormSelect
              name="vendor"
              placeholder="Select vendor"
              options={getVendors(this.props.vendorAccountNumberMappings)}
              clearable={true}
            />
          )}
        </FormGroup>

        {this.props.startDate && this.props.endDate && isShowSubreport && (
          <FormGroup className={'small_group formGroup'}>
            <FormLabel>Location level</FormLabel>
            <ReduxFormSelect
              name="locationGrouping"
              placeholder="Select location level"
              options={locationLevel}
              clearable={false}
            />
          </FormGroup>
        )}

        {this.props.startDate &&
          this.props.endDate &&
          (this.props.reportLocations.isPendingOriginDestinations ? (
            <Box className="small_group formGroup">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {(reportLocationType === 'origin' ||
                reportLocationType === 'origin-destination') && (
                <Box className="small_group formGroup">
                  <LocationsSelect
                    locationType="origin"
                    optionsData={this.props.reportLocations.origin}
                    changeField={this.props.changeField}
                    locationLevel={this.props.locationLevel}
                  />
                </Box>
              )}
              {(reportLocationType === 'destination' ||
                reportLocationType === 'origin-destination') && (
                <Box className="small_group formGroup">
                  <LocationsSelect
                    locationType="destination"
                    optionsData={this.props.reportLocations.destination}
                    changeField={this.props.changeField}
                    locationLevel={this.props.locationLevel}
                  />
                </Box>
              )}
            </>
          ))}

        {ReportFormHelpers.getDateGroupingFormGroupEl(
          this.props.selectedReport,
          this.props.dispatch
        )}

        {ReportFormHelpers.getAccountNumbersFormGroupEl(
          this.props.selectedReport,
          this.props.selectedReport?.isOperationReport
            ? this.props.operationVendorAccountNumberMappings
            : this.props.vendorAccountNumberMappings
        )}

        {isShowPostalCode && (
          <>
            <FormGroup className="small_group formGroup searchInput">
              <FormLabel>Origin postal code</FormLabel>
              <Field
                className="form-control"
                name="origin_postal_code"
                component="input"
                placeholder="Enter One Origin Postal Code"
                type="text"
              />
            </FormGroup>
            <FormGroup className="small_group formGroup searchInput">
              <FormLabel>Destination postal code</FormLabel>
              <Field
                className="form-control"
                name="destination_postal_code"
                component="input"
                placeholder="Enter One Destination Postal Code"
                type="text"
              />
            </FormGroup>
          </>
        )}

        {this.props.isShowBillingEntry && (
          <FormGroup className="small_group formGroup">
            <FormLabel>Charge Name</FormLabel>
            {this.props.reportBillingEntriesName.isWait ? (
              <div className="d-flex justify-content-center mb-2 mt-2">
                <CircularProgress />
              </div>
            ) : (
              <ReduxFormSFMultiSelect
                name="billingEntryCanNames"
                placeholder="Select charge names"
                options={this.props.reportBillingEntriesName.canNames}
                disabled={!this.props.reportBillingEntriesName.canNames.length > 0}
              />
            )}
          </FormGroup>
        )}

        <FormGroup className="small_group formGroup">
          <FormLabel>
            File format
            <HelpIcon>
              Specifies the format in which to export the report. Note that it can take
              longer to generate Excel files, so for larger reports (e.g., the shipment
              details report), we typically recommend exporting as CSV.
            </HelpIcon>
          </FormLabel>
          <ReduxFormSelect
            name="fileFormat"
            placeholder="Select a file format"
            options={ReportFormHelpers.getFileFormatOptions()}
            clearable={false}
          />
        </FormGroup>
        <Box className="form-group">
          {isSelectedReportIncludeTrackingNumber && (
            <UploadBlock
              radioValue={this.props.upload_radio_value}
              onChangeRadioValue={this.onChangeRadioValue}
            />
          )}
          {isSelectedReportIncludeInvoiceNumber && (
            <UploadBlock
              radioValue={this.props.upload_radio_value}
              onChangeRadioValue={this.onChangeRadioValue}
              fieldName="invoiceNumbers"
              textFieldPlaceholder="Insert invoice numbers without comma. Each TN should start from the new line."
              dropzonePlaceholder="Please select csv or txt file to upload. File should not contain any headers, single column with invoice numbers only."
            />
          )}
        </Box>
        <FormGroup className={'receiveCompletionEmail'}>
          <ReduxFormCheckbox name="receiveCompletionEmail">
            Receive notification email when report finishes running.
          </ReduxFormCheckbox>
        </FormGroup>
        <FormGroup className={'small_group formGroup submitButton'}>
          <Button
            type="submit"
            size="large"
            variant="contained"
            isLoading={this.props.submitting}
            disabled={
              !this.props.shipper.hasAxShipments ||
              this.props.submitting ||
              (isSelectedReportIncludeTrackingNumber &&
                (this.props.upload_radio_value === 'file'
                  ? !this.props.files
                  : !this.props.trackingNumbers)) ||
              (isSelectedReportIncludeInvoiceNumber &&
                (this.props.upload_radio_value === 'file'
                  ? !this.props.files
                  : !this.props.invoiceNumbers)) ||
              (isShowPostalCode &&
                (this.checkPostalCodeLength(this.props.origin_postal_code) ||
                  this.checkPostalCodeLength(this.props.destination_postal_code)))
            }
          >
            Run report
          </Button>
        </FormGroup>
      </form>
    );
  }
}

RunReportForm = reduxForm({
  form: 'Reporting.RunReportForm',
})(RunReportForm);

const selector = formValueSelector('Reporting.RunReportForm');
const mapStateToProps = (state) => {
  const selectedReport =
    state.reports &&
    state.reports.find((report) => report.canName === selector(state, 'reportCanName'));
  return {
    initialValues: {
      fileFormat: 'CSV',
      // locations_destination: [{ city: '', country: '', state: '' }],
      // locations_origin: [{ city: '', country: '', state: '' }],
    },
    reports: state.reports,
    shipper: state.shipper,
    shippers: state.shippersList,
    vendorAccountsForShipper: state.vendorAccountsForShipper,
    vendorAccountNumberMappings: state.vendorAccountNumberMappings,
    selectedReport,
    reportLocations: state.reportLocations,
    operationVendorAccountNumberMappings: state.operationVendorAccountNumberMappings,
    isShowBillingEntry: selectedReport
      ? selectedReport.additionalOptions.includes('billing_entries_filtering')
      : false,
    reportBillingEntriesName: state.reportBillingEntriesName,
    shiperId: selector(state, 'shipper_id'),
    startDate: selector(state, 'startDate'),
    endDate: selector(state, 'endDate'),
    reportLocationType: selector(state, 'reportLocationType'),
    vendor: selector(state, 'vendor'),
    locations_destination: selector(state, 'locations_destination'),
    locations_origin: selector(state, 'locations_origin'),
    locationLevel: selector(state, 'locationGrouping'),
    upload_radio_value: selector(state, 'upload_radio_value'),
    trackingNumbers: selector(state, 'trackingNumbers'),
    invoiceNumbers: selector(state, 'invoiceNumbers'),
    files: selector(state, 'files'),
    additional_options: selector(state, 'additional_options'),
    origin_postal_code: selector(state, 'origin_postal_code'),
    destination_postal_code: selector(state, 'destination_postal_code'),
    billingEntryCanNames: selector(state, 'billingEntryCanNames'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeFieldArray: function (field) {
      dispatch(arrayRemoveAll('Reporting.RunReportForm', field));
    },
    addValueToFieldArray: function (field, value) {
      dispatch(arrayPush('Reporting.RunReportForm', field, value));
    },
    changeField: function (field, value) {
      dispatch(change('Reporting.RunReportForm', field, value));
    },
    onUploadRadioValue: function (field, value) {
      dispatch(change('Reporting.RunReportForm', field, value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RunReportForm);
