import React, { useMemo } from 'react';
import MultiLineHeader from 'common/components/table/MultiLineHeader';
import * as utils from 'common/helpers/utils';
import Box from '@mui/material/Box';

import '../ServicesSummaryContent.scss';

const NumShipmentsFormatter = ({ val, row }) => {
  var numShipments = utils.Fmt.int(val),
    numShipmentsPerc = utils.Fmt.percFloat2(row.numShipmentsPerc);
  return (
    <span>
      <Box component={'span'} sx={{ fontWeight: 500 }}>
        {numShipments}
      </Box>{' '}
      <Box component={'span'} sx={{ color: '#1175DB' }}>
        ({numShipmentsPerc})
      </Box>
    </span>
  );
};

const TotalCostFormatter = ({ val, row }) => {
  const totalCost = utils.Fmt.dollars(val);
  const totalCostPerc = utils.Fmt.percFloat2(row.totalCostPerc);
  return (
    <span>
      <Box component={'span'} sx={{ fontWeight: 500 }}>
        {totalCost}{' '}
      </Box>{' '}
      <Box component={'span'} sx={{ color: '#1175DB' }}>
        ({totalCostPerc})
      </Box>
    </span>
  );
};

export const useColumns = () =>
  useMemo(
    () => [
      {
        field: 'carrier',
        headerName: 'CARRIER',
        flex: 2,
        align: 'left',
        headerAlign: 'left',
        minWidth: 150,
        // maxWidth: 200,
        renderCell: (params) => (
          <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <img
              src={params.row.iconUrl}
              alt={params.value}
              style={{
                height: '100%',
                maxHeight: '33px',
                objectFit: 'contain',
                width: 'auto',
                borderRadius: '50%',
              }}
            />
            <Box component={'span'} sx={{ fontWeight: 700 }}>
              {params.value}
            </Box>
          </Box>
        ),
      },

      {
        field: 'serviceType',
        headerName: 'SERVICE',
        flex: 2,
        align: 'left',
        headerAlign: 'left',
        minWidth: 150,
        // maxWidth: 180,
        renderCell: (params) => (
          <Box
            component={'div'}
            sx={{
              fontWeight: 400,
              backgroundColor: '#dadaff',
              height: '100%',
              width: '100%',
              maxWidth: '130px',
              borderRadius: '50px',
              padding: '5px 13px',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className={'autoHeightCell'}
          >
            {params.value}
          </Box>
        ),
      },
      {
        field: 'numShipments',
        headerName: 'WEEKLY VOLUME',
        align: 'left',
        headerAlign: 'left',
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <NumShipmentsFormatter val={params.value} row={params.row} />
        ),
      },
      {
        field: 'totalCost',
        headerName: 'WEEKLY COST',
        align: 'left',
        headerAlign: 'left',
        flex: 2,
        minWidth: 150,
        renderCell: (params) => (
          <TotalCostFormatter val={params.value} row={params.row} />
        ),
      },
      {
        field: 'unitTransportationCost',
        renderHeader: () => (
          <MultiLineHeader
            list={['TRANSPORTATION', 'COST']}
            sx={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'start',
              gap: '3px',
            }}
          />
        ),
        align: 'left',
        headerAlign: 'left',
        flex: 1,
        minWidth: 160,
        renderCell: (params) => (
          <Box component={'span'} sx={{ fontWeight: 500 }} className={'autoHeightCell'}>
            {utils.Fmt.dollars(params.value)}
          </Box>
        ),
      },
      {
        field: 'avgWeight',
        headerName: 'AVG WEIGHT',
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (params) => (
          <Box component={'span'} sx={{ fontWeight: 500 }} className={'autoHeightCell'}>
            {utils.Fmt.mdashIfNullFunc(utils.Fmt.float1)(params.value)}
          </Box>
        ),
      },
      {
        field: 'avgPricingZone',
        headerName: 'AVG ZONE',
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        minWidth: 100,
        renderCell: (params) => (
          <Box component={'span'} sx={{ fontWeight: 500 }} className={'autoHeightCell'}>
            {utils.Fmt.mdashIfNullFunc(utils.Fmt.float1)(params.value)}
          </Box>
        ),
      },
      {
        field: 'busDaysLabelCreatedToDelivered',
        renderHeader: () => (
          <MultiLineHeader
            list={['LABEL CREATION', 'to delivery (DAYS)']}
            sx={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: '3px',
            }}
          />
        ),
        width: 160,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => (
          <Box component={'span'} sx={{ fontWeight: 500 }} className={'autoHeightCell'}>
            {params.value !== '-'
              ? utils.Fmt.mdashIfNullFunc(utils.Fmt.float1)(params.value)
              : params.value}
          </Box>
        ),
      },
      {
        field: 'busDaysCarrierPickedUpToDelivered',
        renderHeader: () => (
          <MultiLineHeader
            list={['CARRIER PICKUP', 'to delivery (DAYS)']}
            sx={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: '3px',
            }}
          />
        ),
        width: 160,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => (
          <Box component={'span'} sx={{ fontWeight: 500 }} className={'autoHeightCell'}>
            {params.value !== '-'
              ? utils.Fmt.mdashIfNullFunc(utils.Fmt.float1)(params.value)
              : params.value}
          </Box>
        ),
      },
    ],
    []
  );
