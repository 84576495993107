import React, { memo } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TableHeaderSearch from './TableHeaderSearch';

const TableHeaderStack = ({
  title,
  actions,
  searchQuery,
  onHandlerSearch,
  settings = {},
  style = {},
  searchStyle = {},
  searchPlaceholder,
}) => (
  <Box
    className="heading"
    sx={{
      margin: '0 0 18px',
      display: 'grid',
      gridTemplateColumns: '1fr fit-content(42px)',
      gap: '50px',
    }}
    {...settings}
  >
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '9px' }}>
      {title && (
        <Typography
          variant="h6"
          component="h3"
          sx={{
            color: '#061A2E',
            leadingTrim: 'both',
            textEdge: 'cap',
            fontFamily: 'Outfit',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '18px',
            textTransform: 'capitalize',
            marginBottom: '5px',
            letterSpacing: 0,
          }}
        >
          {title}
        </Typography>
      )}
      {onHandlerSearch && (
        <Box
          sx={{
            width: '100%',
            maxWidth: '500px',
            ...searchStyle,
          }}
        >
          <TableHeaderSearch
            onHandlerSearch={onHandlerSearch}
            searchQuery={searchQuery}
            placeholder={searchPlaceholder}
          />
        </Box>
      )}
    </Box>

    {actions && (
      <Box
        className="text-right"
        sx={{ display: 'flex', alignItems: 'end', gap: '30px' }}
      >
        {actions}
      </Box>
    )}
  </Box>
);

export default memo(TableHeaderStack);
