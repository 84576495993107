import _sum from 'lodash/sum';
import classNames from 'classnames';
import React, { useMemo } from 'react';

import * as utils from 'common/helpers/utils';

import Chart from './PieChartViewModel';
import * as Constants from './Constants';
import {
  LABELS_BOX_WIDTH,
  MAX_DATASETS_LENGTH_TO_SHOW,
  polarAreaArcAngleChartPlugin,
} from './Constants';

const PolarAreaChart = (props) => {
  const colorPalette = Constants.getColorPallete(props.data.labels.length);

  const chartConfig = useMemo(
    () => ({
      type: 'polarArea',
      data: {
        labels: props.data.labels,
        datasets: props.data.datasets.map((dataset, i) => {
          return Object.assign(
            {
              // For the pie chart, each dataset needs to have an *array* of
              // colors. This is different from other charts, which want a single
              // color per dataset.
              backgroundColor: colorPalette.map((c) => {
                return utils.hexToRGBA(c, 0.7);
              }),
              // Custom field for react select picker
              selectBackgroundColor: colorPalette[i],
              borderColor: '#fff',
              hoverBackgroundColor: colorPalette.map((c) => {
                return utils.hexToRGBA(c, 0.7);
              }),
              borderWidth: 2,

              //only for polar-area-arc-angle-chart-plugin
              // _angle: props.data.angle[i]
              _extraAngle: props.data.extra[0],
            },
            dataset
          );
        }),
      },
      options: {
        responsive: true,
        elements: {
          arc: {},
        },
        layout: {
          padding: {
            bottom: 16,
          },
        },
        plugins: {
          tooltip: {
            backgroundColor: Constants.TOOLTIP_BACKGROUND_COLOR,
            callbacks: {
              label: (context) => {
                const datasetValues = context.dataset.data;
                const itemLabel = context.label;
                let itemValue = context.raw;
                let itemPerc = itemValue / _sum(datasetValues);
                if (props.itemTooltipsValueFmt) {
                  itemValue = props.itemTooltipsValueFmt(itemValue);
                } else {
                  itemValue = utils.Fmt.int(itemValue);
                }

                itemPerc = utils.Fmt.percFloat1(itemPerc);

                return `${itemLabel}: ${itemValue} (${itemPerc})`;
              },
            },
          },
          legend: {
            // display: true,
            labels: {
              boxWidth: LABELS_BOX_WIDTH,
            },
            display: false,
          },
        },
      },
      plugins: [polarAreaArcAngleChartPlugin],
    }),
    [props.data]
  );

  return (
    <Chart
      title={props.title}
      helpText={props.helpText}
      chartConfig={chartConfig}
      className={classNames(props.className, 'PieChart')}
      hideLegend={
        props.hideLegend || props.data.labels.length > MAX_DATASETS_LENGTH_TO_SHOW
      }
      height={props.height}
      shipperSubscriptions={props.shipperSubscriptions}
    />
  );
};

export default PolarAreaChart;
