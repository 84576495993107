'use strict';

import autobind from 'react-autobind';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { enqueueSnackbar } from 'notistack';

import * as ajax from 'common/helpers/ajax';
import Button from 'common/components/Button';
import ReduxFormCheckbox from 'common/components/ReduxFormCheckbox';
import ReduxFormVendorAccountNumbersSelect from 'common/components/ReduxFormVendorAccountNumbersSelect';
import Grid from '@mui/material/Unstable_Grid2';

class EmailPreferencesForm extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchUserEmailPreferences());
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.saveUserEmailPreferences)}>
        <Grid container spacing={2}>
          <Grid md={12}>
            <ReduxFormCheckbox name="receiveBillingEmails">
              Receive billing emails.
              <span className="formText">
                You should only disable this if someone else at your company is managing
                billing.
              </span>
            </ReduxFormCheckbox>
          </Grid>
          <Grid md={12}>
            <ReduxFormCheckbox name="receiveUpdateEmails">
              Receive periodic update emails.
              <span className="formText">
                We may occassionally send emails about new product features or
                announcements.
              </span>
            </ReduxFormCheckbox>
          </Grid>
          <Grid md={12}>
            <ReduxFormCheckbox name="receiveWeeklySummaryEmails">
              Receive weekly summary emails.
              <span className="formText">
                Enables weekly summary emails of your audit results and shipping trends.
                If you'd like to limit this email to show data for specific account
                numbers, then select them below. Otherwise this email will show data
                across all of your account numbers.
              </span>
            </ReduxFormCheckbox>
          </Grid>
          <Grid md={12}>
            <div className="weeklySummaryEmailsVendorAccountNumbersCont">
              <ReduxFormVendorAccountNumbersSelect
                name="weeklySummaryEmailsVendorAccountNumbers"
                vendorAccountNumberMappings={this.props.vendorAccountNumberMappings}
              />
            </div>
          </Grid>
          <Grid md={12}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              isLoading={this.props.submitting}
              disabled={this.props.pristine || this.props.submitting}
            >
              Save changes
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }

  saveUserEmailPreferences(data) {
    return this.props.dispatch(saveUserEmailPreferences(data));
  }
}

const fetchUserEmailPreferences = () => {
  return (dispatch) => {
    ajax.getJSON(
      '/api/user/current-user/email-preferences',
      (response) => {
        dispatch({
          type: 'USER__EMAIL_PREFERENCES__UPDATE',
          payload: response,
        });
      },
      (response) => {
        console.log(response);
      }
    );
  };
};

const saveUserEmailPreferences = (data) => {
  return (dispatch) => {
    return ajax.putJSON(
      '/api/user/current-user/email-preferences',
      data,
      (response) => {
        dispatch({
          type: 'USER__EMAIL_PREFERENCES__UPDATE',
          payload: response,
        });

        enqueueSnackbar('Success! Your email preferences have been updated.', {
          variant: 'success',
        });
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    vendorAccountNumberMappings: state.vendorAccountNumberMappings,
    initialValues: state.user.emailPreferences,
  };
};

EmailPreferencesForm = reduxForm({
  form: 'Account.EmailPreferencesForm',
})(EmailPreferencesForm);

export default connect(mapStateToProps)(EmailPreferencesForm);
