'use strict';

import './ReduxFormDropzone.scss';

import React from 'react';
import Dropzone from 'react-dropzone';
import { Field } from 'redux-form';

/**
 * Defines a wrapper for a dropzone field that works with redux-form.
 *
 * Source: https://github.com/erikras/redux-form/issues/1989
 */
class DropzoneField extends React.Component {
  constructor(props) {
    super(props);
    this.handleDropOrClick = this.handleDropOrClick.bind(this);
  }

  render() {
    let {
      input,
      placeholder,
      meta: { touched, error },
    } = this.props;
    let { accept, multiple } = this.props;
    let selectedFiles = (input && input.value) || null;

    let dropzoneProps = {
      accept,
      multiple,
      onDrop: this.handleDropOrClick,
    };

    // Defines a function that lets us toggle the dropzone contents based on
    // whether the dropzone is active, etc.
    const dropzoneContents = ({
      isDragActive,
      isDragReject,
      acceptedFiles,
      rejectedFiles,
    }) => {
      if (isDragActive) {
        return <span className="placeholder">Drop file.</span>;
      }
      if (isDragReject) {
        return <span className="placeholder">This file is not authorized.</span>;
      }

      if (selectedFiles && selectedFiles.length > 0) {
        return (
          <div>
            {Array.from(selectedFiles).map((f, i) => (
              <div key={i}>{f.name}</div>
            ))}
          </div>
        );
      }
      if (placeholder) {
        return <span className="placeholder">{placeholder}</span>;
      }
    };

    return (
      <div>
        <input type="hidden" disabled {...input} />
        <Dropzone
          {...dropzoneProps}
          className="ReduxFormDropzone"
          activeClassName="active"
          rejectClassName="reject"
        >
          {dropzoneContents}
        </Dropzone>
      </div>
    );
  }

  handleDropOrClick(acceptedFiles, rejectedFiles, e) {
    let eventOrValue = e;
    let {
      input: { onChange, onBlur },
    } = this.props;

    if (e.type === 'drop') {
      if (acceptedFiles.length) {
        // FileList or [File]
        eventOrValue = (e.dataTransfer && e.dataTransfer.files) || acceptedFiles;
      } else {
        eventOrValue = null;
      }
    }

    // Update touched.
    onBlur(eventOrValue);

    // Update value.
    onChange(eventOrValue);
  }
}

const ReduxFormDropzone = (props) => {
  return <Field {...props} component={DropzoneField} />;
};

export default ReduxFormDropzone;
