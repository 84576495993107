import React, { useMemo } from 'react';
import TooltipIcon from 'common/components/TooltipIcon';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { ic_email } from 'react-icons-kit/md/ic_email';
import { ic_supervisor_account } from 'react-icons-kit/md/ic_supervisor_account';
import * as ajax from 'common/helpers/ajax';
import * as actions from '../../store/actions';
import { enqueueSnackbar } from 'notistack';

const removeUser = (uuid) => {
  return (dispatch) => {
    return ajax.deleteJSON(
      '/api/users/' + uuid,
      {},
      (response) => {
        dispatch(actions.fetchUsers());
        enqueueSnackbar('Success! This user has been removed.', { variant: 'success' });
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

const onResendInviteEmail = (userUuid, dispatch) => {
  if (confirm('Are you sure you want to re-send this invitation email?')) {
    dispatch(resendInviteEmail(userUuid));
  }
};

const resendInviteEmail = (uuid) => {
  return (dispatch) => {
    return ajax.postJSON(
      '/api/users/' + uuid + '/resend-invite',
      {},
      (response) => {
        dispatch(actions.fetchUsers());
        enqueueSnackbar('Success! The invitation email has been re-sent to this user.', {
          variant: 'success',
        });
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

const onRemoveUser = (userUuid, dispatch) => {
  if (confirm('Are you sure you want to remove this user?')) {
    dispatch(removeUser(userUuid));
  }
};

const transferOwnership = (uuid) => {
  return (dispatch) => {
    return ajax.postJSON(
      '/api/users/' + uuid + '/set-owner',
      {},
      (response) => {
        dispatch(actions.fetchUsers());
        enqueueSnackbar('Success! This user is now the owner on this account.', {
          variant: 'success',
        });
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

const onTransferOwnership = (userUuid, dispatch) => {
  if (confirm('Are you sure you want to transfer ownership to this user?')) {
    dispatch(transferOwnership(userUuid));
  }
};

/**
 * Renders icon-components appropriate for the current row.
 */
const ActionsFormatter = ({ row, dispatch }) => {
  const transferOwnershipIcon =
    !row.isRegistered || row.isOwner ? null : (
      <TooltipIcon
        icon={ic_supervisor_account}
        onClick={() => onTransferOwnership(row.uuid, dispatch)}
      >
        Transfer ownership to this user
      </TooltipIcon>
    );

  const resendInviteIcon = row.isRegistered ? null : (
    <TooltipIcon icon={ic_email} onClick={() => onResendInviteEmail(row.uuid, dispatch)}>
      Re-send invitation email
    </TooltipIcon>
  );

  const removeUserIcon = row.isOwner ? null : (
    <TooltipIcon icon={ic_delete} onClick={() => onRemoveUser(row.uuid, dispatch)}>
      Remove user
    </TooltipIcon>
  );

  return (
    <div>
      {transferOwnershipIcon}
      {resendInviteIcon}
      {removeUserIcon}
    </div>
  );
};

export const useUsersTableColumns = (dispatch) =>
  useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'email',
        headerName: 'Email',
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },
      // {
      //   field: 'totalAmountDue',
      //   headerName: 'Amount due',
      //   flex: 1,
      //   minWidth: 150,
      //   headerAlign: 'center',
      //   align: 'center',
      //   renderCell: (params) => utils.Fmt.dollars(params.value / 100),
      // },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 80,
        type: 'actions',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <ActionsFormatter row={params.value} dispatch={dispatch} />
        ),
      },
    ],
    [dispatch]
  );
