'use strict';

import './Reporting.scss';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PageContainer from 'cloud/containers/PageContainer';
import PendingImportPlaceholder from 'cloud/components/PendingImportPlaceholder';
import RunReportFormController from './RunReportFormController';
import RecentReportRuns from './RecentReportRuns';
import Box from '@mui/material/Box';
import TabsTitle from 'common/components/Tabs/TabsTitle';
import useChartTabs from 'common/components/Tabs/hooks/useChartTabs';
import ScheduledReports from './ScheduledReports';
import { useCheckPermissions } from '../../common/helpers/hooks/useCheckPermissions';
import * as actions from '../store/actions';

const PAGE = 'reporting';
const PERMISSION_PAGE_KEY = PAGE.toUpperCase();

const RunReports = (props) => {
  const checkPermissions = useCheckPermissions();

  const { currentTab, onChangeCurrentQueryNames, contentId } = useChartTabs(
    tabList,
    PAGE
  );

  useEffect(() => {
    if (
      !props.vendorAccountNumberMappings ||
      props.vendorAccountNumberMappings.length <= 0
    ) {
      props.dispatch(actions.fetchVendorAccountNumberMappings());
    }
  }, []);

  const { isAllowTab } = checkPermissions([PERMISSION_PAGE_KEY, currentTab.path]);

  return (
    <PageContainer
      title="Run Reports"
      contentId="RunReports"
      contentClassName="Reporting RunReports"
    >
      <Box
        sx={{
          maxWidth: '1200px',
        }}
      >
        <TabsTitle
          tabList={tabList}
          value={currentTab.label}
          pages={PAGE}
          permissionPageKey={PERMISSION_PAGE_KEY}
          setValue={onChangeCurrentQueryNames}
        />
        {isAllowTab && (
          <>
            {currentTab.path === 'new-report' && <RunReportFormController />}
            {currentTab.path === 'recent-reports' && <RecentReportRuns />}
            {currentTab.path === 'scheduled-reports' && <ScheduledReports />}
          </>
        )}
      </Box>
    </PageContainer>
  );
};

const tabList = [
  {
    label: 'New Report',
    path: 'new-report',
  },
  {
    label: 'Recent Reports',
    path: 'recent-reports',
  },
  {
    label: 'Scheduled Reports',
    path: 'scheduled-reports',
  },
];

/**
 * Renders the pending import placeholder if the given shipper doesn't have any
 * ax shipments.
 */
const renderPendingImportPlaceholder = (shipper) => {
  if (shipper.hasAxShipments) {
    return null;
  }
  return <PendingImportPlaceholder />;
};

const mapStateToProps = (state) => {
  return {
    shipper: state.shipper,
    vendorAccountNumberMappings: state.vendorAccountNumberMappings,
  };
};

export default connect(mapStateToProps)(RunReports);
