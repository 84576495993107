import React from 'react';
import { useVendorInvoicesTableColumns } from './hooks/useVendorInvoicesTableColumns';
import { useVendorInvoicesTableRows } from './hooks/useVendorInvoicesTableRows';
import DataGridWrapper from 'common/components/table/DataGridWrapper';
import DataGridCustom from 'common/components/table/DataGridCustom';
import humps from 'humps';

const VendorInvoicesTable = (props) => {
  const rows = useVendorInvoicesTableRows(props.vendorInvoices);
  const columns = useVendorInvoicesTableColumns(props.enrolledForPayments);

  const sortModel = [
    {
      field: humps.camelize(props.pagination.sortKey),
      sort: props.pagination.sortOrder,
    },
  ];

  const onRowSelectionModelChange = (selectedRows) => {
    if (!props.vendorInvoices?.length) {
      return;
    }

    if (selectedRows?.length) {
      const selectedVendorInvoices = props.vendorInvoices.filter((vendorInvoice) =>
        selectedRows.includes(vendorInvoice.hid)
      );
      return props.onSelect(selectedVendorInvoices);
    }
    props.onSelect([]);
  };

  return (
    <DataGridWrapper>
      <DataGridCustom
        rows={rows}
        columns={columns}
        sortModel={sortModel}
        onSortModelChange={props.onSortChange}
        settings={{
          hideFooterSelectedRowCount: true,
          checkboxSelection: true,
          disableRowSelectionOnClick: true,
          rowSelection: true,
          onRowSelectionModelChange,
          initialState: {
            pagination: { paginationModel: { pageSize: props.pagination.sizePerPage } },
          },
          pageSizeOptions: [props.pagination.sizePerPage],
          hideFooter: false,
          onPaginationModelChange: props.onPageChange,
          paginationMode: 'server',
          loading: props.isloading,
          rowCount: props.pagination.total,
          density: 'compact',
        }}
      />
    </DataGridWrapper>
  );
};

export default VendorInvoicesTable;
