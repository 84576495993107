'use strict';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import CustomSnackBarProvider from 'common/components/Snackbar/CustomSnackBarProvider';

import history from './history';
import reducers from './store/reducers';
import Routes from './routes';
import { themeMui } from 'common/helpers/themeMui';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material';

import '../scss/config/material-ui.scss';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, window.G, composeEnhancers(applyMiddleware(thunk)));

const container = document.getElementById('app-root');
const root = createRoot(container);
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={themeMui}>
      <CssBaseline />
      <Provider store={store}>
        <CustomSnackBarProvider>
          <Router history={history}>
            <Routes />
          </Router>
        </CustomSnackBarProvider>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>
);

history.listen((location) => {
  if (window.mixpanel) {
    window.mixpanel.track('Page view: ' + location.pathname);
  }

  // Reset the notification queue on page change so we don't show any old
  // notifications after a page change.
  // utils.clearArray(NotificationManager.listNotify);
});
