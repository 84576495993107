import React, { useEffect, useMemo, useState, useCallback, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import cn from 'classnames';
import { ic_vertical_align_bottom } from 'react-icons-kit/md/ic_vertical_align_bottom';
import { NetRateSelect } from './NetRateSelect';
import { Heading } from 'common/components/Heading';
import { AddNetRateBlock } from './AddNetRateBlock';
import { CalculationTable } from './CalculationTable';
import { CALCULATION_COMPARE } from 'common/helpers/constants';
import { HeatmapKey } from 'common/components/HeatmapKey';
import Icon from 'common/components/Icon';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { getShipperNetRate } from '../../store/slices/NetRatesSlices';
import {
  calculateNetRates,
  clearNetRatesCalculations,
} from '../../store/slices/NetRatesAnalysisSlice';
import { IconButton } from '@mui/material';
import Button from 'common/components/Button';
import Box from '@mui/material/Box';
import '../NetRatesAnalysis.scss';
import { LoadingOverlay } from 'common/components/table/DataGridCustom';
import { carrierCalculateNetRates } from '../../store/slices/CarrierNetRatesAnalysisSlice';
import { getCarrierNetRate } from '../../store/slices/CarrierNetRatesSlices';

const Container = styled.div`
  margin-top: 0;

  button:not(:last-of-type) {
    margin-right: 20px;
  }
`;

const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 20px;
  max-width: calc(100% - 20px);
`;

const ToggleBtn = styled(ToggleButton)`
  &.btn-secondary.focus,
  &.btn-primary.focus {
    box-shadow: none;
  }

  &.a-contract {
    border: none;
    background-color: rgba(193, 73, 24, 0.3);

    &.btn-primary:not(:disabled):not(.disabled).active,
    &:active,
    &:active:hover,
    &:hover {
      background-color: rgba(193, 73, 24, 0.7);
    }
  }

  &.b-contract {
    border: none;
    background-color: rgba(55, 105, 186, 0.3);

    &.btn-primary:not(:disabled):not(.disabled).active,
    &:active,
    &:active:hover,
    &:hover {
      background-color: rgba(55, 105, 186, 0.7);
    }
  }
`;

const NetRatesAnalysis = ({
  calculateNetRates,
  netRatesCalculationCompare,
  netRatesCalculations,
  loading,
  netRatesList,
  clearNetRatesCalculations,
  getShipperNetRate,
}) => {
  const dispatch = useDispatch();
  const [netRateSelected, setNetRateSelected] = useState([
    { netRateId: '', charges: [] },
  ]);
  const [selectedCalculationIndex, setSelectedCalculationIndex] = useState(0);

  const clearNetRateSelect = () => setNetRateSelected([{ netRateId: '', charges: [] }]);

  const addNetRateSelect = () => {
    if (netRateSelected.length < 2)
      setNetRateSelected([...netRateSelected, { netRateId: '', charges: [] }]);
  };
  const calculate = useCallback(() => {
    if (netRateSelected && netRateSelected.length > 0 && netRateSelected[0].netRateId)
      dispatch(calculateNetRates(netRateSelected));
  }, [dispatch, netRateSelected]);

  const calculationTableRef = useCallback((node) => {
    if (node) {
      node.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  const selectedCalculationData = useMemo(() => {
    const netRatesCalculationsIndex = netRatesCalculations?.[selectedCalculationIndex];

    if (
      selectedCalculationIndex !== CALCULATION_COMPARE &&
      netRatesCalculations &&
      netRatesCalculationsIndex
    ) {
      return {
        data: netRatesCalculationsIndex.mapping,
        zone: netRatesCalculationsIndex.zone,
        weight: netRatesCalculationsIndex.weight,
        maxValues: netRatesCalculationsIndex.maxValues,
        minValues: netRatesCalculationsIndex.minValues,
        name: netRatesCalculationsIndex.name,
      };
    }
    if (selectedCalculationIndex === CALCULATION_COMPARE && netRatesCalculationCompare)
      return {
        data: netRatesCalculationCompare.mapping,
        zone: netRatesCalculationCompare.zone,
        weight: netRatesCalculationCompare.weight,
        maxValues: netRatesCalculationCompare.maxValues,
        minValues: netRatesCalculationCompare.minValues,
      };
  }, [netRatesCalculations, selectedCalculationIndex, netRatesCalculationCompare]);

  const downloadHandler = () => {
    if (selectedCalculationIndex === 0 || selectedCalculationIndex === 1)
      dispatch(
        getShipperNetRate(
          netRateSelected[selectedCalculationIndex].netRateId,
          netRateSelected[selectedCalculationIndex].name
        )
      );
    else if (selectedCalculationIndex === 'calculatedCompare') {
      const file = netRatesCalculationCompare.calculated_file;
      const url = window.URL.createObjectURL(
        new Blob([file], {
          type: 'text/csv;charset=utf-8;',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      const filename = `A vs B.csv`;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  return (
    <>
      <Box className="selectNetRatesWrapper">
        {loading && <LoadingOverlay sx={{ position: 'absolute' }} />}
        <Heading title="Select net rate(s) to analyze" />
        <FiltersContainer>
          {netRateSelected.map((filter, index) => (
            <NetRateSelect
              key={index}
              filter={filter}
              index={index}
              netRates={netRatesList}
              netRateSelected={netRateSelected}
              setNetRateSelected={setNetRateSelected}
            />
          ))}
          {netRateSelected.length < 2 && (
            <AddNetRateBlock onClick={() => addNetRateSelect()} />
          )}
        </FiltersContainer>
        <Container>
          <Button
            color="primary"
            variant="contained"
            onClick={calculate}
            disabled={!(netRateSelected && netRateSelected[0].netRateId)}
          >
            Calculate rates
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              dispatch(clearNetRatesCalculations());
              clearNetRateSelect();
            }}
            disabled={!netRateSelected[0].netRateId}
          >
            Reset
          </Button>
        </Container>
      </Box>

      {netRatesCalculations && (
        <Box sx={{ position: 'relative' }}>
          {/* we need scroll to Heading but minus 150px*/}
          <Box
            ref={calculationTableRef}
            sx={{ visibility: 'hidden', position: 'absolute', top: '-150px', left: 0 }}
          />
          <Heading
            title={'Effective rates'}
            navigation={
              netRatesCalculations &&
              netRatesCalculations.length > 1 && (
                <ToggleButtonGroup
                  size="small"
                  name={'switchContracts'}
                  exclusive
                  value={selectedCalculationIndex.toString()}
                  onChange={(_event, value) => {
                    setSelectedCalculationIndex(
                      value !== CALCULATION_COMPARE ? parseInt(value) : value
                    );
                  }}
                >
                  {netRatesCalculations.map((item, index) => (
                    <ToggleBtn
                      key={index}
                      value={index.toString()}
                      className={cn({
                        'a-contract': index === 0,
                        'b-contract': index === 1,
                      })}
                    >
                      {index === 0 ? 'A' : 'B'} only
                    </ToggleBtn>
                  ))}

                  {netRatesCalculationCompare && (
                    <ToggleBtn variant="secondary" value={CALCULATION_COMPARE}>
                      A vs. B
                    </ToggleBtn>
                  )}
                </ToggleButtonGroup>
              )
            }
          >
            <HeatmapKey selectedCalculationIndex={selectedCalculationIndex} />
            {(selectedCalculationIndex === 0 ||
              selectedCalculationIndex === 1 ||
              (selectedCalculationIndex === CALCULATION_COMPARE &&
                netRatesCalculationCompare.calculated_file)) && (
              <div>
                <IconButton onClick={downloadHandler} size="large">
                  <Icon icon={ic_vertical_align_bottom} size={24} />
                </IconButton>
              </div>
            )}
          </Heading>
        </Box>
      )}

      {netRatesCalculations && selectedCalculationData && (
        <CalculationTable
          data={selectedCalculationData.data}
          zones={selectedCalculationData.zone}
          weight={selectedCalculationData.weight}
          maxValues={selectedCalculationData.maxValues || null}
          minValues={selectedCalculationData.minValues || null}
          selectedCalculationIndex={selectedCalculationIndex}
        />
      )}
    </>
  );
};

export const ShipperNetRatesAnalysis = () => {
  const { netRatesCalculationCompare, netRatesCalculations, loading } = useSelector(
    (state) => state.netRatesAnalysis
  );
  const { netRatesList } = useSelector((state) => state.netRates);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearNetRatesCalculations());
    };
  }, [dispatch]);

  return (
    <NetRatesAnalysis
      calculateNetRates={calculateNetRates}
      netRatesCalculationCompare={netRatesCalculationCompare}
      netRatesCalculations={netRatesCalculations}
      loading={loading}
      netRatesList={netRatesList}
      clearNetRatesCalculations={clearNetRatesCalculations}
      getShipperNetRate={getShipperNetRate}
    />
  );
};

export const CarrierNetRatesAnalysis = () => {
  const { netRatesCalculationCompare, netRatesCalculations, loading } = useSelector(
    (state) => state.carriersNetRatesAnalysis
  );
  const { netRatesList } = useSelector((state) => state.carriersNetRates);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearNetRatesCalculations());
    };
  }, [dispatch]);

  return (
    <NetRatesAnalysis
      calculateNetRates={carrierCalculateNetRates}
      netRatesCalculationCompare={netRatesCalculationCompare}
      netRatesCalculations={netRatesCalculations}
      loading={loading}
      netRatesList={netRatesList}
      clearNetRatesCalculations={clearNetRatesCalculations}
      getShipperNetRate={getCarrierNetRate}
    />
  );
};
