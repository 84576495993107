import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import styledComponents from 'styled-components';
import Box from '@mui/material/Box';

const Container = styledComponents(TableContainer)`
  max-height: 80vh;
`;

const Info = styledComponents(Typography)`
  margin: 80px;
  text-align: center;
`;

export const AccessorialsTable = ({ accessorials }) => {
  return (
    <Container component={Box}>
      {accessorials.length !== 0 ? (
        <Table bordered={true}  className={'detailsTable_table'}>
          <TableHead className={'detailsTable_thead'}>
            <TableRow>
              <TableCell className={`heading`}>Service</TableCell>
              <TableCell className={`heading`}>Accessorial</TableCell>
              <TableCell className={`heading`}>List rate</TableCell>
              <TableCell className={`heading`}>Discount</TableCell>
              <TableCell className={`heading`}>Effective rate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accessorials.map((row) => (
              <TableRow key={row.serviceName + row.accessorialName}>
                <TableCell className={'detailsTable_row-text'}>
                  {row.serviceName}
                </TableCell>
                <TableCell className={'detailsTable_row-text'}>
                  {row.accessorialName}
                </TableCell>
                <TableCell className={'detailsTable_row-text'}>
                  {row.listRate.rate.formatted}
                  {row.listRate.unit}
                </TableCell>
                <TableCell className={'detailsTable_row-text'}>
                  {row.discount.unit}
                  {row.discount.discount.formatted}
                </TableCell>
                <TableCell className={'detailsTable_row-text'}>
                  {row.effectiveRate.rate.formatted}
                  {row.effectiveRate.unit}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Info variant="subtitle1">No accessorials discount found</Info>
      )}
    </Container>
  );
};
