import { useMemo } from 'react';

export const useVendorAccountNumberMappingsRows = (vendorAccountNumberMappings) =>
  useMemo(
    () =>
      vendorAccountNumberMappings?.reduce((prevState, currentValue) => {
        const currentRow = {
          id: currentValue.uuid || currentValue.id,
          dispVendor: currentValue.dispVendor,
          vendorAccountNumber: currentValue.vendorAccountNumber,
          name: currentValue.name,
          action: currentValue,
          currentValue,
        };
        prevState.push(currentRow);
        return prevState;
      }, []),
    [vendorAccountNumberMappings]
  );
