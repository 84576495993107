import { useMemo } from 'react';

export const useShipperOptimizationInvoicesRows = (shippingLocations) =>
  useMemo(
    () =>
      shippingLocations?.reduce((prevState, currentValue) => {
        const currentRow = {
          id: currentValue.invoiceNumber,
          invoiceDate: currentValue.invoiceDate,
          invoiceNumber: currentValue.invoiceNumber,
          totalSavings: currentValue.totalSavings,
          totalAmountDue: currentValue.totalAmountDue,
          status: currentValue.status,
          currentValue,
        };
        prevState.push(currentRow);
        return prevState;
      }, []),
    [shippingLocations]
  );
