import { styled } from '@mui/material/styles';
import React from 'react';
import Icon from 'common/components/Icon';
import { ic_add } from 'react-icons-kit/md/ic_add';
import Thumb from './Thumb';
import Button from 'common/components/Button';

const PREFIX = 'ImageUploader';

const classes = {
  wrapper: `${PREFIX}-wrapper`,
};

const Root = styled('div')(() => ({
  [`&.${classes.wrapper}`]: {
    '& > *': {
      marginRight: 10,
      marginBottom: 10,
    },
  },
}));

const Uploader = ({ formik }) => {
  const { values, setFieldValue } = formik;

  const handleRemove = (index) => {
    const newFiles = [...values.files];
    newFiles.splice(index, 1);
    setFieldValue('files', newFiles);
  };

  return (
    <Root className={classes.wrapper}>
      {values.files.map((item, index) => {
        return <Thumb key={index} file={item} removeFile={() => handleRemove(index)} />;
      })}
      <Button variant="contained" component="label" color="primary">
        <Icon size={20} icon={ic_add} />
        Add file
        <input
          type="file"
          accept="*"
          hidden
          onChange={(event) => {
            setFieldValue('files', [...values.files, event.currentTarget.files[0]]);
          }}
        />
      </Button>
    </Root>
  );
};

export default Uploader;
