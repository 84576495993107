import './ShipmentSearchForm.scss';

('use strict');

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { enqueueSnackbar } from 'notistack';

import * as actions from 'sudo/store/actionsDashboard';
import Button from 'common/components/Button';
import { SpinnerContainer } from 'common/components/Spinner';
import TableHeaderStack from 'common/components/table/TableHeaderStack';

const ShipmentSearchForm = (props) => {
  const [trackingNumber, setTrackingNumber] = useState(props.trackingNumber);
  const onHandlerSearch = (e) => {
    setTrackingNumber(e.target.value);
  };

  const fetchShipments = () => {
    props.setIsLoading(true);
    return props.dispatch(
      actions.fetchShipments(
        {
          trackingNumber,
          hid: props.hid,
        },
        (response) => {
          // If we get back a successful response, then we can push a new entry
          // to the URL.
          props.setIsLoading(false);
          if (!!props.hid) {
            props.history.push(
              `/shipments/single-tracking/${trackingNumber}/${props.hid}`
            );
          } else {
            props.history.push(`/shipments/single-tracking/${trackingNumber}`);
          }
        },
        (response) => {
          props.setIsLoading(false);
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      )
    );
  };

  return (
    <>
      <TableHeaderStack
        onHandlerSearch={onHandlerSearch}
        searchPlaceholder={'Please enter a tracking number'}
        searchQuery={trackingNumber}
        title={<>Tracking number</>}
        settings={{
          sx: {
            margin: '0 0 18px',
            display: 'grid',
            gridTemplateColumns: '500px fit-content(42px)',
            gap: '20px',
          },
        }}
        actions={
          <Button
            variant="contained"
            onClick={fetchShipments}
            sx={{ padding: '11px', borderRadius: '16px', minWidth: 'auto' }}
          >
            Search
          </Button>
        }
      />

      {props.isLoading && <SpinnerContainer isVisible={true} message="Working..." />}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    trackingNumber: ownProps.trackingNumber,
    hid: ownProps.hid,
  };
};

export default withRouter(connect(mapStateToProps)(ShipmentSearchForm));
