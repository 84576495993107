import { createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { findMaxValue, findMinValue } from 'common/helpers/utils';
import * as ajax from 'common/helpers/ajax';

const carriersNetRatesAnalysis = createSlice({
  name: 'carriersNetRatesAnalysis',
  initialState: {
    netRateId: '',
    netRatesCalculationCompare: null,
    netRatesCalculations: null,
    loading: false,
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    getNetRatesCarriersListSuccess: (state, { payload }) => {
      state.loading = false;
      state.carriersList = payload;
    },
    setNetRatesCalculation: (state, { payload }) => {
      // Set the maximum value of each calculation to create a heatmap
      state.netRatesCalculations = payload.map((calculation) => ({
        ...calculation,
        maxValues: findMaxValue(calculation.mapping),
        minValues: findMinValue(calculation.mapping),
      }));
    },
    setNetRatesCalculationCompare: (state, { payload }) => {
      state.netRatesCalculationCompare = {
        ...payload,
        // Set the maximum value of each calculation to create a heatmap
        maxValues: findMaxValue(payload.mapping),
        minValues: findMinValue(payload.mapping),
      };
    },
    clearNetRatesCalculations: (state) => {
      state.netRatesCalculations = null;
      state.netRatesCalculationCompare = null;
    },
  },
});

export const { clearNetRatesCalculations } = carriersNetRatesAnalysis.actions;

export const carrierCalculateNetRates = (netRates) => {
  return async (dispatch) => {
    dispatch(carriersNetRatesAnalysis.actions.clearNetRatesCalculations());
    dispatch(carriersNetRatesAnalysis.actions.setLoading(true));

    const param = {
      carrier_net_rate_a: netRates[0],
    };
    if (netRates[1]) param.carrier_net_rate_b = netRates[1];

    try {
      await ajax.postJSON(
        '/api/net-rates-analysis/calculate-carrier-net-rates',
        param,
        (response) => {
          if (!Object.keys(response || {}).length) {
            enqueueSnackbar('There are no data', { variant: 'error' });
          }

          const calculations = [{ ...response.a, name: netRates[0].name }];
          if (response.b) {
            calculations.push({ ...response.b, name: netRates[1].name });
          }

          dispatch(carriersNetRatesAnalysis.actions.setNetRatesCalculation(calculations));

          if (response.compare) {
            dispatch(
              carriersNetRatesAnalysis.actions.setNetRatesCalculationCompare(
                response.compare
              )
            );
          }

          dispatch(carriersNetRatesAnalysis.actions.setLoading(false));
        },
        (response) => {
          dispatch(carriersNetRatesAnalysis.actions.setLoading(false));
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      );
    } catch (e) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
      dispatch(carriersNetRatesAnalysis.actions.setLoading(false));
    }
  };
};

export const carriersNetRatesAnalysisReducer = carriersNetRatesAnalysis.reducer;
