'use strict';

import './ShipperInvoices.scss';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

import * as ajax from 'common/helpers/ajax';
import * as utils from 'common/helpers/utils';
import Box from '@mui/material/Box';
import { useShipperInvoicesRows } from './hooks/useShipperInvoicesRows';
import { useVendorAccountNumberMappingsColumns } from './hooks/useShipperInvoicesColumns';
import DataGridWrapper from 'common/components/table/DataGridWrapper';
import DataGridCustom from 'common/components/table/DataGridCustom';

const SIZE_PER_PAGE = 10;

const ShipperInvoices = (props) => {
  const [sortModel, setSortModel] = useState([
    {
      field: 'invoiceDate',
      sort: 'desc',
    },
  ]);
  const [isWorkingList, setIsWorkingList] = useState([]);

  useEffect(() => {
    props.dispatch(fetchShipperInvoices());
  }, []);

  const onPayShipperInvoice = (shipperInvoice) => {
    const invoiceNumber = shipperInvoice.invoiceNumber;

    if (
      confirm(
        `Are you sure you want to pay invoice ${invoiceNumber} for ${utils.Fmt.dollars(
          shipperInvoice.totalAmountDue / 100.0
        )}?`
      )
    ) {
      setIsWorkingList((prevState) => {
        prevState.push(invoiceNumber);
        return [...prevState];
      });
      props.dispatch(payShipperInvoice(invoiceNumber)).then(() => {
        setIsWorkingList((prevState) =>
          prevState.filter((element) => element !== invoiceNumber)
        );
      });
    }
  };

  const rows = useShipperInvoicesRows(props.shipperInvoices);
  const columns = useVendorAccountNumberMappingsColumns(
    onPayShipperInvoice,
    isWorkingList
  );

  return (
    <section className="ShipperInvoices">
      <h3 className="heading">Invoice history</h3>
      <DataGridWrapper
        customPudding={0}
        style={{
          minHeight: '650px',
        }}
      >
        <DataGridCustom
          rows={rows}
          columns={columns}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          customNoTableDataOverlay={<EmptyPlaceholder />}
          settings={{
            initialState: {
              pagination: { paginationModel: { pageSize: SIZE_PER_PAGE } },
            },
            pageSizeOptions: [SIZE_PER_PAGE],
            hideFooter: false,
          }}
        />
      </DataGridWrapper>
    </section>
  );
};

/**
 * An empty placeholder to render when we don't have any invoices to show.
 */
const EmptyPlaceholder = () => {
  return (
    <Box className="table-empty-placeholder">You don&rsquo;t have any invoices yet.</Box>
  );
};

/**
 * Updates the list of shipper invoices.
 */
const fetchShipperInvoices = () => {
  return (dispatch) => {
    return ajax.getJSON(
      '/api/shipper-invoices',
      (response) => {
        dispatch({
          type: 'SHIPPER_INVOICES__UPDATE',
          payload: response,
        });
      },
      (response) => {
        console.log(response);
      }
    );
  };
};

/**
 * Pays off the invoice with the given invoice number.
 */
const payShipperInvoice = (invoiceNumber) => {
  return (dispatch) => {
    return ajax.postJSON(
      '/api/shipper-invoices/' + invoiceNumber + '/pay',
      {},
      (response) => {
        enqueueSnackbar('Success! This invoice has now been paid.', {
          variant: 'success',
        });
        dispatch(fetchShipperInvoices());
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

const mapStateToProps = (state) => {
  return {
    shipperInvoices: state.shipperInvoices,
  };
};

export default connect(mapStateToProps)(ShipperInvoices);
