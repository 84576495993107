'use strict';

import './PageContainer.scss';

import classNames from 'classnames';
import React, { useLayoutEffect, useRef } from 'react';
import PageHeader from 'common/components/PageContainer/PageHeader';
import Sidebar from 'common/components/Sidebar/Sidebar';
import Box from '@mui/material/Box';
import SecondHeader from './SecondHeader';

// tabs have after block
const AFTER_HEIGHT = 20;
const PAGE_CONTENT_PADDING = 40;

const PageContainer = (props) => {
  const contentClassName = classNames('PageContent', props.contentClassName);
  const pageContentWrapperClassName = classNames(
    'PageContentWrapper',
    props.pageContentWrapperClassName
  );
  const customClassName = props.seconHeaderContent ? 'FirstHeader' : null;
  const pageContentWrapperRef = useRef(null);
  const secondHeaderContentRef = useRef(null);
  const pageHeaderContentRef = useRef(null);

  useLayoutEffect(() => {
    if (pageContentWrapperRef.current && pageHeaderContentRef.current) {
      const secondHeaderContentHeight =
        secondHeaderContentRef.current?.offsetHeight + AFTER_HEIGHT || 0;

      const pageHeaderContentHeight = pageHeaderContentRef.current.offsetHeight || 0;

      const height = `${
        window.innerHeight - (secondHeaderContentHeight + pageHeaderContentHeight)
      }px`;

      pageContentWrapperRef.current.style.minHeight = height;
      if (secondHeaderContentHeight) {
        pageContentWrapperRef.current.style.height = `calc(100% - ${
          (secondHeaderContentHeight || 0) + PAGE_CONTENT_PADDING
        }px)`;
      }

      if (secondHeaderContentRef.current) {
        secondHeaderContentRef.current.style.top = `${pageHeaderContentHeight}px`;
      }
    }
  }, [customClassName]);

  return (
    <Box className="PageContainer">
      {props.sidebars}
      <Sidebar user={props.user} SidebarContent={props.SidebarContent}>
        {!props.isClearBody && (
          <>
            <PageHeader
              ref={pageHeaderContentRef}
              title={props.title}
              rightIcons={props.rightIcons}
              customClassName={customClassName}
            />
            {props.seconHeaderContent && (
              <SecondHeader
                ref={secondHeaderContentRef}
                secondHeaderClass={props.secondHeaderClass}
              >
                {props.seconHeaderContent}
              </SecondHeader>
            )}
          </>
        )}

        <div ref={pageContentWrapperRef} className={pageContentWrapperClassName}>
          <div
            id={props.contentId}
            className={contentClassName}
            style={props.isClearBody ? { padding: 0 } : {}}
          >
            {props.children}
          </div>
        </div>
      </Sidebar>
    </Box>
  );
};

export default PageContainer;
