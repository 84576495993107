import './VendorPaymentStatusIcon.scss';

import React from 'react';
import moment from 'moment';
import { fileText } from 'react-icons-kit/oct/fileText';

import * as utils from 'common/helpers/utils';

import TooltipIcon from './TooltipIcon';

export const MAP_PAYMENT_STATUSES = {
  UNKNOWN: {
    text: 'Unknown payment status',
    statusClassName: 'unknown',
  },
  EXCEPTION: {
    text: 'Exception',
    statusClassName: 'exception',
  },
  UNPAID_OVERDUE: {
    text: 'Unpaid (Overdue)',
    statusClassName: 'unpaid-overdue',
  },
  UNPAID_NOT_OVERDUE: {
    text: 'Unpaid (Not overdue)',
    statusClassName: 'unpaid-not-overdue',
  },
  TRANSFER_IN_PROGRESS: {
    text: 'Transfer in progress',
    statusClassName: 'transfer-in-progress',
  },
  PAID_UNCONFIRMED: {
    text: 'Paid (unconfirmed)',
    statusClassName: 'paid-unconfirmed',
  },
  PAID_CONFIRMED: {
    text: 'Paid (confirmed)',
    statusClassName: 'paid-confirmed',
  },
};

/**
 * Renders a TooltipIcon reflecting the current vendor payment status.
 */
function VendorPaymentStatusIcon({
  vendorPaymentStatus,
  paymentTxns,
  invoiceDueDate,
  ...props
}) {
  let paymentStatusClass = '';
  let paymentStatusText;

  if (props.disabled) {
    paymentStatusText = MAP_PAYMENT_STATUSES.UNKNOWN.text;
    paymentStatusClass = MAP_PAYMENT_STATUSES.UNKNOWN.statusClassName;
  } else if (paymentTxns && paymentTxns.length > 0) {
    let statusSnippets = paymentTxns
      .map((paymentTxn) => {
        var txnDate = utils.Fmt.date(paymentTxn.createdDt);
        if (paymentTxn.type == 'SHIPPER_DEPOSIT') {
          return `Received a deposit on ${txnDate}.`;
        } else if (paymentTxn.type == 'VENDOR_PAYMENT') {
          return `Payment sent to carrier on ${txnDate}.`;
        }
      })
      .join(' ');

    if (vendorPaymentStatus === 'TRANSFER_IN_PROGRESS') {
      statusSnippets.push(MAP_PAYMENT_STATUSES.TRANSFER_IN_PROGRESS.text);
      paymentStatusClass = MAP_PAYMENT_STATUSES.TRANSFER_IN_PROGRESS.statusClassName;
    }

    paymentStatusText = statusSnippets.join('');
  } else {
    let paymentStatus = MAP_PAYMENT_STATUSES[vendorPaymentStatus]
      ? MAP_PAYMENT_STATUSES[vendorPaymentStatus]
      : MAP_PAYMENT_STATUSES.UNKNOWN;

    paymentStatusText = paymentStatus.text;
    paymentStatusClass = paymentStatus.statusClassName;

    if (vendorPaymentStatus && vendorPaymentStatus === 'UNPAID') {
      const now = moment(new Date());
      const dueDate = moment(new Date(invoiceDueDate));
      const duration = moment.duration(dueDate.diff(now));
      const diffInDays = duration.asDays();

      if (dueDate < now) {
        paymentStatus = MAP_PAYMENT_STATUSES.UNPAID_OVERDUE;
        paymentStatusText =
          'Payment for this invoice was due on ' + utils.Fmt.date(invoiceDueDate) + '.';
      } else {
        paymentStatus = MAP_PAYMENT_STATUSES.UNPAID_NOT_OVERDUE;
        paymentStatusText =
          'Payment for this invoice is due on ' + utils.Fmt.date(invoiceDueDate) + '.';
      }

      paymentStatusClass = paymentStatus.statusClassName;
    }
  }

  return (
    <TooltipIcon
      {...props}
      icon={fileText}
      className={'VendorPaymentStatusIcon ' + paymentStatusClass}
    >
      {paymentStatusText}
    </TooltipIcon>
  );
}

export default VendorPaymentStatusIcon;
