import React, { useMemo, useState } from 'react';
import { gridDateUtils } from 'common/components/table/gridDateUtils';
import * as utils from 'common/helpers/utils';
import ClockIcon from 'common/components/icon-components/Clock';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { initialize } from 'redux-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import CycleIcon from 'common/components/icon-components/CycleIcon';
import { enqueueSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';

const getReportRunErrorMessage = (error) => {
  switch (error) {
    case 'ROW_LIMIT_EXCEEDED':
      return (
        'This report would contain too many rows. Please try again with a ' +
        'shorter date range or contact admin for limit upgrade.'
      );
    case 'QUERY_TIMEOUT':
      return 'This report timed out. Please try again with a shorter date range.';
    case 'NO_RESULTS':
      return (
        'This report returned no results. Please try again with a different ' +
        'date range.'
      );
    case 'LARGE_REPORT_IN_PROCESS':
      return 'This report will contain too many lines. It will take longer to process.';
    case 'LARGE_REPORT_FAILED':
      return (
        'An unexpected error occurred while processing this report. ' +
        'We\'ve been notified and are looking into it. Please check back later.'
      );
    default:
      return (
        "An unexpected error occurred while processing this report. We've been " +
        'notified and are looking into it. Please check back later.'
      );
  }
};

const StatusFormatter = ({ cell, row }) => {
  const [downloadPending, setDownloadPending] = useState(null);
  const errorCallback = () => {
    setDownloadPending(false);
    enqueueSnackbar('Report result not found. Please create a new one.', {
      variant: 'error',
    });
  };

  const successCallback = () => {
    setDownloadPending(false);
  };

  const onDownload = () => {
    setDownloadPending(true);

    utils.triggerDownload(row.downloadPath, errorCallback, successCallback);
  };

  if (downloadPending) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress
          sx={{
            width: '24px !important',
            height: '24px !important',
          }}
        />
      </Box>
    );
  }

  if (cell === 'COMPLETED') {
    if (!row.error) {
      return (
        <Tooltip title="Download report">
          <IconButton onClick={onDownload} sx={{ padding: '0 !important' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="30"
              viewBox="0 0 29 30"
              fill="none"
            >
              <circle cx="14.5" cy="15" r="14.5" fill="#C7EDD5" />
              <path
                d="M9.91699 15L14.5003 19.6875M14.5003 19.6875L19.0837 15M14.5003 19.6875L14.5003 7.5"
                stroke="#0B4D16"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 22.5H20"
                stroke="#0B4D16"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </Tooltip>
      );
    } else if (row.error === 'LARGE_REPORT_IN_PROCESS') {
      return (
        <Tooltip title={getReportRunErrorMessage(row.error)}>
          <Box
            sx={{
              padding: '2px 6px 6px',
              borderRadius: '50%',
              backgroundColor: '#faf3dc !important',
              svg: {
                transform: 'translateY(4.5px)',
              },
            }}
          >
            <ClockIcon stroke={'#bb934f'} />
          </Box>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={getReportRunErrorMessage(row.error)}>
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="14.5" cy="14.5" r="14.5" fill="#FFEDED" />
            <circle
              cx="14.5"
              cy="14.5"
              r="7.5"
              stroke="#BB4F5C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect
              x="14.5"
              y="17.8333"
              width="0.00833333"
              height="0.00833333"
              stroke="#BB4F5C"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
            <path
              d="M14.5 14.5L14.5 11.1667"
              stroke="#BB4F5C"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Tooltip>
      );
    }
  } else if (
    cell === 'PENDING' ||
    cell === 'PROCESSING' ||
    cell === 'LARGE_REPORT_IN_PROCESS'
  ) {
    return (
      <Tooltip title="Report is being processed.">
        <Box
          sx={{
            padding: '2px 6px 6px',
            borderRadius: '50%',
            backgroundColor: '#faf3dc !important',
            svg: {
              transform: 'translateY(4.5px)',
            },
          }}
        >
          <ClockIcon stroke={'#bb934f'} />
        </Box>
      </Tooltip>
    );
  }
  return <></>;
};

export const useColumns = () => {
  const dispatch = useDispatch();

  return useMemo(
    () => [
      {
        field: 'createdDt',
        headerName: 'DATE',
        // flex: 1,
        headerAlign: 'center',
        align: 'center',
        width: 110,
        sortComparator: gridDateUtils,
        renderCell: (params) => utils.Fmt.dateTime(params.value),
      },
      {
        field: 'reportDispName',
        headerName: 'REPORT TYPE',
        minWidth: 250,
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        renderCell: (params) => <Box color="#1175DB">{params.value}</Box>,
      },
      {
        field: 'startDate',
        headerName: 'START DATE',
        width: 110,
        headerAlign: 'left',
        align: 'left',
        sortComparator: gridDateUtils,
        renderCell: (params) => utils.Fmt.date(params.value),
      },
      {
        field: 'endDate',
        headerName: 'END DATE',
        width: 110,
        headerAlign: 'left',
        align: 'left',
        sortComparator: gridDateUtils,
        renderCell: (params) => utils.Fmt.date(params.value),
      },
      {
        field: 'status',
        headerName: 'STATUS',
        width: 80,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <StatusFormatter cell={params.value} row={params.row.currentValue} />
        ),
      },

      {
        field: 'action',
        headerName: 'Action',
        width: 80,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          if (params.row.currentValue.isInternalReport) {
            return <></>;
          }

          return (
            <Link
              to={'/reporting/new-report'}
              onClick={(e) => {
                const initData = { ...params.row.currentValue };
                if (!!params.row.currentValue.operationVendor) {
                  initData.vendor = params.row.currentValue.operationVendor;
                }

                dispatch(initialize('Reporting.RunReportForm', initData));
              }}
            >
              <Tooltip title="Regenerate option">
                <Box>
                  <CycleIcon />
                </Box>
              </Tooltip>
            </Link>
          );
        },
      },
    ],
    []
  );
};
