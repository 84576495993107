import React from 'react';

const Reports = () => {
  return (
    <svg
      width="192"
      height="194"
      viewBox="0 0 192 194"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 689">
        <rect
          id="Rectangle 2844"
          x="10.3127"
          width="181.463"
          height="183.484"
          rx="90.7313"
          transform="rotate(3.222 10.3127 0)"
          fill="#1175DB"
          fillOpacity="0.19"
        />
        <g id="Group 688">
          <g id="Group 687">
            <rect
              id="Rectangle 2842"
              x="96.3134"
              y="69.3066"
              width="53.0802"
              height="76.0146"
              rx="8.5"
              transform="rotate(18.5011 96.3134 69.3066)"
              fill="white"
              stroke="#1175DB"
            />
            <rect
              id="Rectangle 2843"
              x="62.5547"
              y="53.8754"
              width="56.6537"
              height="80.6423"
              rx="8.5"
              transform="rotate(8.17847 62.5547 53.8754)"
              fill="white"
              stroke="#1175DB"
            />
            <path
              id="Vector"
              d="M146.058 54.0705L146.071 54.06L146.083 54.0488C146.499 53.6546 146.75 53.1172 146.785 52.5451C146.82 51.9731 146.637 51.409 146.272 50.9669C145.907 50.5249 145.388 50.2378 144.819 50.1638C144.251 50.0898 143.676 50.2343 143.21 50.5682L143.196 50.5784L143.182 50.5897L132.863 59.1451L131.515 55.169C131.323 54.6006 130.913 54.1318 130.375 53.8658C129.837 53.5997 129.216 53.5582 128.647 53.7503C128.079 53.9425 127.61 54.3525 127.344 54.8903C127.078 55.428 127.036 56.0493 127.228 56.6177C127.228 56.6178 127.228 56.6178 127.229 56.6179L129.625 63.7203L129.626 63.7227C129.738 64.049 129.923 64.3453 130.168 64.5888C130.412 64.8323 130.709 65.0165 131.036 65.1273L131.038 65.1282L131.136 65.1608L131.147 65.1644L131.158 65.1676C131.512 65.2675 131.884 65.2796 132.243 65.2029C132.602 65.1263 132.937 64.9634 133.218 64.7282C133.218 64.728 133.219 64.7278 133.219 64.7276L146.058 54.0705ZM131.876 41.698C134.892 40.8713 138.086 40.9576 141.053 41.946C145.031 43.2713 148.321 46.1229 150.197 49.8734C152.073 53.624 152.383 57.9663 151.057 61.945C150.069 64.9121 148.223 67.5195 145.753 69.4374C143.283 71.3554 140.299 72.4978 137.18 72.7201C134.06 72.9425 130.945 72.2348 128.228 70.6866C125.51 69.1384 123.313 66.8192 121.914 64.0222C120.515 61.2253 119.976 58.0763 120.367 54.9734C120.757 51.8705 122.059 48.9531 124.108 46.5901C126.156 44.2271 128.86 42.5246 131.876 41.698Z"
              fill="white"
              stroke="#1175DB"
            />
            <g id="Group 686">
              <circle
                id="Ellipse 109"
                cx="47.1731"
                cy="129.062"
                r="14.5618"
                fill="white"
                stroke="#1175DB"
              />
              <path
                id="Vector_2"
                d="M45.2962 121.172C45.1966 121.172 45.101 121.211 45.0306 121.282C44.9601 121.352 44.9205 121.448 44.9205 121.548L44.9205 126.901L42.5725 126.901C42.4982 126.901 42.4256 126.923 42.3638 126.965C42.302 127.006 42.2539 127.064 42.2255 127.133C42.197 127.202 42.1896 127.277 42.2041 127.35C42.2186 127.423 42.2543 127.49 42.3068 127.542L46.9091 132.145C46.9795 132.215 47.0751 132.255 47.1747 132.255C47.2743 132.255 47.3698 132.215 47.4403 132.145L52.0425 127.542C52.095 127.49 52.1308 127.423 52.1453 127.35C52.1598 127.277 52.1523 127.202 52.1239 127.133C52.0955 127.064 52.0473 127.006 51.9856 126.965C51.9238 126.923 51.8512 126.901 51.7769 126.901L49.4288 126.901L49.4288 121.548C49.4288 121.448 49.3892 121.352 49.3188 121.282C49.2483 121.211 49.1528 121.172 49.0531 121.172L45.2962 121.172ZM39.6608 133.57C39.5612 133.57 39.4657 133.609 39.3952 133.68C39.3248 133.75 39.2852 133.846 39.2852 133.945L39.2852 136.575C39.2852 136.675 39.3248 136.77 39.3952 136.841C39.4657 136.911 39.5612 136.951 39.6608 136.951L54.6885 136.951C54.7881 136.951 54.8837 136.911 54.9542 136.841C55.0246 136.77 55.0642 136.675 55.0642 136.575L55.0642 133.945C55.0642 133.846 55.0246 133.75 54.9542 133.68C54.8837 133.609 54.7881 133.57 54.6885 133.57L39.6608 133.57Z"
                fill="#DCE0FF"
                stroke="#1175DB"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Reports;
