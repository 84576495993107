'use strict';

import './PaymentMethods.scss';

import autobind from 'react-autobind';
import { connect } from 'react-redux';
import React from 'react';
import { enqueueSnackbar } from 'notistack';
import { ic_add } from 'react-icons-kit/md/ic_add';
import { ic_delete } from 'react-icons-kit/md/ic_delete';

import * as ajax from 'common/helpers/ajax';
import Button from 'common/components/Button';
import Icon from 'common/components/Icon';
import TooltipIcon from 'common/components/TooltipIcon';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';

class PaymentMethods extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchDefaultMethod());
  }

  render() {
    var content, addCardButton;

    if (!this.props.defaultMethod || !Object.keys(this.props.defaultMethod).length) {
      content = <EmptyPlaceholder showAddCardModal={this.showAddCardModal} />;
      addCardButton = (
        <Button variant="contained" onClick={this.showAddCardModal}>
          <Icon icon={ic_add} /> Add
        </Button>
      );
    } else {
      content = (
        <CardPanel card={this.props.defaultMethod} removeCard={this.removeDefaultCard} />
      );
    }

    return (
      <section className="PaymentMethods">
        <Box
          className="heading"
          sx={{
            paddingBottom: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h3>Payment method</h3>

          {addCardButton}
        </Box>

        {content}
      </section>
    );
  }

  showAddCardModal(e) {
    this.props.dispatch({
      type: 'SETTINGS_BILLING__ADD_PAYMENT_CARD_MODAL__SHOW',
    });
  }

  removeDefaultCard(e) {
    if (confirm('Are you sure you want to remove this card?')) {
      this.props.dispatch(removeDefaultMethod());
    }
  }
}

/**
 * An empty placeholder to render when no payment information has been entered
 * yet.
 */
const EmptyPlaceholder = (props) => {
  return (
    <Button
      className="empty-placeholder_new"
      variant="outlined"
      onClick={props.showAddCardModal}
    >
      You haven&rsquo;t added a preferred payment method yet! Click here to get started.
    </Button>
  );
};

/**
 * Renders the panel with the default payment card information.
 */
const CardPanel = (props) => {
  return (
    <div className="Card">
      <div className="card-body">
        <Grid container spacing={2}>
          <Grid xs={10}>
            <h4>
              {props.card.brand}
              &nbsp;&bull;&bull;&bull;&bull;
              {props.card.last4}
              &nbsp;&nbsp;&bull;&nbsp; Expires {props.card.expMonth} /{' '}
              {props.card.expYear}
            </h4>
            <div>
              Your payment card will automatically be charged on the day an invoice is
              due.
            </div>
          </Grid>
          <Grid sm={2} className="iconsCont">
            <TooltipIcon
              className="RemoveIcon"
              icon={ic_delete}
              onClick={props.removeCard}
            >
              Remove card
            </TooltipIcon>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const fetchDefaultMethod = () => {
  return (dispatch) => {
    return ajax.getJSON(
      '/api/payment-methods/default',
      (response) => {
        dispatch({
          type: 'PAYMENT_METHODS__DEFAULT_METHOD__UPDATE',
          payload: response,
        });
      },
      (response) => {
        console.log(response);
      }
    );
  };
};

const removeDefaultMethod = () => {
  return (dispatch) => {
    return ajax.deleteJSON(
      '/api/payment-methods/default',
      {},
      (response) => {
        dispatch({
          type: 'PAYMENT_METHODS__DEFAULT_METHOD__UPDATE',
          payload: response,
        });
        enqueueSnackbar('Success! Your card has been removed.', { variant: 'success' });
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

const mapStateToProps = (state) => {
  return {
    defaultMethod: state.paymentMethods.defaultMethod,
  };
};

export default connect(mapStateToProps)(PaymentMethods);
