import './Chart.scss';

import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState, memo } from 'react';
import Box from '@mui/material/Box';
import useUSZipGeneratorListener, { zoneLookupTable } from './useUSZipGeneratorListener';
import DataGridCustom, { LoadingOverlay } from '../table/DataGridCustom';
import StyledTextField from '../StyledTextField';
import DataGridWrapper from '../table/DataGridWrapper';
import Fab from '@mui/material/Fab';
import CircularProgress from '@mui/material/CircularProgress';
import { ic_expand_more_twotone } from 'react-icons-kit/md/ic_expand_more_twotone';
import Icon from 'react-icons-kit';
import useIsVisible from '../../helpers/hooks/useIsVisible';

const lookupZonesForOrigin = (originZip) => {
  if (zoneLookupTable == null) {
    // throw new Error('Could not lookup zones. Table not (yet) loaded?');
    return;
  }
  //
  var destinationZipMap = zoneLookupTable[`zip${originZip.substring(0, 3)}`] || {};

  return Object.keys(destinationZipMap).map((zip) => [
    zip.substr(3),
    destinationZipMap[zip],
  ]);
};

const zoneMapParameters = {
  zoneLookup: lookupZonesForOrigin,
  showStates: false,
  showStateLabels: false,
  showStateCodeLabels: false,
  showAreas: true,
  showAreaLabels: false,
  showTownLabels: false,
  showTownDots: false,
  showLegend: true,
  backgroundStyle: {
    fill: 'none',
    stroke: 'none',
  },
  arrowStyle: {
    strokeWidth: '2',
  },
  arrowRadius: 30,
  legendLabelStyle: {
    fontFamily: 'Mikado, sans-serif',
  },
  zoneColors: [
    '#989aff',
    '#9fccff',
    '#acffff',
    '#abffcd',
    '#abffa4',
    '#ceffa4',
    '#feffa5',
    '#ffcd9e',
  ],
  zoneLabels: [
    '1-50 mi',
    '51-150 mi',
    '151-300 mi',
    '301-600 mi',
    '601-1000 mi',
    '1001-1400 mi',
    '1401-1800 mi',
    '1801+ mi',
  ],
};

const USChoroplethZipGenerator = (props) => {
  const chartRef = useRef();

  const [zoneMap, setZoneMap] = useState(null);
  const [isFabVisible, zipTableRef, nodeRef] = useIsVisible(0.15);

  useEffect(() => {
    let mouseenterMap = {};
    let mouseleaveMap = {};

    (async () => {
      const usaByZipCode = await import(`geo-data/usaByZipCode`);

      const ZoneMap = usaByZipCode.default;

      setZoneMap(new ZoneMap(chartRef.current, zoneMapParameters));
    })();

    return () => {
      Object.entries(mouseenterMap).forEach(([key, listener]) => {
        chartRef.current
          .querySelector(`[data-zip="${key}"]`)
          .removeEventListener('mouseenter', listener);
      });
      Object.entries(mouseleaveMap).forEach(([key, listener]) => {
        chartRef.current
          .querySelector(`[data-zip="${key}"]`)
          .removeEventListener('mouseleave', listener);
      });
    };
  }, []);

  const {
    zoneGeneratorResponse,
    zoneGeneratorLoadingStatus,
    onChangeDestinationInputValue,
    onChangeOriginInputValue,
    destinationZip,
    originZip,
    isInitStatus,
  } = useUSZipGeneratorListener(chartRef.current, zoneMap);

  return (
    <Box
      className={classNames(' USChoroplethZipGenerator', props.className)}
      sx={{ minHeight: '100vh' }}
    >
      {!isInitStatus && <LoadingOverlay sx={{ position: 'absolute' }} />}
      <Box ref={chartRef} className={'ps-zone-map-container'} />
      {isInitStatus && (
        <>
          <Box className="USChoroplethZipGenerator_inputWrapper">
            <StyledTextField
              placeholder="Origin"
              value={originZip}
              onChange={onChangeOriginInputValue}
              type="number"
              styled={{
                '& > div > input': {
                  padding: '8px 16px 7px 16px',
                  fontFamily: 'Outfit',
                  fontSize: '11px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: 'normal',
                  color: '#1175DB',
                },
              }}
            />
            <StyledTextField
              placeholder="Destination"
              value={destinationZip}
              onChange={onChangeDestinationInputValue}
              type="number"
              styled={{
                '& > div > input': {
                  padding: '8px 16px 7px 16px',
                  fontFamily: 'Outfit',
                  fontSize: '11px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: 'normal',
                  color: '#1175DB',
                },
              }}
            />
          </Box>
          {!isFabVisible && (
            <>
              <Box sx={{ m: 1, position: 'relative' }}>
                {zoneGeneratorLoadingStatus === 'LOADING' && (
                  <CircularProgress
                    size={46}
                    sx={{
                      position: 'fixed',
                      bottom: '21px',
                      right: '21px',
                      zIndex: 10,
                    }}
                  />
                )}
                <Fab
                  variant="circular"
                  color="info"
                  size="small"
                  disabled={zoneGeneratorLoadingStatus === 'LOADING'}
                  onClick={() => {
                    nodeRef.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                  sx={{
                    position: 'fixed',
                    bottom: '24px',
                    right: '24px',
                    zIndex: 10,
                  }}
                >
                  <Icon icon={ic_expand_more_twotone} />
                </Fab>
              </Box>
            </>
          )}
          <ZipTable
            ref={zipTableRef}
            zoneGeneratorResponse={zoneGeneratorResponse}
            zoneGeneratorLoadingStatus={zoneGeneratorLoadingStatus}
          />
        </>
      )}
    </Box>
  );
};

const ZipTable = memo(
  React.forwardRef(({ zoneGeneratorResponse, zoneGeneratorLoadingStatus }, ref) => {
    const [sortModel, setSortModel] = useState([
      {
        field: 'Service',
        sort: 'asc',
      },
    ]);

    const rows = useMemo(() => {
      return Object.entries(zoneGeneratorResponse || {}).map(([key, value]) => ({
        id: key,
        Service: key,
        ...value,
      }));
    }, [zoneGeneratorResponse]);

    const columns = useMemo(() => {
      const keys = Object.keys(rows?.[0] || {});
      function formatHeaderName(str) {
        let words = str.split('_');
        words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
        return words.join(' ');
      }
      return keys
        .filter((key) => key !== 'id')
        .map((key) => ({
          field: key,
          headerName: formatHeaderName(key),
          flex: 1,
          minWidth: key === 'Service' ? 220 : 130,
          headerAlign: 'left',
          align: 'left',
        }));
    }, [rows]);

    return (
      <Box ref={ref} sx={{ maxWidth: '100%', width: '100%' }}>
        <DataGridWrapper style={{ overflowX: 'auto', maxWidth: '100%', width: '100%' }}>
          <DataGridCustom
            rows={rows}
            columns={columns}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            slotProps={{ toolbar: { csvOptions: { fileName: 'Zone Generator' } } }}
            sx={{
              minWidth: '100%',
              '.MuiDataGrid-columnHeadersInner': {
                backgroundColor: '#F5F8FD',
                borderRadius: '12px 12px 0px 0px',
              },
              '.MuiDataGrid-footerContainer': {
                backgroundColor: '#F5F8FD',
              },
            }}
            settings={{
              loading: zoneGeneratorLoadingStatus === 'LOADING',
            }}
          />
        </DataGridWrapper>
      </Box>
    );
  })
);

export default USChoroplethZipGenerator;
