import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Audit from 'cloud/dashboard/Audit';
import Geography from 'cloud/dashboard/Geography';
import Overview from 'cloud/dashboard/Overview';
import Performance from 'cloud/dashboard/Performance';
import RunReports from 'cloud/reporting/RunReports';
import ServicesSummary from 'cloud/dashboard/ServicesSummary';
import VendorInvoices from 'cloud/vendor-invoices/VendorInvoices';
import NotFound from 'cloud/404/NotFound';
import { ContractsList } from 'cloud/contracts/contracts-list/ContractsList';
import NetRates from 'cloud/net-rates';
import VendorInvoiceDetails from 'cloud/vendor-invoices/VendorInvoiceDetails';
import Shipment from 'cloud/shipment';
import ShippingSetupNew from 'cloud/shipphing-setup';
import AccountSettings from 'cloud/account-settings';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPermissions, fetchUser } from './account/AccountSettingsForm';
import { SpinnerContainer } from '../common/components/Spinner';
import { useCheckPermissions } from '../common/helpers/hooks/useCheckPermissions';
import PageContainerGlobal from './containers/PageContainer';
import ZoneGenerator from 'cloud/zoneGenerator';
import ShipmentDetails from 'cloud/shipment-details/ShipmentDetails';
import ZoneWeight from "./dashboard/ZoneWeight";
import VolumeCost from "./dashboard/VolumeCost";
import OperationVendorInvoices from "cloud/operation-vendor-invoices/VendorInvoices";

const routes = [
  {
    path: '/dashboard/overview',
    exact: false,
    component: Overview,
  },
  {
    path: '/dashboard/services-summary',
    exact: false,
    component: ServicesSummary,
  },
  {
    path: '/dashboard/volume-cost',
    exact: false,
    component: VolumeCost,
  },
  {
    path: '/dashboard/zone-weight',
    exact: false,
    component: ZoneWeight,
  },
  {
    path: '/dashboard/audit',
    exact: false,
    component: Audit,
  },
  {
    path: '/dashboard/performance',
    exact: false,
    component: Performance,
  },
  {
    path: '/dashboard/geography',
    exact: false,
    component: Geography,
  },
  {
    path: '/reporting',
    component: RunReports,
    permissionPath: ['REPORTING', 'PAGE'],
  },
  {
    path: '/shipment-details',
    exact: true,
    component: ShipmentDetails,
    permissionPath: ['SHIPMENT_DETAILS', 'PAGE'],
  },
  {
    path: '/shipments/:tabsName?/:trackingNumber?/:hid?',
    component: Shipment,
    permissionPath: ['SHIPMENT_LOOKUP', 'PAGE'],
  },
  {
    path: '/zone-generator',
    exact: true,
    component: ZoneGenerator,
    permissionPath: ['ZONE_GENERATOR', 'PAGE'],
  },
  {
    path: '/invoices',
    exact: true,
    component: VendorInvoices,
    permissionPath: ['CARRIER_INVOICES', 'PAGE'],
  },
  {
    path: '/invoices/:invoiceId',
    exact: true,
    component: VendorInvoiceDetails,
    permissionPath: ['CARRIER_INVOICES', 'PAGE'],
  },
  {
    path: '/operation-invoices',
    exact: true,
    component: OperationVendorInvoices,
    permissionPath: ['OPERATION_INVOICES', 'PAGE'],
  },
  // {
  //   path: '/contracts/analysis',
  //   exact: true,
  //   component: Analysis,
  //   permissionPath: ['CONTRACTS', 'PAGE'],
  // },
  // {
  //   path: '/contracts/:contractId/analysis',
  //   exact: true,
  //   component: Analysis,
  //   permissionPath: ['CONTRACTS', 'PAGE'],
  // },
  {
    path: '/contracts/:contractId?',
    exact: true,
    component: ContractsList,
    permissionPath: ['CONTRACTS', 'PAGE'],
  },

  {
    path: '/net-rates/:tab?',
    exact: false,
    component: NetRates,
    permissionPath: ['NET_RATES', 'PAGE'],
  },

  {
    path: '/shipping-setup/:tab?',
    exact: false,
    component: ShippingSetupNew,
    permissionPath: ['SHIPPING_SETUP', 'PAGE'],
  },

  {
    path: '/account/:tab?',
    exact: false,
    component: AccountSettings,
    permissionPath: ['ACCOUNT', 'PAGE'],
  },
];

const redirects = [
  {
    path: '/dashboard',
    exact: true,
    to: '/dashboard/overview',
  },
  // {
  //   path: '/settings',
  //   exact: true,
  //   to: '/shipping-setup/carrier-credentials',
  // },
  {
    path: '/reporting',
    exact: true,
    to: '/reporting/run-reports',
  },
  {
    path: '/settings/team',
    exact: true,
    to: '/account/team',
  },
  {
    path: '/partners',
    exact: true,
    to: '/account/partners',
  },

  {
    path: '/settings/billing',
    exact: true,
    to: '/account/billing',
  },

  {
    path: '/settings/shipping-setup',
    exact: true,
    to: '/shipping-setup/carrier-credentials',
  },

  {
    path: '/billing',
    exact: true,
    to: '/account/billing',
  },
];

const ProtectedRoute = (props) => {
  const checkPermissions = useCheckPermissions();

  if (!props.navItemConfig.permissionPath) {
    return <Route {...props} />;
  }

  if (!checkPermissions(props.navItemConfig.permissionPath).isAllowTab) {
    return <Redirect to={'*'} />;
  }
  return <Route {...props} />;
};

export default () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const permissions = useSelector((state) => state.cloudwebViewsRestrictionsMap);

  useEffect(() => {
    if (!userInfo?.email) {
      dispatch(fetchUser());
    }
    dispatch(fetchPermissions());
  }, []);

  if (!permissions?.value) {
    return <SpinnerContainer isVisible message="Loading..." />;
  }

  return (
    <Switch>
      {routes.map((r) => {
        return (
          <ProtectedRoute
            key={r.path}
            navItemConfig={r}
            path={r.path}
            exact={r.exact}
            component={r.component}
          />
        );
      })}

      {redirects.map((r) => (
        <Route
          key={r.path}
          path={r.path}
          exact={r.exact}
          render={() => <Redirect to={r.to} />}
        />
      ))}

      <Route
        path="*"
        render={() => (
          <PageContainerGlobal isClearBody>
            <NotFound />
          </PageContainerGlobal>
        )}
      />
    </Switch>
  );
};
