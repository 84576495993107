'use strict';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ic_add } from 'react-icons-kit/md/ic_add';
import Icon from 'common/components/Icon';
import Button from 'common/components/Button';
import * as actions from '../../store/actions';
import TableHeaderStack from 'common/components/table/TableHeaderStack';
import UsersTable from './UsersTable';

const UsersList = (props) => {
  useEffect(() => {
    props.dispatch(actions.fetchUsers());
  }, []);

  const showInviteUserModal = () => {
    props.dispatch({
      type: 'SETTINGS_TEAM__INVITE_USER_MODAL__SHOW',
    });
  };

  return (
    <section className="UsersList">
      <TableHeaderStack
        actions={
          <Button
            variant="contained"
            onClick={showInviteUserModal}
            style={{ width: 'max-content' }}
          >
            <Icon icon={ic_add} /> Invite user
          </Button>
        }
      />
      <UsersTable users={props.users} dispatch={props.dispatch} />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
  };
};

export default connect(mapStateToProps)(UsersList);
