import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export const CheckboxFormik = ({ label, field, ...otherProps }) => {
  return (
    <FormControlLabel
      control={<Checkbox color="primary" {...field} {...otherProps} />}
      label={label}
    />
  );
};
