import { createSlice } from '@reduxjs/toolkit';
import * as ChartGeo from 'chartjs-chart-geo';

const chartTypeMap = {
  US: null,
  WORLD: null,
  CA: null,
};

const getChartData = (key) => {
  return import(`geo-data/${key.toLowerCase()}`).then((someModule) => {
    chartTypeMap[key] = someModule.default;
    return chartTypeMap[key];
  });
};

const topoSlice = createSlice({
  name: 'topoSlice',
  initialState: {
    US: {
      loading: 'INIT', //INIT DONE PROCESS
      states: null,
      nation: null,
    },
    CA: {
      loading: 'INIT', //INIT DONE PROCESS
      states: null,
      nation: null,
    },
    WORLD: {
      loading: 'INIT',
      states: null,
      nation: null,
    },
  },
  reducers: {
    updateTopo: (state, { payload }) => {
      state[payload.key] = { ...state[payload.key], ...payload };
    },
  },
});

const { updateTopo } = topoSlice.actions;

export const getUpdateTopo = (key) => {
  return async (dispatch, getState) => {
    const state = getState();
    if (
      state.topoSlice[key].loading === 'DONE' ||
      state.topoSlice[key].loading === 'PROCESS'
    ) {
      return;
    }

    dispatch(updateTopo({ key, loading: 'PROCESS' }));

    const currentChartTopo = chartTypeMap[key] || (await getChartData(key));

    const states =
      key === 'WORLD'
        ? ChartGeo.topojson.feature(currentChartTopo, currentChartTopo.objects.countries)
            .features
        : ChartGeo.topojson.feature(currentChartTopo, currentChartTopo.objects.states)
            .features;

    dispatch(updateTopo({ key, loading: 'DONE', states, nation: null }));
  };
};

export const topoSliceReducer = topoSlice.reducer;
