import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as burgerMenuReducer } from 'redux-burger-menu';
import { _flashedMessages } from '../../common/reducers';
import { carriersReducer } from './slices/CarriersSlice';
import { contractsReducer } from './slices/ContractsSlice';
import { analysisReducer } from './slices/AnalysisSlice';
import { netRatesReducer } from './slices/NetRatesSlices';
import { netRatesAnalysisReducer } from './slices/NetRatesAnalysisSlice';
import {
  DATE_RANGES,
  getStartEndDatesFromName,
} from 'cloud/dashboard/DashboardFilterSidebarForm';
import { trackShipmentsReducer } from './slices/TrackShipmentsSlice';
import { multiplyTrackShipmentsReducer } from './slices/MultiplyTrackShipmentsSlice';
import { topoSliceReducer } from './slices/TopoSlice';
import _cloneDeep from 'lodash/cloneDeep';
import { deleteRequest } from '../../common/helpers/ajax';
import { carriersNetRatesReducer } from './slices/CarrierNetRatesSlices';
import { carriersNetRatesAnalysisReducer } from './slices/CarrierNetRatesAnalysisSlice';

// ---------
// Dashboard
// ---------

/**
 * Returns a dictionary with key/value pairs for initializing the date selector.
 *
 * This function is useful for making sure the state gets initialized with a
 * date range that includes the *current* day with each page load. Furthermore,
 * if a user's current filter settings are for a CUSTOM date range, then we
 * reset it to LAST_12_WEEKS since a CUSTOM date range would prevent the
 * dashboard from showing the most up-to-date data.
 */
const getInitialDateRangeFilters = (dateRangeName) => {
  let dateRange = Array.find(DATE_RANGES, (obj) => obj.value === dateRangeName);
  if (!dateRange) {
    dateRangeName = 'LAST_12_WEEKS';
  }
  return Object.assign({ dateRangeName }, getStartEndDatesFromName(dateRangeName));
};

/**
 * Stores the current set of dashboard filters.
 */
const initialDashboardFilters = {
  // Note that these are set by the server as well.
  vendorAccountNumbers: [],
  destinationType: null,

  dateRangeName: null,
  startDate: null,
  endDate: null,
  filterSetId: 0,
  initialized: false,
  shouldReinitialize: false,
};
const dashboardFilters = (state = initialDashboardFilters, action) => {
  switch (action.type) {
    case 'DASHBOARD__FILTERS__INITIALIZE':
      return Object.assign(
        {},
        initialDashboardFilters,
        state,
        action.payload,
        getInitialDateRangeFilters(action.payload.dateRangeName),
        { initialized: true }
      );
    case 'DASHBOARD__FILTERS__UPDATE':
      return Object.assign({}, state, action.payload, {
        filterSetId: state.filterSetId + 1,
      });
    case 'DASHBOARD__FILTERS__FORM_REINITIALIZE':
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

/**
 * Stores the results of the backend queries.
 */
const dashboardMetrics = (state = {}, action) => {
  switch (action.type) {
    case 'DASHBOARD__METRICS__UPDATE':
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

/**
 * Stores information about each dashboard page.
 */
const dashboardPage = (
  state = {
    filterSetId: -1,

    // Indicates whether we should show a static content pane (e.g., an error
    // message, a 'No shipments' message, etc.).
    staticContent: null,
  },
  action
) => {
  return { ...state, ...action.payload };
};

/**
 * Stores information about the dashboard pages collections.
 */
const dashboardPages = (state = {}, action) => {
  switch (action.type) {
    case 'DASHBOARD__PAGES__UPDATE':
      return { ...state, [action.name]: dashboardPage(state[action.name], action) };
    default:
      return state;
  }
};

export const dashboard = (
  state = {
    filters: {},
    metrics: {},
    stateQueryNames: {},
    pages: {},
    tabsState: {},
  },
  action
) => {
  switch (action.type) {
    case 'DASHBOARD__FILTERS__INITIALIZE':
    case 'DASHBOARD__FILTERS__FORM_REINITIALIZE':
      return Object.assign({}, state, {
        filters: dashboardFilters(state.filters, action),
      });
    case 'DASHBOARD__FILTERS__UPDATE':
      return Object.assign({}, state, {
        filters: dashboardFilters(state.filters, action),
      });
    case 'DASHBOARD__METRICS__UPDATE':
      return Object.assign({}, state, {
        metrics: dashboardMetrics(state.metrics, action),
      });
    case 'DASHBOARD__QUERY_NAMES__UPDATE':
      return Object.assign({}, state, {
        stateQueryNames: {
          ...state.stateQueryNames,
          ...action.payload.reduce((prevState, current) => {
            prevState[current] = true;
            return prevState;
          }, {}),
        },
      });
    case 'DASHBOARD__PAGES__UPDATE':
      return Object.assign({}, state, {
        pages: dashboardPages(state.pages, action),
        // metrics: {}
      });

    case 'DASHBOARD__PAGES__CHARTS_TAB_LIST':
      return Object.assign({}, state, {
        tabsState: action.payload,
      });
    default:
      return state;
  }
};

// -----------
// Settings UI
// -----------

const settingsShippingSetup = (
  state = {
    addShippingLocationModal: {
      isVisible: false,
      initialValues: {},
    },
    updateVendorAccountNumberMappingModal: {
      isVisible: false,
      initialValues: {},
    },
  },
  action
) => {
  switch (action.type) {
    case 'SETTINGS_SHIPPING_SETUP__ADD_SHIPPING_LOCATION_MODAL__SHOW':
      return Object.assign({}, state, {
        addShippingLocationModal: {
          isVisible: true,
          initialValues: action.payload,
        },
      });
    case 'SETTINGS_SHIPPING_SETUP__ADD_SHIPPING_LOCATION_MODAL__HIDE':
      return Object.assign({}, state, {
        addShippingLocationModal: {
          isVisible: false,
          initialValues: action.payload,
        },
      });
    case 'SETTINGS_SHIPPING_SETUP__UPDATE_VENDOR_ACCOUNT_NUMBER_MAPPING_MODAL__SHOW':
      return Object.assign({}, state, {
        updateVendorAccountNumberMappingModal: {
          isVisible: true,
          initialValues: action.payload,
        },
      });
    case 'SETTINGS_SHIPPING_SETUP__UPDATE_VENDOR_ACCOUNT_NUMBER_MAPPING_MODAL__HIDE':
      return Object.assign({}, state, {
        updateVendorAccountNumberMappingModal: {
          isVisible: false,
          initialValues: action.payload,
        },
      });
    default:
      return state;
  }
};

const settingsBilling = (
  state = {
    addPaymentCardModal: {
      isVisible: false,
    },
  },
  action
) => {
  switch (action.type) {
    case 'SETTINGS_BILLING__ADD_PAYMENT_CARD_MODAL__SHOW':
      return Object.assign({}, state, {
        addPaymentCardModal: {
          isVisible: true,
        },
      });
    case 'SETTINGS_BILLING__ADD_PAYMENT_CARD_MODAL__HIDE':
      return Object.assign({}, state, {
        addPaymentCardModal: {
          isVisible: false,
        },
      });
    default:
      return state;
  }
};

const settingsTeam = (
  state = {
    inviteUserModal: {
      isVisible: false,
    },
  },
  action
) => {
  switch (action.type) {
    case 'SETTINGS_TEAM__INVITE_USER_MODAL__SHOW':
      return Object.assign({}, state, {
        inviteUserModal: {
          isVisible: true,
        },
      });
    case 'SETTINGS_TEAM__INVITE_USER_MODAL__HIDE':
      return Object.assign({}, state, {
        inviteUserModal: {
          isVisible: false,
        },
      });
    default:
      return state;
  }
};

// ------------
// Current user
// ------------

const userEmailPreferences = (state = {}, action) => {
  switch (action.type) {
    case 'USER__EMAIL_PREFERENCES__UPDATE':
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

const user = (state = {}, action) => {
  switch (action.type) {
    case 'USER__UPDATE':
      return Object.assign({}, state, action.payload);
    case 'USER__EMAIL_PREFERENCES__UPDATE':
      return Object.assign({}, state, {
        emailPreferences: userEmailPreferences(state.emailPreferences, action),
      });
    default:
      return state;
  }
};

// ----------
// Team users
// ----------
const users = (state = [], action) => {
  switch (action.type) {
    case 'USERS__UPDATE':
      return action.payload;
    default:
      return state;
  }
};

// ---------
// Shipments
// ---------

const shipments = (state = [], action) => {
  switch (action.type) {
    case 'SHIPMENTS__UPDATE':
      return action.payload;
    default:
      return state;
  }
};

// ---------------
// Current shipper
// ---------------

const shipper = (state = {}, action) => {
  return state;
};

// ---------------
// Current partner
// ---------------

const partner = (state = {}, action) => {
  switch (action.type) {
    case 'PARTNER__UPDATE':
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

// ----------------
// Shipper invoices
// ----------------

const shipperInvoices = (state = [], action) => {
  switch (action.type) {
    case 'SHIPPER_INVOICES__UPDATE':
      return action.payload;
    default:
      return state;
  }
};

// -----------------------------
// Shipper optimization invoices
// -----------------------------

const shipperOptimizationInvoices = (state = [], action) => {
  switch (action.type) {
    case 'SHIPPER_OPTIMIZATION_INVOICES__UPDATE':
      return action.payload;
    default:
      return state;
  }
};

// -----------------
// Shipping location
// -----------------

const shippingLocations = (state = [], action) => {
  switch (action.type) {
    case 'SHIPPING_LOCATIONS__UPDATE':
      return action.payload;
    default:
      return state;
  }
};

// -----------------
// GL Codes
// -----------------

const glCodesInitialState = {
  data: [],
  showModal: false,
  selectedGlCode: {},
};

const glCodes = (state = glCodesInitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'GL_CODES__DATA__UPDATE':
      return { ...state, data: payload };
    case 'GL_CODES__MODAL__OPEN':
      return {
        ...state,
        showModal: true,
        selectedGlCode: payload.data ? payload.data : glCodesInitialState.selectedGlCode,
      };
    case 'GL_CODES__MODAL__HIDE':
      return {
        ...state,
        showModal: false,
        selectedGlCode: glCodesInitialState.selectedGlCode,
      };
    default:
      return state;
  }
};

// ---------------
// Payment methods
// ---------------

const paymentMethods = (
  state = {
    defaultMethod: {},
  },
  action
) => {
  switch (action.type) {
    case 'PAYMENT_METHODS__DEFAULT_METHOD__UPDATE':
      return Object.assign({}, state, { defaultMethod: action.payload });
    default:
      return state;
  }
};

// -------
// Reports
// -------

const reports = (state = [], action) => {
  switch (action.type) {
    case 'REPORTS__UPDATE':
      return action.payload;
    default:
      return state;
  }
};

// -----------
// Report runs
// -----------

const reportRuns = (state = [], action) => {
  switch (action.type) {
    case 'REPORT_RUNS__UPDATE':
      return action.payload;
    default:
      return state;
  }
};

// -----------------
// Scheduled reports
// -----------------

const scheduledReports = (state = [], action) => {
  switch (action.type) {
    case 'SCHEDULED_REPORTS__UPDATE':
      return action.payload;
    default:
      return state;
  }
};

// -------
// Sidebar
// -------

const sidebar = (state = { isOpen: true, isDocked: true, width: 0 }, action) => {
  switch (action.type) {
    case 'SIDEBAR__TOGGLE':
      return Object.assign({}, state, {
        isOpen: !state.isOpen,
      });
    case 'SIDEBAR__OPEN':
      return Object.assign({}, state, {
        isOpen: true,
      });
    case 'SIDEBAR__CLOSE':
      return Object.assign({}, state, {
        isOpen: false,
      });
    case 'SIDEBAR__SET_DOCKED':
      return Object.assign({}, state, {
        isDocked: true,
      });
    case 'SIDEBAR__SET_UNDOCKED':
      return Object.assign({}, state, {
        isDocked: false,
      });
    case 'SIDEBAR__SET_WIDTH':
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

// ---------------
// Vendor invoices
// ---------------

const vendorInvoicesFilters = (state = {}, action) => {
  switch (action.type) {
    case 'VENDOR_INVOICES__FILTERS__UPDATE':
      return Object.assign({}, state, action.payload, {
        filterSetId: state.filterSetId + 1,
      });
    default:
      return state;
  }
};

const vendorInvoices = (
  state = {
    isVendorPaymentsEnabled: false,
    enrolledForPayments: false,
    vendorInvoices: null,
    errors: {},
    pagination: {
      page: 1,
      sizePerPage: 50,
      total: 0,
      sortKey: 'invoiceDate',
      sortOrder: 'desc',
    },
    filters: {
      vendorAccountNumbers: [],
      startDate: null,
      endDate: null,
      dateRangeOption: 'INVOICE_ISSUED_DATE',
      includeShipmentsDates: false,
      filterSetId: 0,
    },
    invoicesPayment: {
      expectedPaymentDate: null,
    },
  },
  action
) => {
  switch (action.type) {
    case 'VENDOR_INVOICES__FILTERS_ERRORS__UPDATE':
      return Object.assign({}, state, {
        errors: action.payload,
      });
    case 'VENDOR_INVOICES__UPDATE':
      return Object.assign({}, state, {
        isVendorPaymentsEnabled: action.payload.isVendorPaymentsEnabled,
        enrolledForPayments: action.payload.enrolledForPayments,
        vendorInvoices: action.payload.vendorInvoices,
        pagination: {
          page: action.payload.pagination.page,
          sizePerPage: action.payload.pagination.sizePerPage,
          sortKey: action.payload.pagination.sortKey,
          sortOrder: action.payload.pagination.sortOrder,
          total: action.payload.pagination.total,
        },
      });
    case 'VENDOR_INVOICES__FILTERS__UPDATE':
      return Object.assign({}, state, {
        filters: vendorInvoicesFilters(state.filters, action),
      });
    default:
      return state;
  }
};

const vendorInvoiceDetails = (
  state = { vendorInvoiceDetails: null, vendorInvoiceError: '' },
  action
) => {
  switch (action.type) {
    case 'VENDOR_INVOICE_DETAILS':
      return { ...state, vendorInvoiceDetails: action.payload };
    case 'VENDOR_INVOICE_DETAILS_ERROR':
      return { ...state, vendorInvoiceError: action.payload };
    default:
      return state;
  }
};

// ---------------
// Operation Vendor invoices
// ---------------

const operationVendorInvoicesFilters = (state = {}, action) => {
  switch (action.type) {
    case 'OPERATION_VENDOR_INVOICES__FILTERS__UPDATE':
      return Object.assign({}, action.payload, {
        filterSetId: state.filterSetId + 1,
      });
    default:
      return state;
  }
};

const operationVendorInvoices = (
  state = {
    isVendorPaymentsEnabled: false,
    enrolledForPayments: false,
    operationInvoices: null,
    errors: {},
    pagination: {
      page: 1,
      sizePerPage: 50,
      total: 0,
      sortKey: 'invoiceDate',
      sortOrder: 'desc',
    },
    filters: {
      operationVendor: null,
      operationVendorAccountNumbers: [],
      startDate: null,
      endDate: null,
      dateRangeOption: 'INVOICE_ISSUED_DATE',
      includeShipmentsDates: false,
      filterSetId: 0,
    },
    invoicesPayment: {
      expectedPaymentDate: null,
    },
  },
  action
) => {
  switch (action.type) {
    case 'OPERATION_VENDOR_INVOICES__FILTERS_ERRORS__UPDATE':
      return Object.assign({}, state, {
        errors: action.payload,
      });
    case 'OPERATION_VENDOR_INVOICES__UPDATE':
      return Object.assign({}, state, {
        isVendorPaymentsEnabled: action.payload.isVendorPaymentsEnabled,
        enrolledForPayments: action.payload.enrolledForPayments,
        operationInvoices: action.payload.operationInvoices,
        pagination: {
          page: action.payload.pagination.page,
          sizePerPage: action.payload.pagination.sizePerPage,
          sortKey: action.payload.pagination.sortKey,
          sortOrder: action.payload.pagination.sortOrder,
          total: action.payload.pagination.total,
        },
      });
    case 'OPERATION_VENDOR_INVOICES__FILTERS__UPDATE':
      return Object.assign({}, state, {
        filters: operationVendorInvoicesFilters(state.filters, action),
      });
    default:
      return state;
  }
};

const operationVendorInvoiceDetails = (
  state = { operationVendorInvoiceDetails: null, operationVendorInvoiceError: '' },
  action
) => {
  switch (action.type) {
    case 'OPERATION_VENDOR_INVOICE_DETAILS':
      return { ...state, operationVendorInvoiceDetails: action.payload };
    case 'OPERATION_VENDOR_INVOICE_DETAILS_ERROR':
      return { ...state, operationVendorInvoiceError: action.payload };
    default:
      return state;
  }
};

// ------------------------------
// Operation Vendor account number mappings
// ------------------------------

const operationVendorAccountNumberMappings = (state = [], action) => {
  switch (action.type) {
    case 'OPERATION_VENDOR_ACCOUNT_NUMBER_MAPPINGS__UPDATE':
      return action.payload;
    default:
      return state;
  }
};

// ---------------
// Shipment Details
// ---------------

const ShipmentDetailsFilters = (state = {}, action) => {
  switch (action.type) {
    case 'SHIPMENT_DETAILS__FILTERS__UPDATE':
      return Object.assign({}, state, action.payload, {
        filterSetId: state.filterSetId + 1,
      });
    default:
      return state;
  }
};

const ShipmentDetails = (
  state = {
    shipments: null,
    isLoading: false,
    errors: {},
    services: {
      billingEntries: [],
      services: [],
    },
    pagination: {
      page: 1,
      sizePerPage: 50,
      total: 0,
      sortKey: 'carrierPickedUpDate',
      sortOrder: 'desc',
    },
    filters: {
      vendorAccountNumbers: [],
      startDate: null,
      endDate: null,
      dateRangeOption: 'PICKED_UP_DATE',
      shipmentCostOption: 'MORE_THAN',
      filterSetId: 0,
      destinationType: 'BOTH',
    },
  },
  action
) => {
  switch (action.type) {
    case 'SHIPMENT_DETAILS__UPDATE':
      return Object.assign({}, state, {
        shipments: action.payload.shipments,
        pagination: {
          page: action.payload.pagination.page,
          sizePerPage: action.payload.pagination.sizePerPage,
          sortKey: action.payload.pagination.sortKey,
          sortOrder: action.payload.pagination.sortOrder,
          total: action.payload.pagination.total,
        },
        services: action.payload.services,
      });
    case 'SHIPMENT_DETAILS__FILTERS__UPDATE':
      return Object.assign({}, state, {
        filters: ShipmentDetailsFilters(state.filters, action),
      });
    // loader for filter sidebar panel
    case 'SHIPMENT_DETAILS__UPDATE__LOADING':
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

const Services = (state = [], action) => {
  switch (action.type) {
    case 'SHIPMENT_DETAILS__SERVICES':
      return action.payload;
    default:
      return state;
  }
};

// ------------------------------
// Vendor account number mappings
// ------------------------------

const vendorAccountNumberMappings = (state = [], action) => {
  switch (action.type) {
    case 'VENDOR_ACCOUNT_NUMBER_MAPPINGS__UPDATE':
      return action.payload;
    default:
      return state;
  }
};

export const reportLocations = (
  state = {
    origin: null,
    destination: null,
    isPendingOriginDestinations: false,
  },
  action
) => {
  switch (action.type) {
    case 'LOCATION_GET_ORIGIN':
      return Object.assign({}, state, {
        origin: action.payload,
      });
    case 'LOCATION_GET_DESTINATION':
      return Object.assign({}, state, {
        destination: action.payload,
      });
    case 'SET_IS_PENDING_ORIG_DIST':
      return Object.assign({}, state, {
        isPendingOriginDestinations: action.payload,
      });
    default:
      return state;
  }
};

// --------
// unreduser State
// --------
const queryStackList = []; //{queryFn, filterSetId, clearLoading, queryNames, filters}

const startFetch = () => {
  if (queryStackList[0]) {
    queryStackList[0].queryFn().then(() => {
      queryStackList.shift();
      startFetch();
    });
  }
};

export const setQueryStackList = (queryItem, filterSetId) => {
  if (queryStackList[0] && queryStackList[0]?.filterSetId !== filterSetId) {
    const { queryNames, filters } = queryStackList[0];

    const key = `${JSON.stringify({
      queryNames: _cloneDeep(queryNames),
      filters: _cloneDeep(filters),
    })}_/api/dashboard/query-new`;
    deleteRequest(key);
  }

  //iterate through the array in reverse order (to avoid problems with changing the length of the array during deletion)
  for (let i = queryStackList.length - 1; i >= 0; i--) {
    if (queryStackList[i].filterSetId !== filterSetId) {
      queryStackList[i].clearLoading();
      queryStackList.splice(i, 1);
    }
  }

  if (queryStackList.length >= 1) {
    queryStackList.splice(1, 0, queryItem);
  } else {
    queryStackList.push(queryItem);
    startFetch();
  }
};

// --------
// Partners
// --------

const partnersShippers = (state = [], action) => {
  switch (action.type) {
    case 'PARTNERS__SHIPPERS__UPDATE':
      return action.payload;
    default:
      return state;
  }
};

const partnersPartnerInvoices = (state = [], action) => {
  switch (action.type) {
    case 'PARTNERS__PARTNER_INVOICES__UPDATE':
      return action.payload;
    default:
      return state;
  }
};

const partners = (state = {}, action) => {
  switch (action.type) {
    case 'PARTNERS__SHIPPERS__UPDATE':
      return Object.assign({}, state, {
        shippers: partnersShippers(state.shippers, action),
      });
    case 'PARTNERS__PARTNER_INVOICES__UPDATE':
      return Object.assign({}, state, {
        partnerInvoices: partnersPartnerInvoices(state.shippers, action),
      });
    default:
      return state;
  }
};

const articlesInitialState = {
  articlesList: null,
  articlesListFetching: false,
  unreadArticlesCount: null,
  unreadArticlesCountInitialized: false,
  articlesReactionsList: null,
};

const articles = (state = articlesInitialState, action) => {
  switch (action.type) {
    case 'SET_ARTICLES_LIST':
      return { ...state, articlesList: action.payload };
    case 'SET_ARTICLE_LIST_FETCHING':
      return { ...state, articlesListFetching: action.payload };
    case 'SET_UNREAD_ARTICLES_COUNT':
      return {
        ...state,
        unreadArticlesCount: action.payload,
        unreadArticlesCountInitialized: true,
      };
    case 'SET_ARTICLES_REACTIONS_LIST':
      return { ...state, articlesReactionsList: action.payload };
    case 'CHANGE_REACTION':
      if (state.articlesReactionsList && state.articlesReactionsList.length > 0) {
        const findReactions = state.articlesReactionsList.some(
          (item) => Object.keys(item)[0] == action.payload.id
        );
        if (!findReactions)
          return {
            ...state,
            articlesReactionsList: [
              ...state.articlesReactionsList,
              { [action.payload.id]: action.payload.reaction },
            ],
          };
        else {
          const newListReaction = state.articlesReactionsList.map((item) => {
            if (Object.keys(item)[0] == action.payload.id)
              return {
                [action.payload.id]: action.payload.reaction,
              };
            return item;
          });

          return { ...state, articlesReactionsList: newListReaction };
        }
      } else
        return {
          ...state,
          articlesReactionsList: [{ [action.payload.id]: action.payload.reaction }],
        };
    default:
      return state;
  }
};

const feedbackModalOpen = (state = false, action) => {
  switch (action.type) {
    case 'FEEDBACK_MODAL_OPEN':
      return action.payload;
    default:
      return state;
  }
};

const cloudwebViewsRestrictionsMap = (state = [], action) => {
  switch (action.type) {
    case 'CLOUD_WEB_VIEWS_RESTRICTIONS_MAP':
      return { ...state, value: action.payload };
    default:
      return state;
  }
};
// -------------------------------------------

export default combineReducers({
  burgerMenu: burgerMenuReducer,
  form: formReducer,
  _flashedMessages,

  // Pages
  dashboard,
  settingsShippingSetup,
  settingsTeam,
  settingsBilling,

  // Models
  partner,
  paymentMethods,
  reports,
  reportRuns,
  scheduledReports,
  shipper,
  shipments,
  shipperInvoices,
  shipperOptimizationInvoices,
  shippingLocations,
  glCodes,
  sidebar,
  user,
  users,
  vendorAccountNumberMappings,
  vendorInvoices,
  operationVendorInvoices,
  ShipmentDetails,
  vendorInvoiceDetails,
  operationVendorInvoiceDetails,
  operationVendorAccountNumberMappings,

  // Store for partner view.
  partners,

  // Contracts
  // auth: authReducer,
  // notifications: notificationsReducer,
  analysis: analysisReducer,
  contracts: contractsReducer,
  // contract: contractReducer,
  carriers: carriersReducer,
  trackShipments: trackShipmentsReducer,
  netRates: netRatesReducer,
  netRatesAnalysis: netRatesAnalysisReducer,

  // accessorials: accessorialsReducer,
  articles,
  Services,
  reportLocations,
  feedbackModalOpen,
  cloudwebViewsRestrictionsMap,
  multiplyTrackShipments: multiplyTrackShipmentsReducer,
  topoSlice: topoSliceReducer,
  carriersNetRates: carriersNetRatesReducer,
  carriersNetRatesAnalysis: carriersNetRatesAnalysisReducer,
});
