import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import styled from 'styled-components';
import { AddContractForm } from './AddContractForm';
import { ic_add } from 'react-icons-kit/md/ic_add';
import { ic_autorenew } from 'react-icons-kit/md/ic_autorenew';
import Icon from 'common/components/Icon';
import { Modal } from 'common/components/Modal';
import { getCarriersList } from '../../store/slices/CarriersSlice';
import { addContract } from '../../store/slices/ContractsSlice';
import Box from '@mui/material/Box';

const Content = styled.div`
  margin-top: 10px;
`;

const Title = styled(Typography)`
  padding-bottom: 20px;
`;

const ContractContainer = styled(Box)`
  position: relative;
  padding-top: 10px;
  cursor: pointer;
  transition: 0.15s ease-in-out;

  .contract-card-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const AddContractModal = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(true);
  const { carriers } = useSelector((state) => ({
    carriers: state.carriers.carriersList,
  }));

  // Open the modal
  const handleOpen = useCallback(() => {
    setSuccess(false);
    setOpen(true);
  }, [setOpen]);

  // Close the modal
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  // Add Contract submit callback
  const handleAddContract = useCallback(
    async ({ carrier_id, file, services }) => {
      await dispatch(addContract(carrier_id, file, services));

      // Display success message after contract has been added
      setSuccess(true);
      setTimeout(() => {
        setOpen(false);
      }, 5000);
    },
    [dispatch]
  );

  // Load list of carriers
  useEffect(() => {
    dispatch(getCarriersList());
  }, [dispatch]);

  return (
    <Fragment>
      <ContractContainer
        onClick={handleOpen}
        sx={{
          backgroundColor: 'var(--galleon-white) !important',
          borderRadius: '12px !important',
          border: '1px solid var(--galleon-white)',
          svg: {
            color: 'var(--galleon-neutral-8)',
          },

          '&:hover': {
            border: '1px solid var(--galleon-blue-dark-1)',
            svg: {
              color: 'var(--galleon-blue-dark-1)',
            },
          },
        }}
      >
        <CardContent className="contract-card-content">
          {open ? (
            <Icon icon={ic_autorenew} size={48} />
          ) : (
            <Icon icon={ic_add} size={48} />
          )}
        </CardContent>
      </ContractContainer>
      <Modal
        open={open}
        onClose={handleClose}
        title={success ? ' Thank you!' : 'Select which carrier issued the contract'}
        isDefaultCloseButton
      >
        {success ? (
          <Typography mb="20px" variant="subtitle1" align="center">
            We are preparing your contract for real time calculations. When everything is
            ready - you will receive an email from us!
          </Typography>
        ) : (
          <Content>
            <AddContractForm carriers={carriers} onSubmit={handleAddContract} />
          </Content>
        )}
      </Modal>
    </Fragment>
  );
};
