import React from 'react';

import './VendorInvoicesHeader.scss';

export const VendorInvoicesHeader = (props) => (
  <div className="VendorInvoicesHeader">
    <div className="VendorInvoicesHeaderCol">
      {props.leftCol ? props.leftCol.map((one) => one) : ''}
    </div>
    <div className="VendorInvoicesHeaderCol">
      {props.rightCol ? props.rightCol.map((one) => one) : ''}
    </div>
  </div>
);
