import './PendingImportPlaceholder.scss';

import React from 'react';
import { Link } from 'react-router-dom';

const PendingImportPlaceholder = (props) => {
  return (
    <Link
      className="empty-placeholder_new"
      variant="outlined"
      to="/shipping-setup/carrier-credentials"
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <span>
        <strong>Heads up!</strong> This tool isn't available yet because we haven't
        finished importing your shipping data. Please connect your carrier credentials and
        then check back shortly.
      </span>
    </Link>
  );
};

export default PendingImportPlaceholder;
