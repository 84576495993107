import React, { useCallback } from 'react';
import { ContractUpload } from '../ContractUpload';

export const ContractUploadFormik = ({
  field,
  fileFormat,
  form: { setFieldValue },
  ...otherProps
}) => {
  const handleUpload = useCallback(
    (file) => {
      setFieldValue(field.name, file);
    },
    [field.name, setFieldValue]
  );
  return (
    <ContractUpload
      value={field.value}
      onUpload={handleUpload}
      fileFormat={fileFormat}
      {...otherProps}
    />
  );
};
