import './ShipmentDetails.scss';

import React, { useMemo } from 'react';
import { useShipmentDetailsTableColumns } from './hooks/useShipmentDetailsTableColumns';
import { useShipmentDetailsTableRows } from './hooks/useShipmentDetailsTableRows';
import DataGridWrapper from 'common/components/table/DataGridWrapper';
import DataGridCustom from 'common/components/table/DataGridCustom';
import humps from 'humps';
import useColumnVisibility from './hooks/useColumnVisibility';
import Box from '@mui/material/Box';
import ColumnVisibilitySelect from './ColumnVisibilitySelect';
import Button from 'common/components/Button';
import Tooltip from '@mui/material/Tooltip';

const ShipmentDetailsTable = (props) => {
  const [selectionModel, setSelectionModel] = React.useState([]);

  const rows = useShipmentDetailsTableRows(props.shipments);
  const columns = useShipmentDetailsTableColumns(props.shipments);

  const { columnVisibility, handleColumnVisibilityChange } = useColumnVisibility(columns);

  const sortModel = [
    {
      field: humps.camelize(props.pagination.sortKey),
      sort: props.pagination.sortOrder,
    },
  ];

  const columnVisibilitySelectValue = useMemo(() => {
    return Object.keys(columnVisibility).filter((field) => columnVisibility[field]);
  }, [columnVisibility]);

  const onSortModelChange = (newSortModel) => {
    props.onSortChange(newSortModel, rows);
  };

  const onPaginationChange = (newData) => {
    props.onPageChange(newData);
    setSelectionModel([]);
  };

  return (
    <>
      <Box sx={{ display: 'flex', gap: '20px', width: '100%', alignItems: 'end' }}>
        <ColumnVisibilitySelect
          columns={columns}
          columnVisibility={columnVisibility}
          handleColumnVisibilityChange={handleColumnVisibilityChange}
          value={columnVisibilitySelectValue}
        />
        <Box className="reportButton">
          <Tooltip title="This report will be generated based on filters.">
            <Box>
              <Button
                onClick={props.onReportClick}
                disabled={props.reportClickDisabled}
                variant="contained"
              >
                Generate Report
              </Button>
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <Box className={'ShipmentDetails'}>
        <DataGridWrapper>
          <DataGridCustom
            rows={rows}
            columns={columns}
            sortModel={sortModel}
            slotProps={{
              toolbar: {
                csvOptions: {
                  fileName: 'Shipment Details',
                  getRowsToExport: () =>
                    rows.reduce((prevState, row) => {
                      if (selectionModel.includes(row.id)) {
                        prevState.push(row.id);
                      }
                      return prevState;
                    }, []),
                  _isDisabled: !selectionModel.length,
                },
              },
            }}
            onSortModelChange={onSortModelChange}
            settings={{
              cellClassName: 'ShipmentDetails',
              initialState: {
                pagination: {
                  paginationModel: { pageSize: props.pagination.sizePerPage },
                },
              },
              pageSizeOptions: [props.pagination.sizePerPage],
              hideFooter: false,
              onPaginationModelChange: onPaginationChange,
              paginationMode: 'server',
              loading: props.isloading,
              rowCount: props.pagination.total,
              density: 'comfortable',
              columnVisibilityModel: columnVisibility,
              rowSelection: true,
              checkboxSelection: true,
              rowSelectionModel: selectionModel,
              onRowSelectionModelChange: setSelectionModel,
            }}
          />
        </DataGridWrapper>
      </Box>
    </>
  );
};

export default ShipmentDetailsTable;
