import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCarrierNetRates } from '../../store/slices/CarrierNetRatesSlices';
import DataGridWrapper from 'common/components/table/DataGridWrapper';
import DataGridCustom from 'common/components/table/DataGridCustom';
import Box from '@mui/material/Box';
import { useCarrierNetRatesTableRows } from './hooks/useCarrierNetRatesTableRows';
import { useCarrierNetRatesTableColumns } from './hooks/useCarrierNetRatesTableColumns';
import Tips from "common/components/Tips/Tips";

export const CarrierNetRatesCardList = () => {
  const dispatch = useDispatch();
  const { netRatesList } = useSelector((state) => state.carriersNetRates);

  const rows = useCarrierNetRatesTableRows(netRatesList);
  const columns = useCarrierNetRatesTableColumns(dispatch);

  useEffect(() => {
    dispatch(getCarrierNetRates());
  }, []);

  return (
    <Box className="selectNetRatesWrapper">
      <Box sx={{ paddingBottom: '16px' }}>
        <Tips>
            Here you can compare the base net rates for different service types that have been officially
            published by Fedex and UPS carriers.
        </Tips>
      </Box>

      <DataGridWrapper
        customComponentHeight={450}
        style={{ minHeight: '450px', width: '100%' }}
      >
        <DataGridCustom rows={rows} columns={columns} />
      </DataGridWrapper>
    </Box>
  );
};
