'use strict';

import autobind from 'react-autobind';
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'common/components/Modal';

import InviteUserForm from './InviteUserForm';

class InviteUserModal extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      isSubmitting: false,
    };
  }

  render() {
    return (
      <Modal
        className="InviteUserModal"
        maxWidth="sm"
        open={this.props.show}
        onClose={this.hide}
        isDefaultCloseButton
        fullWidth={false}
        title="Invite user"
      >
        <InviteUserForm onSubmittingChange={this.handleSubmittingChange} />
      </Modal>
    );
  }

  hide() {
    // If we're currently adding a user's card information, then don't allow
    // closing the modal.
    if (this.state.isSubmitting) {
      return;
    }

    this.props.dispatch({
      type: 'SETTINGS_TEAM__INVITE_USER_MODAL__HIDE',
    });
  }

  /**
   * Saves the fact that this form is submitting.
   */
  handleSubmittingChange(isSubmitting) {
    this.setState({ isSubmitting });
  }
}

export default connect()(InviteUserModal);
