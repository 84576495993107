import * as ajax from 'common/helpers/ajax';
import { enqueueSnackbar } from 'notistack';
import { CANCEL_REQUEST_MESSAGE } from 'common/helpers/ajax';

export const initializeDashboardFilters = (successCb, errorCb) => {
  return (dispatch) => {
    return ajax.getJSON(
      '/api/dashboard/filters',
      null,
      (response) => {
        dispatch({
          type: 'DASHBOARD__FILTERS__INITIALIZE',
          payload: response,
        });

        return successCb && successCb(response);
      },
      (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        return errorCb && errorCb(error);
      }
    );
  };
};

export const getshippersList = (successCb, errorCb) => {
  return (dispatch) => {
    return ajax.getJSON(
      '/api/shippers-list',
      null,
      (response) => {
        dispatch({
          type: 'SHIPPERS_LIST_GET',
          payload: response,
        });

        return successCb && successCb(response);
      },
      (error) => {
        if (error.message !== CANCEL_REQUEST_MESSAGE) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
        return errorCb && errorCb(error);
      }
    );
  };
};

export const getReportTypes = (
  shipper_id,
  vendor_value,
  successCallback,
  errorCallback
) => {
  return (dispatch) => {
    return ajax.postJSON(
      '/api/rates-report/report-types',
      { shipper_id, vendor_value },
      (response) => {
        dispatch({
          type: 'REPORT_TYPES_LIST_GET',
          payload: response,
        });
        successCallback();
      },
      (error) => {
        errorCallback();
        if (error.message !== CANCEL_REQUEST_MESSAGE) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      false
    );
  };
};

export const fetchShipperVendors = (shipperId, loadingCallaback) => {
  loadingCallaback(true);
  return (dispatch) => {
    ajax.getJSON(
      `/api/shipper-vendors/${shipperId}`,
      (response) => {
        loadingCallaback(false);
        dispatch({
          type: 'SHIPPER_VENDOR_GET',
          payload: response,
        });
      },
      (error) => {
        loadingCallaback(false);
        if (error.message !== CANCEL_REQUEST_MESSAGE) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      }
    );
  };
};

export const getReportLocations = (locationType, data, successCb, errorCb) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_IS_PENDING_ORIG_DIST',
      payload: true,
    });
    return ajax.getJSON(
      `/api/report-locations/${locationType}`,
      data,
      (response) => {
        dispatch({
          type:
            locationType === 'origin'
              ? 'LOCATION_GET_ORIGIN'
              : 'LOCATION_GET_DESTINATION',
          payload: response,
        });
        dispatch({
          type: 'SET_IS_PENDING_ORIG_DIST',
          payload: false,
        });
        return successCb && successCb(response);
      },
      (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        return errorCb && errorCb(error);
      },
      false
    );
  };
};

export const getVendorAccountForShipper = (shipper_id, successCb, errorCb) => {
  return (dispatch) => {
    return ajax.getJSON(
      '/api/vendor-account-number-mappings',
      { shipper_id },
      (response) => {
        dispatch({
          type: 'VENDOR_ACCOUNT_GET',
          payload: response,
        });

        return successCb && successCb(response);
      },
      (error) => {
        dispatch({
          type: 'VENDOR_ACCOUNT_GET',
          payload: [],
        });
        enqueueSnackbar(error.message, { variant: 'error' });
        return errorCb && errorCb(error);
      }
    );
  };
};

export const fetchOperationVendorAccountNumberMappings = (
  shipper_id,
  successCb,
  errorCb
) => {
  return (dispatch) => {
    return ajax.getJSON(
      `/api/operation-vendor-account-number-mappings`,
      { shipper_id },
      (response) => {
        dispatch({
          type: 'OPERATION_VENDOR_ACCOUNT_NUMBER_MAPPINGS__UPDATE',
          payload: response,
        });

        return successCb && successCb(response);
      },
      (response) => {
        return errorCb && errorCb(response);
      }
    );
  };
};

export const fetchReports = (successCb, errorCb) => {
  return (dispatch) => {
    return ajax.getJSON(
      '/api/reports',
      null,
      (response) => {
        dispatch({
          type: 'REPORTS__UPDATE',
          payload: response,
        });
        return successCb && successCb(response);
      },
      (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        return errorCb && errorCb(error);
      }
    );
  };
};

export const fetchReportRuns = (successCb, errorCb) => {
  return (dispatch) => {
    return ajax.getJSON(
      '/api/report-runs',
      null,
      (response) => {
        dispatch({
          type: 'REPORT_RUNS__GET_ALL',
          payload: response,
        });

        return successCb && successCb(response);
      },
      (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        return errorCb && errorCb(error);
      }
    );
  };
};

export const fetchScheduledReports = (successCb, errorCb) => {
  return (dispatch) => {
    return ajax.getJSON(
      '/api/scheduled-reports',
      null,
      (response) => {
        dispatch({
          type: 'SCHEDULED_REPORTS__UPDATE',
          payload: response,
        });

        return successCb && successCb(response);
      },
      (response) => {
        return errorCb && errorCb(response);
      }
    );
  };
};

export const fetchShipments = (data, successCb, errorCb) => {
  return (dispatch) => {
    return ajax.getJSON(
      '/api/shipments',
      data,
      (response) => {
        dispatch({
          type: 'SHIPMENTS__UPDATE',
          payload: response,
        });

        return successCb && successCb(response);
      },
      (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        return errorCb && errorCb(error);
      }
    );
  };
};

export const fetchShippingLocations = (successCb, errorCb) => {
  return (dispatch) => {
    return ajax.getJSON(
      '/api/shipping-locations',
      (response) => {
        dispatch({
          type: 'SHIPPING_LOCATIONS__UPDATE',
          payload: response,
        });

        return successCb && successCb(response);
      },
      (response) => {
        return errorCb && errorCb(response);
      }
    );
  };
};

/**
 * Fetches the users on the same team as the current user.
 */
export const fetchUsers = (successCb, errorCb) => {
  return (dispatch) => {
    return ajax.getJSON(
      '/api/users',
      null,
      (response) => {
        dispatch({
          type: 'USERS__UPDATE',
          payload: response,
        });

        return successCb && successCb(response);
      },
      (response) => {
        return errorCb && errorCb(response);
      }
    );
  };
};

/**
 * GL Codes
 */
export const fetchGlCodes = (cb = null) => {
  return (dispatch) => {
    return ajax.getJSON(
      '/api/gl-rules',
      null,
      (response) => {
        dispatch({ type: 'GL_CODES__DATA__UPDATE', payload: response });
        return cb && cb(response);
      },
      (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        return cb && cb(error);
      },
      false
    );
  };
};

export const addGlCode = (data, cb = null) => {
  return (dispatch) => {
    return ajax.postJSON(
      '/api/gl-rules',
      data,
      (response) => {
        dispatch(fetchGlCodes());
        dispatch({ type: 'GL_CODES__MODAL__HIDE' });

        enqueueSnackbar('Success! Your GL code has been saved.', { variant: 'success' });

        return cb && cb(response);
      },
      (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        return cb && cb(error);
      },
      false
    );
  };
};

export const editGlCode = (data, cb = null) => {
  var { uuid, ...rest } = data;
  return (dispatch) => {
    return ajax.putJSON(
      '/api/gl-rules/' + uuid,
      rest,
      (response) => {
        dispatch(fetchGlCodes());
        dispatch({ type: 'GL_CODES__MODAL__HIDE' });

        enqueueSnackbar('Success! Your GL code has been saved.', { variant: 'success' });

        return cb && cb(response);
      },
      (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        return cb && cb(error);
      },
      false
    );
  };
};

export const deleteGlCode = (id, cb = null) => {
  return (dispatch) => {
    return ajax.deleteJSON(
      '/api/gl-rules/' + id,
      null,
      (response) => {
        dispatch(fetchGlCodes());

        enqueueSnackbar('This GL code has been removed', { variant: 'success' });

        return cb && cb(response);
      },
      (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        return cb && cb(error);
      },
      false
    );
  };
};

//reports run

export const createReport = (data, successCb, errorCb) => {
  return (dispatch) => {
    return ajax.postJSON(
      '/api/report-runs',
      data,
      (response) => {
        dispatch({
          type: 'CREATE_REPORT',
          payload: response,
        });

        return successCb && successCb(response);
      },
      (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        return errorCb && errorCb(error);
      }
    );
  };
};

export const getBillingEntriesCanNames = (shipperId, startDate, endDate, vendor) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_IS_WAIT',
      payload: true,
    });
    return ajax.getJSON(
      '/api/report-billing-entries',
      { shipperId, startDate, endDate, vendor },
      (response) => {
        dispatch({
          type: 'BILLING_ENTRY_CAN_NAMES',
          payload: response,
        });
        dispatch({
          type: 'SET_IS_WAIT',
          payload: false,
        });
      },
      (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    );
  };
};
