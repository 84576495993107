import _sum from 'lodash/sum';
import classNames from 'classnames';
import React from 'react';

import * as utils from 'common/helpers/utils';

import Chart from './PieChartViewModel';
import * as Constants from './Constants';
import { LABELS_BOX_WIDTH, MAX_DATASETS_LENGTH_TO_SHOW } from './Constants';

class PieChart extends React.Component {
  render() {
    if (!this.props.data) {
      return null;
    }

    return (
      <Chart
        title={this.props.title}
        helpText={this.props.helpText}
        chartConfig={this.chartConfig}
        className={classNames(this.props.className, 'PieChart')}
        hideLegend={
          this.props.hideLegend ||
          this.props.data.labels.length > MAX_DATASETS_LENGTH_TO_SHOW
        }
        height={this.props.height}
        shipperSubscriptions={this.props.shipperSubscriptions}
      />
    );
  }

  get chartConfig() {
    const colorPalette = Constants.getColorPallete(this.props.data.labels.length);

    return {
      type: 'pie',
      data: {
        labels: this.props.data.labels,
        datasets: this.props.data.datasets.map((dataset, i) => {
          return Object.assign(
            {
              // For the pie chart, each dataset needs to have an *array* of
              // colors. This is different from other charts, which want a single
              // color per dataset.
              backgroundColor: colorPalette.map((c) => {
                return utils.hexToRGBA(c, 0.9);
              }),
              // Custom field for react select picker
              selectBackgroundColor: colorPalette[i],
              borderColor: '#fff',
              hoverBackgroundColor: colorPalette.map((c) => {
                return utils.hexToRGBA(c, 0.9);
              }),
              borderWidth: 2,
            },
            dataset
          );
        }),
      },
      options: {
        cutout: '50%',
        responsive: true,
        layout: {
          padding: {
            bottom: 16,
          },
        },
        plugins: {
          tooltip: {
            backgroundColor: Constants.TOOLTIP_BACKGROUND_COLOR,
            callbacks: {
              label: (context) => {
                const datasetValues = context.dataset.data;
                const itemLabel = context.label;
                let itemValue = context.raw;
                let itemPerc = itemValue / _sum(datasetValues);
                if (this.props.itemTooltipsValueFmt) {
                  itemValue = this.props.itemTooltipsValueFmt(itemValue);
                } else {
                  itemValue = utils.Fmt.int(itemValue);
                }

                itemPerc = utils.Fmt.percFloat1(itemPerc);

                return `${itemLabel}: ${itemValue} (${itemPerc})`;
              },
            },
          },
          legend: {
            // display: true,
            labels: {
              boxWidth: LABELS_BOX_WIDTH,
            },
            display: false,
          },
        },
      },
    };
  }
}

export default PieChart;
