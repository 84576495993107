import { useMemo } from 'react';

export const useVendorInvoicesTableRows = (vendorInvoices) =>
  useMemo(
    () =>
      vendorInvoices?.reduce((prevState, currentValue) => {
        const currentRow = {
          id: currentValue.hid,
          vendor: currentValue.vendor,
          vendorAccountNumber: currentValue.vendorAccountNumber,
          invoiceNumber: currentValue.invoiceNumber,
          invoiceDate: currentValue.invoiceDate,
          invoiceDueDate: currentValue.invoiceDueDate,
          originalAmount: currentValue.originalAmount,
          finalAmount: currentValue.finalAmount,
          numShipments: currentValue.numShipments,
          vendorPaymentStatus: currentValue.vendorPaymentStatus,
          currentValue,
        };
        prevState.push(currentRow);
        return prevState;
      }, []),
    [vendorInvoices]
  );
