import classNames from 'classnames';
import React from 'react';
import autobind from 'react-autobind';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

class NavItem extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      isSubmenuOpen: props.isSubmenuOpen,
    };
  }

  render() {
    var navLink = null,
      navMenu = null;

    React.Children.toArray(this.props.children).forEach((c) => {
      if (c.type === NavLink) {
        navLink = React.cloneElement(c, {
          onToggleSubmenu: this.handleToggleSubmenu,
        });
      } else if (c.type === NavMenu) {
        navMenu = React.cloneElement(c, {
          isOpen: this.state.isSubmenuOpen,
        });
      }
    });

    var classes = classNames('NavItem', this.props.className, {
      hasSubmenu: navMenu !== null,
      isSubmenuOpen: this.state.isSubmenuOpen,
    });

    return (
      <div className={classes}>
        {navLink}
        {navMenu}
      </div>
    );
  }

  handleToggleSubmenu(e) {
    e.preventDefault();
    this.setState({
      isSubmenuOpen: !this.state.isSubmenuOpen,
    });
  }
}

class NavLink extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  render() {
    // If an icon wasn't defined, then include a spacer. instead.
    var icon = this.props.icon ? (
      <Box className="NavLinkIcon">{this.props.icon}</Box>
    ) : (
      <div className="NavLinkIcon"></div>
    );

    var classes = classNames(
      'NavLink',
      {
        selected: this.props.isSelected,
        disabled: this.props.isDisabled,
      },
      this.props.className
    );

    if (this.props.isDisabled) {
      return (
        <Tooltip title={`Contact us to upgrade plan`} placement="right">
          <span className={classes} onClick={(e) => e.preventDefault()}>
            {icon}
            {this.props.children}
          </span>
        </Tooltip>
      );
    } else if (this.props.forcePageLoad) {
      return (
        <a className={classes} href={this.props.href}>
          {icon}
          {this.props.children}
        </a>
      );
    } else if (this.props.href) {
      return (
        <Link className={classes} to={this.props.href}>
          {icon}
          {this.props.children}
        </Link>
      );
    } else {
      return (
        <a className={classes} onClick={this.props.onToggleSubmenu}>
          {icon}
          {this.props.children}
        </a>
      );
    }
  }
}

class NavMenu extends React.Component {
  /**
   * Update the menu height after the initial render. Need to do this in
   * `componentDidMount` because the component needs to have rendered in order
   * to determine the appropriate height.
   */
  componentDidMount() {
    this.toggleOpen(this.props.isOpen);
  }

  /**
   * Update the menu height whenever the prop's `isOpen` parameter changes.
   */
  componentWillUpdate(nextProps, nextState) {
    this.toggleOpen(nextProps.isOpen);
  }

  render() {
    return <div className="NavMenu">{this.props.children}</div>;
  }

  /**
   * Toggles whether the menu should be open or closed. We do this by setting
   * the `max-height` CSS attribute based on the element's `scrollHeight`, which
   * should always reflect the element's actual height.
   */
  toggleOpen(isOpen) {
    // Trying to toggle open / close the root menu doesn't make sense since the
    // root menu can't be closed.
    if (!this.props.isSubmenu) {
      return;
    }

    const el = ReactDOM.findDOMNode(this);
    if (el) {
      el.style.maxHeight = isOpen ? el.scrollHeight + 'px' : '0';
    }
  }
}

/**
 * Returns true if this menu should be opened because one of its children is
 * selected.
 */
const shouldMenuBeOpened = (children, selectedHref) => {
  return children.some((c) => {
    if (c.children) {
      return shouldMenuBeOpened(c.children, selectedHref);
    } else {
      return c.href.includes(selectedHref);
    }
  });
};

/**
 * Constructs a nav menu from the given configuration and selected href.
 */
const buildNavMenu = (
  navMenuConfig,
  selectedHref,
  shouldNavItemBeVisible,
  getNavItemHasCustomClass,
  shouldNavItemBeDisabled,
  isSubmenu
) => {
  return (
    <NavMenu isSubmenu={isSubmenu}>
      {navMenuConfig.map((c) =>
        buildNavItem(
          c,
          selectedHref,
          shouldNavItemBeVisible,
          getNavItemHasCustomClass,
          shouldNavItemBeDisabled
        )
      )}
    </NavMenu>
  );
};

/**
 * Constructs a nav item from the given configuration and selected href.
 */
const buildNavItem = (
  navItemConfig,
  selectedHref,
  shouldNavItemBeVisible,
  getNavItemHasCustomClass,
  shouldNavItemBeDisabled
) => {
  const customClass = getNavItemHasCustomClass(navItemConfig);

  if (!shouldNavItemBeVisible(navItemConfig)) {
    return null;
  }

  // Construct a nav menu if the nav item config has any children.
  const navMenu = navItemConfig.children
    ? buildNavMenu(
        navItemConfig.children,
        selectedHref,
        shouldNavItemBeVisible,
        getNavItemHasCustomClass,
        shouldNavItemBeDisabled,
        true
      )
    : null;

  // If a nav menu exists, we also need to determine whether we should open it.
  const isSubmenuOpen = navMenu
    ? shouldMenuBeOpened(navItemConfig.children, selectedHref)
    : null;

  const checkPath = (hrefList, selectedHref) => {
    if (!Array.isArray(hrefList)) {
      return false;
    }
    return !!hrefList.find((href) => selectedHref.startsWith(href));
  };

  return (
    <NavItem
      key={navItemConfig.label}
      isSubmenuOpen={isSubmenuOpen}
      className={navItemConfig.className}
    >
      <NavLink
        href={navItemConfig.href?.[0]}
        icon={navItemConfig.icon}
        isSelected={checkPath(navItemConfig.href, selectedHref)}
        isDisabled={shouldNavItemBeDisabled(navItemConfig)}
        forcePageLoad={navItemConfig.forcePageLoad}
        className={customClass}
      >
        {navItemConfig.label}
      </NavLink>
      {navMenu}
    </NavItem>
  );
};

export { NavItem, NavLink, NavMenu, buildNavMenu, buildNavItem };
