import React, { forwardRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Container = styled.div`
  display: flex;

  h3 {
    margin: 0 !important;
  }
`;

const Navigation = styled.div`
  margin: 0 10px;
`;

export const Heading = forwardRef(({ title, navigation, children }, ref) => {
  return (
    <Wrapper ref={ref}>
      <Container>
        <h3>{title}</h3>
        {navigation && <Navigation>{navigation}</Navigation>}
      </Container>

      {children}
    </Wrapper>
  );
});
