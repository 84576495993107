import React from 'react';
import { ic_info } from 'react-icons-kit/md/ic_info';
import Icon from '../Icon';
import './Tips.scss';

const Tips = ({ children, className = '' }) => {
  return (
    <aside className={`MuiCallout-root ${className}`}>
      <Icon icon={ic_info} size={19} style={{ cursor: 'auto' }} />
      <div>{children}</div>
    </aside>
  );
};

export default Tips;
