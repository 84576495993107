import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddNetRatesModal } from './AddNetRatesModal';
import { DownloadTemplateModal } from './DownloadTemplateModal';
import Tips from 'common/components/Tips/Tips';
import Grid from '@mui/material/Unstable_Grid2';
import { useNetRatesTableRows } from './hooks/useNetRatesTableRows';
import DataGridWrapper from 'common/components/table/DataGridWrapper';
import DataGridCustom from 'common/components/table/DataGridCustom';
import { useNetRatesTableColumns } from './hooks/useNetRatesTableColumns';
import Box from '@mui/material/Box';

export const NetRatesCardList = () => {
  const dispatch = useDispatch();
  const { netRatesList } = useSelector((state) => state.netRates);

  const rows = useNetRatesTableRows(netRatesList);
  const columns = useNetRatesTableColumns(dispatch);

  return (
    <Box className="selectNetRatesWrapper">
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Tips>
              Following our manual, you can upload your net rates and make a comparison
              analysis.
              <ul>
                <li>
                  <p
                    style={{
                      marginBlockStart: 0,
                      marginTop: '10px',
                    }}
                  >
                    <a
                      style={{ cursor: 'pointer', fontWeight: '14px', color: '#1175db' }}
                      href="https://docs.google.com/document/d/1jMvIpv_LPh1CYum-KkH6QGwtf--ewA3AShsctmDqmdY/edit?usp=sharing"
                      target="_blank"
                    >
                      Net Rates Analysis Manual
                    </a>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBlockEnd: 0,
                    }}
                  >
                    <DownloadTemplateModal />
                  </p>
                </li>
              </ul>
            </Tips>
            <AddNetRatesModal />
          </Box>
        </Grid>

        <Grid xs={12}>
          <DataGridWrapper
            customComponentHeight={350}
            style={{ minHeight: '350px', width: '100%' }}
          >
            <DataGridCustom rows={rows} columns={columns} />
          </DataGridWrapper>
        </Grid>
      </Grid>
    </Box>
  );
};
