'use strict';

import './ShipmentDetails.scss';

import autobind from 'react-autobind';
import humps from 'humps';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { action as toggleFilterSidebar } from 'redux-burger-menu';
import { enqueueSnackbar } from 'notistack';

import * as ajax from 'common/helpers/ajax';
import Icon from 'common/components/Icon';
import PageContainer from '../containers/PageContainer';
import ShipmentDetailsTable from './ShipmentDetailsTable';
import Button from '@mui/material/Button';
import { ic_filter_alt_outline } from 'react-icons-kit/md/ic_filter_alt_outline';

class ShipmentDetails extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.table = null;

    this.state = {
      // We need to mirror a copy of the filterSetId so we know when we should
      // allow updating the latest value.
      filterSetId: -1,
      showModal: false,
      isFetching: false,
      isServicesFetching: false,
      isTableFetching: false,
      isReportFetching: false,
    };
  }

  /**
   * Load current `filterSetId` from the redux store here.
   */
  componentWillMount() {
    this.setState({
      filterSetId: this.props.filters.filterSetId,
    });
  }

  /**
   * After the component mounts, we should load the initial set of vendor
   * invoices.
   */
  setFetchingData(value) {
    this.setState({
      isFetching: value,
      isTableFetching: value,
    });
  }

  setServicesFetching(value) {
    this.setState({
      isServicesFetching: value,
    });
  }

  setFetchingTableData(value) {
    this.setState({
      isTableFetching: value,
    });
  }

  componentDidMount() {
    this.props.dispatch(
      this.fetchShipments(this.props.pagination, this.props.filters, this.setFetchingData)
    );

    this.props.dispatch(this.fetchServices(this.props.filters, this.setServicesFetching));

    if (this.props.vendorInvoiceDetailsError) {
      enqueueSnackbar(this.props.vendorInvoiceDetailsError, { variant: 'error' });
      this.props.dispatch({
        type: 'VENDOR_INVOICE_DETAILS_ERROR',
        payload: '',
      });
    }
  }

  /**
   * If the component's next props increment the `filterSetId`, then initiate a
   * fetch.
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.filters.filterSetId > this.state.filterSetId) {
      this.onFiltersChange(nextProps.filters);
    }
  }

  render() {
    const rightIcons = [
      <Button
        onClick={this.toggleFilterSidebar}
        size="small"
        variant="contained"
        color="primary"
        className="RightIcons"
        aria-label="settings"
        sx={{ width: '42px', height: '42px', borderRadius: '16px' }}
      >
        <Icon
          className="FilterSidebarIcon"
          key="FilterSidebarIcon"
          icon={ic_filter_alt_outline}
          size={24}
        />
      </Button>,
    ];

    return (
      <PageContainer
        title="Shipment Details"
        contentClassName="ShipmentDetails"
        rightIcons={rightIcons}
      >
        <Fragment>
          <ShipmentDetailsTable
            shipments={this.props.shipments || []}
            pagination={this.props.pagination}
            onPageChange={this.onPageChange}
            onSortChange={this.onSortChange}
            isloading={this.state.isTableFetching || this.state.isFetching}
            onReportClick={this.onReportClick}
            reportClickDisabled={this.state.isReportFetching}
          />
        </Fragment>
      </PageContainer>
    );
  }

  toggleFilterSidebar() {
    this.props.dispatch(toggleFilterSidebar(true, 'ShipmentDetails.FilterSidebar'));
  }

  onPageChange({ page, pageSize }) {
    this.props.dispatch(
      this.fetchShipments(
        { ...this.props.pagination, page: page + 1, sizePerPage: pageSize },
        this.props.filters,
        this.setFetchingTableData
      )
    );
  }
  onReportClick() {
    this.createReport(this.props.pagination, this.props.filters);
  }

  onSortChange(sortModel, rows) {
    const validSortModel = sortModel.filter((sortItem) =>
      Object.keys(rows[0]).includes(humps.camelize(sortItem.field))
    );

    if (validSortModel.length === sortModel.length && sortModel.length > 0) {
      this.props.dispatch(
        this.fetchShipments(
          {
            ...this.props.pagination,
            page: 1,
            sortKey: humps.decamelize(
              sortModel[0]?.field || this.props.pagination.sortKey
            ),
            sortOrder: sortModel[0]?.sort || this.props.pagination.sortOrder,
          },
          this.props.filters,
          this.setFetchingTableData
        )
      );
    }
  }

  onFiltersChange(filters) {
    this.props.dispatch(this.fetchServices(filters, this.setServicesFetching));
    this.props.dispatch(
      this.fetchShipments(
        { ...this.props.pagination, page: 1 },
        filters,
        this.setFetchingData
      )
    );
  }

  fetchShipments(pagination, filters, setFetchingData) {
    if (setFetchingData) setFetchingData(true);
    return (dispatch) => {
      this.setState({
        filterSetId: filters.filterSetId,
      });

      dispatch({
        type: 'SHIPMENT_DETAILS__UPDATE__LOADING',
        payload: true,
      });

      // HACK: The sort key is stored in camelCase. Need to decamelize it before
      // we send it back to the server.
      if (pagination.sortKey) {
        pagination.sortKey = humps.decamelize(pagination.sortKey);
      }

      return ajax.postJSON(
        '/api/shipment-details',
        {
          pagination: pagination,
          filters: filters,
        },
        (response) => {
          // If the current state's `filterSetId` doesn't match the one in the
          // response, that means another request was sent after this one. As a
          // result, we should ignore this response and continue waiting for the
          // response with the matching `filterSetId`.
          if (response.filters.filterSetId !== this.state.filterSetId) {
            dispatch({
              type: 'SHIPMENT_DETAILS__UPDATE__LOADING',
              payload: false,
            });
            return;
          }

          dispatch({
            type: 'SHIPMENT_DETAILS__UPDATE',
            payload: response,
          });

          dispatch({
            type: 'SHIPMENT_DETAILS__UPDATE__LOADING',
            payload: false,
          });
          if (setFetchingData) setFetchingData(false);
        },
        (response) => {
          enqueueSnackbar(response.message, { variant: 'error' });
          if (setFetchingData) setFetchingData(false);

          dispatch({
            type: 'SHIPMENT_DETAILS__UPDATE__LOADING',
            payload: false,
          });
        }
      );
    };
  }

  fetchServices(filters, setFetchingData) {
    this.props.dispatch({
      type: 'SHIPMENT_DETAILS__SERVICES',
      payload: { services: [], billingEntries: [] },
    });
    if (setFetchingData) setFetchingData(true);
    return (dispatch) => {
      return ajax.postJSON(
        '/api/shipment-details/services',
        {
          filters: filters,
        },
        (response) => {
          dispatch({
            type: 'SHIPMENT_DETAILS__SERVICES',
            payload: response || { services: [], billingEntries: [] },
          });
          if (setFetchingData) setFetchingData(false);
        },
        (response) => {
          enqueueSnackbar(response.message, { variant: 'error' });
          if (setFetchingData) setFetchingData(false);
        }
      );
    };
  }

  createReport(pagination, filters) {
    if (pagination.sortKey) {
      pagination.sortKey = humps.decamelize(pagination.sortKey);
    }

    this.setState({
      isReportFetching: true,
    });

    ajax.postJSON(
      '/api/shipment-details/report',
      {
        pagination: pagination,
        filters: filters,
      },

      (response) => {
        this.setState({
          isReportFetching: false,
        });
        enqueueSnackbar(
          'Your report has been generated using Report Run and ' +
            'you can find it on the Recent Reports Page.',
          { variant: 'success' }
        );
      },
      (response) => {
        this.setState({
          isReportFetching: false,
        });
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shipments: state.ShipmentDetails.shipments,
    pagination: state.ShipmentDetails.pagination,
    filters: state.ShipmentDetails.filters,
    errors: state.ShipmentDetails.errors,
    services: state.ShipmentDetails.services,
  };
};

export default connect(mapStateToProps)(ShipmentDetails);
