import React from 'react';
import autobind from 'react-autobind';
import { connect } from 'react-redux';
import { Modal } from 'common/components/Modal';
import { SpinnerContainer } from 'common/components/Spinner';
import AddGLCodeForm from './AddGLCodeForm';
import * as ajax from 'common/helpers/ajax';
import './GLCodes.scss';

class AddGLCodeModal extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      isSubmitting: false,
      isLoading: true,
      template: {
        additional_codes: [],
        code_name: null,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps?.show && !this.props.show) {
      if (!nextProps.glCodes.selectedGlCode.code) {
        this.getGlRulesTemplate();
      } else {
        this.setState({ isLoading: false });
      }
    }
  }

  render() {
    return (
      <Modal
        className="AddGLCodeModal"
        maxWidth="lg"
        open={this.props.show}
        onClose={this.props.onHide}
        fullWidth={false}
        isDefaultCloseButton
        title="GL coding rule"
        contentClassName={'AddGLCodeModal_content'}
      >
        {this.state.isLoading ? (
          <SpinnerContainer isVisible message="Working..." />
        ) : (
          <AddGLCodeForm
            onSubmittingChange={this.handleSubmittingChange}
            onSubmit={this.props.onSubmit}
            template={this.state.template}
          />
        )}
      </Modal>
    );
  }

  hide() {
    if (this.state.isSubmitting) {
      return;
    }

    this.props.dispatch({
      type: 'GL_CODES__MODAL__HIDE',
    });
  }

  handleSubmittingChange(isSubmitting) {
    this.setState({ isSubmitting });
  }

  getGlRulesTemplate() {
    this.setState({ isLoading: true });
    ajax.getJSON(
      '/api/gl-rules/template',
      {},
      (response) => {
        this.setState({
          template: {
            additional_codes: response.additional_code_names,
            code_name: response.code_name,
          },
          isLoading: false,
        });
      },
      (response) => {
        this.setState({ isLoading: false });
      },
      false
    );
  }
}

const mapStateToProps = (state) => {
  return {
    glCodes: state.glCodes,
  };
};

export default connect(mapStateToProps)(AddGLCodeModal);
