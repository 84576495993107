import React from 'react';
import { Stack } from '@mui/material';

/**
 * Displays an error message.
 */
const ErrorContainer = (props) => {
  return (
    <Stack spacing={4} alignItems="center">
      <div className="ErrorContainer alert alert-danger">
        <strong>Well that's embarrassing...</strong> It looks like there was an error
        fetching this data. Please try again later.
      </div>
      {props.button}
    </Stack>
  );
};

export default ErrorContainer;
