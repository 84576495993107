import React, { useMemo, useState } from 'react';
import '../Chart.scss';
import Box from '@mui/material/Box';
import ReactSelect from 'react-select-plus';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Button from '@mui/material/Button';
import ArrowTriangle from '../../icon-components/ArrowTriangle';
import ChartTagLegend from './ChartTagLegend';

const ChartDatasetsSelect = (props) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const hiddenDatasets = useMemo(() => {
    return props.hiddenDatasets.map((dataset) => ({
      label: dataset.label,
      value: dataset,
    }));
  }, [props.hiddenDatasets]);

  const onChangeReactSelect = (value) => {
    props.setSelectedDatasets(value);
    props.addDataToChart(value);
  };

  const onRemoveDataSet = (removedDatasetValue) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    onChangeReactSelect(
      props.selectedDatasets.filter(
        (selectedDataset) => selectedDataset.value !== removedDatasetValue
      )
    );
  };

  const onHandleClickAway = () => {
    if (isSelectOpen) {
      setIsSelectOpen(false);
    }
  };

  const onOpenSelect = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSelectOpen(true);
  };

  const onClearAll = (event) => {
    onOpenSelect(event);
    onChangeReactSelect([]);
  };

  return (
    <Box
      className="ChartDatasetsSelect-container legendContainer"
      onClick={onOpenSelect}
      sx={{
        background: isSelectOpen ? '#F5F8FD' : null,
      }}
    >
      <Box className="tags-control-wrapper">
        <ChartTagLegend
          selectedDatasets={props.selectedDatasets}
          onRemoveDataSet={onRemoveDataSet}
        />
        {/*<Box className="tags-wrapper">*/}

        {/*{props.selectedDatasets.map((dataset) => (*/}
        {/*  <Chip*/}
        {/*    onClick={(e) => e.stopPropagation()}*/}
        {/*    key={dataset.label + dataset.value.value}*/}
        {/*    className="tag"*/}
        {/*    avatar={*/}
        {/*      <Box*/}
        {/*        className="tag_color"*/}
        {/*        sx={{ background: dataset.value.selectBackgroundColor }}*/}
        {/*      />*/}
        {/*    }*/}
        {/*    label={dataset.label}*/}
        {/*    deleteIcon={<Icon icon={ic_clear} className="tag_clear" />}*/}
        {/*    onDelete={onRemoveDataSet(dataset.value)}*/}
        {/*  />*/}
        {/*))}*/}
        {/*</Box>*/}
        <Box className="tags-control">
          <Button
            variant="text"
            className="clearAll-button"
            onClick={onClearAll}
            size="small"
          >
            Clear All
          </Button>

          <Box
            sx={{
              transform: isSelectOpen
                ? 'rotate(180deg)'
                : 'rotate(0deg) translateY(-2px)',
            }}
          >
            <ArrowTriangle />
          </Box>
        </Box>
      </Box>

      <Box
        className="chart-select"
        sx={{
          display: isSelectOpen ? 'block' : 'none',
        }}
      >
        {isSelectOpen && (
          <ClickAwayListener onClickAway={onHandleClickAway}>
            <ReactSelect
              // value={props.selectedDatasets}
              value={props.selectedDatasets}
              options={hiddenDatasets}
              isOpen={isSelectOpen}
              // onOpen={() => setIsSelectOpen(true)}
              onClose={() => setIsSelectOpen(false)}
              autoFocus={true}
              closeOnSelect={false}
              clearable={false}
              multi={true}
              placeholder="Type shipper name"
              onChange={onChangeReactSelect}
              // valueComponent={(props) => (
              //   <div className="Select-value" title={props.value.label}>
              //     {/*<span*/}
              //     {/*  onClick={() => props.onRemove(props.value)}*/}
              //     {/*  className="Select-value-icon"*/}
              //     {/*  aria-hidden="true"*/}
              //     {/*>*/}
              //     {/*  ×*/}
              //     {/*</span>*/}
              //     {/*<Box*/}
              //     {/*  component="span"*/}
              //     {/*  className="Select-value-label"*/}
              //     {/*  style={{ display: 'inline-flex', alignItems: 'center' }}*/}
              //     {/*>*/}
              //     {/*  <Box*/}
              //     {/*    className="Select-value-color"*/}
              //     {/*    sx={{ backgroundColor: props.value.value.selectBackgroundColor }}*/}
              //     {/*  ></Box>*/}
              //     {/*  {props.children}*/}
              //     {/*</Box>*/}
              //   </div>
              // )}
              valueComponent={() => {}}
            />
          </ClickAwayListener>
        )}
      </Box>
    </Box>
  );
};

export default ChartDatasetsSelect;
