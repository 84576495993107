'use strict';

import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { enqueueSnackbar } from 'notistack';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { ic_edit } from 'react-icons-kit/md/ic_edit';
import { ic_keyboard_arrow_up } from 'react-icons-kit/md/ic_keyboard_arrow_up';
import { ic_keyboard_arrow_down } from 'react-icons-kit/md/ic_keyboard_arrow_down';
import * as ajax from 'common/helpers/ajax';
import * as utils from 'common/helpers/utils';
import { Button } from '@mui/material';
import Icon from 'common/components/Icon';
import TooltipIcon from 'common/components/TooltipIcon';
import * as actions from '../store/actions';
import TableHeaderStack from 'common/components/table/TableHeaderStack';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import { Collapse } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Tips from '../../common/components/Tips/Tips';
import Reload from '../../common/components/icon-components/Reload';

const HEAD_CELLS = [
  { label: '', value: 'expand', align: 'center' },
  { label: 'Report type', value: 'reportDispName', align: 'left' },
  { label: 'Date range', value: 'dateRange', align: 'center' },
  { label: 'Frequency', value: 'schedFrequency', align: 'center' },
  { label: '', value: 'status', align: 'center' },
];

const RowExpand = ({ scheduledReport, actionsFormatter }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment key={scheduledReport.uuid}>
      <TableRow
        sx={{
          backgroundColor: '#FFFFFF',
          borderSpacing: 0,
          borderCollapse: 'separate',
          borderRadius: '16px',
          border: '1px',
          overflow: 'hidden',
          '&:last-child td, &:last-child th': { border: 0 },
          height: '52px',
          '& > *': { borderBottom: 'unset' },
        }}
      >
        <TableCell width={20}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <Icon icon={ic_keyboard_arrow_up} />
            ) : (
              <Icon icon={ic_keyboard_arrow_down} />
            )}
          </IconButton>
        </TableCell>
        <TableCell align="left" width={300} component="th" scope="row">
          {scheduledReport.reportDispName}
        </TableCell>
        <TableCell align="center" width={150}>
          {dateRangeFormatter(scheduledReport)}
        </TableCell>
        <TableCell align="center" width={150}>
          {schedFrequencyFormatter(scheduledReport)}
        </TableCell>
        <TableCell align="right" width={60}>
          {actionsFormatter(scheduledReport)}
        </TableCell>
      </TableRow>
      <TableRow sx={{ ...(open ? { backgroundColor: '#e0efff' } : {}) }}>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '12px 0 48px 0' }}>
              <ScheduledReportDetails {...scheduledReport} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const ScheduledReportsList = (props) => {
  const [page, setPage] = useState(0);

  useEffect(() => {
    props.dispatch(actions.fetchScheduledReports());
  }, []);

  const rows = useMemo(() => {
    if (!props.scheduledReports?.length) {
      return [];
    }

    const startElement = !!page ? page * 5 - 1 : 0;
    return props.scheduledReports.slice(startElement, 5);
  }, [page, props.scheduledReports]);

  const actionsFormatter = (row) => {
    return (
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <TooltipIcon
          className="EditIcon"
          icon={ic_edit}
          onClick={(e) => editScheduledReport(row)}
        >
          Edit scheduled report
        </TooltipIcon>
        <TooltipIcon
          className="RemoveIcon"
          icon={ic_delete}
          onClick={(e) => onDeleteScheduledReport(row)}
        >
          Delete scheduled report
        </TooltipIcon>
      </Box>
    );
  };

  const handleRefresh = () => {
    props.dispatch(
      actions.fetchScheduledReports((response) => {
        enqueueSnackbar('Scheduled reports are now up to date.', { variant: 'success' });
        setPage(0);
      })
    );
  };

  const editScheduledReport = (scheduledReport) => {
    props.dispatch(fetchScheduledReport(scheduledReport.uuid));
  };

  const onDeleteScheduledReport = (scheduledReport) => {
    if (confirm('Are you sure you want to delete this scheduled report?')) {
      props.dispatch(deleteScheduledReport(scheduledReport));
    }
  };

  const onHandleChangePage = (e, value) => {
    setPage(value);
  };

  return (
    <div>
      <TableHeaderStack
        style={{ height: 'auto' }}
        title={
          <Tips>
            You can setup an automatic scheduled reports here. All of them will be shared
            with your whole team by default.
          </Tips>
        }
        actions={
          <Button
            variant="contained"
            onClick={handleRefresh}
            sx={{ padding: '11px', borderRadius: '16px', minWidth: 'auto' }}
          >
            <Reload />
          </Button>
        }
      />

      <TableContainer sx={{ border: 'none' }}>
        <Table
          sx={{
            borderSpacing: 0,
            borderCollapse: 'separate',
            borderRadius: '16px',
            border: '1px',
            overflow: 'hidden',
          }}
          aria-label="collapsible table"
          className={'detailsTable'}
        >
          <TableHead sx={{ backgroundColor: '#f5f8fd' }}>
            <TableRow>
              {HEAD_CELLS.map((cell) => (
                <TableCell key={cell.value} align={cell.align} sx={{ borderBottom: 0 }}>
                  {cell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((scheduledReport) => (
              <RowExpand
                key={scheduledReport.uuid}
                scheduledReport={scheduledReport}
                actionsFormatter={actionsFormatter}
              />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={props.scheduledReports?.length || 0}
          rowsPerPage={5}
          rowsPerPageOptions={[5]}
          page={page}
          onPageChange={onHandleChangePage}
          sx={{
            '.MuiTablePagination-actions': {
              marginTop: 0,
            },
          }}
        />
      </TableContainer>
    </div>
  );
  // }
};

const ScheduledReportDetails = (props) => {
  const recipients = useMemo(() => {
    if (typeof props.recipients === 'string' || props.recipients instanceof String) {
      return props.recipients;
    }

    return props.recipients.join(', ');
  }, []);

  return (
    <Table
      sx={{
        borderSpacing: 0,
        borderCollapse: 'separate',
        borderRadius: '16px',
        border: '1px',
        overflow: 'hidden',
      }}
      aria-label="collapsible table"
      className={'detailsTable'}
    >
      <TableHead sx={{ backgroundColor: '#f5f8fd' }}>
        <TableRow>
          <TableCell align="center">Date</TableCell>
          <TableCell align="center">Account numbers</TableCell>
          <TableCell align="center">File format</TableCell>
          <TableCell align="center">Recipients</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{ backgroundColor: '#FFFFFF' }}>
        <TableRow>
          <TableCell align="center">{props.dateGrouping ?? '-'}</TableCell>
          <TableCell align="center">
            {props.vendorAccountNumbers ? props.vendorAccountNumbers.join(', ') : '-'}
          </TableCell>
          <TableCell align="center">{props.fileFormat ?? '-'}</TableCell>
          <TableCell align="center">{recipients}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const dateRangeFormatter = (row) => {
  return [
    row.dateRangeLength,
    'full',
    row.schedFrequency.toLowerCase() + (row.dateRangeLength > 1 ? 's' : '') + ',',
    'starting',
    row.dateRangeStart,
    row.schedFrequency.toLowerCase() + (row.dateRangeLength > 1 ? 's' : ''),
    'back',
  ].join(' ');
};

const schedFrequencyFormatter = (row) => {
  if (row.schedFrequency === 'WEEK') {
    return 'Every ' + utils.getDayName(row.schedDayInPeriod);
  } else if (row.schedFrequency === 'MONTH') {
    return utils.getNumberOrdinal(row.schedDayInPeriod) + ' day of each month';
  }
  return null;
};

const fetchScheduledReport = (uuid) => {
  return (dispatch) => {
    return ajax.getJSON(
      `/api/scheduled-reports/${uuid}`,
      null,
      (response) => {
        dispatch(initialize('Reporting.ScheduledReportForm', response));
        document
          .getElementById(`ScheduledReportForm`)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

const deleteScheduledReport = (data) => {
  return (dispatch) => {
    return ajax.deleteJSON(
      `/api/scheduled-reports/${data.uuid}`,
      {},
      (response) => {
        enqueueSnackbar('Success! This scheduled report has been deleted.', {
          variant: 'success',
        });
        dispatch(actions.fetchScheduledReports());
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

const mapStateToProps = (state) => {
  return {
    scheduledReports: state.scheduledReports,
  };
};

export default connect(mapStateToProps)(ScheduledReportsList);
