'use strict';

import autobind from 'react-autobind';
import React from 'react';
import Payment from 'payment';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { enqueueSnackbar } from 'notistack';

import * as ajax from 'common/helpers/ajax';
import Button from 'common/components/Button';
import ReduxFormFloatingLabelFormGroup from 'common/components/ReduxFormFloatingLabelFormGroup';
import Grid from '@mui/material/Unstable_Grid2';

class AddPaymentCardForm extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.submitting != nextProps.submitting) {
      this.props.onSubmittingChange(nextProps.submitting);
    }
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.addPaymentCard)}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <ReduxFormFloatingLabelFormGroup
              type="text"
              name="cardNumber"
              label="Credit card number"
              normalize={normalizeCardNumber}
            />
          </Grid>

          <Grid xs={6}>
            <ReduxFormFloatingLabelFormGroup
              type="text"
              name="expiration"
              label="Expiration (MM/YY)"
              normalize={normalizeExpDate}
            />
          </Grid>
          <Grid xs={6}>
            <ReduxFormFloatingLabelFormGroup
              type="text"
              name="cvc"
              label="CVC"
              // The CVC field should only permit numeric input.
              normalize={(val) => val.replace(/[^\d]/, '')}
            />
          </Grid>
          <Grid xs={12}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              isLoading={this.props.submitting}
              disabled={this.props.pristine || this.props.submitting}
            >
              Save payment card
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }

  addPaymentCard(data) {
    if (!Stripe.card.validateCardNumber(data.cardNumber)) {
      enqueueSnackbar('Please double-check your card number', { variant: 'error' });
      return null;
    }

    // Use the `Payment` library here since it supports 'MM/YY' inputs.
    if (!Payment.fns.validateCardExpiry(data.expiration)) {
      enqueueSnackbar('Please double-check your expiration date', { variant: 'error' });
      return null;
    }

    if (!Stripe.card.validateCVC(data.cvc)) {
      enqueueSnackbar('Please double-check your CVC', { variant: 'error' });
      return null;
    }

    return generateCardToken(data.cardNumber, data.expiration, data.cvc).then(
      (response) => {
        return this.props.dispatch(addPaymentCard(response.id));
      },
      (response) => {
        enqueueSnackbar(response.error.message, { variant: 'error' });
      }
    );
  }
}

/**
 * Normalizes a credit card number.
 */
const normalizeCardNumber = (value) => {
  // First, remove any non-numeric, non-whitespace characters.
  value = value.replace(/[^\d\s]/, '');
  return Payment.fns.formatCardNumber(value).trim();
};

/**
 * Returns a formatted version of the expiration date.
 *
 * This function requires both a new value and an old value in order to handle
 * character additions separately from character deletions.
 */
const normalizeExpDate = (newValue, prevValue = '') => {
  // Only permit numeric characters, whitespace characters, and slashes.
  newValue = newValue.replace(/[^\d\s\/]/, '');

  // We need to handle additions separately from deletions.
  if (newValue.length < prevValue.length) {
    // If this is a deletion, and all we have left in the newValue is a
    // trailing slash, then we should automatically remove it.
    if (/\/\s*$/.test(newValue)) {
      newValue = newValue.replace(/[\s\/]+$/, '');
    }
  }
  // Additions are more complicated -- there are a few more cases we need to
  // handle.
  else {
    // If all we have is a single integer whose value is >1, then add a
    // leading 0.
    if (/^\d$/.test(newValue) && ['0', '1'].indexOf(newValue) === -1) {
      newValue = '0' + newValue;
    }

    // If we have a single digit and a slash, then we should add a leading 0.
    if (/^\d\/$/.test(newValue)) {
      newValue = '0' + newValue;
    }

    // If we have 2 or more digits, then automatically add a trailing slash
    // separator after the second digit.
    if (/^\d\d+$/.test(newValue)) {
      newValue = newValue.substring(0, 2) + ' / ' + newValue.substring(2);
    }

    // If we have a slash character without any spaces before or after it,
    // then add spaces.
    if (/[\s\/]+$/.test(newValue)) {
      newValue = newValue.replace(/[\s\/]+/, ' / ');
    }

    // If we already have a full month and year, then don't allow additional
    // inputs.
    if (/^\d{2}\s+\/\s+\d{2}$/.test(prevValue)) {
      newValue = prevValue;
    }
  }

  return newValue;
};

/**
 * Generates a Stripe card token.
 */
const generateCardToken = (cardNumber, expiration, cvc) => {
  var { month, year } = Payment.fns.cardExpiryVal(expiration);

  return new Promise((resolve, reject) => {
    Stripe.card.createToken(
      {
        number: cardNumber,
        exp_month: month,
        exp_year: year,
        cvc: cvc,
      },
      (status, response) => {
        if (response.error) {
          reject(response);
        } else {
          resolve(response);
        }
      }
    );
  });
};

const addPaymentCard = (cardToken) => {
  return (dispatch) => {
    return ajax.postJSON(
      '/api/payment-methods',
      {
        tokenId: cardToken,
      },
      (response) => {
        dispatch({
          type: 'PAYMENT_METHODS__DEFAULT_METHOD__UPDATE',
          payload: response,
        });
        dispatch({
          type: 'SETTINGS_BILLING__ADD_PAYMENT_CARD_MODAL__HIDE',
        });
        enqueueSnackbar('Success! Your card has been saved.', { variant: 'success' });
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

AddPaymentCardForm = reduxForm({
  form: 'Settings.AddPaymentCardForm',
})(AddPaymentCardForm);

const mapStateToProps = (state) => {
  return {
    defaultMethod: state.paymentMethods.defaultMethod,
  };
};

export default connect(mapStateToProps)(AddPaymentCardForm);
