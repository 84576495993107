import React from 'react';

const NetRatesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4 8H20V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V8Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 4H16L20 8H4L8 4Z" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 12H12" stroke="#B0CEEC" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default NetRatesIcon;
