'use strict';

import React from 'react';
import { FormGroup, FormLabel } from '@mui/material';
import moment from 'moment';
import isAfterDay from 'react-dates/lib/utils/isAfterDay';

import ReduxFormDateRangePicker from 'common/components/ReduxFormDateRangePicker';
import ReduxFormSelect from 'common/components/ReduxFormSelect';
import ReduxFormVendorAccountNumbersSelect from 'common/components/ReduxFormVendorAccountNumbersSelect';
import { change } from 'redux-form';
import Box from '@mui/material/Box';
import { getOperationVendors } from '../../common/helpers/utils';
import ReduxFormOperationVendorAccountNumbersSelect from '../../common/components/ReduxFormOperationVendorAccountNumbersSelect';

/**
 * Returns a container with the help text for the selected report.
 */
const getHelpTextEl = (selectedReport) => {
  if (!selectedReport || !selectedReport.helpText) {
    return null;
  }

  return <span className="help-block">{selectedReport.helpText}</span>;
};

const getFileFormatOptions = () => {
  return [
    { label: '.csv', value: 'CSV' },
    { label: '.xlsx', value: 'XLSX' },
  ];
};

/**
 * Returns the date range form group element if necessary.
 */
const getDateRangeFormGroupEl = (selectedReport) => {
  // Note that if no report is selected, we want this to be visible by default.
  if (selectedReport && !selectedReport.requiresDate) {
    return null;
  }

  return (
    <Box className="small_group formGroup">
      <FormGroup>
        <FormLabel>Date range</FormLabel>
        <ReduxFormDateRangePicker
          names={['startDate', 'endDate']}
          numberOfMonths={2}
          isOutsideRange={(day) => isAfterDay(day, moment())}
          startDateId="StartDateReports"
          endDateId="EndDateReports"
        />
      </FormGroup>
    </Box>
  );
};

/**
 * Returns the date grouping form group element if necessary.
 */
const getDateGroupingFormGroupEl = (selectedReport, dispatch) => {
  if (!selectedReport) {
    return null;
  }
  if (!selectedReport.additionalOptions.includes('date_grouping')) {
    if (dispatch) dispatch(change('Reporting.RunReportForm', 'dateGrouping', ''));
    return null;
  }

  const dateGroupingOptions = [
    { label: 'Day', value: 'DAY' },
    { label: 'Week', value: 'WEEK' },
    { label: 'Month', value: 'MONTH' },
  ];

  return (
    <FormGroup className={'formGroup small_group'}>
      <FormLabel>Date grouping</FormLabel>
      <ReduxFormSelect
        name="dateGrouping"
        placeholder="Selected a date grouping"
        options={dateGroupingOptions}
        clearable={false}
      />
    </FormGroup>
  );
};

/**
 * Returns the account number form group element if necessary.
 */
const getAccountNumbersFormGroupEl = (
  selectedReport,
  vendorAccountNumberMappings,
) => {
  // Note that if no report is selected, we want this to be visible by default. or we select tracking_number_filtering
  if (
    (selectedReport && !selectedReport.requiresAccountNumbers) ||
    selectedReport?.additionalOptions?.includes('tracking_number_filtering')
  ) {
    return null;
  }

  return (
    <FormGroup className={'formGroup small_group'}>
      <FormLabel>Account numbers</FormLabel>
      {selectedReport?.isOperationReport ? (
        <ReduxFormOperationVendorAccountNumbersSelect
          name="vendorAccountNumbers"
          placeholder="Leave empty to include all account numbers"
          operationVendorAccountNumberMappings={vendorAccountNumberMappings}
        />
      ) : (
        <ReduxFormVendorAccountNumbersSelect
          name="vendorAccountNumbers"
          placeholder="Leave empty to include all account numbers"
          vendorAccountNumberMappings={vendorAccountNumberMappings}
        />
      )}
    </FormGroup>
  );
};

export default {
  getHelpTextEl,
  getFileFormatOptions,
  getDateRangeFormGroupEl,
  getDateGroupingFormGroupEl,
  getAccountNumbersFormGroupEl,
};
