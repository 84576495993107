import React from 'react';
import classNames from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

import Icon from './Icon';

/**
 * Renders an icon with tooltip.
 *
 * The text for the tooltip will be determined by the text inside the
 * `<TooltipIcon></TooltipIcon>` tags.
 */
function TooltipIcon(props) {
  var { className, icon, size, onClick } = props;

  return (
    <Tooltip title={props.children} placement={props.placement || 'bottom'}>
      {/* Tooltip doesn`t work with Icon. We should to wrap span */}
      <span>
        <Icon
          className={classNames('TooltipIcon', className)}
          icon={icon}
          size={size}
          onClick={onClick}
        />
      </span>
    </Tooltip>
  );
}

TooltipIcon.propTypes = {
  // placement: PropTypes.string,
  size: PropTypes.number,
};

TooltipIcon.defaultProps = {
  // placement: 'bottom',
  size: 22,
};

export default TooltipIcon;
