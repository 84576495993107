'use strict';

// This is a massive hack: We re-use the styling from the AuthPageContainer
// since it already gets customized based on the partner's theme.
import '../../auth/containers/AuthPageContainer.scss';

import React from 'react';
import { setFeedbackModalOpen } from '../store/actions';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';

const NotFound = (props) => {
  const dispatch = useDispatch();

  return (
    <div className="AuthPageContainer" style={{ minHeight: '100vh' }}>
      <div className="PageContent" style={{ maxWidth: '500px' }}>
        <div className="SiteLogo" style={{ marginBottom: '50px' }}></div>
        <div className="text-container text-center">
          <h2>Oh no! We couldn't find that page.</h2>
          <div className="vspace-20" />

          <Box
            component="h4"
            sx={{
              cursor: 'pointer',
              fontWeight: '14px',
              color: 'var(--galleon-blue-1)',
              textDecoration: 'underline',
            }}
            onClick={() => {
              dispatch(setFeedbackModalOpen(true));
            }}
          >
            If you have any questions, feel free to contact us.
          </Box>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
