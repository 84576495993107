import _get from 'lodash/get';
import * as utils from '../../../helpers/utils';

export const downloadChartCSV = (downloadElementRef, chartConfig, title) => {
  let indexHeader = _get(
    chartConfig,
    'options.scales.xAxes[0].scaleLabel.labelString',
    ''
  );
  let indexValues = chartConfig.data.labels;
  let datasetHeaders = chartConfig.data.datasets.map((d) => d.label);
  let datasetValues = chartConfig.data.datasets.map((d) => d.data);
  // Build an array of arrays for the rows that should go into the
  // CSV. Initialize this array with the header row.
  let csvRows = [[indexHeader, ...datasetHeaders]];
  for (let i = 0; i < indexValues.length; i++) {
    csvRows.push([indexValues[i], ...datasetValues.map((d) => d[i])]);
  }

  utils.triggerDownloadCSV(
    utils.rowsToCSV(csvRows),
    title || 'Export.csv',
    downloadElementRef.current
  );
};

export const downloadZonesCSV = (downloadElementRef, chartConfig, title) => {
  let csvRows = chartConfig.data.datasets
    .map((d) => d.data)
    .reduce(
      (state, data) => {
        data.forEach((d) => {
          state.push([d.y, d.x, d.clearValue]);
        });

        return state;
      },
      [['weight', 'zone', 'value']]
    );

  utils.triggerDownloadCSV(
    utils.rowsToCSV(csvRows),
    title || 'Export.csv',
    downloadElementRef.current
  );
};

export const downloadChoroplethCSV = (
  downloadElementRef,
  chartConfig,
  valueLabel,
  title = 'Export.csv'
) => {
  let indexValues = Object.keys(chartConfig.data);
  let headers = [chartConfig.data.datasets[0].label, valueLabel, 'Percentage'];

  // Build an array of arrays for the rows that should go into the
  // CSV. Initialize this array with the header row.
  let csvRows = [headers];
  chartConfig.data.datasets[0].data.forEach((dataItem) => {
    csvRows.push([dataItem.feature.properties.name, dataItem._baseValue, dataItem.value]);
  });

  utils.triggerDownloadCSV(utils.rowsToCSV(csvRows), title, downloadElementRef.current);
};
