import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { dateFormatter } from 'common/components/table/gridDateUtils';
import * as constants from 'common/helpers/constants';
import * as utils from 'common/helpers/utils';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

// Define keys to skip during column creation
const keysToSkip = ['id', 'companyName', 'hid', 'shipmentId', 'vendorInvoiceId'];

const TooltipWrapper = ({ value }) => {
  if (value === '—') {
    return value;
  }
  return (
    <Tooltip title={value} enterDelay={500}>
      <Box className={'truncate'}>{value}</Box>
    </Tooltip>
  );
};

// Function to render cell content based on field type
const renderCellByFieldType = (key, params) => {
  // Switch statement to handle different field types

  let formatedValue;

  switch (key) {
    case 'trackingNumber':
      return (
        <NavLink
          style={{ display: 'block', overflow: 'hidden' }}
          to={{
            pathname: `/shipments/single-tracking/${params.value}`,
          }}
          target="_blank"
          rel="noopener noreferrer" // Adding noreferrer for security reasons
        >
          <TooltipWrapper value={params.value} />
        </NavLink>
      );
    case 'carrierPickedUpDate':
    case 'labelCreatedDate':
    case 'deliveredDate':
    case 'invoiceDate':
      formatedValue =
        !params.value || params.value.length === 0 || params.value === 'None'
          ? '—'
          : dateFormatter(params.value);
      return <TooltipWrapper value={formatedValue} />;
    case 'vendor':
    case 'carrier':
      return <TooltipWrapper value={constants.getDispValue(params.value)} />;
    case 'ratedWeight':
    case 'scaleWeight':
      formatedValue = !!params.value ? utils.Fmt.float1(params.value) + ' lb' : '—';
      return <TooltipWrapper value={formatedValue} />;
    case 'totalCost':
    case 'netCost':
      return <TooltipWrapper value={utils.Fmt.dollars(params.value / 100)} />;
    case 'invoiceNumber':
      if (!params.value || params.value.length === 0 || params.value === 'None'){
        return <TooltipWrapper value='-' />
      } else {
        return <NavLink
            to={{
              pathname: `/invoices/${params.value}`,
              state: {vendor_invoice_id: params.row.vendorInvoiceId},
            }}
        >
          <TooltipWrapper value={params.value}/>
        </NavLink>
      }
    default:
      let formattedValue;
      if (key.includes("Cost")) {
        formattedValue = utils.Fmt.dollars(params.value / 100);
      } else {
        formattedValue = !params.value || params.value.length === 0 || params.value === 'None' ? '—' : params.value;
      }
      return <TooltipWrapper value={formattedValue} />;
  }
};

// Custom hook to generate shipment details table columns
export const useShipmentDetailsTableColumns = (shipments) =>
  useMemo(() => {
    if (!shipments || shipments.length === 0) {
      return [];
    }
    return Object.keys(shipments[0])
      .map((key) => {
        if (!keysToSkip.includes(key)) {
          return {
            field: key,
            headerName: utils.convertToReadableFormat(key),
            flex: 1,
            minWidth: 200,
            headerAlign: 'left',
            align: 'left',
            renderCell: (params) => renderCellByFieldType(key, params),
          };
        }
        return null; // Skip this key
      })
      .filter((column) => column !== null); // Filter out the skipped keys (null entries)
  }, [shipments]);
