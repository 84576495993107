import React from 'react';
import { Stack } from '@mui/material';
import Tips from 'common/components/Tips/Tips';

/**
 * Displays an error message.
 */
const ErrorContainer = (props) => {
  return (
    <Stack spacing={4} alignItems="center">
      <Tips className="alert alert-danger">
        <strong>Well that's embarrassing...</strong> It looks like there was an error
        fetching this data. Please try again later.
      </Tips>
      {props.button}
    </Stack>
  );
};

export default ErrorContainer;
