import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Field, Form, Formik } from 'formik';
import { SelectFormik } from 'common/components/formik/SelectFormik';
import { ContractUploadFormik } from 'common/components/formik/ContractUplaodFormik';
import { useDispatch } from 'react-redux';
import { getNetRatesServiceTypeList } from '../../store/slices/NetRatesSlices';
import { TextFieldFormik } from 'common/components/formik/TextFieldFormik';
import SingleDatePickerFormik from 'common/components/SingleDatePickerFormik';
import moment from 'moment';
import Button from "common/components/Button";

const FormRow = styled.div`
  margin: 0 0 20px;

  h6 {
    margin: 0 0 6px;
  }

  .MuiSelect-select:focus {
    background-color: inherit;
  }

  .selectFileBtn {
    margin-right: 10px;
  }
  &.formRow {
    display: flex;
    div.column {
      display: flex;
      width: 50%;
      height: 100%;
      flex-direction: column;
      :first-child {
        margin-right: 10px;
      }
      :last-child {
        margin-left: 10px;
      }
    }
  }
`;

const ValidationError = styled.div`
  color: #f44336;
  margin-left: ${(props) => (props.fileUpload ? '0' : '14px')};
  font-size: 0.75rem;
  margin-top: 3px;
  font-family:
    Open Sans,
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
  line-height: 1.66;
`;

const FormNavigation = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const validationSchema = yup.object().shape({
  carrier: yup.string().required('Please choose a carrier!'),
  serviceType: yup.string().required('Please choose a service!'),
  file: yup.mixed().required('Please select the file!'),
  name: yup.string().required('Please choose a name!'),
  date: yup.object().nullable().required('Please select a date!'),
});

export const AddNetRatesForm = ({
  carriers,
  serviceTypeList,
  onSubmit,
  fetchingSendingData,
}) => {
  const dispatch = useDispatch();

  const handleChangeCarrier = (event, setFieldValue) => {
    setFieldValue('carrier', event.target.value);
    dispatch(getNetRatesServiceTypeList(event.target.value));
  };

  const isOutsideRange = (day) => {
    return (
      day.isAfter(moment().add(10, 'years')) ||
      day.isBefore(moment().subtract(10, 'years'))
    );
  };

  return (
    <Formik
      initialValues={{ carrier: '', serviceType: '', name: '', date: null, file: '' }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, touched, values, setFieldValue, setFieldTouched }) => {
        return (
          <Form>
            <FormRow>
              <Field
                name="carrier"
                component={SelectFormik}
                label="Carrier*"
                variant="outlined"
                onChange={(e) => handleChangeCarrier(e, setFieldValue)}
                value={values.carrier}
              >
                {carriers &&
                  carriers.map((item) => (
                    <MenuItem key={item[0]} value={item[0]}>
                      {item[1]}
                    </MenuItem>
                  ))}
              </Field>
            </FormRow>
            {serviceTypeList && (
              <FormRow>
                <Field
                  name="serviceType"
                  component={SelectFormik}
                  label="What services do you want to add*"
                  variant="outlined"
                >
                  {serviceTypeList.map((item) => (
                    <MenuItem key={item[0]} value={item[1]}>
                      {item[1]}
                    </MenuItem>
                  ))}
                </Field>
              </FormRow>
            )}
            <FormRow className="formRow">
              <div className="column form-control searchInputMui">
                <Field
                  name="name"
                  component={TextFieldFormik}
                  placeholder="Number"
                  variant="outlined"
                  shrink={false}
                  fullWidth={true}
                />
              </div>
              <div className="column">
                <SingleDatePickerFormik
                  isOutsideRange={isOutsideRange}
                  setDate={setFieldValue}
                  date={values.date}
                  setFieldTouched={setFieldTouched}
                />
                {touched.date && errors.date && !values.date && (
                  <ValidationError>{errors.date}</ValidationError>
                )}
              </div>
            </FormRow>
            <FormRow>
              <Typography variant="subtitle1">
                Upload a CSV version of the net rates*
              </Typography>
              <Field name="file" component={ContractUploadFormik} fileFormat={'.csv'} />
              {touched.file && errors.file && (
                <ValidationError fileUpload>{errors.file}</ValidationError>
              )}
            </FormRow>
            <FormNavigation>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={fetchingSendingData}
              >
                {fetchingSendingData ? 'Working...' : 'Finish upload'}
              </Button>
            </FormNavigation>
          </Form>
        );
      }}
    </Formik>
  );
};
