'use strict';

import React from 'react';
import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';

import ReduxFormSelect from 'common/components/ReduxFormSelect';

const ReduxFormVendorAccountNumbersSelect = (props) => {
  var {
    vendorAccountNumberMappings,
    multi = true,
    closeOnSelect = false,
    placeholder,
    labelModelField = 'name',
    ...props
  } = props;

  return (
    <ReduxFormSelect
      options={getVendorAccountNumberOptions(
        vendorAccountNumberMappings,
        labelModelField
      )}
      placeholder={placeholder || 'Select an account number'}
      multi={multi}
      closeOnSelect={closeOnSelect}
      {...props}
    />
  );
};

const getVendorAccountNumberOptions = (vendorAccountNumberMappings, labelModelField) => {
  return _map(
    // First, group the vendor account numbers by vendor.
    _groupBy(vendorAccountNumberMappings, (obj) => obj.dispVendor),
    // Then construct the nested options for it.
    (models, dispVendor) => {
      return {
        label: dispVendor,
        options: models.map((model) => ({
          label: model[labelModelField],
          value: model.vendorAccountNumber,
        })),
      };
    }
  );
};
export default ReduxFormVendorAccountNumbersSelect;
