import React from 'react';

const OperationInvoicesIcon = () => {
  return (
    <svg
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.5 5.35441C0.5 4.57979 1.14076 3.93774 1.94872 3.93774H15.5897C16.3977 3.93774 17.0385 4.57979 17.0385 5.35441V14.9377C17.0385 15.7124 16.3977 16.3544 15.5897 16.3544H1.94872C1.14076 16.3544 0.5 15.7124 0.5 14.9377V5.35441Z" />
      <path d="M4 8H13.7436" />
      <path d="M4 12H13.7436" />
      <path d="M3 3.5V2.50008C3 1.67165 3.67157 1.00008 4.49999 1.00008L18.5 1.00001C19.3284 1 20 1.67158 20 2.50001V13.5C20 14.3284 19.329 15 18.5005 15C18.3332 15 18.0216 15 17.4242 15" />
    </svg>
  );
};

export default OperationInvoicesIcon;
