'use strict';

import autobind from 'react-autobind';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { enqueueSnackbar } from 'notistack';

import * as ajax from 'common/helpers/ajax';
import Button from 'common/components/Button';
import ReduxFormFloatingLabelFormGroup from 'common/components/ReduxFormFloatingLabelFormGroup';
import * as actions from '../store/actions';
import Grid from '@mui/material/Unstable_Grid2';
import ReduxFormSelect from '../../common/components/ReduxFormSelect';
import { FormGroup, FormLabel } from '@mui/material';

class AddShippingLocationForm extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.submitting != nextProps.submitting) {
      this.props.onSubmittingChange(nextProps.submitting);
    }
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.updateShippingLocation)}>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <ReduxFormFloatingLabelFormGroup
              type="text"
              name="name"
              label="Location name"
            />
          </Grid>

          <Grid xs={12}>
            <ReduxFormFloatingLabelFormGroup
              type="text"
              name="street1"
              label="Street line 1"
            />
          </Grid>

          <Grid xs={12}>
            <ReduxFormFloatingLabelFormGroup
              type="text"
              name="street2"
              label="Street line 2"
            />
          </Grid>

          <Grid xs={5}>
            <ReduxFormFloatingLabelFormGroup type="text" name="city" label="City" />
          </Grid>
          <Grid xs={3}>
            <ReduxFormFloatingLabelFormGroup type="text" name="state" label="State" />
          </Grid>
          <Grid xs={4}>
            <ReduxFormFloatingLabelFormGroup
              type="text"
              name="postalCode"
              label="Postal code"
            />
          </Grid>

          <Grid xs={12}>
            <FormGroup id="c-countryCode">
              <FormLabel className="control-label" htmlFor="c-countryCode">
                Country
              </FormLabel>
              <ReduxFormSelect
                name="countryCode"
                clearable={false}
                options={[
                  { label: 'United States', value: 'US' },
                  { label: 'Canada', value: 'CA' },
                ]}
              />
            </FormGroup>
          </Grid>
          <Grid xs={12}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              isLoading={this.props.submitting}
              disabled={this.props.pristine || this.props.submitting}
            >
              Save location
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }

  updateShippingLocation(data) {
    const newData = {
      name: data.name,
      street1: data.street1,
      street2: data.street2,
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
      countryCode: data.countryCode || 'US',
      uuid: data.uuid,
    };

    // debugger;
    var actionCreator = newData.uuid ? editShippingLocation : addShippingLocation;
    return this.props.dispatch(actionCreator(newData));
  }
}

const addShippingLocation = (data) => {
  return (dispatch) => {
    return ajax.postJSON(
      '/api/shipping-locations',
      data,
      (response) => {
        dispatch(actions.fetchShippingLocations());
        dispatch({
          type: 'SETTINGS_SHIPPING_SETUP__ADD_SHIPPING_LOCATION_MODAL__HIDE',
        });
        enqueueSnackbar('Success! Your shipping location has been saved.', {
          variant: 'success',
        });
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

const editShippingLocation = (data) => {
  return (dispatch) => {
    var { uuid, ...rest } = data;
    return ajax.putJSON(
      '/api/shipping-locations/' + uuid,
      rest,
      (response) => {
        dispatch(actions.fetchShippingLocations());
        dispatch({
          type: 'SETTINGS_SHIPPING_SETUP__ADD_SHIPPING_LOCATION_MODAL__HIDE',
        });
        enqueueSnackbar('Success! Your shipping location has been saved.', {
          variant: 'success',
        });
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

AddShippingLocationForm = reduxForm({
  form: 'Settings.AddShippingLocationForm',
})(AddShippingLocationForm);

const mapStateToProps = (state) => {
  return {
    initialValues: state.settingsShippingSetup.addShippingLocationModal.initialValues,
  };
};

export default connect(mapStateToProps)(AddShippingLocationForm);
