import React from 'react';

const Reload = () => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.86144 4.71026C3.48408 4.69389 3.24537 4.39869 3.31455 3.9361V0.856066C3.31455 0.0644733 4.51738 0.0644733 4.51738 0.856066V2.64362C10.958 -2.84657 21 1.7076 21 10.2527L20.9335 11.4045C20.8437 12.19 19.6503 12.0538 19.7401 11.2682C20.5244 4.41058 13.5057 -0.571072 7.34344 2.24888C6.62148 2.57919 5.95154 3.00483 5.34999 3.50967H7.17153C7.96321 3.50967 7.96321 4.71237 7.17153 4.71237L3.86144 4.71026ZM19.5331 18.5513V15.2962C19.5331 14.964 19.2638 14.6947 18.9316 14.6947H15.6761C14.8842 14.6947 14.8842 15.8974 15.6761 15.8974H17.7498C17.0485 16.7341 16.1934 17.4394 15.2277 17.9693C8.59909 21.6083 0.677037 15.6481 2.4426 8.20932C2.62533 7.43965 1.45551 7.16161 1.27251 7.93208C0.461293 11.3498 1.49433 14.8869 3.93009 17.3224C7.95133 21.3432 14.4852 21.1964 18.3303 17.0504V18.551C18.3303 19.3428 19.5331 19.3428 19.5331 18.5513Z"
        fill="#F5F8FD"
        stroke="#F5F8FD"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default Reload;
