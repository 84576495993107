import React, { useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import Icon from 'common/components/Icon';
import { ic_vertical_align_bottom } from 'react-icons-kit/md/ic_vertical_align_bottom';
import { getCarrierNetRate } from '../../../store/slices/CarrierNetRatesSlices';

export const useCarrierNetRatesTableColumns = (dispatch) =>
  useMemo(
    () => [
      {
        field: 'number',
        headerName: 'Number',
        flex: 1,
        minWidth: 150,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'service_type',
        headerName: 'Service type',
        flex: 1,
        minWidth: 150,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'carrier',
        headerName: 'Carrier',
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'effective_date',
        headerName: 'Effective date',
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },

      {
        field: 'actions',
        headerName: 'Actions',
        width: 120,
        type: 'actions',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <IconButton
            size="small"
            onClick={() =>
              dispatch(
                getCarrierNetRate(
                  params.value.id,
                  params.value.service_type,
                  params.value.number
                )
              )
            }
          >
            <Icon icon={ic_vertical_align_bottom} size={20} />
          </IconButton>
        ),
      },
    ],
    [dispatch]
  );
