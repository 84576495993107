import React from 'react';
import USChoroplethZipGenerator from 'common/components/Charts/USChoroplethZipGenerator';
import PageContainer from '../containers/PageContainer';
import './ZoneGenerator.scss';

const ZoneGenerator = (props) => {
  return (
    <PageContainer
      title="Zone Generator"
      seconHeaderContent={null}
      contentId="generator"
      contentClassName="Dashboard "
    >
      <USChoroplethZipGenerator className="zoneGenerator" />
    </PageContainer>
  );
};

export default ZoneGenerator;
