'use strict';

import React from 'react';

import DashboardFilterSidebar from './DashboardFilterSidebar';
import VendorInvoicesFilterSidebar from './VendorInvoicesFilterSidebar';
import ShipmentDetailsFilterSidebar from './ShipmentDetailsFilterSidebar';

import SidebarContent from './SidebarContent';
import PageContainer from 'common/components/PageContainer';
import OperationVendorInvoicesFilterSidebar from './OperationVendorInvoicesFilterSidebar';

const PageContainerGlobal = (props) => {
  return (
    <PageContainer
      sidebars={
        <>
          <DashboardFilterSidebar />
          <VendorInvoicesFilterSidebar />
          <OperationVendorInvoicesFilterSidebar />
          <ShipmentDetailsFilterSidebar />
        </>
      }
      {...props}
      SidebarContent={SidebarContent}
    />
  );
};

export default PageContainerGlobal;
