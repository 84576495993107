import React, { useMemo } from 'react';
import * as constants from 'common/helpers/constants';
import * as utils from 'common/helpers/utils';
import TooltipIcon from 'common/components/TooltipIcon';
import moment from 'moment/moment';
import { ic_schedule } from 'react-icons-kit/md/ic_schedule';

const StillProcessing = () => {
  return <TooltipIcon icon={ic_schedule}>Still processing...</TooltipIcon>;
};

const DollarFormatter = ({ val }) => {
  if (val === null) {
    return <StillProcessing />;
  }
  return utils.Fmt.dollars(val / 100);
};

const InvoiceDateFormatter = ({ val, row }) => {
  if (val === null) {
    return constants.MDASH;
  } else {
    const formatedDueDate = utils.Fmt.date(row.invoiceDueDate);
    const formatedInvoiceDate = utils.Fmt.date(val);
    if (moment(formatedInvoiceDate) > moment(formatedDueDate)) {
      return <span className="red">{formatedInvoiceDate}</span>;
    } else {
      return formatedInvoiceDate;
    }
  }
};

export const useVendorInvoicesTableColumns = (enrolledForPayments) =>
  useMemo(
    () => [
      {
        field: 'operationVendor',
        headerName: 'Operation Vendor',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center ',
      },
      {
        field: 'operationVendorAccountNumber',
        headerName: 'Account Number',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'invoiceNumber',
        headerName: 'Invoice number',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'invoiceDate',
        headerName: 'Invoice date',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <InvoiceDateFormatter val={params.value} row={params.row.currentValue} />
        ),
      },
      {
        field: 'invoiceDueDate',
        headerName: 'Due date',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => utils.Fmt.mdashIfNullFunc(utils.Fmt.date)(params.value),
      },
      {
        field: 'originalAmount',
        headerName: 'Original amount',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => <DollarFormatter val={params.value} />,
      },
      {
        field: 'adjustedAmount',
        headerName: 'Adjusted Amount',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => <DollarFormatter val={params.value} />,
      },
      {
        field: 'finalAmount',
        headerName: 'Final amount',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => <DollarFormatter val={params.value} />,
      },
    ],
    [enrolledForPayments]
  );
