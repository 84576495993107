import React, { useMemo } from 'react';
import useChartTabs from 'common/components/Tabs/hooks/useChartTabs';
import PageContainer from '../containers/PageContainer';
import Box from '@mui/material/Box';
import TabsTitle from 'common/components/Tabs/TabsTitle';
import ShipmentLookup from 'common/components/lookup/ShipmentLookup';
import { useHistory, useRouteMatch } from 'react-router-dom';
import MultiplyTrackShipments from 'common/components/Track/MultiplyTrackShipments';
import classNames from 'classnames';
import './Shipment.scss';
import { uploadShipmentTrackingList } from '../store/slices/MultiplyTrackShipmentsSlice';
import { fetchShipments } from '../store/actions';
import { useCheckPermissions } from '../../common/helpers/hooks/useCheckPermissions';
import TrackShipments from '../trackShipments/TrackShipments';
import { useSelector } from 'react-redux';

const Shipment = () => {
  const history = useHistory();
  const shipper = useSelector((state) => state.user)?.shipper;

  const checkPermissions = useCheckPermissions();

  const isMultiplyAllowed = checkPermissions([
    PERMISSION_PAGE_KEY,
    'batch-tracking',
  ]).isAllowTab;

  const match = useRouteMatch(`/${PAGE}/:slug?/:trackingNumber?/:hid?`);

  const tabList = useMemo(() => {
    const tabs = [
      {
        label: 'Batch Tracking',
        path: 'batch-tracking',
      },
      {
        label: 'Api Tracking',
        path: 'api-tracking',
      },
    ];

    //show only if multiply-tracking is not allowed
    if (!isMultiplyAllowed || shipper.subscription === 'DEMO') {
      tabs.splice(0, 0, {
        label: 'Single Tracking',
        path: 'single-tracking',
      });
    }

    // redirect to multiply-tracking if premium
    if (match.params.slug === 'single-tracking' && shipper.subscription !== 'DEMO') {
      let newPath = `/${PAGE}/batch-tracking`;
      if (match.params.trackingNumber) {
        newPath += `/${match.params.trackingNumber}`;
      }
      if (match.params.hid) {
        newPath += `/${match.params.hid}`;
      }
      history.push(newPath);
    }

    return tabs;
  }, []);

  const { currentTab, onChangeCurrentQueryNames } = useChartTabs(tabList, PAGE);

  const { isAllowTab } = checkPermissions([PERMISSION_PAGE_KEY, currentTab.path]);

  return (
    <PageContainer
      title="Shipment Lookup"
      contentId="shipmentLookup"
      contentClassName={classNames('ShipmentLookup', 'PageContentWithTabs')}
      secondHeaderClass={'max-width-1200 secondHeaderClass'}
      pageContentWrapperClassName={'max-width-1200'}
      seconHeaderContent={
        <TabsTitle
          tabList={tabList}
          value={currentTab.label}
          pages={PAGE}
          permissionPageKey={PERMISSION_PAGE_KEY}
          setValue={onChangeCurrentQueryNames}
        />
      }
    >
      <Box
        sx={{
          maxWidth: '1200px',
        }}
      >
        {isAllowTab && (
          <>
            {currentTab.path === 'single-tracking' && (
              <ShipmentLookup match={match} fetchShipments={fetchShipments} />
            )}
            {currentTab.path === 'batch-tracking' && (
              <MultiplyTrackShipments
                initialValues={{
                  trackingNumbers:
                    match.params.trackingNumber?.split(',')?.join('\n') || '',
                }}
                uploadShipmentTrackingList={uploadShipmentTrackingList}
                pages={PAGE}
              />
            )}

            {currentTab.path === 'api-tracking' && <TrackShipments />}
          </>
        )}
      </Box>
    </PageContainer>
  );
};

const PAGE = 'shipments';

const PERMISSION_PAGE_KEY = 'SHIPMENT_LOOKUP';

export default Shipment;
