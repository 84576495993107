import './FilterSidebar.scss';

import React from 'react';
import Menu from 'react-burger-menu/lib/menus/slide';
import { connect } from 'react-redux';
import { decorator as reduxBurgerMenu } from 'redux-burger-menu';
import { ic_close } from 'react-icons-kit/md/ic_close';

import Icon from 'common/components/Icon';

let styles = {
  bmCrossButton: {
    width: '36px',
    height: '36px',
    right: '10px',
    top: '10px',
  },
};

class FilterSidebar extends React.Component {
  constructor(props) {
    super(props);

    // Purposely store this as a local value instead of in `state` since we do
    // *not* want to trigger a re-render whenever a dropdown is opened or
    // closed.
    this.isDropdownOpen = false;
  }

  toggleDropdownOpen(isDropdownOpen) {
    this.isDropdownOpen = isDropdownOpen;
  }

  render() {
    let { Component, width, ...props } = this.props;

    return (
      // Don't render a burger icon -- we should control this ourselves. Also,
      // make the size 320px to match the size of the calendar popup.
      <Menu
        className="FilterSidebar"
        styles={styles}
        right={true}
        customBurgerIcon={false}
        customCrossIcon={<Icon icon={ic_close} size={24} />}
        crossButtonClassName="FilterSidebar__CrossButton"
        overlayClassName="FilterSidebar__Overlay"
        menuClassName="FilterSidebar__Menu"
        disableOverlayClick={() => this.isDropdownOpen}
        width={width || '340px'}
        {...props}
      >
        <Component
          isSidebarOpen={this.props.isOpen}
          onDropdownOpen={() => this.toggleDropdownOpen(true)}
          onDropdownClose={() => this.toggleDropdownOpen(false)}
        />
      </Menu>
    );
  }
}

FilterSidebar = connect()(FilterSidebar);

export default (menuId) => reduxBurgerMenu(FilterSidebar, menuId);
