import React from 'react';
import * as utils from 'common/helpers/utils';
import * as Charts from 'common/components/Charts';
import USChoroplethZip from 'common/components/Charts/USChoroplethZip';
import VerticalTable from 'common/components/Charts/VerticalTable';
import { getUpdateTopo } from '../store/slices/TopoSlice';

export const yAxisFormatMap = {
  dollar: utils.Fmt.dollars,
  int: utils.Fmt.int,
  'float-1': utils.Fmt.float1,
  'float-2': utils.Fmt.float2,
  'percent-int': utils.Fmt.percInt,
  'percent-float-2': utils.Fmt.percFloat2,
  string: (value) => value,
};

export const xAxisFormat = {
  date: utils.Fmt.dateCustomFormat('MM/DD/YYYY'),
  'date-month': utils.Fmt.dateCustomFormat('MM/YYYY'),
  string: (value) => value,
};

export const chartMap = {
  line: Charts.MultiLineChart,
  timeseries: Charts.MultiLineChart,
  'line-filled': Charts.MultiLineChart,
  bar: Charts.BarChart,
  'stacked-bar': Charts.BarChart,
  'stacked-bar-groups': Charts.BarChart,
  heatmap: Charts.HeatMapChart,
  pie: Charts.PieChart,
  polar: Charts.PolarAreaChart,
  table: Charts.Table,
  'vertical-table': VerticalTable,
  'geo-choropleth': (props) => (
    <Charts.GeoChoroplethChart getUpdateTopo={getUpdateTopo} {...props} />
  ),
  'geo-choropleth-zip': USChoroplethZip,
};
