'use strict';

import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as ajax from 'common/helpers/ajax';
import DataGridWrapper from 'common/components/table/DataGridWrapper';
import DataGridCustom from 'common/components/table/DataGridCustom';
import { useVendorAccountNumberMappingsRows } from '../vendor-invoices/hooks/useVendorAccountNumberMappingsRows';
import { useVendorAccountNumberMappingsColumns } from '../vendor-invoices/hooks/useVendorAccountNumberMappingsColumns';
import Box from '@mui/material/Box';
import Tips from 'common/components/Tips/Tips';

const SIZE_PER_PAGE = 20;

const VendorAccountNumberMappings = (props) => {
  const [sortModel, setSortModel] = useState([
    {
      field: 'dispVendor',
      sort: 'asc',
    },
  ]);

  const updateShippingLocations = () => {
    ajax.getJSON(
      '/api/shipping-locations',
      (response) => {
        this.setState({ shippingLocations: response });
      },
      (response) => {
        console.log(response);
      }
    );
  };

  const rows = useVendorAccountNumberMappingsRows(props.vendorAccountNumberMappings);
  const columns = useVendorAccountNumberMappingsColumns(props.dispatch);

  return (
    <section className="VendorAccountNumberMappings">
      <Box sx={{ paddingBottom: '16px' }}>
        <Tips>Set a nickname for your account numbers to make them easier to find.</Tips>
      </Box>

      <DataGridWrapper
        style={{
          minHeight: rows.length > 8 ? '650px' : '420px',
        }}
      >
        <DataGridCustom
          rows={rows}
          columns={columns}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          customNoTableDataOverlay={<EmptyPlaceholder />}
          settings={{
            initialState: {
              pagination: { paginationModel: { pageSize: SIZE_PER_PAGE } },
            },
            pageSizeOptions: [SIZE_PER_PAGE],
            hideFooter: false,
          }}
        />
      </DataGridWrapper>
    </section>
  );
};

/**
 * An empty placeholder to render when no payment information has been entered
 * yet.
 */
const EmptyPlaceholder = () => {
  return (
    <Box className="table-empty-placeholder">
      We haven't imported any account numbers yet. Once you enter your carrier
      credentials, then we'll automatically find all of your account numbers.
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    vendorAccountNumberMappings: state.vendorAccountNumberMappings,
  };
};

export default connect(mapStateToProps)(VendorAccountNumberMappings);
