import { createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { deleteJSON, getJSON, postFormData } from 'common/helpers/ajax';

const contractsSlice = createSlice({
  name: 'contracts',
  initialState: {
    loading: false,
    list: [],
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getContractsSuccess: (state, { payload }) => {
      state.loading = false;
      state.list = payload;
    },
    getContractsFailure: (state, { payload }) => {
      state.loading = false;
      state.list = [];
    },
    addContractSuccess: (state, { payload }) => {
      state.loading = false;
      state.list = [...state.list, payload.contract];
    },
    addContractFailure: (state, { payload }) => {
      state.loading = false;
      state.list = [];
    },
    deleteContractSuccess: (state, { payload }) => {
      const deletedIndex = state.list.findIndex((el) => el.id === payload);

      state.loading = false;
      state.list.splice(deletedIndex, 1);
    },
    deleteContractFailure: (state, { payload }) => {
      state.loading = false;
    },
  },
});

const {
  setLoading,
  getContractsSuccess,
  getContractsFailure,
  addContractSuccess,
  addContractFailure,
  deleteContractSuccess,
  deleteContractFailure,
} = contractsSlice.actions;

export const getContracts = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setLoading());

    getJSON(
      '/shipper/contracts/',
      null,
      (response) => dispatch(getContractsSuccess(response)),
      (response) => {
        dispatch(getContractsFailure(response));
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

export const addContract = (carrier_id, file, services) => {
  return (dispatch) => {
    // dispatch(setLoading());
    const formData = new FormData();

    formData.append('file', file, file.name);
    formData.append('carrier_id', carrier_id);

    services.map((s) => formData.append('services', s));

    postFormData(
      '/shipper/contracts/',
      formData,
      (response) => {
        dispatch(addContractSuccess(response));
      },
      (response) => {
        dispatch(addContractFailure());
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

export const deleteContract = (contractId) => {
  return (dispatch, getState) => {
    dispatch(setLoading());
    const state = getState();

    deleteJSON(
      `/shipper/contracts/${contractId}/`,
      null,
      (response) => {
        dispatch(deleteContractSuccess(contractId));
      },
      (response) => {
        dispatch(deleteContractFailure());
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

export const contractsReducer = contractsSlice.reducer;
