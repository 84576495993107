import moment from 'moment/moment';

export const gridDateUtils = (v1 = 0, v2 = 0) => moment(v1).valueOf() - moment(v2).valueOf();

export const dateFormatter = (value) => {
  if (value) {
    let date = new Date(value);
    return date.toLocaleString();
  }
};
