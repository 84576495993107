import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import styled from 'styled-components';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ic_vertical_align_bottom } from 'react-icons-kit/md/ic_vertical_align_bottom';
import { ic_delete } from 'react-icons-kit/md/ic_delete';

import cn from 'classnames';
import Icon from 'common/components/Icon';
import { AddContractModal } from './AddContractModal';

import { Confirmation } from 'common/components/Confirmation';
import { deleteContract } from '../../store/slices/ContractsSlice';
import { getDispValue } from 'common/helpers/constants';
import { StatusIcon } from 'common/components/StatusIcon';
import Tips from 'common/components/Tips/Tips';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

const UPLOAD_URL = process.env.UPLOAD_URL;

const Wrapper = styled.div`
  .no-contracts-message {
    margin-bottom: 30px;
  }
`;

const ContractsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 120px);
  grid-gap: 10px;
  grid-auto-rows: 150px;
  margin-bottom: 17px;
  .MuiCard-root {
    max-width: 120px;
  }

  .selected {
    border: 1px solid var(--galleon-blue-dark-1);
    border-radius: 12px;
  }
`;

const ContractContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: var(--galleon-blue-1);

  .circle {
    position: absolute;
    top: 7px;
    left: 92px;
  }

  .card-content {
    height: 70%;
    text-decoration: none;
    color: #333;
  }

  .contract-number {
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 70%;
  }

  border: 1px solid transparent;
  border-radius: 12px;

  &:hover {
    border: 1px solid var(--galleon-blue-dark-1);
  }
`;

const StyledCardActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Plug = styled(Typography)`
  color: #bdbdbd;
`;

const StyledLink = styled(Link)`
  color: #757575;
`;

export const ContractCardsList = ({ contractsList, loading }) => {
  const dispatch = useDispatch();
  const { contractId } = useParams();

  return (
    <Wrapper>
      {!loading && contractsList.length === 0 && (
        <Tips className="no-contracts-message">
          <Typography
            variant="subtitle1"
            sx={{
              lineHeight: 'normal',
            }}
          >
            It looks you don't have any contracts yet. Click "Add contract" to upload a
            contract and get started.
          </Typography>
        </Tips>
      )}
      <ContractsList>
        {contractsList.length > 0 &&
          contractsList.map((contract) => {
            return (
              <ContractContainer
                key={contract.id}
                elevation={1}
                className={cn({
                  selected: parseInt(contractId) === contract.id,
                })}
              >
                <CardContent
                  className="card-content"
                  component={RouterLink}
                  to={`/contracts/${contract.id}`}
                  sx={{
                    backgroundColor: 'var(--galleon-white) !important',
                    borderRadius: '12px !important',
                    border: '1px solid var(--galleon-white)',
                  }}
                >
                  <StatusIcon isReady={contract.isReady} />
                  <Tooltip title={contract.number}>
                    <Typography
                      variant="body2"
                      className="contract-number truncate"
                      title={contract.number}
                    >
                      {contract.number || <Plug variant="caption">Contract</Plug>}
                    </Typography>
                  </Tooltip>
                  <Tooltip title={getDispValue(contract.carrier.name)}>
                    <Typography variant="subtitle1" className="contract-carrier truncate">
                      {getDispValue(contract.carrier.name)}
                    </Typography>
                  </Tooltip>
                </CardContent>
                <StyledCardActions>
                  <Confirmation
                    onSubmit={() => {
                      dispatch(deleteContract(contract.id));
                    }}
                    btn={
                      <IconButton value={contract.id} name={contract.number} size="large">
                        <Icon icon={ic_delete} size={20} />
                      </IconButton>
                    }
                  >
                    Do you really want to delete this contract?
                  </Confirmation>
                  <StyledLink
                    component={IconButton}
                    href={
                      contract.contractFile
                        ? `${UPLOAD_URL}/${contract.contractFile.path}/${contract.contractFile.filename}`
                        : ''
                    }
                  >
                    <Icon icon={ic_vertical_align_bottom} size={20} />
                  </StyledLink>
                </StyledCardActions>
              </ContractContainer>
            );
          })}
        <AddContractModal />
      </ContractsList>
    </Wrapper>
  );
};
