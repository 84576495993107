import { useCallback, useEffect, useRef, useState } from 'react';

const useIsVisible = (threshold = 0.75) => {
  const [isSelectedVisible, setIsSelectedVisible] = useState(true);
  const currentSelectedRef = useRef();
  const intersectionObserverRef = useRef(
    new IntersectionObserver(
      (entries) => {
        // Assuming there's only one element being observed
        if (entries[0].isIntersecting) {
          setIsSelectedVisible(true);
        } else {
          setIsSelectedVisible(false);
        }
      },
      { threshold }
    )
  );

  const selectedRef = useCallback((current) => {
    const observer = intersectionObserverRef.current;

    if (currentSelectedRef.current) {
      observer.unobserve(currentSelectedRef.current);
    }

    if (current) {
      currentSelectedRef.current = current;
      observer.observe(current);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (selectedRef.current) {
        intersectionObserverRef.current.unobserve(selectedRef.current);
      }
    };
  }, []);

  return [isSelectedVisible, selectedRef, currentSelectedRef];
};

export default useIsVisible;
