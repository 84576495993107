'use strict';

import React from 'react';
import ReactSelect from 'react-select-plus';
import { Field } from 'redux-form';

import './ReduxFormSelect.scss';

/**
 * Parses the given value returned by `react-select`.
 *
 * This function is useful because the input's value could be an array of
 * objects (for a multi-select) or null. We need to be able to handle all of
 * these cases appropriately.
 */
const parseValue = (value) => {
  // If the value is an array, then it could either be an array of objects, or
  // an array of strings. If it's an array of objects with a `value` key, then
  // we actually need to pull that out.
  if (Array.isArray(value)) {
    value = value.map((obj) => (obj.hasOwnProperty('value') ? obj.value : obj));
  }
  // Otherwise the `value` is simply an object, and we want to pull its `value`
  // key out.
  else if (value != null && value.hasOwnProperty('value')) {
    value = value.value;
  }

  return value;
};

const handleBlur = (input) => {
  input.onBlur(parseValue(input.value));
};

const handleChange = (input, value) => {
  input.onChange(parseValue(value));
};

/**
 * Custom `filterOption` implementation that implements fuzzy filtering so that
 * an option is considered a match if every token in the query is the prefix for
 * some token in the option.
 */
const fuzzyFilterOption = (option, query) => {
  // Defines a helper for normalizing a string by converting it to lowercase and
  // removing all non-word characters.
  const normalize = (str) => str.toLowerCase().replace(/[^a-z0-9]/g, '');

  // Defines a helper for tokenizing a string by splitting across any non-word
  // characters, normalizing each token.
  const tokenize = (str) => {
    return str
      .split(/[^A-Za-z0-9]+/)
      .map(normalize)
      .filter((s) => s);
  };

  const normQueryTokens = tokenize(query || '');
  const normOptionTokens = tokenize(option.label || '');

  // Return `true` if every query token is the prefix for some option token.
  return normQueryTokens.every((queryToken) => {
    for (let i = 0; i < normOptionTokens.length; i++) {
      if (normOptionTokens[i].startsWith(queryToken)) {
        return true;
      }
    }
    return false;
  });
};

const wrapSelect = (props) => {
  var {
    input,
    filterOption,

    onChange,
    onBlur,

    ...props
  } = props;

  // HACK: There's a bug in react-select that causes an error in a single-select
  // dropdown if the user clears the value by hitting "backspace" after
  // selecting a value. We need this workaround to fix the issue until a fix
  // is pushed: https://github.com/JedWatson/react-select/issues/746
  if (!props.multi && Array.isArray(input.value) && input.value.length === 0) {
    input.value = null;
  }

  // If we explicitly don't want this element to be clearable, then we shouldn't
  // allow clearing it with the backspace or delete keys.
  if (props.clearable === false) {
    props = { ...props, backspaceRemoves: false, deleteRemoves: false };
  }

  // If a different `filterOption` isn't explicitly passed in, then we should
  // default to using our custom fuzzy filter.
  if (!filterOption) {
    filterOption = fuzzyFilterOption;
  }

  return (
    <ReactSelect
      value={input.value}
      filterOption={filterOption}
      onChange={(value) => {
        handleChange(input, value);
        onChange && onChange(value);
      }}
      onBlur={() => {
        handleBlur(input);
        onBlur && onBlur();
      }}
      {...props}
    />
  );
};

export const ClearReactSelect = (props) => {
  return (
    <ReactSelect
      value={props.value}
      filterOption={props.filterOption}
      onChange={(value) => {
        props.onChange(value);
      }}
      onBlur={() => {
        props.onBlur && onBlur();
      }}
      {...props}
    />
  );
};

const ReduxFormSelect = React.forwardRef((props, ref) => {
  return <Field {...props} component={wrapSelect} ref={ref} />;
});

export default ReduxFormSelect;
