'use strict';

import './Settings.scss';

import React from 'react';
import { connect } from 'react-redux';

import UsersList from './UsersList/UsersList';
import InviteUserModal from './InviteUserModal';

const Team = (props) => {
  return (
    <>
      <UsersList />
      <InviteUserModal show={props.settingsTeam.inviteUserModal.isVisible} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    settingsTeam: state.settingsTeam,
  };
};

export default connect(mapStateToProps)(Team);
