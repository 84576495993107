import { Chip, IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ic_more_horiz } from 'react-icons-kit/md/ic_more_horiz';
import Icon from 'common/components/Icon';
import { smileO } from 'react-icons-kit/fa/smileO';
import { mehO } from 'react-icons-kit/fa/mehO';
import { frownO } from 'react-icons-kit/fa/frownO';
import { useDispatch } from 'react-redux';
import { updateArticleReaction } from '../../store/actions';
import classNames from 'classnames';
import Box from '@mui/material/Box';

const modules = {
  toolbar: false,
};

const Article = ({ article, articlesReactionsList }) => {
  const { header, publicationDate, text, owner, status } = article;
  const [isFullText, setIsFullText] = useState(true);
  const [reaction, setReaction] = useState(null);

  const quillRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (quillRef.current) {
      setTimeout(() => {
        const height = quillRef.current.editor.root.clientHeight;
        if (height > 120) setIsFullText(false);
      });
    }
  }, [quillRef.current]);

  useEffect(() => {
    if (articlesReactionsList && article) {
      const reactionFind = articlesReactionsList.find((item) => {
        return Object.keys(item)[0] == article.id;
      });
      if (reactionFind) setReaction(Object.values(reactionFind)[0]);
    }
  }, [articlesReactionsList, article]);

  // const props = {
  //   backgroundColorStatus: status === 'DRAFT' ? '#b3b3b3' : '#78B961',
  //   isFullText,
  // };

  const publicationDateFormat = publicationDate
    ? moment(publicationDate).utc().format('DD-MM-YYYY HH:mm')
    : publicationDate;

  const articleReactionClickHandler = (reaction) => {
    dispatch(updateArticleReaction(article.id, reaction));
  };

  return (
    <Box
      sx={{
        borderRadius: '8px',
        padding: '10px',
        marginTop: '10px',
        backgroundColor: 'var(--galleon-blue-1)',
        '& h5': {
          fontSize: '18px',
          marginBottom: '3px',
          fontWeight: 600,
        },
        '& .date': {
          color: 'var(--galleon-dark)',
          fontSize: 14,
        },
      }}
    >
      <h5>{header}</h5>
      <p className="date">{publicationDateFormat}</p>

      <Box
        sx={{
          marginTop: '10px',
          maxHeight: isFullText ? 'max-content' : '120px',
          overflow: 'hidden',
          '& .ql-container': {
            border: 'none !important',
            '& .ql-editor': {
              padding: 0,
              fontFamily: 'Outfit',
            },
          },
        }}
      >
        <ReactQuill
          readOnly
          theme="snow"
          defaultValue={text}
          modules={modules}
          ref={quillRef}
        />
      </Box>
      {!isFullText && (
        <Box
          sx={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            position: 'relative',
            top: '5px',
            '&:hover': {
              '._button': {
                background: 'white',
                borderColor: 'var(--galleon-blue)',
                svg: {
                  fill: 'var(--galleon-blue) !important',
                },
              },
              '._line': {
                background: 'var(--galleon-blue)',
              },
            },
          }}
          onClick={() => setIsFullText(true)}
        >
          <Box
            className={'_line'}
            sx={{
              height: 2,
              width: '100%',
              background: 'var(--galleon-blue-5)',
              position: 'absolute',
              top: '14px',
            }}
          ></Box>
          <IconButton
            className={'_button'}
            aria-label="delete"
            size="large"
            sx={{
              width: '120px',
              height: '30px',
              borderRadius: '20px',
              background: 'white',
              border: '2px solid var(--galleon-blue-5)',
              svg: {
                fill: '#92bde8 !important',
              },
            }}
          >
            <Icon size={50} icon={ic_more_horiz} />
          </IconButton>
        </Box>
      )}
      <Box
        sx={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            '& > div': {
              marginBottom: '5px',
            },
            '& > div:not(:last-child)': {
              marginRight: '5px',
            },
          }}
        >
          {article.tags &&
            article.tags.length > 0 &&
            article.tags.map((item) => (
              <Tooltip key={item.id} title={item.description}>
                <Chip size="small" label={item.name} color="primary" />
              </Tooltip>
            ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              // marginRight: 10,
              '& p': {
                textAlign: 'right',
              },
              '& :first-child': {
                color: 'var(--galleon-dark-10)',
                fontSize: 11,
                marginBottom: '2px',
              },
              '& :last-child': { margin: 0, fontSize: 14 },
            }}
          >
            <p>Written by</p>
            <p>{owner.name}</p>
          </Box>
          {/* <Box sx={{
    width: 32,
    height: 32,
    background: ({ backgroundColorStatus }) => backgroundColorStatus,
    borderRadius: '50%',
  }}></div> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <IconButton
          aria-label="delete"
          className={classNames({ active: reaction === 'DISLIKE' })}
          onClick={() => articleReactionClickHandler('DISLIKE')}
          size="large"
          sx={{
            padding: '5px',
            color: 'var(--galleon-neutral-8)',
            '&.active': {
              color: 'var(--red)',
            },
          }}
        >
          <Icon size={22} icon={frownO} />
        </IconButton>
        <IconButton
          aria-label="delete"
          className={classNames({ active: reaction === 'NEUTRAL' })}
          onClick={() => articleReactionClickHandler('NEUTRAL')}
          size="large"
          sx={{
            padding: '5px',
            color: 'var(--galleon-neutral-8)',
            '&.active': {
              color: 'var(--galleon-blue)',
            },
          }}
        >
          <Icon size={22} icon={mehO} />
        </IconButton>
        <IconButton
          aria-label="delete"
          className={classNames({ active: reaction === 'LIKE' })}
          name="LIKE"
          onClick={() => articleReactionClickHandler('LIKE')}
          size="large"
          sx={{
            padding: '5px',
            color: 'var(--galleon-neutral-8)',
            '&.active': {
              color: 'var(--galleon-green-5)',
            },
          }}
        >
          <Icon size={22} icon={smileO} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Article;
