import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styledComponents from 'styled-components';
import { setFeedbackModalOpen } from '../store/actions';
import FeedbackModal from './FeedbackModal';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Button from 'common/components/Button';

const WidgetButtonWrapper = styledComponents.div`
  position: fixed;
  z-index: 999999900;
  bottom: 25px;
  left: auto;
  right: 25px;
  height: 55px;
  width: 55px;
  overflow: hidden;
  background-color: rgb(70, 145, 218);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  & .iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .closeFeedbackIcon {
      opacity: 0;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    .closeFeedbackIcon,
    .openFeedbackIcon {
      position: absolute;
    }
    &.activate {
      .closeFeedbackIcon {
        -webkit-animation: rotate-90-closeIcon-show 0.4s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: rotate-90-closeIcon-show 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }
      .openFeedbackIcon {
        -webkit-animation: rotate-90-cw-openIcon-hide 0.4s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: rotate-90-cw-openIcon-hide 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          both;
      }
    }
    &.inactive {
      .closeFeedbackIcon {
        -webkit-animation: rotate-90-ccw-closeIcon-hide 0.4s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: rotate-90-ccw-closeIcon-hide 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          both;
      }
      .openFeedbackIcon {
        -webkit-animation: rotate-90-ccw-openIcon-show 0.4s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: rotate-90-ccw-openIcon-show 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          both;
      }
    }
  }
  @keyframes rotate-90-closeIcon-show {
    0% {
      opacity: 0%;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
    100% {
      opacity: 100%;
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
  }

  @keyframes rotate-90-cw-openIcon-hide {
    0% {
      opacity: 100%;
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }

    100% {
      opacity: 0%;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
  @keyframes rotate-90-ccw-closeIcon-hide {
    0% {
      opacity: 100%;
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      opacity: 0%;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }
  @keyframes rotate-90-ccw-openIcon-show {
    0% {
      opacity: 0%;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    100% {
      opacity: 100%;
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
  }
`;

const FeedbackWidget = () => {
  const openFeedback = useSelector((state) => state.feedbackModalOpen);
  const dispatch = useDispatch();
  const [headerText, setHeaderText] = useState('Share your feedback!');

  const handleCloseModal = () => {
    setTimeout(() => {
      setHeaderText('Share your feedback!');
    }, 300);
    dispatch(setFeedbackModalOpen(false));
  };

  const handleToggleModal = () => {
    if (!openFeedback) setHeaderText("Have questions? We're here to help.");

    if (openFeedback)
      setTimeout(() => {
        setHeaderText('Share your feedback!');
      }, 300);

    dispatch(setFeedbackModalOpen(!openFeedback));
  };

  return (
    <>
      <div
        style={{
          margin: 'auto auto 21px auto',
          width: 'calc(100% - 42px)',
        }}
      >
        <Button
          sx={{
            letterSpacing: '0.15px',
            fontSize: '13px',
            fontWeight: '400',
            width: '100%',
            borderRadius: '8px !important',
            border: '1px solid var(--galleon-blue-1-73) !important',
            color: '#fff !important',
            padding: '4px !important',
          }}
          startIcon={<MailOutlineIcon />}
          variant="outlined"
          onClick={handleToggleModal}
        >
          Feedback
        </Button>
      </div>

      <FeedbackModal
        open={openFeedback}
        handleCloseModal={handleCloseModal}
        headerText={headerText}
      />
    </>
  );
};

export default FeedbackWidget;
