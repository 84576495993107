import React, { memo, useMemo } from 'react';
import Paper from '@mui/material/Paper';
import useTableHeight from './useTableHeight';

const DataGridWrapper = ({
  children,
  style = {},
  customPudding,
  countRowsPeerPage,
  customComponentHeight,
}) => {
  const { sellQuantity, homeSvgViewerRef, componentHeight } =
    useTableHeight(customPudding);

  const minHeight = useMemo(() => {
    return countRowsPeerPage ? countRowsPeerPage * 40 + 56 + 52 : 600;
  }, [countRowsPeerPage]);

  return (
    <Paper
      ref={homeSvgViewerRef}
      elevation={1}
      className="DiagramPaper"
      sx={{
        height: `${customComponentHeight || componentHeight}px`,
        borderRadius: '16px',
        minHeight: `${minHeight}px`,
        overflow: 'hidden',
        backgroundColor: '#fff',
        ...style,
      }}
    >
      {componentHeight || componentHeight === 0 ? children : null}
    </Paper>
  );
};

export default memo(DataGridWrapper);
