import React from 'react';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import { ic_add } from 'react-icons-kit/md/ic_add';
import Button from 'common/components/Button';
import Icon from 'common/components/Icon';
import { ic_clear } from 'react-icons-kit/md/ic_clear';
import { ic_help } from 'react-icons-kit/md/ic_help';
import TooltipIcon from 'common/components/TooltipIcon';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import ReactSelect from 'react-select-plus';
import { FormGroup, FormLabel } from '@mui/material';
import InputMui from '@mui/material/Input';

const FormRow = styled.div`
  margin: 0 0 20px;
  .MuiButtonBase-root {
    padding: 3px 9px;
  }
  h6 {
    margin: 0 0 6px;
  }
  font-size: 12px;
  text-align: start;
`;

const ContractBlock = styled.div`
  h5 {
    margin: 0 0 20px;
  }

  .MuiSelect-select:focus {
    background-color: inherit;
  }
`;

const ToolTipCustom = styled(TooltipIcon)`
  vertical-align: top !important;
  margin-top: -10px;
`;

const ContractBlockContent = styled(Box)`
  padding: 20px 20px 10px;
  margin-bottom: 30px;
  width: 100%;
`;

const StyledFormControl = styled(FormControl)`
  min-width: 100%;
  max-width: 100%;
`;

export const NetRateSelect = ({
  netRates,
  index,
  netRateSelected,
  setNetRateSelected,
}) => {
  const selectNetRateHandler = (value, label) => {
    const newNetRate = [...netRateSelected];
    newNetRate[index] = {
      ...newNetRate[index],
      netRateId: value,
      name: label,
    };
    setNetRateSelected(newNetRate);
  };

  const selectSurcharge = (event) => {
    const { value, name } = event.target;
    const newNetRate = [...netRateSelected];
    newNetRate[index] = { ...newNetRate[index], [name]: value };
    setNetRateSelected(newNetRate);
  };

  const addChargeRow = () => {
    const newNetRate = [...netRateSelected];
    newNetRate[index].charges.push({
      charge_type: '',
      charge_surcharge_type: '',
      charge_surcharge_value: '',
    });
    setNetRateSelected(newNetRate);
  };

  const changeChargeValues = (value, name, chargeIndex) => {
    const newNetRate = [...netRateSelected];
    newNetRate[index].charges[chargeIndex] = {
      ...newNetRate[index].charges[chargeIndex],
      [name]: value,
    };
    setNetRateSelected(newNetRate);
  };

  const removeChargeRow = (chargeIndex) => {
    const newNetRate = [...netRateSelected];
    newNetRate[index].charges.splice(chargeIndex, 1);
    setNetRateSelected(newNetRate);
  };

  return (
    <ContractBlock>
      <ContractBlockContent className="new_mui_card">
        <FormRow>
          <FormGroup>
            <FormLabel>Net Rate</FormLabel>
            <ReactSelect
              placeholder="Select Net Rate"
              clearable={false}
              value={netRateSelected[index].netRateId}
              options={
                netRates && netRates.length > 0
                  ? netRates.map((item) => ({
                      value: item.id,
                      label: `${item.number} (${item.service_type})`,
                    }))
                  : []
              }
              onChange={({ value, label }) => {
                selectNetRateHandler(value, label);
              }}
            />
          </FormGroup>
        </FormRow>
        {netRateSelected[index].charges &&
          netRateSelected[index].charges.length > 0 &&
          netRateSelected[index].charges.map((chargeItem, chargeIndex) => {
            return (
              <FormRow key={chargeIndex}>
                <Grid container spacing={1}>
                  <Grid xs={4}>
                    <FormGroup>
                      <FormLabel>Charge name</FormLabel>
                      <ReactSelect
                        name="charge_type"
                        placeholder="Select Net Rate"
                        clearable={false}
                        value={netRateSelected[index].charges[chargeIndex].charge_type}
                        onChange={({ value }) =>
                          changeChargeValues(value, 'charge_type', chargeIndex)
                        }
                        options={charge_type_map}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid xs={4}>
                    {/*<StyledFormControl variant="outlined">*/}
                    {/*  <InputLabel>Charge type</InputLabel>*/}
                    {/*  <Select*/}
                    {/*    variant="standard"*/}
                    {/*    label="Surcharge"*/}
                    {/*    name="charge_surcharge_type"*/}
                    {/*    value={*/}
                    {/*      netRateSelected[index].charges[chargeIndex]*/}
                    {/*        .charge_surcharge_type*/}
                    {/*    }*/}
                    {/*    onChange={(e) => changeChargeValues(e, chargeIndex)}*/}
                    {/*  >*/}
                    {/*    <MenuItem value="numeric">numeric (+/-)</MenuItem>*/}
                    {/*    <MenuItem value="percent">percent (%/-%)</MenuItem>*/}
                    {/*  </Select>*/}
                    {/*</StyledFormControl>*/}
                    <FormGroup>
                      <FormLabel>Charge type</FormLabel>
                      <ReactSelect
                        name="charge_surcharge_type"
                        placeholder="Select Charge type"
                        clearable={false}
                        value={
                          netRateSelected[index].charges[chargeIndex]
                            .charge_surcharge_type
                        }
                        onChange={({ value }) =>
                          changeChargeValues(value, 'charge_surcharge_type', chargeIndex)
                        }
                        options={charge_surcharge_type_map}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid xs={3}>
                    <StyledFormControl variant="outlined">
                      <FormLabel className="control-label">Value</FormLabel>
                      <InputMui
                        variant="outlined"
                        size="small"
                        className="form-control searchInputMui"
                        name="charge_surcharge_value"
                        type="number"
                        placeholder=" "
                        inputProps={{
                          step: 0.1,
                        }}
                        value={
                          netRateSelected[index].charges[chargeIndex]
                            .charge_surcharge_value
                        }
                        onChange={(e) =>
                          changeChargeValues(e.target.value, e.target.name, chargeIndex)
                        }
                        sx={{
                          marginTop: '0 !important',
                        }}
                      />
                      {/*<OutlinedInput*/}
                      {/*  label="Value"*/}
                      {/*  type="number"*/}
                      {/*  name="charge_surcharge_value"*/}
                      {/*  inputProps={{*/}
                      {/*    step: 0.1,*/}
                      {/*  }}*/}
                      {/*  value={*/}
                      {/*    netRateSelected[index].charges[chargeIndex]*/}
                      {/*      .charge_surcharge_value*/}
                      {/*  }*/}
                      {/*  onChange={(e) => changeChargeValues(e, chargeIndex)}*/}
                      {/*/>*/}
                    </StyledFormControl>
                  </Grid>
                  <Grid xs={1}>
                    <Button
                      variant="outline-danger"
                      style={{ 'margin-top': '17px' }}
                      onClick={() => removeChargeRow(chargeIndex)}
                    >
                      <Icon icon={ic_clear} />
                    </Button>
                  </Grid>
                </Grid>
              </FormRow>
            );
          })}
        <FormRow>
          <Button variant="contained" onClick={addChargeRow}>
            <Icon icon={ic_add} /> Add charge
          </Button>
          <ToolTipCustom
            placement="right"
            icon={ic_help}
            size="15px"
            onClick={() =>
              window.open(
                'https://docs.google.com/document/d/14TnDfYbO0WdL_l5pbGpnPc_gOg_l92LcnLTtOP2rYyU/edit?usp=sharing'
              )
            }
          >
            Click to see documentation
          </ToolTipCustom>
        </FormRow>
      </ContractBlockContent>
    </ContractBlock>
  );
};

const charge_type_map = [
  {
    value: 'Peak',
    label: 'Peak',
  },
  {
    value: 'Additional Handling',
    label: 'Additional Handling',
  },
  {
    value: 'Address Correction',
    label: 'Address Correction',
  },
  {
    value: 'Dangerous Goods',
    label: 'Dangerous Goods',
  },
  {
    value: 'Declared Value',
    label: 'Declared Value',
  },
  {
    value: 'Delivery Area',
    label: 'Delivery Area',
  },
  {
    value: 'Delivery Reattempt',
    label: 'Delivery Reattempt',
  },
  {
    value: 'Cash On Delivery (COD)',
    label: 'Cash On Delivery (COD)',
  },
  {
    value: 'Signature',
    label: 'Signature',
  },
  {
    value: 'Package',
    label: 'Package',
  },
  {
    value: 'Pickup',
    label: 'Pickup',
  },
  {
    value: 'Oversized',
    label: 'Oversized',
  },
  {
    value: 'Residential Delivery',
    label: 'Residential Delivery',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

const charge_surcharge_type_map = [
  {
    value: 'numeric',
    label: 'numeric (+/-)',
  },
  {
    value: 'percent',
    label: 'percent (%/-%)',
  },
];
