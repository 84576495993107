import React, { memo, useEffect } from 'react';
import ChartJS from 'chart.js/auto';
import _sumBy from 'lodash/sumBy';
import * as ChartGeo from 'chartjs-chart-geo';
import * as Constants from './Constants';
import GeoChartViewModel from './GeoChartViewModel';
import classNames from 'classnames';
import * as utils from 'common/helpers/utils';
import { useDispatch, useSelector } from 'react-redux';

const chartProjectionMap = {
  US: {
    projection: 'albersUsa',
  },
  WORLD: {
    projection: 'equalEarth',
  },
  CA: {
    projection: 'mercator',
    projectionScale: 6,
    projectionOffset: [0, 0],
  },
};

const chartLabelMap = {
  US: 'State',
  WORLD: 'Country',
};

ChartJS.register(
  ChartGeo.ChoroplethController,
  ChartGeo.GeoFeature,
  ChartGeo.ColorScale,
  ChartGeo.ProjectionScale
);

const colorPaletteMap = {
  '0-1%': '#99CA8A',
  '2-3%': '#78B780',
  '4-5%': '#5A985D',
  '6-10%': '#3B7852',
  '>10%': '#2E694B',
  defaultFill: '#D7EF91',
};

const getColorByPercent = (percentValue = 0) => {
  if (percentValue === 0) {
    return colorPaletteMap.defaultFill;
  }
  if (percentValue <= 1) {
    return colorPaletteMap['0-1%'];
  }
  if (percentValue <= 3) {
    return colorPaletteMap['2-3%'];
  }
  if (percentValue <= 5) {
    return colorPaletteMap['4-5%'];
  }
  if (percentValue <= 10) {
    return colorPaletteMap['6-10%'];
  }
  return colorPaletteMap['>10%'];
};

const GeoChoroplethChart = (props) => {
  const topo = useSelector((state) => state.topoSlice);

  const key = props.queryChartData.geo;

  const totalValue = _sumBy(props.data, 'value');

  const chartConfig = {
    type: 'choropleth',
    data: {
      labels: topo[key].states.map((d) => d.properties.name),
      datasets: [
        {
          label: chartLabelMap[props.queryChartData.geo],
          outline: topo[key].nation,
          data: topo[key].states.map((d) => {
            const baseValue =
              props.data.find((propsData) => propsData.id === d.id)?.value || 0;

            const percentValue = baseValue
              ? Number(((baseValue / totalValue) * 100).toFixed(1))
              : 0;
            // console.log(utils.hexToRGB(getColorByPercent(percentValue)));

            return {
              backgroundColor: utils.hexToRGB(getColorByPercent(percentValue)),
              feature: d,
              value: percentValue,
              _baseValue: baseValue,
            };
          }),
        },
      ],
    },
    options: {
      responsive: true,
      parsing: false,
      animation: false,
      maintainAspectRatio: false,
      // normalized:frue,
      // backgroundColor: (context) => {
      //   if (context.raw) {
      //     return context.raw.backgroundColor;
      //   }
      // },
      // borderColor: () => 'rgb(255, 255, 255)',
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: Constants.TOOLTIP_BACKGROUND_COLOR,
          callbacks: {
            label: (context) => {
              const itemLabel = context.raw.feature.properties.name;

              if (!context.raw._baseValue) {
                return `${itemLabel}: 0`;
              }

              let itemValue = context.raw._baseValue;

              if (props.yAxisTooltipsFmt) {
                itemValue = props.yAxisTooltipsFmt(itemValue);
              }

              let itemPerc = '';
              if (props.yAxisTooltipsPercFmt) {
                // itemValue = props.yAxisTooltipsPercFmt(itemValue);
                itemPerc = ` (${context.raw.value}%)`;
              }

              return `${itemLabel}: ${itemValue}${itemPerc}`;
            },
          },
        },
      },

      // defaults: {
      //   color: '#2E694B',
      // },
      // legend: {
      //   display: false,
      // },
      showOutline: false,
      showGraticule: false,
      scales: {
        projection: {
          axis: 'x',
          // projection: 'mercator',
          // projectionScale: 6,
          // projectionOffset: [0, 0],
          ...chartProjectionMap[key],
        },
      },
      geo: {
        colorScale: {
          display: true,
          position: 'bottom',
          quantize: 5,
          legend: {
            position: 'bottom-right',
          },
          // interpolate: (v) => {
          //   // map a value from v 0 to 1 to my target colors;
          //   // 0 - 3 is green
          //   // 3- 7 is orange
          //   // 7- 10 is red
          //   console.log(v)
          //   if (v <= 0.3) {
          //     return 'green';
          //   }
          //   if (v <= 0.7) {
          //     return 'orange';
          //   }
          //   return 'red';
          // },
        },
      },
    },
  };

  return (
    <GeoChartViewModel
      title={props.title}
      helpText={props.helpText}
      chartConfig={chartConfig}
      className={classNames(props.className, 'GeoChart')}
      hideLegend={false}
      isHideDatasetsSelect={true}
      height={props.height}
      shipperSubscriptions={props.shipperSubscriptions}
    />
  );
};

const GeoChoroplethChartHoc = (props) => {
  const dispatch = useDispatch();
  const topo = useSelector((state) => state.topoSlice);

  const key = props.queryChartData.geo;

  useEffect(() => {
    if (topo[key].loading === 'INIT') {
      dispatch(props.getUpdateTopo(key));
    }
  }, []);

  if (topo[key].loading === 'INIT' || topo[key].loading === 'PROCESS') {
    return <></>;
  }

  return <GeoChoroplethChart {...props} />;
};
export default memo(GeoChoroplethChartHoc);
