import React, { useLayoutEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { Field } from 'redux-form';
import { insertValidate, required } from './utils';
import FormGroup from '@mui/material/FormGroup';
import ReduxFormDropzone from '../ReduxFormDropzone';
import TextField from '@mui/material/TextField';
import ReduxFormToggleButton from '../reduxFormToggleButton/ReduxFormToggleButton';
import Box from '@mui/material/Box';

const UploadBlock = ({
  radioValue,
  onChangeRadioValue,
  fieldName = 'trackingNumbers',
  dropzoneName = 'files',
  textFieldPlaceholder = 'Insert tracking numbers without comma. Each TN should start from the new line.',
  dropzonePlaceholder = 'Please select csv or txt file to upload. File should not contain any headers, single column with tracking numbers only.',
  label = 'Upload Type',
  type = 'radio', //radio button
}) => {
  useLayoutEffect(() => {
    if (!radioValue && onChangeRadioValue) {
      onChangeRadioValue('insert');
    }
  }, [radioValue]);

  return (
    <>
      <FormControl>
        <FormLabel sx={{ marginBottom: 0 }} id="demo-radio-buttons-group-label">
          {label}
        </FormLabel>
        {type === 'radio' ? (
          <Field
            name="upload_radio_value"
            component={RadioComponent}
            radioValue={radioValue}
            props={{ onChangeRadioValue: onChangeRadioValue }}
          />
        ) : (
          <Box sx={{ marginTop: '10px' }}>
            <FormToggleButtonComponent
              onChangeRadioValue={onChangeRadioValue}
              radioValue={radioValue}
              fieldName="upload_radio_value"
            />
          </Box>
        )}
      </FormControl>
      {radioValue === 'file' && (
        <DropzoneComponent placeholder={dropzonePlaceholder} name={dropzoneName} />
      )}
      {radioValue === 'insert' && (
        <Field
          name={fieldName}
          component={TextFieldWrapper}
          label={textFieldPlaceholder}
          validate={[required, insertValidate]}
        />
      )}
    </>
  );
};

const DropzoneComponent = ({ placeholder, name }) => {
  return (
    <FormGroup>
      <ReduxFormDropzone
        name={name}
        placeholder={placeholder}
        multiple={false}
        required
        accept={['text/csv', 'text/plain']}
        validate={[required]}
      />
    </FormGroup>
  );
};

export const TextFieldWrapper = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => {
  return (
    <FormGroup>
      <TextField
        sx={{
          '& .MuiInputBase-root': {
            padding: '11px 14px !important',
          },
        }}
        minRows={4}
        multiline
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
      />
    </FormGroup>
  );
};

const RadioComponent = ({ input, custom, radioValue, onChangeRadioValue }) => {
  const onChangeHandler = (event, value) => {
    onChangeRadioValue(value);
    input.onChange(event, value);
  };

  return (
    <FormGroup>
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        value={radioValue}
        sx={{
          '& .MuiFormControlLabel-root ': {
            marginBottom: 0,
          },
        }}
        // value={value}
        {...custom}
        {...input}
        onChange={onChangeHandler}
      >
        <FormControlLabel value="insert" control={<Radio />} label="Insert here" />

        <FormControlLabel
          value="file"
          control={<Radio />}
          label="Upload file (csv / txt)"
        />
      </RadioGroup>
    </FormGroup>
  );
};

const FormToggleButtonComponent = ({ fieldName, radioValue, onChangeRadioValue }) => {
  const onChange = (_fieldName, newValue) => {
    onChangeRadioValue(newValue);
  };

  return (
    <ReduxFormToggleButton
      value={radioValue}
      fieldName={fieldName}
      change={onChange}
      optionList={[
        {
          value: 'insert',
          label: 'Insert here',
        },
        {
          value: 'file',
          label: 'Upload file (csv / txt)',
        },
      ]}
    />
  );
};

export default UploadBlock;
