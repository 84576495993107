import React from 'react';

const ShippingSetupIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path d="M16 3H1V16H16V3Z" />
      <path d="M16 8H20L23 11V16H16V8Z" />
      <path d="M5.5 21C6.88071 21 8 19.8807 8 18.5C8 17.1193 6.88071 16 5.5 16C4.11929 16 3 17.1193 3 18.5C3 19.8807 4.11929 21 5.5 21Z" />
      <path d="M18.5 21C19.8807 21 21 19.8807 21 18.5C21 17.1193 19.8807 16 18.5 16C17.1193 16 16 17.1193 16 18.5C16 19.8807 17.1193 21 18.5 21Z" />
      <path d="M8.05155 11.849L7.99748 11.5868L7.74921 11.4864C7.61689 11.433 7.49474 11.3776 7.38242 11.3205C7.28035 11.2686 7.17163 11.2028 7.05627 11.1214L6.85719 10.981L6.62391 11.0512L5.94836 11.2546L5.6493 10.7614L6.15227 10.3372L6.36792 10.1553L6.32372 9.87668C6.30373 9.75059 6.29381 9.62511 6.29381 9.5C6.29381 9.37489 6.30373 9.24941 6.32372 9.12332L6.36792 8.84469L6.15227 8.6628L5.6493 8.23856L5.94836 7.74541L6.62391 7.94878L6.85719 8.019L7.05627 7.87857C7.17163 7.79718 7.28035 7.73137 7.38242 7.67951C7.49474 7.62244 7.61689 7.56705 7.74921 7.51357L7.99748 7.41323L8.05155 7.15097L8.18577 6.5H8.81423L8.94845 7.15097L9.00252 7.41323L9.25079 7.51357C9.37448 7.56356 9.49228 7.62075 9.60445 7.68504C9.71661 7.74933 9.82355 7.82096 9.92543 7.90002L10.1368 8.06405L10.3906 7.97918L11.053 7.75759L11.3549 8.27002L10.8477 8.6978L10.6321 8.8797L10.6763 9.15832C10.6966 9.28654 10.7062 9.40611 10.7062 9.5175C10.7062 9.62889 10.6966 9.74846 10.6763 9.87668L10.6321 10.1553L10.8477 10.3372L11.3507 10.7614L11.0516 11.2546L10.3761 11.0512L10.1428 10.981L9.94373 11.1214C9.82836 11.2028 9.71965 11.2686 9.61758 11.3205C9.50526 11.3776 9.38311 11.433 9.25079 11.4864L9.00252 11.5868L8.94845 11.849L8.81423 12.5H8.18577L8.05155 11.849ZM8.5 11.4C9.02572 11.4 9.49069 11.2131 9.86746 10.8476C10.2447 10.4817 10.4433 10.0237 10.4433 9.5C10.4433 8.97632 10.2447 8.51827 9.86746 8.15235C9.49069 7.78689 9.02572 7.6 8.5 7.6C7.97428 7.6 7.50931 7.78689 7.13254 8.15235C6.75531 8.51827 6.5567 8.97632 6.5567 9.5C6.5567 10.0237 6.75531 10.4817 7.13254 10.8476C7.50931 11.2131 7.97428 11.4 8.5 11.4Z" />
      <path d="M8.05155 11.849L7.99748 11.5868L7.74921 11.4864C7.61689 11.433 7.49474 11.3776 7.38242 11.3205C7.28035 11.2686 7.17163 11.2028 7.05627 11.1214L6.85719 10.981L6.62391 11.0512L5.94836 11.2546L5.6493 10.7614L6.15227 10.3372L6.36792 10.1553L6.32372 9.87668C6.30373 9.75059 6.29381 9.62511 6.29381 9.5C6.29381 9.37489 6.30373 9.24941 6.32372 9.12332L6.36792 8.84469L6.15227 8.6628L5.6493 8.23856L5.94836 7.74541L6.62391 7.94878L6.85719 8.019L7.05627 7.87857C7.17163 7.79718 7.28035 7.73137 7.38242 7.67951C7.49474 7.62244 7.61689 7.56705 7.74921 7.51357L7.99748 7.41323L8.05155 7.15097L8.18577 6.5H8.81423L8.94845 7.15097L9.00252 7.41323L9.25079 7.51357C9.37448 7.56356 9.49228 7.62075 9.60445 7.68504C9.71661 7.74933 9.82355 7.82096 9.92543 7.90002L10.1368 8.06405L10.3906 7.97918L11.053 7.75759L11.3549 8.27002L10.8477 8.6978L10.6321 8.8797L10.6763 9.15832C10.6966 9.28654 10.7062 9.40611 10.7062 9.5175C10.7062 9.62889 10.6966 9.74846 10.6763 9.87668L10.6321 10.1553L10.8477 10.3372L11.3507 10.7614L11.0516 11.2546L10.3761 11.0512L10.1428 10.981L9.94373 11.1214C9.82836 11.2028 9.71965 11.2686 9.61758 11.3205C9.50526 11.3776 9.38311 11.433 9.25079 11.4864L9.00252 11.5868L8.94845 11.849L8.81423 12.5H8.18577L8.05155 11.849ZM8.5 11.4C9.02572 11.4 9.49069 11.2131 9.86746 10.8476C10.2447 10.4817 10.4433 10.0237 10.4433 9.5C10.4433 8.97632 10.2447 8.51827 9.86746 8.15235C9.49069 7.78689 9.02572 7.6 8.5 7.6C7.97428 7.6 7.50931 7.78689 7.13254 8.15235C6.75531 8.51827 6.5567 8.97632 6.5567 9.5C6.5567 10.0237 6.75531 10.4817 7.13254 10.8476C7.50931 11.2131 7.97428 11.4 8.5 11.4Z" />
      <path
        d="M8.05155 11.849L7.99748 11.5868L7.74921 11.4864C7.61689 11.433 7.49474 11.3776 7.38242 11.3205C7.28035 11.2686 7.17163 11.2028 7.05627 11.1214L6.85719 10.981L6.62391 11.0512L5.94836 11.2546L5.6493 10.7614L6.15227 10.3372L6.36792 10.1553L6.32372 9.87668C6.30373 9.75059 6.29381 9.62511 6.29381 9.5C6.29381 9.37489 6.30373 9.24941 6.32372 9.12332L6.36792 8.84469L6.15227 8.6628L5.6493 8.23856L5.94836 7.74541L6.62391 7.94878L6.85719 8.019L7.05627 7.87857C7.17163 7.79718 7.28035 7.73137 7.38242 7.67951C7.49474 7.62244 7.61689 7.56705 7.74921 7.51357L7.99748 7.41323L8.05155 7.15097L8.18577 6.5H8.81423L8.94845 7.15097L9.00252 7.41323L9.25079 7.51357C9.37448 7.56356 9.49228 7.62075 9.60445 7.68504C9.71661 7.74933 9.82355 7.82096 9.92543 7.90002L10.1368 8.06405L10.3906 7.97918L11.053 7.75759L11.3549 8.27002L10.8477 8.6978L10.6321 8.8797L10.6763 9.15832C10.6966 9.28654 10.7062 9.40611 10.7062 9.5175C10.7062 9.62889 10.6966 9.74846 10.6763 9.87668L10.6321 10.1553L10.8477 10.3372L11.3507 10.7614L11.0516 11.2546L10.3761 11.0512L10.1428 10.981L9.94373 11.1214C9.82836 11.2028 9.71965 11.2686 9.61758 11.3205C9.50526 11.3776 9.38311 11.433 9.25079 11.4864L9.00252 11.5868L8.94845 11.849L8.81423 12.5H8.18577L8.05155 11.849ZM8.5 11.4C9.02572 11.4 9.49069 11.2131 9.86746 10.8476C10.2447 10.4817 10.4433 10.0237 10.4433 9.5C10.4433 8.97632 10.2447 8.51827 9.86746 8.15235C9.49069 7.78689 9.02572 7.6 8.5 7.6C7.97428 7.6 7.50931 7.78689 7.13254 8.15235C6.75531 8.51827 6.5567 8.97632 6.5567 9.5C6.5567 10.0237 6.75531 10.4817 7.13254 10.8476C7.50931 11.2131 7.97428 11.4 8.5 11.4Z"
        strokeOpacity="0.2"
      />
    </svg>
  );
};

export default ShippingSetupIcon;
