import './Chart.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import ChartJS from 'chart.js/auto';
import _debounce from 'lodash.debounce';

import HelpIcon from 'common/components/HelpIcon';
import Box from '@mui/material/Box';
import {
  POINT_CONFIG,
  CIRCLE_CHARTS,
  ONE_ELEMENT_IN_CHART,
  useClassSubscriptionsForCharts,
} from './Constants';
import ChartPopoverWrapper from './components/ChartPopoverWrapper';
import { SpinnerContainer } from '../Spinner';
import { Divider } from '@mui/material';
import ChartDatasetsSelect from './components/ChartDatasetsSelect';
import ChartTagLegend from './components/ChartTagLegend';

/**
 * Renders a chart. Expects the following props:
 *
 *   - title {string}: The chart title.
 *   - xAxisLabel {string}: The label for the x axis.
 *   - yAxisLabel {string}: The label for the y axis.
 *   - data {Array}: The data array.
 *   - spec {Object}: The Vega chart specification.
 *   - aspectRatio {Number}: The aspect ratio
 *   - padding {Object}: An object specifying the `top`, `right`, `bottom`, and
 *     `left` padding values.
 *
 * Note that that this component *always* renders an SVG chart so as to allow us
 * to specify custom fonts.
 */
const Chart = React.forwardRef((props) => {
  const additionalClass = useClassSubscriptionsForCharts(props.shipperSubscriptions);

  const chartCanvasRef = useRef(null);
  const wrapperRef = useRef(null);
  const [canvasWidth, setCanvasWidth] = useState(0);

  /**
   * Calculate canvas width.
   */
  useLayoutEffect(() => {
    const blockElement = wrapperRef.current;

    const updateBlockSize = _debounce(() => {
      if (blockElement) {
        const { width, height } = blockElement.getBoundingClientRect();
        setCanvasWidth(width);
      }
    }, 10);

    const resizeObserver = new ResizeObserver(updateBlockSize);
    if (blockElement) {
      resizeObserver.observe(blockElement);
      updateBlockSize();
    }

    return () => {
      if (blockElement) {
        resizeObserver.unobserve(blockElement);
      }
    };
  }, []);

  const rebuildChartCallback = useCallback(async () => {
    if (chartCanvasRef.current && props.config) {
      const chartInstance = await new ChartJS(chartCanvasRef.current, props.config);

      props.setChart(chartInstance);
    }
  }, [props.config, props.setChart, props.chart]);

  const updateChart = useCallback(() => {
    if (!!props.chart && !!props.chart.ctx) {
      // props.chart.update();
    } else {
      rebuildChartCallback();
    }
  }, [props.chart]);

  /**
   * Parse the vega spec and create the view.
   * If the component's state / props changed, then we should update the data in
   * the view.
   */
  useEffect(() => {
    if (!!canvasWidth) {
      updateChart();
    }

    return () => {
      if (!!props.chart) {
        props.chart.destroy();
      }
    };
  }, [canvasWidth]);

  //when there is not enough space, we remove points
  useEffect(() => {
    if (canvasWidth && props.chart && props.chartConfig.type === 'line') {
      const chartDatasets = props.chart.data.datasets;
      const isEqual = chartDatasets[0]?.pointRadius === POINT_CONFIG.pointRadius;

      const changePointRadius = (width) => {
        const assignment = (dataset) => (dataset.pointRadius = width);
        props.chart.data.datasets.forEach(assignment);
        props.chartConfig.data.datasets.forEach(assignment);
      };

      if (
        props.chart.chartArea.width <
        chartDatasets[0].data.length * (POINT_CONFIG.pointRadius * 2)
      ) {
        if (isEqual) {
          changePointRadius(0);
          props.chart.update();
        }
      } else {
        if (!isEqual) {
          changePointRadius(POINT_CONFIG.pointRadius);
          props.chart.update();
        }
      }
    }
  }, [canvasWidth, props.chart, props.chartConfig]);

  return (
    <Box
      ref={wrapperRef}
      className={classNames('Chart', props.className)}
      sx={{ height: '100%' }}
    >
      {props.modalCloseButton || <></>}
      <div className="buttons">
        <ChartPopoverWrapper
          {...props}
          canvasWidth={canvasWidth}
          rebuildChartCallback={rebuildChartCallback}
        />
      </div>
      <Box className="chartHeader">
        {props.title && (
          <Box className="chartHeader_titleBlock">
            <h3 className={`chartTitle ${additionalClass}`}>{props.title}</h3>
            {props.helpText && (
              <Box className="helpIcon">
                <HelpIcon>{props.helpText}</HelpIcon>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Divider className="divider" />

      {!props.isHideDatasetsSelect &&
        (props.hideLegend ? (
          <ChartDatasetsSelect
            modalCloseButton={props.modalCloseButton}
            selectedDatasets={props.selectedDatasets}
            hiddenDatasets={props.hiddenDatasets}
            setSelectedDatasets={props.setSelectedDatasets}
            addDataToChart={props.addDataToChart}
          />
        ) : (
          props.selectedDatasets.length > ONE_ELEMENT_IN_CHART && (
            <Box className="legendContainer">
              <ChartTagLegend
                selectedDatasets={props.selectedDatasets}
                onRemoveDataSet={null}
              />
            </Box>
          )
        ))}

      <div
        className={`chartWrapper`}
        style={{
          width: `${
            props.modalCloseButton
              ? '100%'
              : (CIRCLE_CHARTS.includes(props.chartConfig.type)
                  ? 400
                  : canvasWidth || 500) + 'px'
          }`,
          height: `${props.modalCloseButton ? '100%' : (props.height || 400) + 'px'}`,
        }}
      >
        {!props.chart && <SpinnerContainer isVisible className="chartCanvas_loader" />}
        <canvas
          id={`canvas-${props.title}`}
          ref={chartCanvasRef}
          className={`${props.modalCloseButton ? 'chartCanvas' : ''}`}
          style={
            {
              // visibility: isPending || !props.chart ? 'hidden' : 'visible',
            }
          }
        />
      </div>
    </Box>
  );
});

Chart.propTypes = {
  chartConfig: PropTypes.object.isRequired,
};

export default Chart;
