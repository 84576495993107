import { useSelector } from 'react-redux';

export const useCheckPermissions = () => {
  const shipper = useSelector((state) => state.user)?.shipper;

  const value = useSelector((state) => state.cloudwebViewsRestrictionsMap)?.value;

  return (pathListToPermission) => {
    // allow all
    if (!shipper || !value || !pathListToPermission?.[0]?.length) {
      return {
        isAllowTab: true,
        needSubscription: '',
      };
    }

    let current = value;

    for (let part of pathListToPermission) {
      if (current[part] === undefined) {
        return false;
      }
      current = current[part];
    }
    // allow all
    if (!current.length) {
      return {
        isAllowTab: true,
        needSubscription: '',
      };
    }

    // allow all for DEMO
    if (shipper.subscription === 'DEMO') {
      return {
        isAllowTab: true,
        needSubscription: current?.[0],
      };
    }

    return {
      isAllowTab: current?.includes(shipper.subscription),
      needSubscription: current?.[0],
    };
  };
};
