import React, { useCallback, useRef, Fragment } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ic_done } from 'react-icons-kit/md/ic_done';
import { ic_cancel } from 'react-icons-kit/md/ic_cancel';
import styled from 'styled-components';
import Icon from './Icon';
import Button from './Button';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #757575;

  .check {
    color: #00c853;
  }
`;

const FileNavigation = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 10px;
  align-items: center;
`;

export const ContractUpload = ({ onUpload, value, fileFormat = '.pdf' }) => {
  const inputRef = useRef();

  const handleClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);
  return (
    <Fragment>
      {value ? (
        <Wrapper>
          <Typography variant="body1">{value.name}</Typography>
          <FileNavigation>
            <Typography variant="body1" align="center">
              {`${(value.size / 1000).toFixed(1)} kb`}{' '}
            </Typography>
            <Icon className="check" size={24} icon={ic_done} />
            <IconButton onClick={() => onUpload('')} size="small">
              <Icon size={24} icon={ic_cancel} />
            </IconButton>
          </FileNavigation>
        </Wrapper>
      ) : (
        <Wrapper>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={handleClick}
            className="new_button_small"
          >
            Select the file
          </Button>
          <input
            type="file"
            hidden
            accept={fileFormat}
            ref={inputRef}
            onChange={(e) => onUpload(e.target.files[0])}
          />
        </Wrapper>
      )}
    </Fragment>
  );
};
