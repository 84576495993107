import './Chart.scss';

import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

import * as utils from 'common/helpers/utils';
import { ic_file_download } from 'react-icons-kit/md/ic_file_download';
import HelpIcon from 'common/components/HelpIcon';
import TooltipIcon from 'common/components/TooltipIcon';

// const usaByZipCode = import(`geo-data/usaByZipCode`).then((someModule) => {
//   return someModule.default;
// });

import Box from '@mui/material/Box';
import { createColorPalette } from 'common/helpers/utils';
import { Divider } from '@mui/material';
import { useClassSubscriptionsForCharts } from './Constants';

const sumUs = (array) => {
  return array.reduce((a, b) => a + b.value, 0);
};

const percFillColorsList = [
  // '#D7EF91',
  '#99CA8A',
  '#78B780',
  '#5A985D',
  '#3B7852',
  '#2E694B',
];

const getPercFill = (number, coefficient = 1) => {
  // const valueForCalc = Math.floor(number * coefficient > 5 ? 5 : number * coefficient);
  const valueForCalc = number * 100 * coefficient;

  if (valueForCalc < 2) {
    return percFillColorsList[0];
  } else if (valueForCalc < 4) {
    return percFillColorsList[1];
  } else if (valueForCalc < 6) {
    return percFillColorsList[2];
  } else if (valueForCalc < 10) {
    return percFillColorsList[3];
  } else {
    return percFillColorsList[4];
  }
};
const zoneLabelsWithPerc10 = ['0-2%', '2-4%', '4-6%', '6-10%', '>10%', '', '', ''];
const zoneLabelsWithPerc = [
  '0-0.2%',
  '0.2-0.4%',
  '0.4-0.6%',
  '0.6-1%',
  '>1%',
  '',
  '',
  '',
];
const zoneLabelsWithoutPerc = [
  '1-2',
  '2-3',
  '3-4',
  '4-5',
  '5-6',
  '6-7',
  '7-8',
  '8-9',
  '9-10',
  '>10',
];

const baseColor = '#bcf8a9';
const numValues = 13;

const colorPaletteList = createColorPalette(baseColor, numValues);

const colorPaletteListLength10 = colorPaletteList.slice(0, 10);

const getDaysFill = (number) => {
  const valueForCalc = Math.floor(number > 9 ? 9 : number);
  return colorPaletteList[valueForCalc];
};

const lookupZonesForOrigin = (zoneLookupTable) => (originZip) => {
  // if (zoneLookupTable == null) {
  //   throw new Error('Could not lookup zones. Table not (yet) loaded?');
  // }
  //
  // var destinationZipMap = zoneLookupTable[`zip${originZip.substring(0, 3)}`] || {};
  //
  // return Object.keys(destinationZipMap).map((zip) => [
  //   zip.substr(3),
  //   destinationZipMap[zip],
  // ]);
};

const ZoneMapParameters = (
  zoneLookupTable,
  yAxisTooltipsShowPerc,
  isAverageLessThenOne
) => {
  return {
    zoneLookup: lookupZonesForOrigin(zoneLookupTable),
    showStates: false,
    showStateLabels: false,
    showStateCodeLabels: false,
    showAreas: true,
    showAreaLabels: false,
    showTownLabels: false,
    showTownDots: false,
    showLegend: true,
    backgroundStyle: {
      fill: 'none',
      stroke: 'none',
    },
    arrowStyle: {
      strokeWidth: '2',
    },
    arrowRadius: 30,
    legendLabelStyle: {
      fontFamily: 'Mikado, sans-serif',
    },
    zoneColors: yAxisTooltipsShowPerc ? percFillColorsList : colorPaletteListLength10,
    zoneLabels: yAxisTooltipsShowPerc
      ? isAverageLessThenOne
        ? zoneLabelsWithPerc
        : zoneLabelsWithPerc10
      : zoneLabelsWithoutPerc,
  };
};

const tooltipBuilder = (dataItem, percent, percColor) => (event) => {
  const tooltip = document.createElement('div');
  tooltip.className = 'tooltip-data';
  tooltip.innerHtml += `<div>${dataItem.label}: ${dataItem.value} itemPerc</div>`;

  tooltip.style.top = event.clientY + 'px';
  tooltip.style.left = event.clientX + 20 + 'px';
  tooltip.style.position = 'absolute';
  tooltip.style.display = 'flex';
  tooltip.style.alignItems = 'center';
  tooltip.style.gap = '6px';
  tooltip.style.backgroundColor = 'rgba(97, 97, 97, 0.92)';
  tooltip.style.borderRadius = '4px';
  tooltip.style.color = 'rgb(255, 255, 255)';
  tooltip.style.padding = '4px 8px';
  tooltip.style.fontSize = '0.6875rem';
  tooltip.style.maxWidth = '300px';
  tooltip.style.margin = '2px';
  tooltip.style.overflowWrap = 'break-word';
  tooltip.style.fontWeight = 500;

  const square = document.createElement('div');
  square.style.height = '0.6875rem';
  square.style.width = '0.6875rem';
  square.style.backgroundColor = percColor;
  tooltip.appendChild(square);

  const text = document.createElement('div');
  text.textContent = event.target.getAttribute('data-tooltip');
  tooltip.appendChild(text);

  tooltip.innerHtml += `<div>${dataItem.label}: ${dataItem.value} (${utils.Fmt.percFloat2(
    percent
  )})</div>`;

  document.body.appendChild(tooltip);
};

const USChoroplethZip = (props) => {
  const chartRef = useRef(null);
  const additionalClass = useClassSubscriptionsForCharts(props.shipperSubscriptions);

  var zoneLookupTable = null;

  let zoneMap = null;
  useEffect(() => {
    let mouseenterMap = {};
    let mouseleaveMap = {};

    const sum = sumUs(props.data);
    const dataArray = props.data.map((d) => d.value);

    const isAverageLessThenOne =
      dataArray.reduce((a, b) => {
        if (b / sum > 1) {
          return a + 1;
        }
        return a;
      }, 0) < dataArray.length;

    (async () => {
      const usaByZipCode = await import(`geo-data/usaByZipCode`);

      const ZoneMap = usaByZipCode.default;
      new ZoneMap(
        chartRef.current,
        ZoneMapParameters(
          zoneLookupTable,
          props.yAxisTooltipsShowPerc,
          isAverageLessThenOne
        )
      );

      props.data?.forEach((dataItem) => {
        const foundSvg = chartRef.current.querySelector(`[data-zip="${dataItem.id}"]`);
        if (foundSvg?.style) {
          const percent = dataItem.value / sum;

          const percColor = props.yAxisTooltipsShowPerc
            ? getPercFill(percent, isAverageLessThenOne ? 10 : 1)
            : getDaysFill(dataItem.value);
          foundSvg.style.fill = percColor;
          foundSvg.style.position = 'relative';

          const itemPerc = props.yAxisTooltipsShowPerc
            ? `(${utils.Fmt.percFloat2(percent)})`
            : '';

          foundSvg.setAttribute(
            'data-tooltip',
            `${dataItem.label}: ${dataItem.value} ${itemPerc}`
          );

          mouseenterMap[dataItem.id] = tooltipBuilder(dataItem, percent, percColor);

          mouseleaveMap[dataItem.id] = () => {
            const tooltip = document.querySelector('.tooltip-data');
            if (tooltip) {
              tooltip.remove();
            }
          };

          foundSvg.addEventListener('mouseenter', mouseenterMap[dataItem.id]);

          foundSvg.addEventListener('mouseleave', mouseleaveMap[dataItem.id]);
        }
      });
    })();

    const currentRef = chartRef.current;
    return () => {
      Object.entries(mouseenterMap).forEach(([key, listener]) => {
        currentRef
          .querySelector(`[data-zip="${key}"]`)
          .removeEventListener('mouseenter', listener);
      });
      Object.entries(mouseleaveMap).forEach(([key, listener]) => {
        currentRef
          .querySelector(`[data-zip="${key}"]`)
          .removeEventListener('mouseleave', listener);
      });
    };
  }, []);

  const handleDownloadCSV = () => {
    let headers = ['ZIP', 'value'];

    // Build an array of arrays for the rows that should go into the
    // CSV. Initialize this array with the header row.
    let csvRows = [headers];
    for (let key in props.data) {
      csvRows.push([props.data[key].label, props.data[key].value]);
    }
    utils.triggerDownloadCSV(utils.rowsToCSV(csvRows), props.title || 'Export.csv');
  };

  return (
    <div className={classNames('Chart USChoropleth', props.className)}>
      <div className="buttons">
        <TooltipIcon
          className="FileDownloadIcon"
          icon={ic_file_download}
          onClick={handleDownloadCSV}
        >
          Download as CSV
        </TooltipIcon>
      </div>
      <Box className="chartHeader">
        {props.title && (
          <Box className="chartHeader_titleBlock">
            <h3 className={`chartTitle ${additionalClass}`}>{props.title}</h3>
            {props.helpText && (
              <Box className="helpIcon">
                <HelpIcon>{props.helpText}</HelpIcon>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Divider className="divider" />
      <Box ref={chartRef} sx={{ width: '100%' }} />
    </div>
  );
};

export default USChoroplethZip;
