import { createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { deleteJSON, getJSON, postFormData } from 'common/helpers/ajax';

const netRatesSlice = createSlice({
  name: 'netRates',
  initialState: {
    loading: false,
    error: null,
    carriersList: null,
    serviceTypeList: null,
    netRatesList: null,
    fetchingSendingData: false,
  },
  reducers: {
    getNetRatesCarriersListStart: (state) => {
      state.loading = true;
    },
    getNetRatesCarriersListSuccess: (state, { payload }) => {
      state.loading = false;
      state.carriersList = payload;
    },
    getNetRatesCarriersListFailure: (state) => {
      state.loading = false;
    },
    setServiceTypeList: (state, { payload }) => {
      state.serviceTypeList = payload;
    },
    addNetRate: (state, { payload }) => {
      state.netRatesList = [...state.netRatesList, payload];
    },
    setNetRate: (state, { payload }) => {
      state.netRatesList = payload;
    },
    setNeteRateFailure: (state, { payload }) => {
      state.netRatesList = [];
    },
    setFetchingSendingData: (state, { payload }) => {
      state.fetchingSendingData = payload;
    },
    setDeleteNetRate: (state, { payload }) => {
      state.netRatesList = state.netRatesList.filter((i) => i.id !== payload);
    },
  },
});

export const {
  getNetRatesCarriersListStart,
  getNetRatesCarriersListSuccess,
  getNetRatesCarriersListFailure,
  setServiceTypeList,
} = netRatesSlice.actions;

export const getNetRatesCarriersList = () => {
  return async (dispatch, getState) => {
    dispatch(getNetRatesCarriersListStart());

    await getJSON(
      '/api/net-rates-analysis/carriers',
      null,
      (response) => {
        if (response && Object.keys(response).length > 0) {
          const arraysOfValues = Object.entries(response);
          dispatch(getNetRatesCarriersListSuccess(arraysOfValues));
        }
      },
      (response) => {
        dispatch(getNetRatesCarriersListFailure());
        enqueueSnackbar(response.message, { variant: 'error' });
      },
      false
    );
  };
};

export const getNetRatesServiceTypeList = (carrier) => {
  return async (dispatch) => {
    dispatch(netRatesSlice.actions.setServiceTypeList([]));
    await getJSON(
      `/api/net-rates-analysis/services-types`,
      { carrier },
      (response) => {
        if (response && Object.keys(response).length > 0) {
          const arraysOfValues = Object.entries(response);
          dispatch(netRatesSlice.actions.setServiceTypeList(arraysOfValues));
        }
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      },
      false
    );
  };
};

export const addNetRate = (formData) => {
  return async (dispatch) => {
    dispatch(netRatesSlice.actions.setFetchingSendingData(true));
    await postFormData(
      '/api/net-rates-analysis/shipper-net-rate',
      formData,
      (response) => {
        dispatch(netRatesSlice.actions.addNetRate(response));
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      },
      false
    ).then(() => dispatch(netRatesSlice.actions.setFetchingSendingData(false)));
  };
};

export const downloadTemplate = (carrier, service_type) => {
  return async (dispatch) => {
    await getJSON(
      `/api/net-rates-analysis/download-template`,
      { carrier, service_type },
      (response) => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        const filename = `${carrier} ${service_type} (template).csv`;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      },
      false,
      'blob'
    );
  };
};

export const getNetRates = () => {
  return async (dispatch) => {
    await getJSON(
      `/api/net-rates-analysis/shipper-net-rate`,
      null,
      (response) => {
        if (response) {
          dispatch(netRatesSlice.actions.setNetRate(response));
        }
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      },
      false
    );
  };
};

export const getShipperNetRate = (shipper_net_rate_id, service_type, name) => {
  return async (dispatch) => {
    await getJSON(
      `/api/net-rates-analysis/download-shipper-net-rate`,
      { shipper_net_rate_id },
      (response) => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        const filename = name ? `${name} (${service_type}).csv` : `${service_type}.csv`;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      },
      false,
      'blob'
    );
  };
};

export const deleteNetRate = (net_rate_id) => {
  return (dispatch, getState) => {
    deleteJSON(
      `/api/net-rates-analysis/shipper-net-rate/${net_rate_id}/`,
      null,
      (response) => {
        dispatch(netRatesSlice.actions.setDeleteNetRate(net_rate_id));
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

export const netRatesReducer = netRatesSlice.reducer;
