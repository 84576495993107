import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddNetRatesForm } from './AddNetRatesForm';
import { ic_add } from 'react-icons-kit/md/ic_add';
import Icon from 'common/components/Icon';
import { Modal } from 'common/components/Modal';
import {
  addNetRate,
  getNetRatesCarriersList,
  setServiceTypeList,
} from '../../store/slices/NetRatesSlices';
import Button from 'common/components/Button';

export const AddNetRatesModal = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { carriersList, serviceTypeList, fetchingSendingData } = useSelector(
    (state) => state.netRates
  );

  // Open the modal
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  // Close the modal
  const handleClose = useCallback(() => {
    setOpen(false);
    dispatch(setServiceTypeList(null));
  }, [setOpen]);

  const handleAddNetRate = useCallback(
    async ({ carrier, serviceType, name, date, file }) => {
      const formData = new FormData();

      formData.append('carrier', carrier);
      formData.append('service_type', serviceType);
      formData.append('number', name);
      formData.append('effective_date', date.format('YYYY-MM-DD'));
      formData.append('file', file, file.name);

      await dispatch(addNetRate(formData)).then(() => handleClose());
    },
    [dispatch]
  );

  // Load list of carriers
  useEffect(() => {
    if (open) dispatch(getNetRatesCarriersList());
  }, [dispatch, open]);

  return (
    <>
      <Button variant="contained" onClick={handleOpen}>
        <Icon icon={ic_add} /> Add Net Rate
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        contentClassName="withOverflow"
        className="withOverflow"
        title="Select which carrier issued the contract"
        isDefaultCloseButton
      >
        <AddNetRatesForm
          carriers={carriersList}
          serviceTypeList={serviceTypeList}
          onSubmit={handleAddNetRate}
          fetchingSendingData={fetchingSendingData}
        />
      </Modal>
    </>
  );
};
