import { useMemo } from 'react';

export const useVendorInvoiceDetailsRows = (vendorInvoiceDetails) =>
  useMemo(
    () =>
      vendorInvoiceDetails?.reduce((prevState, currentValue) => {
        const currentRow = {
          id: currentValue.tracking_number,
          trackingNumber: currentValue.tracking_number,
          carrierPickedUpDate: currentValue.carrier_picked_up_date,
          serviceType: currentValue.service_type,
          chargeAmount: currentValue.charge_amount,
          adjustmentAmount: currentValue.adjustment_amount,
          discountAmount: currentValue.discount_amount,
          refundAmount: currentValue.refund_amount,
          totalAmount: currentValue.total_amount,
          currentValue,
        };
        prevState.push(currentRow);
        return prevState;
      }, []),
    [vendorInvoiceDetails]
  );
