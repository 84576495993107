'use strict';

import React from 'react';

import OperationVendorInvoicesFilterSidebarForm from 'cloud/operation-vendor-invoices/VendorInvoicesFilterSidebarForm';

import createFilterSidebar from 'common/components/FilterSidebar/FilterSidebar';
import { matchPath } from 'react-router-dom';

const OperationVendorInvoicesFilterSidebar = createFilterSidebar('OperationVendorInvoices.FilterSidebar');

export default (props) => {
  const match = matchPath(location.pathname, {
    path: '/operation-invoices',
    exact: true,
  });

  if (match) {
    return <OperationVendorInvoicesFilterSidebar Component={OperationVendorInvoicesFilterSidebarForm} />;
  } else {
    return null;
  }
};
