import { createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { findMaxValue, findMinValue } from 'common/helpers/utils';
import * as ajax from 'common/helpers/ajax';

const netRatesAnalysis = createSlice({
  name: 'netRatesAnalysis',
  initialState: {
    netRateId: '',
    netRatesCalculationCompare: null,
    netRatesCalculations: null,
    loading: false,
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    getNetRatesCarriersListSuccess: (state, { payload }) => {
      state.loading = false;
      state.carriersList = payload;
    },
    setNetRatesCalculation: (state, { payload }) => {
      // Set the maximum value of each calculation to create a heatmap
      state.netRatesCalculations = payload.map((calculation) => ({
        ...calculation,
        maxValues: findMaxValue(calculation.mapping),
        minValues: findMinValue(calculation.mapping),
      }));
    },
    setNetRatesCalculationCompare: (state, { payload }) => {
      state.netRatesCalculationCompare = {
        ...payload,
        // Set the maximum value of each calculation to create a heatmap
        maxValues: findMaxValue(payload.mapping),
        minValues: findMinValue(payload.mapping),
      };
    },
    clearNetRatesCalculations: (state) => {
      state.netRatesCalculations = null;
      state.netRatesCalculationCompare = null;
    },
  },
});

export const { clearNetRatesCalculations } = netRatesAnalysis.actions;

export const calculateNetRates = (netRates) => {
  return async (dispatch) => {
    dispatch(netRatesAnalysis.actions.clearNetRatesCalculations());
    dispatch(netRatesAnalysis.actions.setLoading(true));

    const param = {
      shipper_net_rate_a: netRates[0],
    };
    if (netRates[1]) param.shipper_net_rate_b = netRates[1];

    await ajax.postJSON(
        '/api/net-rates-analysis/calculate-net-rates',
        param,
        (response) => {
          const calculatios = [{...response.a, name: netRates[0].name}];
          if (response.b) calculatios.push({...response.b, name: netRates[1].name});
          dispatch(netRatesAnalysis.actions.setNetRatesCalculation(calculatios));
          if (response.compare)
            dispatch(
                netRatesAnalysis.actions.setNetRatesCalculationCompare(response.compare)
            );
          dispatch(netRatesAnalysis.actions.setLoading(false));
        },
        (response) => {
          dispatch(netRatesAnalysis.actions.setLoading(false));
          enqueueSnackbar(response.message, {variant: 'error'});
        }
    );
  };
};

export const netRatesAnalysisReducer = netRatesAnalysis.reducer;
