import { useMemo, useState } from 'react';
import * as ajax from '../../helpers/ajax';
import { enqueueSnackbar } from 'notistack';
import _debounce from 'lodash.debounce';

const OBJECT_STORE = 'zoneTable';

const requestStackList = [];

const usingRequestStackList = () => {
  if (requestStackList.length) {
    requestStackList.forEach((fn) => fn());
  }
};

let db;

const request = indexedDB.open('MyTestDatabase', 1);

request.onerror = function (event) {
  console.log('Database error: ' + event.target.errorCode);
};

request.onsuccess = function (event) {
  db = event.target.result;
  usingRequestStackList();
};

request.onupgradeneeded = function (event) {
  let db = event.target.result;
  db.createObjectStore(OBJECT_STORE, { keyPath: 'id' });
};

function storeData(data) {
  const transaction = db.transaction([OBJECT_STORE], 'readwrite');

  transaction.oncomplete = function (event) {
    console.log('Transaction completed.');
  };

  transaction.onerror = function (event) {
    console.log('Transaction error: ' + event.target.errorCode);
  };

  let objectStore = transaction.objectStore(OBJECT_STORE);
  objectStore.add({ id: 'uniqueId', value: data });
}

const loadingDbChecker = (fn) => {
  if (!db) {
    requestStackList.push(fn);
  } else {
    return fn();
  }
};

const getData = (callback) => () => {
  const transaction = db.transaction([OBJECT_STORE]);
  const objectStore = transaction.objectStore(OBJECT_STORE);
  const request = objectStore.get('uniqueId');

  request.onerror = function (event) {
    console.log('Error fetching data');
  };

  request.onsuccess = function (event) {
    if (request.result) {
      callback(request.result.value);
    } else {
      graphqlRequest('query ($carrierId: ID!) { zoneTable(carrierId: $carrierId) }', {
        carrierId: 1,
      }).then((res) => {
        storeData(res.data.zoneTable);
        callback(res.data.zoneTable);
      });
    }
  };
};

function graphqlRequest(query, variables = {}) {
  return fetch('https://api.pirateship.com/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: query,
      variables: variables,
    }),
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.errors) {
        res.errors.forEach(function (error) {
          console.warn('GraphQL error: ' + error.message, error.extensions);
        });
      }
      return res;
    });
}

export var zoneLookupTable = null;

const useUSZipGeneratorListener = (mapContainer, zoneMap) => {
  const [originZip, setOriginZip] = useState('');
  const [destinationZip, setDestinationZip] = useState('');
  const [zoneGeneratorResponse, setZoneGeneratorResponse] = useState(null);
  const [zoneGeneratorLoadingStatus, setZoneGeneratorLoadingStatus] = useState(null); //LOADING SUCCESS ERROR
  const [isInitStatus, setIsInitStatus] = useState(false);

  const { onChangeOriginInputValue, onChangeDestinationInputValue } = useMemo(() => {
    let originZip = '';
    let destinationZip = '';

    if (!zoneMap) {
      return {};
    }
    let carrierId = 1;
    let defaultZip = 83001;

    let lookupZoneFromApiCache = {};
    let debouncedLookupZoneFromApi = _debounce(lookupZoneFromApi, 1000);
    let hasOrigin = false;
    let hasDestination = false;
    let arrow = null;
    let lastAnimationFrame;
    let lastArea;

    function modifyColorBrightness(color, amount) {
      var usePound = false;

      if (color[0] === '#') {
        color = color.slice(1);
        usePound = true;
      }

      var num = parseInt(color, 16);

      var r = (num >> 16) + amount;
      if (r > 255) r = 255;
      else if (r < 0) r = 0;

      var b = ((num >> 8) & 0x00ff) + amount;
      if (b > 255) b = 255;
      else if (b < 0) b = 0;

      var g = (num & 0x0000ff) + amount;
      if (g > 255) g = 255;
      else if (g < 0) g = 0;

      return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
    }

    function updateActiveAreas() {
      var activeAreas = mapContainer.querySelectorAll('.zoneMap-area--active');
      activeAreas.forEach(function (area) {
        area.classList.remove('zoneMap-area--active');
        area.style.fill = '';
      });

      if (originZip) {
        var originAreas = mapContainer.querySelectorAll(
          '.zoneMap-area-' + originZip.substr(0, 3)
        );
        originAreas.forEach(function (area) {
          area.classList.add('zoneMap-area--active');
          var color = area.getAttribute('fill');
          if (color) {
            area.style.fill = modifyColorBrightness(color, -25);
          }
        });
      }

      if (destinationZip) {
        var destinationAreas = mapContainer.querySelectorAll(
          '.zoneMap-area-' + destinationZip.substr(0, 3)
        );
        destinationAreas.forEach(function (area) {
          area.classList.add('zoneMap-area--active');
          var color = area.getAttribute('fill');
          if (color) {
            area.style.fill = modifyColorBrightness(color, -25);
          }
        });
      }
    }

    function updateArrow() {
      if (arrow) {
        zoneMap.removeArrow(arrow);
        arrow = null;
      }

      if (originZip && destinationZip) {
        arrow = zoneMap.drawArrow(originZip.substr(0, 3), destinationZip.substr(0, 3));
      }
    }

    function cachedLookupZoneFromApi(originZip, destinationZip, doneCallback) {
      var cacheKey = originZip + '-' + destinationZip;
      if (lookupZoneFromApiCache[cacheKey]) {
        doneCallback(lookupZoneFromApiCache[cacheKey]);
      } else {
        debouncedLookupZoneFromApi(originZip, destinationZip, function (zone) {
          lookupZoneFromApiCache[cacheKey] = zone;
          doneCallback(zone);
        });
      }
    }

    const updateZoneOutput = _debounce(() => {
      // var output = document.getElementById('ps-zone-output');

      if (originZip.length < 3 || destinationZip.length < 3) {
        return;
      }

      if (zoneLookupTable) {
        var zone =
          zoneLookupTable[`zip${originZip.substring(0, 3)}`]?.[
            `zip${destinationZip.substring(0, 3)}`
          ];

        if (zone != null && zone > 0) {
          // output.textContent = 'Zone ' + zone;
        }
      }

      // Also get a more exact result from the server
      cachedLookupZoneFromApi(originZip, destinationZip, function (zone) {
        setZoneGeneratorResponse(zone);
        // output.textContent = 'Zone ' + zone;
      });
    }, 500);

    function lookupZoneFromApi(originZip, destinationZip, doneCallback, errorCallback) {
      setZoneGeneratorLoadingStatus('LOADING');
      ajax.postJSON(
        '/api/dashboard/zone-generator',
        { zip1: originZip, zip2: destinationZip, country_code: 'US', vendors: [] },
        (response) => {
          setZoneGeneratorLoadingStatus('SUCCESS');
          doneCallback(response);
        },
        (response) => {
          enqueueSnackbar(response.message, { variant: 'error' });
          setZoneGeneratorLoadingStatus('ERROR');
        },
        false
      );
    }

    loadingDbChecker(
      getData((zoneTable) => {
        zoneLookupTable = zoneTable;
        zoneMap.paintZones(String(defaultZip));
        setIsInitStatus(true);
      })
    );

    const updateChart = _debounce(() => {
      updateActiveAreas();
      updateArrow();
      updateZoneOutput();
    }, 300);

    const onChangeOriginInputValue = (event) => {
      const zip = event.target.value;
      if (zip.length > 5) {
        return;
      }
      setOriginZip(zip);
      originZip = zip;

      zoneMap.paintZones(zip);
      hasOrigin = true;
      updateChart();
    };

    const onChangeDestinationInputValue = (event) => {
      const zip = event.target.value;
      if (zip.length > 5) {
        return;
      }
      setDestinationZip(zip);
      destinationZip = zip;
      hasDestination = true;
      updateChart();
    };

    zoneMap.addChangeListener(function (area) {
      if (!zoneLookupTable) {
        return;
      }
      var zip = String(area.dataset.zip || '').padEnd(5, '0');

      if (area && area !== lastArea) {
        if (lastAnimationFrame) {
          cancelAnimationFrame(lastAnimationFrame);
          lastAnimationFrame = null;
        }
        if (!hasOrigin) {
          lastAnimationFrame = requestAnimationFrame(function () {
            zoneMap.paintZones(zip);
          });
        }
        lastArea = area;
      }

      if (!hasOrigin) {
        setOriginZip(zip);
        originZip = zip;
      } else if (!hasDestination) {
        setDestinationZip(zip);
        destinationZip = zip;
      }
      if (!hasDestination) {
        updateActiveAreas();
        updateArrow();
        // updateZoneOutput();
      }
    });

    mapContainer.addEventListener('click', function (event) {
      if (!zoneLookupTable) {
        return;
      }

      var targetArea = event.target.closest('.zoneMap-area');
      if (targetArea) {
        var zip = targetArea.dataset.zip;
        var paddedZip = String(zip).padEnd(5, '0');

        if (hasOrigin && hasDestination) {
          hasOrigin = false;
          hasDestination = false;
          setOriginZip('');
          originZip = '';
          setDestinationZip('');
          destinationZip = '';

          if (lastArea) {
            var zip = String(lastArea.dataset.zip || '').padEnd(5, '0');
            zoneMap.paintZones(zip);
            setOriginZip(zip);
            originZip = zip;
          }
          // set originZip
        } else if (!hasOrigin) {
          hasOrigin = true;
          setOriginZip(paddedZip);
          originZip = paddedZip;
          // set destinationZip
        } else if (hasOrigin) {
          hasDestination = true;
          setDestinationZip(paddedZip);
          destinationZip = paddedZip;
        }
        updateActiveAreas();
        updateArrow();

        updateZoneOutput();
      }
    });

    return { onChangeOriginInputValue, onChangeDestinationInputValue };
  }, [zoneMap]);

  return {
    zoneGeneratorResponse,
    zoneGeneratorLoadingStatus,
    onChangeOriginInputValue,
    onChangeDestinationInputValue,
    destinationZip,
    originZip,
    isInitStatus,
  };
};

export default useUSZipGeneratorListener;
