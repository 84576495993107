'use strict';

import autobind from 'react-autobind';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { enqueueSnackbar } from 'notistack';

import * as ajax from 'common/helpers/ajax';
import * as utils from 'common/helpers/utils';
import Button from 'common/components/Button';
import ReduxFormFloatingLabelFormGroup from 'common/components/ReduxFormFloatingLabelFormGroup';
import * as actions from '../store/actions';
import Grid from '@mui/material/Unstable_Grid2';

class InviteUserForm extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.submitting != nextProps.submitting) {
      this.props.onSubmittingChange(nextProps.submitting);
    }
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.inviteUser)}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <ReduxFormFloatingLabelFormGroup type="text" name="name" label="Full name" />
          </Grid>
          <Grid xs={12}>
            <ReduxFormFloatingLabelFormGroup
              type="text"
              name="email"
              label="Email address"
            />
          </Grid>
          <Grid xs={12}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              isLoading={this.props.submitting}
              disabled={this.props.pristine || this.props.submitting}
            >
              Invite user
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }

  inviteUser(data) {
    if (data.name.length <= 0 || !data.name.includes(' ')) {
      enqueueSnackbar("Please enter the user's full name.", { variant: 'error' });
      return null;
    }
    if (!utils.isValidEmail(data.email)) {
      enqueueSnackbar("This doesn't look like a valid email address.", {
        variant: 'error',
      });
      return null;
    }

    return this.props.dispatch(inviteUser(data));
  }
}

const inviteUser = (data) => {
  return (dispatch) => {
    return ajax.postJSON(
      '/api/users/invite',
      data,
      (response) => {
        dispatch(actions.fetchUsers());
        dispatch({
          type: 'SETTINGS_TEAM__INVITE_USER_MODAL__HIDE',
        });
        enqueueSnackbar('Success! An invitation email has been sent to this user.', {
          variant: 'success',
        });
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

InviteUserForm = reduxForm({
  form: 'Settings.InviteUserForm',
})(InviteUserForm);

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(InviteUserForm);
