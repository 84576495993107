export const locationTypes = [
  { value: 'origin', label: 'Origin' },
  { value: 'destination', label: 'Destination' },
  { value: 'origin-destination', label: 'Origin - Destination' },
];

export const locationLevel = [
  {
    label: 'City',
    value: 'CITY',
  },
  {
    label: 'State',
    value: 'STATE',
  },
  {
    label: 'Country',
    value: 'COUNTRY',
  },
];
