import React from 'react';

const SignOutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9 4H19V18C19 19.1046 18.1046 20 17 20H9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 15L5 12M5 12L8 9M5 12H15"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SignOutIcon;
