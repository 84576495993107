import { createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { getJSON } from 'common/helpers/ajax';

const carriersSlice = createSlice({
  name: 'carriers',
  initialState: {
    loading: false,
    error: null,
    carriersList: [],
  },
  reducers: {
    getCarriersListStart: (state) => {
      state.loading = true;
    },
    getCarriersListSuccess: (state, { payload }) => {
      state.loading = false;
      state.carriersList = payload;
    },
    getCarriersListFailure: (state) => {
      state.loading = false;
    },
  },
});

const { getCarriersListStart, getCarriersListSuccess, getCarriersListFailure } =
  carriersSlice.actions;

export const getCarriersList = () => {
  return async (dispatch, getState) => {
    const state = getState();
    if (state.carriers.carriersList.length > 0) {
      return;
    }

    dispatch(getCarriersListStart());

    await getJSON(
      '/carriers',
      null,
      (response) => {
        const filteredCarriers = response.filter(
          (carrier) => carrier.services.length > 0
        );
        dispatch(getCarriersListSuccess(filteredCarriers));
      },
      (response) => {
        dispatch(getCarriersListFailure());
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

export const carriersReducer = carriersSlice.reducer;
