import './Dashboard.scss';
import { connect } from 'react-redux';
import { ic_filter_alt_outline } from 'react-icons-kit/md/ic_filter_alt_outline';
import { action as toggleFilterSidebar } from 'redux-burger-menu';
import classNames from 'classnames';
import humps from 'humps';
import React, { memo, useEffect, useState } from 'react';

import * as ajax from 'common/helpers/ajax';
import Icon from 'common/components/Icon';
import PageContainer from '../containers/PageContainer';

import ErrorContainer from './ErrorContainer';
import NoShipmentsContainer from './NoShipmentsContainer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import autobind from 'react-autobind';
import { CANCEL_REQUEST_MESSAGE } from 'common/helpers/ajax';
import { setQueryStackList } from '../store/reducers';
import _cloneDeep from 'lodash/cloneDeep';
import { chartMap, xAxisFormat, yAxisFormatMap } from '../common/chartConstants';
import * as utils from 'common/helpers/utils';
import { enqueueSnackbar } from 'notistack';
import { LoadingOverlay } from '../../common/components/table/DataGridCustom';
import PendingImportPlaceholder from '../components/PendingImportPlaceholder';

class DashboardContainerViewModel extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    /**
     * Checking whether we have a list of downloaded tabs
     * if they are missing we must first get them and then get queryNames
     */
    if (!this.props.tabsState.tabsMap) {
      this.props.dispatch(this.fetchChartsTabList());
    } else if (
      !this.props.page &&
      !!Object.keys(this.props.filters || {}).length &&
      /**
       * Only if tabs was loaded and after this page gets queryNames.
       */
      this.props.queryNames
    ) {
      this.fetchDashboard(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filters.initialized) {
      if (nextProps.filters.shouldReinitialize) {
        this.props.dispatch({
          type: 'DASHBOARD__FILTERS__FORM_REINITIALIZE',
          payload: { shouldReinitialize: false },
        });
      } else {
        if (
          nextProps.page?.filterSetId !== nextProps.filters.filterSetId &&
          /** Only if tabs was loaded and after this page gets queryNames.
           * now we have problems with double rendering after loading tabsMap
           * when the wrapper hasn't set queryNames yet
           */
          !!nextProps.tabsState.tabsMap &&
          nextProps.queryNames
        ) {
          this.fetchDashboard(nextProps);
        }
      }
    }
  }

  /**
   * On unmount, we need to make sure we save the `filterSetId`
   */
  componentWillUnmount() {
    this._isMounted = false;

    this.props.dispatch({
      type: 'DASHBOARD__FILTERS__FORM_REINITIALIZE',
      payload: { shouldReinitialize: true },
    });
  }

  fetchDashboard(nextProps) {
    this.props.dispatch({
      type: 'DASHBOARD__PAGES__UPDATE',
      payload: {
        filterSetId: nextProps.filters.filterSetId,
        contentId: nextProps.contentId,
        isLoading: true,
        isError: false,
        isNoShipments: false,
        isSuccess: false,
      },
      name: nextProps.contentId,
    });

    /**
     * Updates any dashboard metrics as necessary. We should *not* update metrics
     * if the filter's `filterSetId` has not incremented, or if the shipper
     * doesn't have any ax shipments.
     */
    setQueryStackList(
      {
        queryFn: this.props.dispatch(
          this.fetchDashboardMetrics(
            nextProps.queryNames,
            nextProps.filters,
            nextProps.contentId,
            nextProps.filters.filterSetId,
            // for DEMO we should use other api
            // and we will receive additional param - shipper_subscriptions
            this.props.shipper.subscription === 'DEMO'
              ? '/api/dashboard/query-demo'
              : '/api/dashboard/query-new'
          )
        ),
        filterSetId: nextProps.filters.filterSetId,
        queryNames: nextProps.queryNames.map(humps.decamelize),
        filters: nextProps.filters,
        clearLoading: () => {
          this.props.dispatch({
            type: 'DASHBOARD__PAGES__UPDATE',
            payload: {
              filterSetId: nextProps.filters.filterSetId,
              contentId: nextProps.contentId,
              isLoading: false,
              isError: false,
              isNoShipments: false,
              isSuccess: false,
            },
            name: nextProps.contentId,
          });
        },
      },
      nextProps.filters.filterSetId
    );
  }

  render() {
    let content = null;
    const metrics = this.props.metrics || {};

    // If we're currently loading data, then show the spinner.
    if (!this.props.page || this.props.page.isLoading || this.props.tabsState.isLoading) {
      content = (
        <LoadingOverlay sx={{ position: 'absolute', width: '100%', height: '100%' }} />
      );
    }

    // Show the carrier-credentials error
    else if (!this.props.shipper.hasAxShipments) {
      content = (
        <Box
          sx={{
            margin: '0 auto',
            maxWidth: '800px',
          }}
        >
          <PendingImportPlaceholder />
        </Box>
      );
    }

    // Show the error container if there was an error.
    else if (this.props.page && this.props.page.isError) {
      content = (
        <ErrorContainer
          button={
            <Button
              variant="contained"
              size="large"
              onClick={() => this.fetchDashboard(this.props)}
            >
              TRY AGAIN
            </Button>
          }
        />
      );
    }
    // Show the 'No shipments' container if there were no shipments.
    else if (this.props.page && this.props.page.isNoShipments) {
      content = <NoShipmentsContainer />;
    }
    // If the shipper doesn't have any analytics yet, then show the pendingfilterSetId
    // import placeholder.
    // else if (!this.props.shipper.hasAxShipments) {
    //   content = <PendingImportPlaceholder />;
    // }
    // Otherwise if we have a `getCharts()` method, then use that to generate
    // the content.
    else if (this.props.getCharts) {
      content = (
        <RenderGridNew
          defaultChartSettings={this.props.getCharts}
          metrics={metrics}
          contentTab={this.props.contentTab}
        />
      );
    } else if (this.props.renderGrid) {
      const RenderGrid = this.props.renderGrid;
      content = <RenderGrid metrics={metrics} contentTab={this.props.contentTab} />;
    }
    // // But if a `getContent()` method is defined instead, then use that.
    // else if (this.props.getContent) {
    //   content = this.props.getContent(metrics, this.props.contentTab);
    // }

    return (
      <PageContainer
        title={this.props.title}
        seconHeaderContent={this.props.seconHeaderContent}
        secondHeaderClass={this.props.secondHeaderClass || ''}
        contentId={this.props.contentId}
        contentClassName={classNames(this.props.contentClassName, 'Dashboard')}
        rightIcons={
          <Button
            key={'rightIcons_filter'}
            onClick={this.toggleFilterSidebar}
            size="small"
            variant="contained"
            color="primary"
            className="RightIcons"
            aria-label="settings"
            sx={{ width: '42px', height: '42px', borderRadius: '16px' }}
          >
            <Icon
              className="FilterSidebarIcon"
              key="FilterSidebarIcon"
              icon={ic_filter_alt_outline}
              size={24}
            />
          </Button>
        }
      >
        {content}
      </PageContainer>
    );
  }
  toggleFilterSidebar() {
    this.props.dispatch(toggleFilterSidebar(true, 'Dashboard.FilterSidebar'));
  }

  fetchChartsTabList() {
    return (dispatch) => {
      dispatch({
        type: 'DASHBOARD__PAGES__CHARTS_TAB_LIST',
        payload: {
          isLoading: true,
          tabsMap: null,
        },
      });

      ajax.getJSON(
        '/api/dashboard/charts-tablist',
        {},
        (response) => {
          dispatch({
            type: 'DASHBOARD__PAGES__CHARTS_TAB_LIST',
            payload: {
              isLoading: false,
              tabsMap: response,
            },
          });
        },
        (response) => {
          enqueueSnackbar(response.message, { variant: 'error' });
          dispatch({
            type: 'DASHBOARD__PAGES__CHARTS_TAB_LIST',
            payload: {
              isLoading: false,
              tabsMap: null,
            },
          });
        },
        false
      );
    };
    // OVERVIEW', 'SERVICES_SUMMARY', 'GENERAL', 'AUDIT', 'PERFORMANCE', 'GEOGRAPHY', 'CUSTOM'
  }

  fetchDashboardMetrics(queryNames, filters, contentId, pageFilterSetId, url) {
    return (dispatch) => {
      // Track the event in mixpanel.
      window.mixpanel &&
        window.mixpanel.track('Executed dashboard query', {
          page: contentId,
          filters: filters,
        });

      dispatch({
        type: 'DASHBOARD__QUERY_NAMES__UPDATE',
        payload: queryNames,
      });

      return () =>
        ajax.postJSON(
          url,
          {
            queryNames: queryNames.map(humps.decamelize),
            filters: filters,
          },
          (response) => {
            const isActiveFilter = pageFilterSetId === this.props.filters.filterSetId;

            // first set data then set status. if you do the opposite, charts will be updated with the old data
            if (response.metrics && isActiveFilter) {
              dispatch({
                type: 'DASHBOARD__METRICS__UPDATE',
                payload: response.metrics,
              });
            }

            dispatch({
              type: 'DASHBOARD__PAGES__UPDATE',
              payload: {
                isLoading: false,
                isError: false,
                isSuccess: true,
                isNoShipments: !Object.keys(response.metrics || {}).length,
              },
              name: contentId,
            });
          },
          (response) => {
            if (response.message !== CANCEL_REQUEST_MESSAGE) {
              dispatch({
                type: 'DASHBOARD__PAGES__UPDATE',
                payload: {
                  isLoading: false,
                  isError: true,
                  isNoShipments: false,
                  isSuccess: false,
                },
                name: contentId,
              });
            }
          },
          true,
          'json',
          `${JSON.stringify({
            queryNames: _cloneDeep(queryNames),
            filters: _cloneDeep(filters),
          })}_/api/dashboard/query-new`
        );
    };
  }
}

/**
 * Returns an array of   <Grid container spacing={1}> objects with a number of charts corresponding to the
 * given width.
 */
const RenderGridNew = memo(({ defaultChartSettings, metrics, contentTab = [] }) => {
  if (!metrics || !Object.entries(metrics).length) {
    return [];
  }

  const chartList = Object.entries(metrics)
    .filter(([key, value]) => contentTab.includes(key) && !!value && value.index + 1)
    .sort((a, b) => a[1].index - b[1].index);

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
      {chartList.map(([chartName, chartData]) =>
        chartData.index ? (
          <ChartWrapper
            key={`RenderGridNew_Paper_${chartData.index}_${chartData.title}_${chartData.helpText}`}
            chartName={chartName}
            chartData={chartData}
            defaultChartSettings={defaultChartSettings}
          />
        ) : (
          <></>
        )
      )}
    </Box>
  );
});

const ChartWrapper = memo(({ chartData, defaultChartSettings, chartName }) => {
  const Chart = chartMap[chartData.chartType];
  const [maxWidth, setMaxWidth] = useState(
    chartData.widthRate === 2 || window.innerWidth < 1300 ? '100%' : 'calc(50% - 10px)'
  );

  useEffect(() => {
    const changeWidth = () => {
      setMaxWidth(
        chartData.widthRate === 2 || window.innerWidth < 1300
          ? '100%'
          : 'calc(50% - 10px)'
      );
    };

    window.addEventListener('resize', changeWidth);

    return () => {
      window.removeEventListener('resize', changeWidth);
    };
  }, []);

  const padding =
    chartData.chartType === 'table' ? '27px 11px 11px' : '27px 27px 11px 11px';

  return (
    <Paper
      elevation={2}
      className="DiagramPaper"
      sx={{
        minWidth: '500px',
        padding,
        maxWidth,
        flex: `1 1 ${maxWidth}`,
      }}
    >
      <Chart
        key={`ChartWrapper_${chartData.chartType}_${chartData.title}`}
        {...defaultChartSettings[chartName]}
        height={chartData.heightRate === 2 ? 800 : 400}
        queryChartData={chartData}
        data={chartData.chartData}
        helpText={chartData.helpText}
        title={chartData.title}
        yAxisTicksFmt={chartData.yAxisFormat && yAxisFormatMap[chartData.yAxisFormat]}
        xAxisTicksFmt={chartData.xAxisFormat && xAxisFormat[chartData.xAxisFormat]}
        yAxisTooltipsFmt={chartData.yAxisFormat && yAxisFormatMap[chartData.yAxisFormat]}
        shipperSubscriptions={chartData.shipperSubscriptions}
        yAxisTooltipsPercFmt={
          chartData.displayPercentage === 'disabled' ? null : utils.Fmt.percFloat1
        }
        yAxisTooltipsShowPerc={chartData.displayPercentage !== 'disabled'}
      />
    </Paper>
  );
});

const mapStateToProps = (state, ownProps) => {
  return {
    shipper: state.shipper,
    filters: state.dashboard.filters,
    metrics: state.dashboard.metrics,
    stateQueryNames: state.dashboard.stateQueryNames,
    page: state.dashboard.pages[ownProps.contentId],
    tabsState: state.dashboard.tabsState,
  };
};

export default connect(mapStateToProps)(DashboardContainerViewModel);
