import React, { useMemo } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import * as utils from 'common/helpers/utils';
import { Checkbox, Divider, ListItemText, MenuItem } from '@mui/material';

const ColumnVisibilitySelect = ({
  value,
  columns,
  columnVisibility,
  handleColumnVisibilityChange,
}) => {
  const isSelectedAll = useMemo(() => {
    return columns.every((column) => columnVisibility[column.field]);
  }, [columnVisibility, columns]);

  const handleSelectAllChange = (event) => {
    event.stopPropagation();
    if (event.target.checked) {
      handleColumnVisibilityChange({
        target: { value: columns.map((column) => column.field) },
      });
    } else {
      handleColumnVisibilityChange({ target: { value: [] } });
    }
  };

  return (
    <FormControl sx={{ m: 1, width: 300 }} variant="standard">
      <InputLabel id="multiple-checkbox-label">Column Visibility</InputLabel>
      <Select
        labelId="multiple-checkbox-label"
        id="multiple-checkbox-select"
        multiple
        MenuProps={{
          // Disable scroll to bottom
          disableAutoFocusItem: true,
        }}
        value={value}
        onChange={handleColumnVisibilityChange}
        renderValue={(selected) => selected.map(utils.convertToReadableFormat).join(', ')}
      >
        <MenuItem value="">
          <Checkbox checked={isSelectedAll} onChange={handleSelectAllChange} />
          <ListItemText primary="Select All" />
        </MenuItem>

        <Divider />

        {columns.map((column) => (
          <MenuItem key={column.field} value={column.field}>
            <Checkbox checked={columnVisibility[column.field] || false} />
            <ListItemText primary={column.headerName} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ColumnVisibilitySelect;
