import React, { useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Button from 'common/components/Button';
import ReduxFormFloatingLabelFormGroup from 'common/components/ReduxFormFloatingLabelFormGroup';
import ReduxFormVendorAccountNumbersSelect from 'common/components/ReduxFormVendorAccountNumbersSelect';
import { editGlCode, addGlCode } from '../../store/actions';
import Grid from '@mui/material/Unstable_Grid2';
import DataGridWrapper from 'common/components/table/DataGridWrapper';
import DataGridCustom from 'common/components/table/DataGridCustom';
import HelpIcon from 'common/components/HelpIcon';
import { ic_edit } from 'react-icons-kit/md/ic_edit';
import { ic_save } from 'react-icons-kit/md/ic_save';
import { ic_cancel } from 'react-icons-kit/md/ic_cancel';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import {
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import Icon from 'common/components/Icon';
import Box from '@mui/material/Box';
import ReduxFormSelect from 'common/components/ReduxFormSelect';
import ReduxFormSingleDatePicker from '../../../common/components/ReduxFormSingleDatePicker';
import isBeforeDay from 'react-dates/lib/utils/isBeforeDay';
import moment from 'moment/moment';
import Tips from '../../../common/components/Tips/Tips';

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = Math.random();
    setRows((oldRows) => [...oldRows, { id, code_name: '', value: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'code_name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'end',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '12px',
            alignItems: 'start',
            flexDirection: 'column',
          }}
        >
          <h5>Additional Codes </h5>
          <Tips>
            If you have additional fields like `Location` for your GL rule, please list
            them here with their respective values.
          </Tips>
        </Box>
        <Button color="primary" variant="contained" onClick={handleClick}>
          Add New
        </Button>
      </Box>
    </GridToolbarContainer>
  );
}

let AddGLCodeForm = (props) => {
  const startedAdditionalCodeNames = useRef(
    Object.entries(props.initialValues.additional_codes).map(([key, value]) => ({
      id: key + value,
      code_name: key,
      value,
    }))
  );

  const [rows, setRows] = React.useState(startedAdditionalCodeNames.current);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const columns = useMemo(() => {
    return [
      {
        field: 'code_name',
        headerName: 'Column Name',
        minWidth: 180,
        flex: 1,
        editable: true,
      },
      {
        field: 'value',
        headerName: 'Value',
        minWidth: 180,
        flex: 1,
        editable: true,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 80,
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<Icon size={20} icon={ic_save} />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<Icon size={20} icon={ic_cancel} />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<Icon size={20} icon={ic_edit} />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<Icon size={20} icon={ic_delete} />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ];
  }, [rowModesModel]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const updateGlCode = (data) => {
    //saved additional_code_names from rows
    data.additional_codes = rows.reduce((acc, currentValue) => {
      acc[currentValue.code_name] = currentValue.value;
      return acc;
    }, {});

    const actionCreator = data.hid ? editGlCode : addGlCode;
    return props.dispatch(actionCreator(data));
  };

  const isDisabledButton = useMemo(
    () =>
      (props.pristine &&
        JSON.stringify(startedAdditionalCodeNames.current) === JSON.stringify(rows)) ||
      props.submitting,
    [rows, props.pristine, props.submitting]
  );

  return (
    <form onSubmit={props.handleSubmit(updateGlCode)}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormGroup>
            <FormLabel>
              Vendor account{' '}
              <HelpIcon size="14px">
                You must select the account to which the GL code will be attached.
              </HelpIcon>
            </FormLabel>
            <ReduxFormVendorAccountNumbersSelect
              multi={false}
              closeOnSelect={true}
              name="vendor_account_number"
              label="Account number"
              labelModelField="vendorAccountNumber"
              vendorAccountNumberMappings={props.vendorAccountNumberMappings}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={6}>
          <FormGroup>
            <FormLabel>
              GL Type{' '}
              <HelpIcon size="14px">
                GL Type is needed to distinguish between standard and duties invoices
              </HelpIcon>
            </FormLabel>
            <ReduxFormSelect
              name="gl_type"
              placeholder="Gl Type"
              clearable={false}
              options={[
                { label: 'STANDARD', value: 'STANDARD' },
                { label: 'DUTIES', value: 'DUTIES' },
              ]}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={6}>
          <FormGroup>
            <FormLabel>
              GL Column Name{' '}
              <HelpIcon size="14px">
                If you've given your GL rule a custom name, then specify it here.
              </HelpIcon>
            </FormLabel>
            <ReduxFormFloatingLabelFormGroup
              type="text"
              name="code_name"
              // label="GL Column Name"
            />
          </FormGroup>
        </Grid>

        <Grid item xs={6}>
          <FormGroup>
            <FormLabel>
              GL Code <HelpIcon size="14px">Specify the value for your GL rule.</HelpIcon>
            </FormLabel>
            <ReduxFormFloatingLabelFormGroup type="text" name="code" />
          </FormGroup>
        </Grid>

        <Grid item xs={12} style={{ paddingBottom: '24px' }}>
          <FormGroup sx={{ maxWidth: 'fit-content' }}>
            <FormLabel>
              Starting From Date{' '}
              <HelpIcon size="14px">
                If no date is specified, the mapping will apply to all invoices. However,
                if a date is specified, the mapping will only apply to invoices received
                on or after that date.
              </HelpIcon>
            </FormLabel>

            <ReduxFormSingleDatePicker
              name="starting_from_date"
              label="Starting from date"
              isOutsideRange={(day) => isBeforeDay(day, moment())}
              // placeholder='mm/dd/yyyy'
            />
          </FormGroup>
        </Grid>

        <DataGridWrapper
          customComponentHeight={350}
          style={{ minHeight: '350px', width: '100%' }}
        >
          <DataGridCustom
            rows={rows}
            columns={columns}
            slots={{
              toolbar: EditToolbar,
              footer: React.Fragment,
            }}
            settings={{
              editMode: 'row',
              rowModesModel: rowModesModel,
              onRowModesModelChange: handleRowModesModelChange,
              onRowEditStop: handleRowEditStop,
              processRowUpdate: processRowUpdate,
              slotProps: {
                toolbar: {
                  setRows,
                  setRowModesModel,
                },
              },
            }}
          />
        </DataGridWrapper>

        <Grid item xs={12}>
          <Button
            type="submit"
            size="large"
            variant="contained"
            isLoading={props.submitting}
            disabled={isDisabledButton}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const AddGLCodeReduxForm = reduxForm({
  form: 'Settings.AddGLCodeForm',
})(AddGLCodeForm);

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: {
      ...{ gl_type: 'STANDARD' },
      ...ownProps.template,
      ...(state.glCodes.selectedGlCode || {}),
    },
    vendorAccountNumberMappings: state.vendorAccountNumberMappings,
  };
};

export default connect(mapStateToProps)(AddGLCodeReduxForm);
