import React from 'react';

const DashboardIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon Frame">
        <circle
          id="Ellipse 24"
          cx="17"
          cy="7"
          r="3"
          // stroke="#B0CEEC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          id="Ellipse 25"
          cx="7"
          cy="17"
          r="3"
          // stroke="#B0CEEC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Rectangle 13"
          d="M14 14H20V19C20 19.5523 19.5523 20 19 20H15C14.4477 20 14 19.5523 14 19V14Z"
          // stroke="#B0CEEC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Rectangle 14"
          d="M4 4H10V9C10 9.55228 9.55228 10 9 10H5C4.44772 10 4 9.55228 4 9V4Z"
          // stroke="#B0CEEC"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default DashboardIcon;
