'use strict';

import './ReduxFormCheckbox.scss';

import React from 'react';
import { Field } from 'redux-form';

const ReduxFormCheckbox = (props) => {
  var { children, ...props } = props;
  return (
    <div className="ReduxFormCheckbox custom-control custom-checkbox checkbox">
      <label>
        <Field {...props} component="input" type="checkbox" normalize={(v) => !!v} />
        <div className="CheckboxContent">{children}</div>
      </label>
    </div>
  );
};

export default ReduxFormCheckbox;
