export const loactionMatchingToObject = (data) => {
  return data.reduce((acc, item, index) => {
    const cityIsArray = Array.isArray(item.city);
    const stateIsArray = Array.isArray(item.state);
    const countryIsArray = Array.isArray(item.country);
    if (countryIsArray) {
      item.country.forEach((country) => {
        acc[country] = {};
      });
    } else {
      if (!acc[item.country])
        if (stateIsArray)
          acc[item.country] = item.state.reduce((a, i) => {
            a[i] = [];
            return a;
          }, {});
        else
          acc[item.country] = {
            [item.state]: cityIsArray ? item.city : [item.city],
          };
      else if (acc[item.country]) {
        if (stateIsArray) {
          acc[item.country] = {
            ...acc[item.country],
            ...item.state.reduce((a, i) => {
              a[i] = [];
              return a;
            }, {}),
          };
        } else
          acc[item.country] = {
            ...acc[item.country],
            [item.state]: acc[item.country][item.state]
              ? cityIsArray
                ? [...acc[item.country][item.state], ...item.city]
                : [...acc[item.country][item.state], item.city]
              : cityIsArray
              ? [...item.city]
              : [item.city],
          };
      }
    }
    return acc;
  }, {});
};
