import React, { memo, useRef, useState } from 'react';
import Popover from '@mui/material/Popover';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import SaveChart from './SaveChart';
import Paper from '@mui/material/Paper';
import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
} from '@mui/material';
import Icon from '../../Icon';
import { ic_image } from 'react-icons-kit/md/ic_image';
import { ic_file_download } from 'react-icons-kit/md/ic_file_download';
import { ic_keyboard_arrow_left } from 'react-icons-kit/md/ic_keyboard_arrow_left';
import { ic_keyboard_arrow_right } from 'react-icons-kit/md/ic_keyboard_arrow_right';
import { downloadChartCSV, downloadChoroplethCSV, downloadZonesCSV } from './utils';

const ChartPopover = (props) => {
  const downloadElementRef = useRef(null);
  const [currentTab, setCurrentTab] = useState('main'); // main / saveImg

  const onSelectSaveImage = (e) => {
    setCurrentTab('saveImg');
  };

  const onClickToMain = (e) => {
    setCurrentTab('main');
  };
  const onClickAway = (e) => {
    props.onClose();
    setTimeout(onClickToMain, 200);
  };

  const handleDownloadCSV = () => {
    if (props.chartConfig.type === 'choropleth') {
      downloadChoroplethCSV(downloadElementRef, props.chartConfig, props.title);
    } else if (props.chartConfig.type === 'bubble') {
      downloadZonesCSV(downloadElementRef, props.chartConfig, props.title);
    } else {
      downloadChartCSV(downloadElementRef, props.chartConfig, props.title);
    }
  };

  return (
    <Popover
      id={`ChartPopover_${props.title}`}
      open={!!props.anchorEl}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={onClickAway}
      >
        <Box>
          <Paper sx={{ minWidth: '390px', minHeight: '40px' }}>
            {currentTab === 'main' && (
              <MenuList>
                {props.additionalMenu || <></>}

                <MenuItem onClick={onSelectSaveImage}>
                  <ListItemIcon>
                    <Icon icon={ic_image} size={24} />
                  </ListItemIcon>
                  <ListItemText>Download as image</ListItemText>
                  <Icon icon={ic_keyboard_arrow_right} size={24} />
                </MenuItem>

                <MenuItem onClick={handleDownloadCSV}>
                  <ListItemIcon>
                    <Icon icon={ic_file_download} size={24} />
                  </ListItemIcon>
                  <ListItemText>Download as CSV</ListItemText>
                </MenuItem>
              </MenuList>
            )}
            {currentTab === 'saveImg' && (
              <Stack spacing={1} sx={{ padding: '14px 16px' }}>
                <Button
                  variant="text"
                  startIcon={<Icon icon={ic_keyboard_arrow_left} size={24} />}
                  onClick={onClickToMain}
                  // fullWidth={false}
                  sx={{ justifyContent: 'left' }}
                >
                  BACK
                </Button>
                <Divider sx={{ marginBottom: '18px !important' }} />
                <SaveChart {...props} />
              </Stack>
            )}
          </Paper>
          <span ref={downloadElementRef} />
        </Box>
      </ClickAwayListener>
    </Popover>
  );
};

export default memo(ChartPopover);
