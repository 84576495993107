import React, { useEffect, useMemo } from 'react';
import useChartTabs from 'common/components/Tabs/hooks/useChartTabs';
import PageContainer from '../containers/PageContainer';
import Box from '@mui/material/Box';
import TabsTitle from 'common/components/Tabs/TabsTitle';
import { useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import './AccountSettings.scss';
import { useDispatch, useSelector } from 'react-redux';
import Account from '../account/Account';
import Team from '../settings/Team';
import Billing from '../settings/Billing';
import { useCheckPermissions } from '../../common/helpers/hooks/useCheckPermissions';
import * as actions from '../store/actions';

const AccountSettings = () => {
  const { isDemo } = useSelector((state) => state.user);
  const vendorAccountNumberMappings = useSelector(
    (state) => state.vendorAccountNumberMappings
  );

  const dispatch = useDispatch();

  const checkPermissions = useCheckPermissions();

  const tabList = useMemo(() => {
    return [
      {
        label: 'Billing',
        path: 'billing',
      },
      {
        label: 'Team',
        path: 'team',
      },
      {
        label: 'Profile',
        path: 'profile',
      },
    ];

    // if (isPartner) {
    //   tabs.splice(2, 0, {
    //     label: 'Partners',
    //     path: 'partners',
    //     disabled: isDemo,
    //   });
    // }
  }, []);

  useEffect(() => {
    if (!vendorAccountNumberMappings || vendorAccountNumberMappings.length <= 0) {
      dispatch(actions.fetchVendorAccountNumberMappings());
    }
  }, []);

  const { currentTab, onChangeCurrentQueryNames } = useChartTabs(tabList, PAGE);

  const match = useRouteMatch(`/${PAGE}/:tab?`);

  const { isAllowTab } = checkPermissions([PERMISSION_PAGE_KEY, currentTab.path]);

  return (
    <PageContainer
      title="Account"
      contentId="account"
      contentClassName={classNames('ShipmentSetup', 'PageContentWithTabs')}
      secondHeaderClass={'max-width-1200 secondHeaderClass'}
      pageContentWrapperClassName={'max-width-1200'}
      seconHeaderContent={
        <TabsTitle
          tabList={tabList}
          value={currentTab.label}
          pages={PAGE}
          permissionPageKey={PERMISSION_PAGE_KEY}
          setValue={onChangeCurrentQueryNames}
        />
      }
    >
      <Box
        sx={{
          maxWidth: '1200px',
        }}
      >
        {isAllowTab && (
          <>
            {currentTab.path === 'billing' && <Billing />}

            {currentTab.path === 'team' && <Team />}

            {/*{currentTab.path === 'partners' && !isDemo && <Partners />}*/}

            {currentTab.path === 'profile' && <Account />}
          </>
        )}
      </Box>
    </PageContainer>
  );
};

const PAGE = 'account';
const PERMISSION_PAGE_KEY = PAGE.toUpperCase();

export default AccountSettings;
