import React from 'react';
import { connect } from 'react-redux';
import autobind from 'react-autobind';
import Button from 'common/components/Button';
import Icon from 'common/components/Icon';
import { ic_add } from 'react-icons-kit/md/ic_add';
import { GLCodesTable } from './GLCodesTable';
import AddGLCodeModal from './AddGLCodeModal';
import * as actions from '../../store/actions';
import Box from '@mui/material/Box';
import Tips from 'common/components/Tips/Tips';

/**
 * An empty placeholder to render when no payment information has been entered
 * yet.
 */
const EmptyPlaceholder = (props) => {
  return (
    <Button
      className="empty-placeholder_new"
      variant="outlined"
      onClick={props.showModal}
    >
      You haven&rsquo;t added any GL codes yet! Click here to get started.
    </Button>
  );
};

class GLCodes extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    this.props.fetchGlCodes();
  }

  render() {
    const glCodes = this.props.glCodes;

    return (
      <section className="GLCodes">
        <Box
          sx={{
            paddingBottom: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Tips>
            Set up 'GL Rule' for your vendor accounts to facilitate interaction with them
            in certain reports.
          </Tips>

          <Button
            variant="contained"
            // className="addButton"
            onClick={() => this.props.openModal()}
          >
            <Icon icon={ic_add} /> Add
          </Button>
        </Box>
        {glCodes.data.length > 0 ? (
          <GLCodesTable
            data={glCodes.data}
            onEdit={(row) => {
              this.props.openModal(row);
            }}
            onDelete={(id) => {
              if (confirm('Are you sure you want to remove this GL code?')) {
                this.props.deleteGlCode(id);
              }
            }}
          />
        ) : (
          <EmptyPlaceholder showModal={() => this.props.openModal()} />
        )}

        <AddGLCodeModal onHide={() => this.props.hideModal()} show={glCodes.showModal} />
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    glCodes: state.glCodes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchGlCodes: () => dispatch(actions.fetchGlCodes()),
  deleteGlCode: (id) => dispatch(actions.deleteGlCode(id)),
  openModal: (data = null) =>
    dispatch({ type: 'GL_CODES__MODAL__OPEN', payload: { data } }),
  hideModal: () => dispatch({ type: 'GL_CODES__MODAL__HIDE' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(GLCodes);
