import React, { useMemo } from 'react';

import * as utils from 'common/helpers/utils';

const InvoiceNumberFormatter = ({ cell }) => {
  return (
    <div>
      {cell}
      <div className="downloadLinks">
        <a href={`/shipper-optimization-invoices/${cell}.pdf`} target="_blank">
          PDF
        </a>
        <a href={`/shipper-optimization-invoices/${cell}.csv`} target="_blank">
          CSV
        </a>
      </div>
    </div>
  );
};

const StatusFormatter = ({ cell, row }) => {
  let status;
  if (cell === 'PAYMENT_NOT_NEEDED') {
    status = 'No payment needed';
  } else if (cell === 'CANCELLED') {
    status = 'Cancelled';
  } else if (cell === 'PAID') {
    status = `Paid ${utils.Fmt.date(row.paidDt)}`;
  } else if (window.G.partner.isShipperBillingEnabled) {
    var dueDateStr = utils.Fmt.date(row.paymentDueDate);
    status = `Due ${dueDateStr}`;
  } else {
    status = 'Unpaid';
  }

  return <div>{status}</div>;
};

export const useShipperOptimizationInvoicesColumns = () =>
  useMemo(
    () => [
      {
        field: 'invoiceDate',
        headerName: 'Invoice date',
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center ',
        renderCell: (params) => <InvoiceNumberFormatter cell={params.value} />,
      },
      {
        field: 'invoiceNumber',
        headerName: 'Invoice number',
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => utils.Fmt.date(params.value),
      },
      {
        field: 'totalSavings',
        headerName: 'Total savings',
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => utils.Fmt.dollars(params.value / 100),
      },
      {
        field: 'totalAmountDue',
        headerName: 'Amount due',
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => utils.Fmt.dollars(params.value / 100),
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <StatusFormatter cell={params.value} row={params.row.currentValue} />
        ),
      },
    ],
    []
  );
