import React, { useState } from 'react';
import { useUsersTableColumns } from '../hooks/useUsersTableColumns';
import { useUsersTableRows } from '../hooks/useUsersTableRows';
import DataGridWrapper from 'common/components/table/DataGridWrapper';
import DataGridCustom from 'common/components/table/DataGridCustom';

const SIZE_PER_PAGE = 10;

const UsersTable = (props) => {
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc',
    },
  ]);

  const rows = useUsersTableRows(props.users);
  const columns = useUsersTableColumns(props.dispatch);

  return (
    <div className="UsersTable show-icons-on-hover">
      <DataGridWrapper
        style={{
          minHeight: '500px',
        }}
      >
        <DataGridCustom
          rows={rows}
          columns={columns}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          settings={{
            initialState: {
              pagination: { paginationModel: { pageSize: SIZE_PER_PAGE } },
            },
            pageSizeOptions: [SIZE_PER_PAGE],
            hideFooter: false,
          }}
        />
      </DataGridWrapper>
    </div>
  );
};

export default UsersTable;
