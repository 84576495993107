import React, { useMemo } from 'react';
import TooltipIcon from 'common/components/TooltipIcon';
import { ic_edit } from 'react-icons-kit/md/ic_edit';

const ActionsFormatter = ({ row, dispatch }) => {
  const showUpdateVendorAccountNumberMappingModal = () => {
    dispatch({
      type: 'SETTINGS_SHIPPING_SETUP__UPDATE_VENDOR_ACCOUNT_NUMBER_MAPPING_MODAL__SHOW',
      payload: row,
    });
  };

  return (
    <div>
      <TooltipIcon
        className="EditIcon"
        icon={ic_edit}
        onClick={showUpdateVendorAccountNumberMappingModal}
      >
        Edit name
      </TooltipIcon>
    </div>
  );
};

export const useVendorAccountNumberMappingsColumns = (dispatch) =>
  useMemo(
    () => [
      {
        field: 'dispVendor',
        headerName: 'Vendor',
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center ',
      },
      {
        field: 'vendorAccountNumber',
        headerName: 'Account number',
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'action',
        headerName: '',
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: 'actions',
        renderCell: (params) => (
          <ActionsFormatter row={params.value} dispatch={dispatch} />
        ),
      },
    ],
    []
  );
