'use strict';

import autobind from 'react-autobind';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { enqueueSnackbar } from 'notistack';

import * as ajax from 'common/helpers/ajax';
import Button from 'common/components/Button';
import ReduxFormFloatingLabelFormGroup from 'common/components/ReduxFormFloatingLabelFormGroup';
import * as actions from '../store/actions';
import Grid from '@mui/material/Unstable_Grid2';

class UpdateVendorAccountNumberMappingForm extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.submitting != nextProps.submitting) {
      this.props.onSubmittingChange(nextProps.submitting);
    }
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.updateVendorAccountNumberMapping)}>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <ReduxFormFloatingLabelFormGroup
              type="text"
              name="vendorAccountNumber"
              label="Account number"
              disabled={true}
            />
          </Grid>
          <Grid xs={12}>
            <ReduxFormFloatingLabelFormGroup type="text" name="name" label="Name" />
          </Grid>
          <Grid xs={12}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              isLoading={this.props.submitting}
              disabled={this.props.pristine || this.props.submitting}
              sx={{ marginTop: '24px' }}
            >
              Save name
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }

  updateVendorAccountNumberMapping(data) {
    return this.props.dispatch(updateVendorAccountNumberMapping(data));
  }
}

const updateVendorAccountNumberMapping = (data) => {
  return (dispatch) => {
    var { id, ...rest } = data;
    return ajax.putJSON(
      '/api/vendor-account-number-mappings/' + id,
      rest,
      (response) => {
        dispatch(actions.fetchVendorAccountNumberMappings());
        dispatch({
          type: 'SETTINGS_SHIPPING_SETUP__UPDATE_VENDOR_ACCOUNT_NUMBER_MAPPING_MODAL__HIDE',
        });
        enqueueSnackbar('Success! Your account number name has been updated.', {
          variant: 'success',
        });
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

UpdateVendorAccountNumberMappingForm = reduxForm({
  form: 'Settings.UpdateVendorAccountNumberMappingForm',
})(UpdateVendorAccountNumberMappingForm);

const mapStateToProps = (state) => {
  return {
    initialValues:
      state.settingsShippingSetup.updateVendorAccountNumberMappingModal.initialValues,
  };
};

export default connect(mapStateToProps)(UpdateVendorAccountNumberMappingForm);
