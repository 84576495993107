export const required = (value) => (value ? undefined : 'Required');

export const insertValidate = (value) => {
  if (value?.includes(' ')) {
    return 'Spaces are not allowed, only line breaks';
  }
  return value && /^[a-zA-Z0-9\n-]+$/.test(value) ? undefined : 'Invalid data';
};

export const convertToJSON = (value) => {
  const linesArray = value.split('\n');
  const nonEmptyLinesArray = linesArray.filter((line) => line.trim() !== '');

  return JSON.stringify(nonEmptyLinesArray);
};
