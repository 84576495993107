import React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { ic_add } from 'react-icons-kit/md/ic_add';
import Icon from 'common/components/Icon';
import cn from 'classnames';
import Button from '@mui/material/Button';

const Content = styled(Button)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 20px 10px;
  min-height: calc(100% - 30px);
  width: 100%;
`;

export const AddNetRateBlock = ({ disabled, onClick }) => {
  return (
    <div>
      <Content
        className={cn('new_mui_button_card', {
          disabled: disabled,
        })}
        onClick={disabled ? undefined : onClick}
      >
        <Typography variant="subtitle1" align="center">
          Add another net rates to compare
        </Typography>

        <Icon icon={ic_add} size={32} />
      </Content>
    </div>
  );
};
