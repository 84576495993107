'use strict';

import React, { useEffect, useMemo, useState } from 'react';
import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import ReactSelect from 'react-select-plus';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import ArrowTriangle from './icon-components/ArrowTriangle';
import { connect, useSelector } from 'react-redux';
import { Field, change } from 'redux-form';
import Dialog from '@mui/material/Dialog';
import { ic_close } from 'react-icons-kit/md/ic_close';
import './ReduxFormReportsSelect.scss';
import cn from 'classnames';
import useIsVisible from '../helpers/hooks/useIsVisible';
import Icon from './Icon';
import DataGridCustom from './table/DataGridCustom';
import { SpinnerContainer } from './Spinner';
import useIsMounted from '../helpers/hooks/useIsMounted';
import * as ajax from '../helpers/ajax';
import { enqueueSnackbar } from 'notistack';
import { csvStringToObject } from '../helpers/utils';
import Button from '@mui/material/Button';
import DataGridWrapper from './table/DataGridWrapper';

const ReduxFormReportsSelect = (props) => {
  var { reports, placeholder, clearable, selectedReport, formName, ...props } = props;
  const isPremiumSubscription =
    useSelector((state) => state.user)?.shipper?.subscription === 'PREMIUM';

  const [isSelectedVisible, selectedRef] = useIsVisible();

  const [selectedOption, setSelectedOption] = useState(null);
  const [searchValue, setSearchValue] = useState(null);

  const options = useMemo(() => getReportCanNameOptions(reports), [reports]);

  const selectedOptionList = useMemo(
    () => options.filter((option) => option.label === selectedOption)?.[0]?.options || [],
    [selectedOption, options]
  );

  useEffect(() => {
    if (selectedReport && !selectedOption) {
      setSelectedOption(selectedReport.groupName);
    }
  }, [selectedReport, selectedOption]);

  const handleSelectedOption = (event, newAlignment) => {
    if (!!newAlignment) {
      setSelectedOption(newAlignment);
    }
  };

  const searchChange = (selectedOption) => {
    if (!selectedOption) {
      return;
    }

    const foundOption = getReportCanNameOptions([
      reports.find((report) => report.canName === selectedOption.value),
    ]);
    if (foundOption.length) {
      setSelectedOption(foundOption[0].label);
      props.dispatch(change(formName, 'reportCanName', selectedOption.value));
      setSearchValue(null);
      setTimeout(() => {
        document
          .getElementById(`reportCanName_label_${selectedOption.value}`)
          ?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 100);
    }
  };

  return (
    <Box className={'ReduxFormReportsSelect'}>
      <Box>
        <ReactSelect
          className={'middle_group'}
          value={searchValue}
          options={options}
          placeholder={'Search for report type'}
          clearable={true}
          onChange={searchChange}
        />
        <Box className={'choose_one'}>
          Choose one of the <span>{options.length} report types:</span>
        </Box>
      </Box>

      <ToggleButtonGroup
        value={selectedOption}
        exclusive
        onChange={handleSelectedOption}
        className={'ToggleButtonGroup'}
      >
        {options.map((option) => (
          <ToggleButton
            key={`ReduxFormReportsSelect_${option.label}`}
            value={option.label}
            className={'ToggleButton'}
          >
            <Box
              component="span"
              sx={{
                fontFamily: 'Outfit',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '17px',
                letterSpacing: 0,
              }}
            >
              {option.label}
            </Box>
            <ArrowTriangle />
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {!!selectedOptionList.length && (
        <Box className={'selectedOptionList'}>
          {selectedOptionList.map((option) => (
            <label
              id={`reportCanName_label_${option.value}`}
              key={`ReduxFormReportsSelect_selectedOptionList_${option.value}`}
              className={cn('label', {
                selectedLabel: selectedReport?.canName === option.value,
              })}
            >
              <Field
                type="radio"
                id={`reportCanName_${option.value}`}
                value={option.value}
                name="reportCanName"
                label={option.label}
                component="input"
                className="radio-button-native"
              />
              <Box className={'radio-button'}>
                {selectedReport?.canName === option.value ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                  >
                    <circle cx="6.5" cy="6.5" r="6.5" fill="#1175DB" />
                    <path
                      d="M5.69231 9C5.49315 9 5.29399 8.91994 5.15385 8.78166L3.22129 6.87482C2.92624 6.5837 2.92624 6.10334 3.22129 5.81222C3.51633 5.5211 4.00316 5.5211 4.29821 5.81222L5.69231 7.18777L8.70179 4.21834C8.99684 3.92722 9.48367 3.92722 9.77871 4.21834C10.0738 4.50946 10.0738 4.98981 9.77871 5.28093L6.23077 8.78166C6.09062 8.91994 5.89884 9 5.69231 9Z"
                      fill="#F5F8FD"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                  >
                    <circle cx="6.5" cy="6.5" r="6" stroke="#1175DB" />
                  </svg>
                )}
              </Box>

              <Box>
                <Box
                  className={'label-title'}
                  ref={selectedReport?.canName === option.value ? selectedRef : null}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div
                      className={`left-area ${
                        option.isPremium && !isPremiumSubscription ? 'PREMIUM' : ''
                      }`}
                    >
                      {option.label}
                    </div>
                    {option.tag && (
                      <Box
                        className="rightTag"
                        sx={{
                          backgroundColor: option.tag === 'NEW' ? '#71BB4F' : '#9747FF',
                        }}
                      >
                        {option.tag}
                      </Box>
                    )}
                    {option.isDemo && (
                      <Box
                        className="rightTag"
                        sx={{
                          backgroundColor: 'var(--galleon-blue-dark-1)',
                        }}
                      >
                        ONLY DEMO
                      </Box>
                    )}
                  </div>
                </Box>
                {option.helpText && <Box className={'help-text'}>{option.helpText}</Box>}
                {option.hasExample && (
                  <RunReportPreviewWrapper
                    reportCanName={option.value}
                    reportLabel={option.label}
                  />
                )}
              </Box>
            </label>
          ))}
        </Box>
      )}

      {(!isSelectedVisible ||
        (!!selectedReport?.canName &&
          !selectedOptionList.find(
            (option) => option.value === selectedReport?.canName
          ))) && (
        <Box
          className={'selectedHint'}
          onClick={() => {
            searchChange({ value: selectedReport.canName });
          }}
        >
          <span className={'selectedHint_title'}>Selected: </span>{' '}
          <span className={'selectedHint_value'}>
            {reports.find((report) => report.canName === selectedReport.canName).dispName}
          </span>
        </Box>
      )}
    </Box>
  );
};

const getReportCanNameOptions = (reports) => {
  let result = _map(
    // First, group the reports by group name.
    _groupBy(reports, (obj) => obj.groupName),
    // Then construct the nested options for it.
    (reports, groupName) => {
      return {
        label: groupName,
        options: reports.map((report) => ({
          label: report.dispName,
          value: report.canName,
          helpText: report.helpText,
          tag: report.tag,
          isDemo: report.isDemo,
          isPremium: report.isPremium,
          hasExample: report.hasExample,
        })),
      };
    }
  );
  return _sortBy(result, (obj) => obj.label);
};

const RunReportPreviewWrapper = ({ reportCanName, reportLabel }) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Button
          variant="text"
          className={'see-example'}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();

            setOpen(true);
          }}
        >
          See example
        </Button>
        <Dialog
          fullScreen={true}
          open={open}
          onClose={handleClose}
          maxWidth="xl"
          PaperProps={{
            style: {
              width: '100%',
              height: '100vh',
            },
          }}
        >
          <Box
            sx={{
              margin: '10px auto',
              display: 'flex',
              gap: '18px',
              alignItems: 'center',
            }}
          >
            <h3 style={{ marginBottom: 0, marginTop: 0 }}>{reportLabel}</h3>
            <Button
              variant="outlined"
              endIcon={<Icon icon={ic_close} size={24} />}
              color="error"
              onClick={handleClose}
              sx={{ fontWeight: '600 !important' }}
            >
              Close preview
            </Button>
          </Box>

          {/* Modal content goes here */}
          <RunReportPreviewContent reportCanName={reportCanName} />
        </Dialog>
      </div>
    </>
  );
};

const RunReportPreviewContent = ({ reportCanName }) => {
  const [loadingStatus, setLoadingStatus] = useState('LOADING'); //'SUCCESS' 'ERROR'
  const [runReport, setRunReport] = useState(null);
  const isMounted = useIsMounted();

  useEffect(() => {
    setLoadingStatus('LOADING');
    ajax.getJSON(
      `/api/report-runs/${reportCanName}/preview`,
      {},
      (response) => {
        if (isMounted) {
          setRunReport(csvStringToObject(response).data);
          setLoadingStatus('SUCCESS');
        }
      },
      (response) => {
        if (isMounted) {
          enqueueSnackbar(response.message, { variant: 'error' });
          setLoadingStatus('ERROR');
        }
      }
    );
  }, [reportCanName]);

  const rows = useMemo(() => {
    if (!runReport) {
      return [];
    }
    return runReport.map((row, index) => ({
      id: index,
      ...row,
    }));
  }, [runReport]);

  const columns = useMemo(() => {
    const keys = Object.keys(runReport?.[0] || {});

    return keys.map((key) => ({
      field: key,
      headerName: key,
      flex: 1,
      minWidth: 140,
      headerAlign: 'left',
      align: 'left',
    }));
  }, [runReport]);

  return (
    <Box sx={{ height: '100%', position: 'relative' }}>
      {loadingStatus === 'SUCCESS' ? (
        <DataGridWrapper customPudding={0}>
          <DataGridCustom
            rows={rows}
            columns={columns}
            sx={{
              '.MuiDataGrid-columnHeadersInner': {
                backgroundColor: '#F5F8FD',
                borderRadius: '12px 12px 0px 0px',
              },
              '.MuiDataGrid-footerContainer': {
                backgroundColor: '#F5F8FD',
              },
            }}
          />
        </DataGridWrapper>
      ) : (
        <SpinnerContainer isVisible message="Loading..." />
      )}
    </Box>
  );
};

export default connect()(ReduxFormReportsSelect);
