import * as utils from 'common/helpers/utils';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { ic_arrow_upward_twotone } from 'react-icons-kit/md/ic_arrow_upward_twotone';
import { ic_south } from 'react-icons-kit/md/ic_south';
import Icon from 'common/components/Icon';

const LastPeriodComponentWrapper = ({ tooltipValue, isShowTooltip, oldValue, value }) => {
  const isOldValueNumber = oldValue && typeof oldValue === 'number';

  let lastPeriodValuePerc = null;
  let lastPeriodValueTrending = null; //up down
  if (oldValue) {
    if (isOldValueNumber) {
      const lastPeriodValueNumberDif = value - oldValue;
      const lastPeriodValueDif = Math.abs(
        utils.Fmt.float2(((oldValue - value) / oldValue) * 100)
      );
      lastPeriodValueTrending =
        lastPeriodValueNumberDif > 0
          ? 'up'
          : lastPeriodValueNumberDif === 0
          ? 'same'
          : 'down';
      lastPeriodValuePerc = (
        <>
          {lastPeriodValueNumberDif > 0 ? (
            <Icon
              icon={ic_arrow_upward_twotone}
              style={{ transform: 'translateY(-2px)' }}
            />
          ) : (
            <Icon icon={ic_south} />
          )}
          {lastPeriodValueDif}%
        </>
      );
    } else {
      lastPeriodValuePerc = oldValue;
      lastPeriodValueTrending = 'same';
    }
  }

  if (isShowTooltip) {
    return (
      <Tooltip title={tooltipValue}>
        <span className="lastPeriod_wrapper">
          <div
            className={`lastPeriod_value ${
              lastPeriodValueTrending === 'up' ? 'lastPeriod_value_green' : ''
            } ${lastPeriodValueTrending === 'down' ? 'lastPeriod_value_red' : ''} ${
              lastPeriodValueTrending === 'same' ? 'lastPeriod_value_blue' : ''
            }`}
          >
            {lastPeriodValuePerc}
          </div>
          <span className="lastPeriod_text">from last period</span>
        </span>
      </Tooltip>
    );
  }
  return (
    <span className="lastPeriod_wrapper">
      <div
        className={`lastPeriod_value ${
          lastPeriodValueTrending === 'up' ? 'lastPeriod_value_green' : ''
        } ${lastPeriodValueTrending === 'down' ? 'lastPeriod_value_red' : ''} ${
          lastPeriodValueTrending === 'same' ? 'lastPeriod_value_blue' : ''
        }`}
      >
        {lastPeriodValuePerc}
      </div>
      <span className="lastPeriod_text">from last period</span>
    </span>
  );
};

export default LastPeriodComponentWrapper;
