'use strict';

import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { Button } from '@mui/material';
import * as actions from '../store/actions';
import TableHeaderStack from 'common/components/table/TableHeaderStack';
import Box from '@mui/material/Box';
import DataGridWrapper from 'common/components/table/DataGridWrapper';
import DataGridCustom from 'common/components/table/DataGridCustom';
import { styled } from '@mui/material/styles';
import { useSearch } from 'common/components/table/TableHeaderSearch';
import { useRows } from './hooks/useRecentReportRunsRows';
import { useColumns } from './hooks/useRecentReportRunsColumns';
import { searchTableFn } from 'common/helpers/utils';
import Reload from 'common/components/icon-components/Reload';
import Reports from 'common/components/icon-components/Reports';
import Tips from 'common/components/Tips/Tips';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

const SIZE_PER_PAGE = 10;

const RecentReportRuns = ({ dispatch, reportRuns }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: 'createdDt',
      sort: 'desc',
    },
  ]);
  const { searchQuery, onHandlerSearch } = useSearch('');

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      actions.fetchReportRuns(
        () => {
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        }
      )
    );
  }, []);

  const onRefresh = () => {
    setIsLoading(true);
    dispatch(
      actions.fetchReportRuns(
        (response) => {
          setIsLoading(false);
          enqueueSnackbar('Your recent reports are now up to date.', {
            variant: 'success',
          });
        },
        () => {
          setIsLoading(false);
        }
      )
    );
  };

  const rows = useRows(reportRuns);
  const columns = useColumns();

  const searchedReports = useMemo(() => {
    const keyList = ['username', 'companyName', 'reportDispName'];
    return searchTableFn(searchQuery, rows, keyList);
  }, [searchQuery, rows]);

  return (
    <Box className="RecentReportRuns tableWrapper">
      <DataGridWrapper
        style={{
          minHeight: '500px',
          padding: '28px 32px 60px 32px',
        }}
      >
        <TableHeaderStack
          style={{ height: 'auto' }}
          onHandlerSearch={onHandlerSearch}
          searchPlaceholder={'Search for report type'}
          searchQuery={searchQuery}
          title={
            <>
              <Tips>
                Reports that appear here will be removed after 7 days (Basic) /30 days
                (Pro) and won't be visible to any other members of your team.
              </Tips>
            </>
          }
          actions={
            <Button
              variant="contained"
              onClick={onRefresh}
              sx={{ padding: '11px', borderRadius: '16px', minWidth: 'auto' }}
            >
              <Reload />
            </Button>
          }
        />
        <DataGridCustom
          rows={searchedReports}
          columns={columns}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          slotProps={{ toolbar: { csvOptions: { fileName: 'Recent reports' } } }}
          settings={{
            initialState: {
              pagination: { paginationModel: { pageSize: SIZE_PER_PAGE } },
            },
            pageSizeOptions: [SIZE_PER_PAGE, 30, 50],
            hideFooter: false,
            loading: isLoading,
          }}
          customNoTableDataOverlay={
            <StyledGridOverlay>
              <Reports />
              <Box className="styledGridOverlay_title">Reports will appear here</Box>
              <Box className="styledGridOverlay_text">
                You will be able to download successful reports and see information about
                failed reports.
              </Box>
            </StyledGridOverlay>
          }
          sx={{
            height: 'calc(100% - 62px)',
            '.MuiDataGrid-columnHeadersInner': {
              backgroundColor: '#F5F8FD',
              borderRadius: '12px 12px 0px 0px',
            },
            '.MuiDataGrid-columnHeaders': {
              minHeight: '34px !important',
              maxHeight: '34px !important',
              fontFamily: 'Outfit',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal !important',
              textTransform: 'uppercase',
            },
            '.MuiDataGrid-row': {
              backgroundColor: '#FFF',
            },
          }}
        />
      </DataGridWrapper>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    reportRuns: state.reportRuns,
  };
};

export default connect(mapStateToProps)(RecentReportRuns);
