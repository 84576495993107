'use strict';

import './Reporting.scss';

import React from 'react';
import autobind from 'react-autobind';
import _range from 'lodash/range';
import { FormGroup, FormLabel } from '@mui/material';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, formValueSelector } from 'redux-form';
import { enqueueSnackbar } from 'notistack';

import * as actions from '../store/actions';
import * as ajax from 'common/helpers/ajax';
import Button from 'common/components/Button';

import HelpIcon from 'common/components/HelpIcon';
import ReduxFormSelect from 'common/components/ReduxFormSelect';
import ReduxFormReportsSelect from 'common/components/ReduxFormReportsSelect';
import ReduxFormVendorAccountNumbersSelect from 'common/components/ReduxFormVendorAccountNumbersSelect';

import ReportFormHelpers from './ReportFormHelpers';
import _filter from 'lodash/filter';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import ReduxFormFloatingLabelFormGroup from '../../common/components/ReduxFormFloatingLabelFormGroup';
import { getVendors } from '../../common/helpers/utils';

class ScheduledReportForm extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentWillMount() {
    if (this.props.reports.length === 0) {
      this.props.dispatch(actions.fetchReports());
    }
  }

  render() {
    const filteredReport = _filter(this.props.reports, function (o) {
      return o.scheduledReport !== false;
    });

    return (
      <form
        className="ScheduledReportForm ReportingForm defaultColumnFormStyle"
        onSubmit={this.props.handleSubmit(this.saveScheduledReport)}
        id="ScheduledReportForm"
      >
        <h3
          className="small_group"
          style={{
            paddingLeft: '32px',
            margin: 0,
          }}
        >
          Report settings
        </h3>
        <FormGroup
          className={'RunReport_reportType formGroup'}
          sx={{
            borderRadius: '22px !important',
          }}
        >
          <FormLabel className={'RunReport_reportType_label'}>Report type</FormLabel>
          <ReduxFormReportsSelect
            name="reportCanName"
            placeholder="Select a report type"
            reports={filteredReport}
            formName={'Reporting.ScheduledReportForm'}
            selectedReport={this.props.selectedReport}
          />
        </FormGroup>
        {ReportFormHelpers.getDateGroupingFormGroupEl(this.props.selectedReport)}
        <FormGroup className={'small_group formGroup'}>
          <FormLabel>Vendor</FormLabel>
          <ReduxFormSelect
            name="vendor"
            placeholder="Select vendor"
            options={getVendors(this.props.vendorAccountNumberMappings)}
            clearable={true}
          />
        </FormGroup>
        <FormGroup className={'small_group formGroup'}>
          <FormLabel>Account numbers</FormLabel>
          <ReduxFormVendorAccountNumbersSelect
            name="vendorAccountNumbers"
            placeholder="Leave empty to include all account numbers"
            vendorAccountNumberMappings={this.props.vendorAccountNumberMappings}
          />
        </FormGroup>
        <FormGroup className={'small_group formGroup'}>
          <FormLabel>File format</FormLabel>
          <ReduxFormSelect
            name="fileFormat"
            placeholder="Select a file format"
            options={ReportFormHelpers.getFileFormatOptions()}
            clearable={false}
          />
        </FormGroup>
        <Box>
          <FormGroup className={'small_group formGroup'}>
            <FormLabel>Scheduled frequency</FormLabel>
            <ReduxFormSelect
              name="schedFrequency"
              placeholder="Select a frequency"
              options={getSchedFrequencyOptions()}
              clearable={false}
            />
          </FormGroup>
        </Box>
        {getSchedRunOnDayFormGroup(this.props.formValues)}
        {getDateRangeFormGroup(this.props.formValues)}
        <Box className="small_group formGroup">
          <ReduxFormFloatingLabelFormGroup
            name="recipients"
            component="input"
            placeholder="johnny@email.com, bobby@email.com"
            type="text"
            label="Recipients"
          />

          <span className="help-block">
            Separate multiple email addresses with commas.
          </span>
        </Box>

        <FormGroup className={'small_group formGroup'}>
          <Button
            type="submit"
            size="large"
            variant="contained"
            isLoading={this.props.submitting}
            disabled={!this.props.shipper.hasAxShipments || this.props.submitting}
          >
            Save report
          </Button>
        </FormGroup>
      </form>
    );
  }

  saveScheduledReport(data) {
    return this.props.dispatch(
      saveScheduledReport(
        data,
        (response) => {
          enqueueSnackbar('Success! Your scheduled report has been saved.', {
            variant: 'success',
          });
          this.props.dispatch(actions.fetchScheduledReports());
          this.resetForm();
        },
        (response) => {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      )
    );
  }

  resetForm() {
    this.props.initialize(getInitialValues(this.props));
  }
}

const getDateRangeFormGroup = (formValues) => {
  if (!formValues || !formValues.schedFrequency) {
    return null;
  }

  const dateRangeLengthOptions = _range(1, 15).map((i) => ({
    label: `${i} full ${formValues.schedFrequency.toLowerCase()}` + (i > 1 ? 's' : ''),
    value: i,
  }));

  // If the use is running a weekly report, then we should force the date range
  // to start at least 2 weeks back. It makes no sense to let the user just look
  // at data from the last week since that data is almost certainly incomplete.
  let dateRangeStartMin = formValues.dateRangeLength || 1;
  if (formValues.schedFrequency === 'WEEK') {
    dateRangeStartMin = Math.max(dateRangeStartMin, 2);
  }

  const dateRangeStartOptions = _range(dateRangeStartMin, 15).map((i) => ({
    label:
      `starting ${i} ${formValues.schedFrequency.toLowerCase()}` +
      (i > 1 ? 's' : '') +
      ' back',
    value: i,
  }));

  return (
    <FormGroup className={'small_group formGroup'}>
      <FormLabel>
        Report date range
        <HelpIcon>
          Specifies the date range the report should cover, and the start of that date
          range. The start date will always be relative to the date that the report is
          generated to you.
        </HelpIcon>
      </FormLabel>
      <Grid container spacing={1}>
        <Grid md={6}>
          <ReduxFormSelect
            name="dateRangeLength"
            placeholder="Please select a date range"
            options={dateRangeLengthOptions}
            clearable={false}
          />
        </Grid>
        <Grid md={6}>
          <ReduxFormSelect
            name="dateRangeStart"
            placeholder="Please select a start date"
            options={dateRangeStartOptions}
            clearable={false}
          />
        </Grid>
      </Grid>
    </FormGroup>
  );
};

const getSchedFrequencyOptions = () => {
  return [
    { label: 'Every week', value: 'WEEK' },
    { label: 'Every month', value: 'MONTH' },
  ];
};

const getSchedRunOnDayFormGroup = (formValues) => {
  if (!formValues || !formValues.schedFrequency) {
    return null;
  }

  // HACK: react-select doesn't work with a `0` integer value, so we need to
  // store these as strings.
  const dayOfWeekOptions = [
    { label: 'Sunday', value: 0 },
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 },
    { label: 'Saturday', value: 6 },
  ];

  // Show day of month options from 1-28.
  const dayOfMonthOptions = _range(1, 29).map((i) => ({
    label: i.toString(),
    value: i,
  }));

  return (
    <FormGroup className={'small_group formGroup'}>
      <FormLabel>Scheduled day of {formValues.schedFrequency.toLowerCase()}</FormLabel>
      <ReduxFormSelect
        name="schedDayInPeriod"
        placeholder={
          formValues.schedFrequency === 'WEEK'
            ? 'Please select a day of the week'
            : 'Please select a day of the month'
        }
        options={
          formValues.schedFrequency === 'WEEK' ? dayOfWeekOptions : dayOfMonthOptions
        }
        clearable={false}
      />
    </FormGroup>
  );
};

ScheduledReportForm = reduxForm({
  form: 'Reporting.ScheduledReportForm',
})(ScheduledReportForm);

/**
 * Thunk for submitting a scheduled report.
 */
const saveScheduledReport = (data, successCallback, errorCallback) => {
  return (dispatch) => {
    const func = data.uuid ? ajax.putJSON : ajax.postJSON;
    const url = '/api/scheduled-reports' + (data.uuid ? '/' + data.uuid : '');

    return func(
      url,
      data,
      (response) => {
        if (successCallback) {
          successCallback(response);
        }
      },
      (response) => {
        if (errorCallback) {
          errorCallback(response);
        }
      }
    );
  };
};

const getInitialValues = (state) => ({
  fileFormat: 'CSV',
  schedFrequency: 'WEEK',
  recipients: state.user.email,
});
const selector = formValueSelector('Reporting.ScheduledReportForm');
const mapStateToProps = (state) => {
  return {
    initialValues: getInitialValues(state),

    user: state.user,
    shipper: state.shipper,
    reports: state.reports,
    vendorAccountNumberMappings: state.vendorAccountNumberMappings,

    formValues: getFormValues('Reporting.ScheduledReportForm')(state),
    selectedReport: state.reports.find((report) => {
      return report.canName === selector(state, 'reportCanName');
    }),
  };
};

export default connect(mapStateToProps)(ScheduledReportForm);
