import { useMemo } from 'react';

export const useShipperInvoicesRows = (shipperInvoices) =>
  useMemo(
    () =>
      shipperInvoices?.reduce((prevState, currentValue) => {
        const currentRow = {
          id: currentValue.invoiceNumber,
          invoiceDate: currentValue.invoiceDate,
          invoiceNumber: currentValue.invoiceNumber,
          amountRecovered: currentValue.amountRecovered,
          totalAmountDue: currentValue.totalAmountDue,
          status: currentValue.status,
          currentValue,
        };
        prevState.push(currentRow);
        return prevState;
      }, []),
    [shipperInvoices]
  );
