'use strict';

import React from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { Field } from 'redux-form';

const wrapToggleButtonGroup = (props) => {
  var { options, input, meta, ...props } = props;

  var toggleButtons = options.map((option) => (
    <ToggleButton variant="outline-secondary" key={option.value} value={option.value}>
      {option.label}
    </ToggleButton>
  ));

  return (
    <ToggleButtonGroup
      type="radio"
      exclusive
      name="options"
      color="primary"
      onChange={input.onChange}
      value={input.value}
      {...props}
    >
      {toggleButtons}
    </ToggleButtonGroup>
  );
};

const ReduxFormToggleButtonGroup = (props) => {
  return <Field {...props} component={wrapToggleButtonGroup} />;
};

export default ReduxFormToggleButtonGroup;
