import React from 'react';
import TextField from '@mui/material/TextField';

const StyledTextField = (props) => {
  return (
    <TextField
      sx={{
        width: '100%',
        height: '100%',

        '& > div > input': {
          padding: '8px 16px 7px 0px',

          fontFamily: 'Outfit',
          fontSize: '13px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',
          color: '#1175DB',
        },
        'input::placeholder': {
          color: '#1175DB',
        },
        '& .MuiOutlinedInput-root': {
          background: '#FFF',
        },
        'input:-internal-autofill-selected': {
          backgroundColor: '#FFF !important',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#B0CEEC !important',
          // borderWidth: '1px ',
        },
        '& .MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline': {
          borderColor: '#B0CEEC !important',
          // borderWidth: '1px ',
        },
        '& .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline': {
          borderColor: '#B0CEEC !important',
          // borderWidth: '1px ',
        },
        '.MuiOutlinedInput-notchedOutline': {
          border: '1px solid',
          borderColor: '#B0CEEC !important',
          borderRadius: '3px',
        },
        '.MuiOutlinedInput-notchedOutline:hover, .MuiOutlinedInput-notchedOutline:active, .MuiOutlinedInput-notchedOutline:focus':
          {
            border: '1px solid',
            borderColor: '#92bde8 !important',
          },

        '.MuiInputBase-adornedStart': {
          paddingLeft: '7px',
          '.MuiInputAdornment-outlined': {},
        },
        ...(props.styled || {}),
      }}
      {...props}
    />
  );
};

export default StyledTextField;
