import React, { useState } from 'react';
import moment from 'moment';
import * as ajax from 'common/helpers/ajax';
import * as utils from 'common/helpers/utils';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import Tooltip from '@mui/material/Tooltip';
import { ic_help } from 'react-icons-kit/md/ic_help';
import isBeforeDay from 'react-dates/lib/utils/isBeforeDay';
import { enqueueSnackbar } from 'notistack';
import humps from 'humps';
import ReduxFormSingleDatePicker from 'common/components/ReduxFormSingleDatePicker';
import { useVendorInvoicesPaymentFormColumns } from './hooks/useVendorInvoicesPaymentFormColumns';
import { useVendorInvoicesPaymentFormRows } from './hooks/useVendorInvoicesPaymentFormRows';
import DataGridWrapper from 'common/components/table/DataGridWrapper';
import DataGridCustom from 'common/components/table/DataGridCustom';
import Grid from '@mui/material/Unstable_Grid2';
import HelpIcon from '../../common/components/HelpIcon';

const SIZE_PER_PAGE = 30;

let VendorInvoicesPaymentForm = (props) => {
  const { filteredInvoicesCount, selectedInvoices } = props;
  const [expectedPaymentDate, setExpectedPaymentDate] = useState(null);

  const [sortModel, setSortModel] = useState([
    {
      field: 'vendor',
      sort: 'asc',
    },
  ]);

  const makeScheduledPayment = () => {
    const invoiceNumbers = props.selectedInvoices.map((one) => one.invoiceNumber);

    return ajax.postJSON(
      '/api/scheduled-payment',
      {
        invoiceNumbers: invoiceNumbers,
        expectedPaymentDate: expectedPaymentDate,
      },
      (response) => {
        props.dispatch(fetchVendorInvoices(props.pagination, props.filters));

        enqueueSnackbar('Thank you, your Payment request was submitted!', {
          variant: 'success',
        });
        props.onClose && props.onClose();
      },
      (response) => {
        enqueueSnackbar(
          'Something went wrong! Please contact us at support@galleonlogistics.com',
          { variant: 'error' }
        );
        props.onClose && props.onClose();
      }
    );
  };

  const fetchVendorInvoices = (pagination, filters) => {
    return (dispatch) => {
      if (pagination.sortKey) {
        pagination.sortKey = humps.decamelize(pagination.sortKey);
      }

      return ajax.postJSON(
        '/api/vendor-invoices',
        {
          pagination: pagination,
          filters: filters,
        },
        (response) => {
          dispatch({ type: 'VENDOR_INVOICES__UPDATE', payload: response });
        },
        (response) => {
          console.log(response);
        }
      );
    };
  };

  const rows = useVendorInvoicesPaymentFormRows(selectedInvoices);
  const columns = useVendorInvoicesPaymentFormColumns(props.onRemove);

  return (
    <form
      className="VendorInvoicesPaymentForm"
      onSubmit={props.handleSubmit(makeScheduledPayment)}
    >
      <p className="form-title">
        Pre-payment summary{' '}
        {filteredInvoicesCount > 0 ? (
          <span className="warning">
            {filteredInvoicesCount} invoices excluded because status was not "Unpaid" or
            their amount is not defined yet.
          </span>
        ) : (
          ''
        )}
      </p>

      <DataGridWrapper
        customComponentHeight={300}
        style={{ minHeight: '300px', width: '100%' }}
      >
        <DataGridCustom
          rows={rows}
          columns={columns}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          settings={{
            initialState: {
              pagination: { paginationModel: { pageSize: SIZE_PER_PAGE } },
            },
            pageSizeOptions: [SIZE_PER_PAGE],
            hideFooter: false,
          }}
        />
      </DataGridWrapper>

      {props.totalAmount > 0 && (
        <div className="TotalPaymentAmount">
          Total to be paid <span>{utils.Fmt.dollars(props.totalAmount / 100)}</span>
        </div>
      )}

      <Grid className="ExpectedPaymentDate">
        <Grid md={4}>
          <FormGroup>
            <div className="form-title">
              Expected payment date
              <Tooltip
                title={
                  <p>
                    Please indicate the day you expect funds to arrive. <br />
                    If we do not see your payment by this date, we will get in touch with
                    you.
                  </p>
                }
                placement="top"
              >
                <span>
                  <HelpIcon icon={ic_help} size={16} />
                </span>
              </Tooltip>
            </div>

            <ReduxFormSingleDatePicker
              name="expectedPaymentDate"
              isOutsideRange={(day) => isBeforeDay(day, moment())}
              onChange={(date) => {
                setExpectedPaymentDate(moment(date).format('YYYY-MM-DD'));
              }}
            />
          </FormGroup>
        </Grid>
      </Grid>

      <div className="TooltipButtonWrap">
        <Button
          disabled={selectedInvoices.length === 0 || !expectedPaymentDate}
          className="GreenButton"
          type="submit"
          variant="contained"
        >
          Send payment info
        </Button>
        <Tooltip
          title={
            <>
              <p>
                Hitting "Send payment info" will notiify our payment partner of which
                invoices you intend to pay in this batch.
              </p>
              <p>
                To ensure timely payment, you will also need to initiate an ACH to the
                account and routing info provided when you enrolled in Galleon Payments.
              </p>
              <p>
                You will receive a confirmation email + CSV summary of this transaction.
              </p>
            </>
          }
          placement="right"
        >
          <span>
            <HelpIcon icon={ic_help} size={16} />
          </span>
        </Tooltip>
      </div>
    </form>
  );
};

VendorInvoicesPaymentForm = reduxForm({
  form: 'VendorInvoices.PaymentForm',
  enableReinitialize: true,
})(VendorInvoicesPaymentForm);

const mapStateToProps = (state) => {
  return {
    initialValues: state.vendorInvoices.invoicesPayment,
    pagination: state.vendorInvoices.pagination,
    filters: state.vendorInvoices.filters,
  };
};

export default connect(mapStateToProps)(VendorInvoicesPaymentForm);
