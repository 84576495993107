import React, { useMemo, useState } from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import Chart from './Chart';
import _merge from 'lodash/merge';
import { defaultChartJSConf } from './Constants';

const ChartViewModel = (props) => {
  const [chart, setChart] = useState(null);

  const [hiddenDatasets, setHiddenDatasets] = useState(
    props.hideLegend ? props.chartConfig.data.datasets.slice(5) : []
  );

  const config = useMemo(() => {
    const cloneConfig = _cloneDeep(props.chartConfig);
    cloneConfig.data.datasets = props.hideLegend
      ? props.chartConfig.data.datasets.slice(0, 5)
      : props.chartConfig.data.datasets;

    if (props.hideLegend) {
      cloneConfig.options = {
        ...cloneConfig.options,
        legend: { display: false },
      };
    }
    _merge(cloneConfig.options, defaultChartJSConf);
    return cloneConfig;
  }, [props.chartConfig]);

  const [selectedDatasets, setSelectedDatasets] = useState(
    config.data.datasets.map((dataset) => ({
      label: dataset.label,
      value: dataset,
    }))
  );

  const addDataToChart = (newDatasets) => {
    const newDatasetLabels = newDatasets.map((dataset) => dataset.label);

    setHiddenDatasets(
      props.chartConfig.data.datasets.filter(
        (dataset, i) => !newDatasetLabels.includes(dataset.label)
      )
    );

    chart.data.datasets = newDatasets.map((one) => one.value);
    chart.update();
  };

  return (
    <Chart
      selectedDatasets={selectedDatasets}
      setSelectedDatasets={setSelectedDatasets}
      hiddenDatasets={hiddenDatasets}
      setHiddenDatasets={setHiddenDatasets}
      addDataToChart={addDataToChart}
      chart={chart}
      setChart={setChart}
      config={config}
      {...props}
    />
  );
};

export default ChartViewModel;
