'use strict';

import './VendorInvoices.scss';

import autobind from 'react-autobind';
import humps from 'humps';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { action as toggleFilterSidebar } from 'redux-burger-menu';
import { ic_schedule } from 'react-icons-kit/md/ic_schedule';
import { ic_filter_alt_outline } from 'react-icons-kit/md/ic_filter_alt_outline';

import DropdownButton from 'common/components/DropdownButton';
import Chip from '@mui/material/Chip';
import { enqueueSnackbar } from 'notistack';
import { VendorInvoicesHeader } from './VendorInvoicesHeader';
import VendorInvoicesPaymentModal from './VendorInvoicesPaymentModal';

import * as ajax from 'common/helpers/ajax';
import * as utils from 'common/helpers/utils';
import * as constants from 'common/helpers/constants';
import Icon from 'common/components/Icon';
import PageContainer from 'cloud/containers/PageContainer';
import TooltipIcon from 'common/components/TooltipIcon';
import { SpinnerContainer } from 'common/components/Spinner';
import VendorInvoicesTable from './VendorInvoicesTable';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import * as actions from '../store/actions';

const DOWNLOAD_SELECTED_FORMATS = {
  PREFER_PDF: {
    value: 'prefer_pdf',
    label: 'Prefer PDF',
  },
  PREFER_CSV: {
    value: 'prefer_csv',
    label: 'Prefer CSV',
  },
  INVOICE_SUMMARY_REPORT: {
    value: 'invoice_summary_report',
    label: 'Invoice summary report',
  },
};

class VendorInvoices extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.table = null;

    this.state = {
      // We need to mirror a copy of the filterSetId so we know when we should
      // allow updating the latest value.
      filterSetId: -1,
      showModal: false,
      selectedVendorInvoices: [],
      isFetching: false,
    };
  }

  /**
   * Load current `filterSetId` from the redux store here.
   */
  componentWillMount() {
    this.setState({
      filterSetId: this.props.filters.filterSetId,
    });
  }

  /**
   * After the component mounts, we should load the initial set of vendor
   * invoices.
   */
  setFetchingData(value) {
    this.setState({
      isFetching: value,
    });
  }
  componentDidMount() {
    this.props.dispatch(
      this.fetchVendorInvoices(
        this.props.pagination,
        this.props.filters,
        this.setFetchingData
      )
    );

    if (
      !this.props.vendorAccountNumberMappings ||
      this.props.vendorAccountNumberMappings.length <= 0
    ) {
      this.props.dispatch(actions.fetchVendorAccountNumberMappings());
    }

    if (this.props.vendorInvoiceDetailsError) {
      enqueueSnackbar(this.props.vendorInvoiceDetailsError, { variant: 'error' });
      this.props.dispatch({
        type: 'VENDOR_INVOICE_DETAILS_ERROR',
        payload: '',
      });
    }
  }

  /**
   * If the component's next props increment the `filterSetId`, then initiate a
   * fetch.
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.filters.filterSetId > this.state.filterSetId) {
      this.onFiltersChange(nextProps.filters);
    }
  }

  render() {
    const invoicesCount = this.state.selectedVendorInvoices.length;
    const rightIcons = [
      <Button
        key={'rightIcons_filter'}
        onClick={this.toggleFilterSidebar}
        size="small"
        variant="contained"
        color="primary"
        className="RightIcons"
        aria-label="settings"
        sx={{ width: '42px', height: '42px', borderRadius: '16px' }}
      >
        <Icon
          className="FilterSidebarIcon"
          key="FilterSidebarIcon"
          icon={ic_filter_alt_outline}
          size={24}
        />
      </Button>,
    ];

    return (
      <PageContainer
        title="Carrier Invoices"
        contentClassName="VendorInvoices"
        rightIcons={rightIcons}
      >
        {this.state.isFetching ? (
          <SpinnerContainer isVisible={true} message="Working..." />
        ) : (
          <Fragment>
            <VendorInvoicesHeader
              leftCol={
                !!invoicesCount && [
                  <Chip
                    key="CountOfSelected"
                    label={`${invoicesCount} ${
                      invoicesCount === 1 ? 'invoice' : 'invoices'
                    } selected`}
                  />,
                ]
              }
              rightCol={[
                <DropdownButton
                  key="DownloadSelected"
                  title="Download selected"
                  id="DownloadSelected"
                  variant="contained"
                  menuAlign="right"
                >
                  {Object.entries(DOWNLOAD_SELECTED_FORMATS).map(([key, one]) => (
                    <MenuItem
                      key={one.value}
                      onClick={() => this.downloadSelected(one.value)}
                    >
                      {one.label}
                    </MenuItem>
                  ))}
                </DropdownButton>,
                <VendorInvoicesPaymentModal
                  key="VendorInvoicesPaymentModal"
                  onShow={this.handleModalOpen}
                  onClose={this.handleModalClose}
                  show={this.state.showModal}
                  enrolledForPayments={this.props.enrolledForPayments}
                  selectedVendorInvoices={this.state.selectedVendorInvoices}
                />,
              ]}
            />
            {this.props.vendorInvoices && (
              <VendorInvoicesTable
                vendorInvoices={this.props.vendorInvoices}
                pagination={this.props.pagination}
                enrolledForPayments={this.props.enrolledForPayments}
                onPageChange={this.onPageChange}
                onSortChange={this.onSortChange}
                isloading={this.state.isFetching}
                onSelect={this.onSelect}
              />
            )}
          </Fragment>
        )}
      </PageContainer>
    );
  }

  handleModalOpen() {
    this.setState({ showModal: true });
  }

  handleModalClose() {
    this.setState({ showModal: false });
  }

  toggleFilterSidebar() {
    this.props.dispatch(toggleFilterSidebar(true, 'VendorInvoices.FilterSidebar'));
  }

  onSelect(rows) {
    this.setState({ selectedVendorInvoices: rows });
  }

  onPageChange({ page, pageSize }) {
    this.props.dispatch(
      this.fetchVendorInvoices(
        { ...this.props.pagination, page: page + 1, sizePerPage: pageSize },
        this.props.filters
      )
    );
  }

  onSortChange(sortModel) {
    this.props.dispatch(
      this.fetchVendorInvoices(
        {
          ...this.props.pagination,
          page: 1,
          sortKey: humps.decamelize(sortModel[0]?.field || this.props.pagination.sortKey),
          sortOrder: sortModel[0]?.sort || this.props.pagination.sortOrder,
        },
        this.props.filters
      )
    );
  }

  onFiltersChange(filters) {
    this.props.dispatch(
      this.fetchVendorInvoices(
        { ...this.props.pagination, page: 1 },
        filters,
        this.setFetchingData
      )
    );
  }

  fetchVendorInvoices(pagination, filters, setFetchingData) {
    if (setFetchingData) setFetchingData(true);
    return (dispatch) => {
      this.setState({
        filterSetId: filters.filterSetId,
      });

      // HACK: The sort key is stored in camelCase. Need to decamelize it before
      // we send it back to the server.
      if (pagination.sortKey) {
        pagination.sortKey = humps.decamelize(pagination.sortKey);
      }

      return ajax.postJSON(
        '/api/vendor-invoices',
        {
          pagination: pagination,
          filters: filters,
        },
        (response) => {
          // If the current state's `filterSetId` doesn't match the one in the
          // response, that means another request was sent after this one. As a
          // result, we should ignore this response and continue waiting for the
          // response with the matching `filterSetId`.
          if (response.filters.filterSetId !== this.state.filterSetId) {
            return;
          }

          dispatch({
            type: 'VENDOR_INVOICES__UPDATE',
            payload: response,
          });
          if (setFetchingData) setFetchingData(false);
        },
        (response) => {
          if (setFetchingData) setFetchingData(false);
          if (
            response.message &&
            response.message === constants.ERROR_MESSAGES.NO_DATE_RANGE_PROVIDED
          ) {
            dispatch({
              type: 'VENDOR_INVOICES__FILTERS_ERRORS__UPDATE',
              payload: {
                ...this.props.errors,
                invoiceDates: { message: 'Please select dates' },
              },
            });
          }
        }
      );
    };
  }

  downloadSelected(selectedFormatPreference) {
    if (!selectedFormatPreference) return;

    const selectedRowKeys = this.state.selectedVendorInvoices?.map(
      (selectedVendorInvoice) => selectedVendorInvoice.hid
    );

    if (selectedRowKeys.length === 0) return;

    let downloadQueryString = '?hid=' + selectedRowKeys.join('&hid=');

    if (
      selectedFormatPreference === DOWNLOAD_SELECTED_FORMATS.INVOICE_SUMMARY_REPORT.value
    ) {
      this.props.dispatch(
        requestInvoiceSummaryReport(
          downloadQueryString,
          (response) => {
            this.pollVendorInvoicesReportStatus(response.uuid);

            enqueueSnackbar(
              `Your report has been successfully submitted, 
                and will automatically begin downloading when it finishes running.`,
              { variant: 'success' }
            );
          },
          (response) => {
            enqueueSnackbar(response.message, { variant: 'error' });
          }
        )
      );
    } else {
      if (
        selectedRowKeys.length === 1 &&
        selectedFormatPreference === DOWNLOAD_SELECTED_FORMATS.PREFER_PDF.value
      ) {
        utils.triggerDownloadUrl(
          `/vendor-invoices${downloadQueryString}&format=${selectedFormatPreference}`
        );
      } else {
        utils.triggerDownload(
          `/vendor-invoices${downloadQueryString}&format=${selectedFormatPreference}`
        );
      }
    }
  }

  pollVendorInvoicesReportStatus(uuid) {
    return ajax.getJSON(
      '/api/report-runs/' + uuid,
      (response) => {
        if (response.status === 'COMPLETED') {
          if (!response.error) {
            enqueueSnackbar(
              `Success! Your "${response.reportDispName}" report will begin downloading shortly.`,
              { variant: 'success' }
            );

            utils.triggerDownload(response.downloadPath);
          } else {
            enqueueSnackbar(
              `Unfortunately there was an error running your "${response.reportDispName}" report. 
              Please check the report status for more details.`,
              { variant: 'error' }
            );
          }
        } else {
          setTimeout(() => {
            this.pollVendorInvoicesReportStatus(uuid);
          }, 2500);
        }
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  }
}

const requestInvoiceSummaryReport = (
  downloadQueryString,
  successCallback,
  errorCallback
) => {
  return (dispatch) => {
    return ajax.postJSON(
      `/vendor-invoices/invoice-summary${downloadQueryString}`,
      null,
      (response) => {
        if (successCallback) {
          successCallback(response);
        }
      },
      (response) => {
        if (errorCallback) {
          errorCallback(response);
        }
      }
    );
  };
};

export const dollarFormatter = (val, row) => {
  if (val === null) {
    return <TooltipIcon icon={ic_schedule}>Still processing...</TooltipIcon>;
  }
  return utils.Fmt.dollars(val / 100);
};

export const numShipmentsFormatter = (val, row) => {
  if (val === null) {
    return <TooltipIcon icon={ic_schedule}>Still processing...</TooltipIcon>;
  }
  return val.toLocaleString('en');
};

const invoiceDateFormater = (val, row) => {
  if (val === null) {
    return constants.MDASH;
  } else {
    const formatedDueDate = utils.Fmt.date(row.invoiceDueDate);
    const formatedInvoiceDate = utils.Fmt.date(val);
    if (moment(formatedInvoiceDate) > moment(formatedDueDate)) {
      return <span className="red">{formatedInvoiceDate}</span>;
    } else {
      return formatedInvoiceDate;
    }
  }
};

const mapStateToProps = (state) => {
  return {
    vendorAccountNumberMappings: state.vendorAccountNumberMappings,
    isVendorPaymentsEnabled: state.vendorInvoices.isVendorPaymentsEnabled,
    enrolledForPayments: state.vendorInvoices.enrolledForPayments,
    vendorInvoices: state.vendorInvoices.vendorInvoices,
    pagination: state.vendorInvoices.pagination,
    filters: state.vendorInvoices.filters,
    errors: state.vendorInvoices.errors,
    vendorInvoiceDetailsError:
      state.vendorInvoiceDetails && state.vendorInvoiceDetails.vendorInvoiceError,
  };
};

export default connect(mapStateToProps)(VendorInvoices);
