import { useMemo } from 'react';

export const useVendorInvoicesPaymentFormRows = (selectedInvoices) =>
  useMemo(
    () =>
      selectedInvoices?.reduce((prevState, currentValue) => {
        const currentRow = {
          id: currentValue.hid,
          vendor: currentValue.vendor,
          invoiceNumber: currentValue.invoiceNumber,
          invoiceDueDate: currentValue.invoiceDueDate,
          finalAmount: currentValue.finalAmount,
          actions: currentValue,
          currentValue,
        };
        prevState.push(currentRow);
        return prevState;
      }, []),
    [selectedInvoices]
  );
