import React, { memo, useState } from 'react';
import { InputAdornment } from '@mui/material';
import { ic_search_twotone } from 'react-icons-kit/md/ic_search_twotone';
import Icon from '../Icon';
import StyledTextField from '../StyledTextField';

export const useSearch = (initValue = '') => {
  const [searchQuery, setSearchQuery] = useState(initValue);
  const onHandlerSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  return { searchQuery, onHandlerSearch, setSearchQuery };
};

const TableHeaderSearch = ({ onHandlerSearch, searchQuery, placeholder = 'Search' }) => {
  return (
    <StyledTextField
      // className="custom"
      id="searchQuery"
      placeholder={placeholder}
      variant="outlined"
      type="text"
      onChange={onHandlerSearch}
      value={searchQuery}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon icon={ic_search_twotone} style={{ color: '#1175DB' }} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default memo(TableHeaderSearch);
