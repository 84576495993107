import './Chart.scss';

import autobind from 'react-autobind';
import classNames from 'classnames';
import React from 'react';

import * as utils from 'common/helpers/utils';
import { ic_file_download } from 'react-icons-kit/md/ic_file_download';
import HelpIcon from 'common/components/HelpIcon';
import TooltipIcon from 'common/components/TooltipIcon';
import Datamap from './Datamap';

class USChoropleth extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  render() {
    if (!this.props.data) {
      return null;
    }

    let helpIcon;
    if (this.props.helpText) {
      helpIcon = <HelpIcon>{this.props.helpText}</HelpIcon>;
    }

    let geographyConfig = Object.assign(
      {
        popupTemplate: (geography, data) =>
          `<div class='hoverinfo'>${
            geography.properties.name
          }: ${this.props.tooltipTemplate(geography, data)}</div>`,
      },
      this.props.geographyConfig
    );

    return (
      <div className={classNames('Chart WorldChoropleth', this.props.className)}>
        <div className="buttons">
          <TooltipIcon
            className="FileDownloadIcon"
            icon={ic_file_download}
            onClick={this.handleDownloadCSV}
          >
            Download as CSV
          </TooltipIcon>
        </div>
        <h3>
          {this.props.title} {helpIcon}
        </h3>
        <Datamap
          legend
          scope="world"
          height={this.props.height}
          fills={this.props.fills}
          data={this.props.data}
          geographyConfig={geographyConfig}
        />
      </div>
    );
  }

  handleDownloadCSV() {
    let indexValues = Object.keys(this.props.data);
    let headers = ['Country', this.props.valueLabel];
    // If a `.percentage` field exists in one of the data objects, then add a
    // 'Percentage' column.
    if (this.props.data[Object.keys(this.props.data)[0]].percentage) {
      headers.push('Percentage');
    }

    // Build an array of arrays for the rows that should go into the
    // CSV. Initialize this array with the header row.
    let csvRows = [headers];
    for (let key in this.props.data) {
      csvRows.push([key, this.props.data[key].value, this.props.data[key].percentage]);
    }

    utils.triggerDownloadCSV(utils.rowsToCSV(csvRows), this.props.title || 'Export.csv');
  }
}

export default USChoropleth;
