import autobind from 'react-autobind';
import { connect } from 'react-redux';
import React from 'react';
import ReactSidebar from 'react-sidebar';

import SidebarContent from 'cloud/containers/SidebarContent';

import 'common/components/Sidebar/Sidebar.scss';

const mql = window.matchMedia(`(min-width: 845px)`);

// Override default content styling.
const sidebarStyles = {
  content: {
    overflowY: 'auto',
    // display: 'flex',
    // flexDirection: 'column'
  },
};

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentWillMount() {
    mql.addListener(this.updateSidebarFromMql);
    this.updateSidebarFromMql();
  }

  componentWillUnmount() {
    mql.removeListener(this.updateSidebarFromMql);
  }

  /**
   * We need to record the sidebar width in redux whenever this component gets
   * updated. This is important so we can properly align the left position of
   * the header. Be careful not to save the sidebar width if it's the same as
   * what we currently have in redux, otherwise we might get in an infinite
   * loop.
   */
  componentDidUpdate() {
    if (this.sidebar && this.props.sidebar.width !== this.sidebar.state.sidebarWidth) {
      this.props.setSidebarWidth(this.sidebar.state.sidebarWidth);
    }
  }

  render() {
    const SidebarContent = this.props.SidebarContent;

    return (
      <ReactSidebar
        sidebarClassName="Sidebar"
        sidebar={<SidebarContent user={this.props.user} />}
        styles={sidebarStyles}
        open={this.props.sidebar.isOpen}
        docked={this.props.sidebar.isDocked}
        transitions={!this.props.sidebar.isDocked}
        onSetOpen={this.handleSetOpen}
        ref={(component) => (this.sidebar = component)}
      >
        {this.props.children}
      </ReactSidebar>
    );
  }

  /**
   * Callback that gets executed when the sidebar initiates an open / close
   * event via dragging, etc.
   */
  handleSetOpen(open) {
    if (open) {
      this.props.openSidebar();
    } else {
      this.props.closeSidebar();
    }
  }

  updateSidebarFromMql() {
    if (mql.matches) {
      this.props.setSidebarDocked();
      this.props.openSidebar();
    } else {
      this.props.setSidebarUndocked();
      this.props.closeSidebar();
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    sidebar: state.sidebar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openSidebar: () => dispatch({ type: 'SIDEBAR__OPEN' }),
    closeSidebar: () => dispatch({ type: 'SIDEBAR__CLOSE' }),
    setSidebarDocked: () => dispatch({ type: 'SIDEBAR__SET_DOCKED' }),
    setSidebarUndocked: () => dispatch({ type: 'SIDEBAR__SET_UNDOCKED' }),
    setSidebarWidth: (width) =>
      dispatch({
        type: 'SIDEBAR__SET_WIDTH',
        payload: { width },
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
