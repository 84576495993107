'use strict';

import autobind from 'react-autobind';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { enqueueSnackbar } from 'notistack';
import { FormGroup, FormLabel } from '@mui/material';
import isAfterDay from 'react-dates/lib/utils/isAfterDay';

import './VendorInvoicesFilterSidebarForm.scss';

import Button from 'common/components/Button';
import ReduxFormSelect from 'common/components/ReduxFormSelect';
import ReduxFormDateRangePicker from 'common/components/ReduxFormDateRangePicker';
import { getOperationVendors } from 'common/helpers/utils';
import ReduxFormFloatingLabelFormGroup from 'common/components/ReduxFormFloatingLabelFormGroup';
import ReduxFormToggleButton from '../../common/components/reduxFormToggleButton/ReduxFormToggleButton';
import Box from '@mui/material/Box';
import ReduxFormOperationVendorAccountNumbersSelect from 'common/components/ReduxFormOperationVendorAccountNumbersSelect';

const FORM = 'OperationVendorInvoices.FilterSidebarForm';

export const DATE_RANGE_OPTIONS = [
  {
    label: 'Invoice issued date',
    value: 'INVOICE_ISSUED_DATE',
  },
  {
    label: 'Invoice due date',
    value: 'INVOICE_DUE_DATE',
  },
];

class OperationVendorInvoicesFilterSidebarForm extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  render() {
    return (
      <form
        className="VendorInvoicesFilterSidebarForm defaultColumnFormStyle"
        onSubmit={this.props.handleSubmit(this.saveFilters)}
      >
        <FormGroup>
          <FormLabel>Operation Vendor</FormLabel>
          <ReduxFormSelect
            name="operationVendor"
            placeholder="Select vendor"
            options={getOperationVendors(this.props.operationVendorAccountNumberMappings)}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>Account numbers</FormLabel>
          <ReduxFormOperationVendorAccountNumbersSelect
            name="operationVendorAccountNumbers"
            operationVendorAccountNumberMappings={
              this.props.operationVendorAccountNumberMappings
            }
            onOpen={this.handleOpenDropdown}
            onClose={this.handleCloseDropdown}
          />
        </FormGroup>

        <ReduxFormFloatingLabelFormGroup
          type="text"
          name="invoiceNumber"
          className="form-control"
          label="Invoice numbers"
          placeholder="Enter an invoice number"
        />

        <FormGroup className="DateRangeFormGroup">
          <FormLabel>Invoice dates</FormLabel>

          <ReduxFormToggleButton
            optionList={DATE_RANGE_OPTIONS}
            value={this.props.dateRangeOption}
            fieldName={'dateRangeOption'}
            change={this.props.change}
            orientation="vertical"
          />
          <Box className="invoiceDatePicker">
            <ReduxFormDateRangePicker
              names={['startDate', 'endDate']}
              numberOfMonths={1}
              isOutsideRange={(day) =>
                this.props.dateRangeOption !== 'INVOICE_DUE_DATE' &&
                isAfterDay(day, moment())
              }
              onFocusChange={this.handleOpenDropdown}
              onClose={this.handleCloseDropdown}
              startDateId="Invoice start date"
              endDateId="Invoice end date"
            />
            {this.props.errors.invoiceDates && (
              <div className="DateRangeFormGroup-error">
                {this.props.errors.invoiceDates.message}
              </div>
            )}
          </Box>
        </FormGroup>

        <FormGroup>
          <Button
            type="submit"
            size="large"
            variant="contained"
            disabled={this.props.pristine || this.props.submitting}
          >
            Apply
          </Button>
        </FormGroup>
        <FormGroup>
          <Button
            size="small"
            variant="outlined"
            onClick={(e) => {
              e.preventDefault();
              this.resetForm();
            }}
          >
            Reset form
          </Button>
        </FormGroup>
      </form>
    );
  }

  saveFilters(data) {
    this.props.dispatch(clearErrors());

    if ((data.startDate && !data.endDate) || (!data.startDate && data.endDate)) {
      enqueueSnackbar(
        'Please enter 8 digits separated with “/” signs, in such a format: MM/DD/YYYY.',
        { variant: 'error' }
      );
      return;
    }

    this.props.dispatch({
      type: 'OPERATION_VENDOR_INVOICES__FILTERS__UPDATE',
      payload: data,
    });
  }

  /**
   * Lets the parent know that a dropdown was opened.
   */
  handleOpenDropdown() {
    this.props.onDropdownOpen && this.props.onDropdownOpen();
  }

  /**
   * Lets the parent know that a dropdown was closed.
   *
   * Note that this function uses a massive hack by delaying calling the
   * callback by 200ms. We need to do this because we want to prevent clicks on
   * the filter menu's background overlay from closing the filter menu while the
   * dropdown is open. In order to do so, we need to make sure the click event
   * on the overlay is processed *before* we notify the parent that the dropdown
   * has closed (hence delaying the callback).
   */
  handleCloseDropdown() {
    setTimeout(() => {
      this.props.onDropdownClose && this.props.onDropdownClose();
    }, 200);
  }

  resetForm() {
    this.props.dispatch({
      type: 'OPERATION_VENDOR_INVOICES__FILTERS__UPDATE',
      payload: {
        operationVendorAccountNumbers: [],
        dateRangeOption: DATE_RANGE_OPTIONS[0].value,
        operationVendor: null,
        invoiceNumber: null,
        startDate: null,
        endDate: null,
      },
    });
  }
}

const clearErrors = () => {
  return (dispatch) => {
    dispatch({
      type: 'OPERATION_VENDOR_INVOICES__FILTERS_ERRORS__UPDATE',
      payload: {},
    });
  };
};

const selector = formValueSelector(FORM);

const mapStateToProps = (state) => {
  return {
    initialValues: state.vendorInvoices.filters,
    errors: state.vendorInvoices.errors,
    dateRangeOption: selector(state, 'dateRangeOption'),

    operationVendorAccountNumberMappings: state.operationVendorAccountNumberMappings,
    enrolledForPayments: state.vendorInvoices.enrolledForPayments,
  };
};

OperationVendorInvoicesFilterSidebarForm = reduxForm({
  form: FORM,
  enableReinitialize: true,
})(OperationVendorInvoicesFilterSidebarForm);

export default connect(mapStateToProps)(OperationVendorInvoicesFilterSidebarForm);
