'use strict';

import './VendorAuth.scss';

import autobind from 'react-autobind';
import { FormLabel } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import { Modal } from 'common/components/Modal';
import React, { Fragment, useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { ic_add } from 'react-icons-kit/md/ic_add';
import { ic_pause_circle_outline } from 'react-icons-kit/md/ic_pause_circle_outline';
import { ic_play_circle_outline } from 'react-icons-kit/md/ic_play_circle_outline';
import { ic_not_interested } from 'react-icons-kit/md/ic_not_interested';
import { ic_help_outline } from 'react-icons-kit/md/ic_help_outline';

import * as ajax from 'common/helpers/ajax';
import * as constants from 'common/helpers/constants';
import * as utils from 'common/helpers/utils';
import Button from 'common/components/Button';
import Icon from 'common/components/Icon';
import { FloatingLabelFormGroup } from 'common/components/Inputs';
import TooltipIcon from 'common/components/TooltipIcon';

import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import EditIcon from 'common/components/icon-components/EditIcon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Tips from 'common/components/Tips/Tips';
import { ClearReactSelect } from 'common/components/ReduxFormSelect';
import { required } from 'common/components/UploadTrackingField/utils';
import Stack from '@mui/material/Stack';
import { setFeedbackModalOpen } from '../store/actions';
import { connect, useDispatch } from 'react-redux';

const VendorAuth = (props) => {
  const [vendorAuths, setVendorAuths] = useState([]);
  const [isShowHeadsUpModal, setIsShowHeadsUpModal] = useState(false);
  const [isShowNewVendorSelectionModal, setIsShowNewVendorSelectionModal] =
    useState(false);
  const [editVendorAuthModalData, setEditVendorAuthModalData] = useState(null);
  const [isVisibleEditVendorAuthModal, setIsVisibleEditVendorAuthModal] = useState(false);

  useEffect(() => {
    ajax.getJSON(
      '/api/vendor-auths',
      (response) => {
        setVendorAuths(response);
      },
      (response) => {
        console.log(response);
      }
    );
  }, []);

  /**
   * Returns a list of `VendorAuthSummaryPanels` to insert into the vendor body.
   */
  const getVendorAuthSummaryPanels = () => {
    if (vendorAuths.length === 0) {
      return (
        <Button
          className="empty-placeholder_new"
          variant="outlined"
          onClick={showNewVendorSelectionModal}
        >
          You haven&rsquo;t added any login credentials yet! Click here to get started.
        </Button>
      );
    }

    // Sort the vendors alphabetically, and then initialize a
    // `VendorAuthSummaryPanel` for each `VendorAuth`.
    return (
      <Grid container spacing={2}>
        {utils.sortBy(vendorAuths, 'vendor').map((vendorAuth) => {
          return (
            <Grid xs={12} sm={6} md={4} xl={3} key={vendorAuth.uuid}>
              <VendorAuthSummaryPanel
                vendorAuth={vendorAuth}
                onClickEditLink={showEditVendorAuthModal}
              />
            </Grid>
          );
        })}
        <Grid xs={12} sm={6} md={4} xl={3}>
          <Button
            className="add-new-vendor"
            variant="outlined"
            onClick={showNewVendorSelectionModal}
          >
            <Icon icon={ic_add} size={84} />
          </Button>
        </Grid>
      </Grid>
    );
  };

  const showNewVendorSelectionModal = () => {
    setIsShowNewVendorSelectionModal(true);
  };

  /**
   * Callback for when the user selects to add a new vendor.
   *
   * We should open the `EditVendorAuthModal` to add credentials for the given
   * vendor. Note that we need to initialize a dummy `VendorAuth` object to make
   * this work.
   */
  const onSelectNewVendor = (vendor, currencyIso3) => {
    showEditVendorAuthModal({ vendor: vendor, currencyIso3: currencyIso3 });
  };

  /**
   * Opens the EditVendorAuth form to create / edit the given vendor and
   * `VendorAuth` object.
   */
  const showEditVendorAuthModal = (vendorAuth, credentialsType) => {
    setEditVendorAuthModalData({ vendorAuth, credentialsType });
    setIsVisibleEditVendorAuthModal(true);
  };

  /**
   * Updates the vendor auth list view in response to any changes from child
   * components (i.e., from adding new auths, or changing usernames).
   */
  const onUpdateVendorAuth = (vendorAuth) => {
    // If the given vendorAuth already exists in the current list, then we should
    // remove the old one and insert the new one.
    var filteredVendorAuths = vendorAuths.filter((va) => {
      return va.uuid !== vendorAuth.uuid;
    });

    // If the filtered vendor auths list has the same number of elements as the
    // base list, then this is a new vendor auths object, in which case we
    // should show the`HeadsUpModal`.
    if (filteredVendorAuths.length === vendorAuths.length) {
      setIsShowHeadsUpModal(true);
    }

    setVendorAuths(filteredVendorAuths.concat(vendorAuth));
  };

  return (
    <section className="VendorAuth">
      <Box sx={{ paddingBottom: '16px' }}>
        <Tips>
          <ul>
            <li>
              When everything is OK, your credential status is{' '}
              <Icon icon={ic_play_circle_outline}></Icon> (Active/Unnecessary).
            </li>

            <li>
              When the status of your credentials is{' '}
              <Icon icon={ic_pause_circle_outline}></Icon> (Pending) we're checking it.
            </li>

            <li>
              If any credentials problem arise, the status will be changed to{' '}
              <Icon icon={ic_not_interested}></Icon> (Failed).
            </li>

            <li>In case of any additional questions, please let us know.</li>

            <li>
              If data import is disabled, we don't use these credentials.
              <br />
              All your credentials are marked with a corresponding status color.{' '}
              <Box
                component="span"
                sx={{ cursor: 'pointer', fontWeight: '14px', color: '#1175db' }}
                onClick={() => {
                  props.dispatch(setFeedbackModalOpen(true));
                }}
              >
                In case of any additional questions, please contact us.
              </Box>
            </li>
          </ul>
        </Tips>
      </Box>
      {getVendorAuthSummaryPanels()}
      <HeadsUpModal
        isShowHeadsUpModal={isShowHeadsUpModal}
        setIsShowHeadsUpModal={setIsShowHeadsUpModal}
      />
      <NewVendorSelectionModal
        onSelectVendor={onSelectNewVendor}
        isShowNewVendorSelectionModal={isShowNewVendorSelectionModal}
        setIsShowNewVendorSelectionModal={setIsShowNewVendorSelectionModal}
      />
      <EditVendorAuthModal
        isVisible={isVisibleEditVendorAuthModal}
        setIsVisible={setIsVisibleEditVendorAuthModal}
        onSave={onUpdateVendorAuth}
        editVendorAuthModalData={editVendorAuthModalData}
      />
    </section>
  );
};

/**
 * Implements a panel that holds all of the information about a single vendor.
 */
class VendorAuthSummaryPanel extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  getUsernameSummary() {
    const formConfig = constants.getVendorAuthFormConfig(this.props.vendorAuth.vendor);

    return formConfig.map((sectionConfig) => {
      return (
        <tr key={sectionConfig.header}>
          <td className={'detailsTable_row-title'}>{sectionConfig.header} </td>
          <td>
            <span>
              {utils.getByPath(
                this.props.vendorAuth,
                [sectionConfig.credentialsType, sectionConfig.previewField].join('.')
              )}
            </span>
            <Tooltip title="Edit">
              <IconButton
                onClick={(e) => this.onClickEditLink(sectionConfig.credentialsType)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </td>
        </tr>
      );
    });
  }

  render() {
    const statusCredentialStatusColor = credentialStatusClass(
      this.props.vendorAuth.isDataImportEnabled,
      this.props.vendorAuth.credentialStatus,
      this.props.vendorAuth.apiCredentialStatus
    );

    return (
      <Table bordered={true} className={'detailsTable_table VendorAuthSummaryPanel'}>
        <thead className={'detailsTable_thead'}>
          <tr>
            <td className={`heading heading_${statusCredentialStatusColor} `} colSpan={2}>
              <Box className="header_edit">
                <span>{constants.getDispValue(this.props.vendorAuth.vendor)}</span>
                <IconButton onClick={(e) => this.onClickEditLink()} size="small">
                  <EditIcon />
                </IconButton>
              </Box>
            </td>
          </tr>
        </thead>
        <tbody>
          {/*{this.getUsernameSummary()}*/}
          <tr>
            <td className={'detailsTable_row-title'} style={{ width: '180px' }}>
              Data import
            </td>
            <td className={'detailsTable_row-text'}>
              {this.props.vendorAuth.isDataImportEnabled ? 'Enabled' : 'Disabled'}
            </td>
          </tr>
          <tr>
            <td className={'detailsTable_row-title'} style={{ width: '180px' }}>
              Last sync date
            </td>
            <td className={'detailsTable_row-text'}>
              {utils.Fmt.mdashIfNullFunc(utils.Fmt.dateTime)(
                this.props.vendorAuth.lastSyncDt
              )}
            </td>
          </tr>
          <tr>
            <td className={'detailsTable_row-title'} style={{ width: '180px' }}>
              Currency
            </td>
            <td className={'detailsTable_row-text'}>
              {this.props.vendorAuth.currencyIso3}
            </td>
          </tr>

          <tr>
            <td className={'detailsTable_row-title'} style={{ width: '180px' }}>
              Credential status
            </td>
            <td className={'detailsTable_row-text'}>
              {showCredentialStatus(
                this.props.vendorAuth.credentialStatus,
                this.props.vendorAuth.apiCredentialStatus
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }

  onClickEditLink(credentialsType) {
    this.props.onClickEditLink(this.props.vendorAuth, credentialsType);
  }
}

/**
 * Implements the modal that allows the user the select a new vendor whose
 * credentials they'd like to add.
 *
 * This modal should only show vendors whose credentials haven't been previously
 * added.
 */
function NewVendorSelectionModal(props) {
  const [vendor, setVendor] = useState(null);
  const [currencyIso3, setCurrencyIso3] = useState(null);

  const onChangeVendor = (value) => {
    setVendor(value);
  };

  const onCurrencyChange = (value) => {
    setCurrencyIso3(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    props.setIsShowNewVendorSelectionModal(false);
    props.onSelectVendor(vendor.value, currencyIso3.value);
  };

  const getBodyContent = () => {
    const vendorOptions = constants.VENDORS.sort().map((vendor) => ({
      label: constants.getDispValue(vendor),
      value: vendor,
    }));

    const currencyOptions = constants.CURRENCY.sort().map((currency) => ({
      label: currency,
      value: currency,
    }));

    return (
      <form onSubmit={onSubmit}>
        <Grid container spacing={3}>
          <Grid xs={12} container spacing={2}>
            <Grid xs={12}>
              <FormGroup>
                <FormLabel>Carrier or Vendor</FormLabel>
                <ClearReactSelect
                  name="vendorAuthId"
                  placeholder="Please select a carrier or vendor"
                  options={vendorOptions}
                  clearable={false}
                  validate={[required]}
                  onChange={onChangeVendor}
                  value={vendor}
                />
              </FormGroup>
            </Grid>
            <Grid xs={12}>
              <FormGroup>
                <FormLabel>Currency</FormLabel>
                <ClearReactSelect
                  name="currencyAuthId"
                  placeholder="Please select a currency"
                  options={currencyOptions}
                  clearable={false}
                  validate={[required]}
                  onChange={onCurrencyChange}
                  value={currencyIso3}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={!vendor || !currencyIso3}
            >
              Select
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  };

  return (
    <Modal
      className="NewVendorSelectionModal"
      maxWidth="sm"
      open={props.isShowNewVendorSelectionModal}
      onClose={() => props.setIsShowNewVendorSelectionModal(false)}
      title="Select a carrier or vendor"
      isDefaultCloseButton={true}
      contentClassName="NewVendorSelectionModal_content"
      {...props}
    >
      {getBodyContent()}
    </Modal>
  );
}

/**
 * Implements a modal that allows the user to edit credentials for a specific
 * vendor.
 *
 * This modal will dynamically render a different set of input fields based on
 * the specific vendor's needs.
 */
class EditVendorAuthModal extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = this.initialState;
  }

  get initialState() {
    return {
      vendorAuth: {},

      // Determines whether we should only show a particular type of credentials
      // (e.g., webCredentials, apiCredentials, ftpCredentials).
      credentialsType: null,

      // Toggles whether or not the save button is in the 'saving' state.
      isSaving: false,
    };
  }

  rebuildState(nextProps) {
    /**
     * Shows this modal for the given vendorAuth.
     *
     * At a minimum, the `vendorAuth` argument needs a `vendor` field to determine
     * the set of inputs we should show.
     */

    // Make a copy of the `vendorAuth` object since we don't want changes in
    // the account input fields to mutate the underlying object.
    const vendorAuth = nextProps?.editVendorAuthModalData?.vendorAuth;
    const credentialsType = nextProps?.editVendorAuthModalData?.credentialsType;

    if (!!vendorAuth) {
      this.setState({
        vendorAuth: Object.assign({}, vendorAuth),
        credentialsType: credentialsType,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.rebuildState(nextProps);
  }

  render() {
    var { onSave, onExited, ...props } = this.props;

    return (
      <Modal
        className="EditVendorAuthModal"
        maxWidth="sm"
        open={this.props.isVisible}
        onClose={this.hide}
        onExited={this.onExited}
        isDefaultCloseButton={true}
        title={this.getModalTitle()}
        {...props}
      >
        <form onSubmit={this.onSubmit}>
          <Grid container spacing={3}>
            <Grid xs={12}>{this.getFormSections()}</Grid>
            <Grid xs={12}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                loadingText="Saving..."
                isLoading={this.state.isSaving}
                disabled={!this.shouldSaveButtonBeEnabled()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>
    );
  }

  /**
   * Returns the modal title based on whether we're adding a new set of vendor
   * credentials.
   */
  getModalTitle() {
    var dispVendor = constants.getDispValue(this.state.vendorAuth.vendor);
    return this.state.vendorAuth.uuid
      ? 'Update ' + dispVendor + ' credentials'
      : 'Add ' + dispVendor + ' credentials';
  }

  /**
   * Returns the expected set of input fields for the expected of set of
   * accounts for the current vendor.
   */
  getFormSections() {
    let formConfig = constants.getVendorAuthFormConfig(this.state.vendorAuth.vendor);
    let showHeader = formConfig.length > 1;

    if (this.state.credentialsType) {
      formConfig = formConfig.filter(
        (sectionConfig) => sectionConfig.credentialsType === this.state.credentialsType
      );
    }

    return (
      <Stack spacing={3}>
        {formConfig.map((sectionConfig) => (
          <EditVendorAuthFormSection
            key={sectionConfig.header}
            sectionConfig={sectionConfig}
            vendorAuth={this.state.vendorAuth}
            showHeader={showHeader}
            onChange={this.onChangeAccountInputs}
          />
        ))}
      </Stack>
    );
  }

  /**
   * Returns `true` if the credentials for all accounts have been populated.
   */
  shouldSaveButtonBeEnabled() {
    let formConfig = constants.getVendorAuthFormConfig(this.state.vendorAuth.vendor);
    for (var i = 0; i < formConfig.length; i++) {
      let sectionConfig = formConfig[i];

      // If a `credentialsType` was specified, and it doesn't match the current
      // sectionConfig, then we don't want to consider it.
      if (
        this.state.credentialsType &&
        this.state.credentialsType !== sectionConfig.credentialsType
      ) {
        continue;
      }

      for (var j = 0; j < sectionConfig.inputs.length; j++) {
        if (!utils.getByPath(this.state.vendorAuth, sectionConfig.inputs[j].key)) {
          return false;
        }
      }
    }

    return true;
  }

  hide() {
    this.props.setIsVisible(false);
  }

  /**
   * Whenever the modal finishes exiting, reset it back to its original state.
   */
  onExited() {
    this.setState(this.initialState);
    this.hide();

    if (this.props.onExited) {
      this.props.onExited();
    }
  }

  /**
   * This callback gets called whenever there's a change event on an input
   * field. When this happens, we simply need to update the account username /
   * password in the state object.
   *
   * This callback needs to accept an `account` argument so it knows which
   * account the input was associated with.
   */
  onChangeAccountInputs(e) {
    utils.setByPath(this.state.vendorAuth, e.target.name, e.target.value);
    this.setState({ vendorAuth: this.state.vendorAuth });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({ isSaving: true });

    var updateFunc = this.state.vendorAuth.uuid ? editVendorAuth : addVendorAuth;
    var payload = Object.assign({}, this.state.vendorAuth);

    // If we've specified a single credentials type, then we should filter out
    // the other credential types.
    if (this.state.credentialsType) {
      ['webCredentials', 'apiCredentials', 'ftpCredentials'].forEach((key) => {
        if (key !== this.state.credentialsType) {
          delete payload[key];
        }
      });
    }

    updateFunc(
      payload,
      (response) => {
        // Hide this modal. Need to set `isSaving` to false so that `this.hide`
        // doesn't short-circuit.
        this.setState({ isSaving: false });
        this.hide();

        // Propagate the changes to the vendor credentials list.
        this.props.onSave(response);

        enqueueSnackbar('Success! Your credentials have been updated.', {
          variant: 'success',
        });
      },
      (response) => {
        this.setState({ isSaving: false });

        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  }
}

/**
 * This component represents a username / password input group for a single
 * account type.
 */
class EditVendorAuthFormSection extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  render() {
    return (
      <div>
        {this.props.showHeader ? this.getHeader() : null}
        {this.getInputs()}
      </div>
    );
  }

  getInputs() {
    return (
      <Stack spacing={2}>
        {this.props.sectionConfig.inputs.map((inputConfig) => (
          <FloatingLabelFormGroup
            key={inputConfig.key}
            name={inputConfig.key}
            type={inputConfig.type}
            label={inputConfig.label}
            defaultValue={utils.getByPath(this.props.vendorAuth, inputConfig.key)}
            onChange={this.props.onChange}
          />
        ))}
      </Stack>
    );
  }

  getHeader() {
    return <div style={{ marginBottom: '10px' }}>{this.props.sectionConfig.header}</div>;
  }
}

const HeadsUpModal = ({ isShowHeadsUpModal, setIsShowHeadsUpModal }) => {
  const dispatch = useDispatch();

  const hide = () => {
    setIsShowHeadsUpModal(false);
  };

  return (
    <Modal
      className="HeadsUpModal"
      maxWidth="sm"
      open={isShowHeadsUpModal}
      onClose={hide}
      title="Heads up!"
      isDefaultCloseButton
    >
      <>
        <p>
          Your carrier credentials have been saved! It will take a couple days for your
          initial data import to complete. Unfortunately, Galleon will have limited
          functionality until then.
        </p>
        <>
          When everything is OK, your credential status is{' '}
          <Icon icon={ic_play_circle_outline}></Icon> (Active/Unnecessary).
          <br />
          When the status of your credentials is{' '}
          <Icon icon={ic_pause_circle_outline}></Icon> (Pending) we're checking it. <br />
          If any credentials problem arise, the status will be changed to{' '}
          <Icon icon={ic_not_interested}></Icon> (Failed). <br />
          In case of any additional questions, please let us know.
          <br />
          If data import is disabled, we don't use these credentials.
          <br />
          All your credentials are marked with a corresponding status color{' '}
          <Box
            component="span"
            sx={{ cursor: 'pointer', fontWeight: '14px', color: '#1175db' }}
            onClick={() => {
              hide();
              dispatch(setFeedbackModalOpen(true));
            }}
          >
            In case of any additional questions, please contact us.
          </Box>
        </>
      </>
    </Modal>
  );
};

function addVendorAuth(vendorAuth, success, error) {
  ajax.postJSON(
    '/api/vendor-auths',
    vendorAuth,
    (response) => {
      if (success) {
        success(response);
      }
    },
    (response) => {
      if (error) {
        error(response);
      }
    }
  );
}

function editVendorAuth(vendorAuth, success, error) {
  var { uuid, ...rest } = vendorAuth;
  ajax.putJSON(
    '/api/vendor-auths/' + vendorAuth.uuid,
    rest,
    (response) => {
      if (success) {
        success(response);
      }
    },
    (response) => {
      if (error) {
        error(response);
      }
    }
  );
}

function showCredentialStatus(status, api_status) {
  let statusData;
  switch (status) {
    case 'IN_PROGRESS':
      statusData = { icon: ic_pause_circle_outline, text: 'WEB: Pending' };
      break;
    case 'CORRECT':
      statusData = { icon: ic_play_circle_outline, text: 'WEB: Active' };
      break;
    case 'INCORRECT':
      statusData = { icon: ic_not_interested, text: 'WEB: Incorrect' };
      break;
    case 'UNKNOWN':
      statusData = { icon: ic_help_outline, text: 'WEB: Unknown' };
      break;
    case 'UNNECESSARY':
      statusData = { icon: ic_play_circle_outline, text: 'WEB: Unnecessary' };
      break;
    default:
      statusData = { icon: ic_help_outline, text: 'WEB: Unknown' };
  }
  let apiStatusData;
  switch (api_status) {
    case 'IN_PROGRESS':
      apiStatusData = { icon: ic_pause_circle_outline, text: 'API/FTP: Pending' };
      break;
    case 'CORRECT':
      apiStatusData = { icon: ic_play_circle_outline, text: 'API/FTP: Active' };
      break;
    case 'INCORRECT':
      apiStatusData = { icon: ic_not_interested, text: 'API: Incorrect' };
      break;
    case 'UNKNOWN':
      apiStatusData = { icon: ic_help_outline, text: 'API/FTP: Unknown' };
      break;
    case 'UNNECESSARY':
      apiStatusData = { icon: ic_play_circle_outline, text: 'API/FTP: Unnecessary' };
      break;
    default:
      apiStatusData = { icon: ic_help_outline, text: 'API/FTP: Unknown' };
  }
  return (
    <span>
      <TooltipIcon placement="bottom" icon={statusData.icon}>
        {statusData.text}
      </TooltipIcon>
      <TooltipIcon placement="bottom" icon={apiStatusData.icon}>
        {apiStatusData.text}
      </TooltipIcon>
    </span>
  );
}

function credentialStatusClass(isDataImportEnabled, status, api_status) {
  if (!isDataImportEnabled) {
    return 'neutral';
  }

  if (
    (status === 'UNNECESSARY' || status === 'CORRECT') &&
    (api_status === 'UNNECESSARY' || api_status === 'CORRECT')
  ) {
    return 'success';
  }

  if (
    status === 'INCORRECT' ||
    api_status === 'INCORRECT' ||
    status === 'UNKNOWN' ||
    api_status === 'UNKNOWN'
  ) {
    return 'error';
  }

  if (status === 'IN_PROGRESS' || api_status === 'IN_PROGRESS') {
    return 'progress';
  }

  return 'neutral';
}

export default connect()(VendorAuth);
