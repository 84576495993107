import React from 'react';
import Box from '@mui/material/Box';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Field } from 'redux-form';
import ToggleButton from '@mui/material/ToggleButton';
import './ShipmentDates.scss';

const ReduxFormToggleButton = (props) => {
  const handleChange = (event, newValue) => {
    props.change(props.fieldName, newValue);
  };

  return (
    <Box sx={{ width: '100%' }} className="DateRangeFormGroup">
      <ToggleButtonGroup
        color="primary"
        value={props.value}
        exclusive
        onChange={handleChange}
        className={props.fieldName}
        aria-label="basic tabs example"
        orientation={props.orientation}
        sx={{
          '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
            marginTop: '0px',
            marginLeft: '0px',
          },
        }}
      >
        {props.optionList.map((option) => (
          <ToggleButton value={option.value} key={option.value} className="toggleButton">
            <Field
              name={props.fieldName}
              component="input"
              type="hidden"
              id={`${props.fieldName}-${option.value}`}
              key={option.value}
              value={option.value}
            />
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};

export default ReduxFormToggleButton;
