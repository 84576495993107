import React from 'react';
import styled from 'styled-components';
import { ic_fiber_manual_record } from 'react-icons-kit/md/ic_fiber_manual_record';
import Icon from './Icon';

const StyledIcon = styled(Icon)`
  &.circle {
    padding: 5px;
  }
  &.ready {
    color: #73cd6a;
  }

  &.pending {
    color: #f8bd68;
  }
`;

// export const StatusIcon = ({ isReady }) => {
//   return (
//     <Tooltip title={isReady ? 'Ready' : 'Pending'}>
//       <StyledIcon
//         size={16}
//         icon={ic_fiber_manual_record}
//         className={isReady ? 'circle ready' : 'circle pending'}
//       />
//     </Tooltip>
//   );
// };

export const StatusIcon = ({ isReady }) => {
  return (
    <StyledIcon
      size={16}
      icon={ic_fiber_manual_record}
      className={isReady ? 'circle ready' : 'circle pending'}
    />
  );
};
