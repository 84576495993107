import { createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { getJSON } from 'common/helpers/ajax';
import { reset } from 'redux-form';
import * as ajax from 'common/helpers/ajax';
import { convertToJSON } from 'common/components/UploadTrackingField/utils';

const trackShipmentsSlice = createSlice({
  name: 'trackShipments',
  initialState: {
    isLoading: true,
    error: null,
    vendorAuthList: [],
    isSendingLoading: false,
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setVendorAuthList: (state, { payload }) => {
      state.vendorAuthList = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
    setIsSendingLoading: (state, { payload }) => {
      state.isSendingLoading = payload;
    },
  },
});

const { setLoading, setVendorAuthList, setError, setIsSendingLoading } =
  trackShipmentsSlice.actions;

export const getVendorAuthListList = () => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    getJSON(
      '/api/bulk-shipment-tracking/vendor-auth',
      null,
      (responseVendorAuth) => {
        getJSON(
          '/api/bulk-shipment-tracking/allowed-carriers',
          null,
          (response) => {
            const filtered = responseVendorAuth.filter((vendorAuth) =>
              response.includes(vendorAuth.vendor)
            );
            dispatch(setVendorAuthList(filtered));
            dispatch(setLoading(false));
          },
          (response) => {
            dispatch(setLoading(false));
            dispatch(setError(response.message));
            enqueueSnackbar(response.message, { variant: 'error' });
          }
        );
      },
      (response) => {
        dispatch(setLoading(false));
        dispatch(setError(response.message));
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

export const uploadBulkShipmentTracking = ({
  vendorAuthId,
  trackingNumbers,
  files,
  upload_radio_value,
}) => {
  return async (dispatch) => {
    let formData = new FormData();
    formData.append('vendor_auth_id', vendorAuthId);
    if (upload_radio_value === 'file') {
      Array.from(files).forEach((file) => {
        formData.append('files[]', file);
      });
    } else {
      formData.append('tracking_numbers', convertToJSON(trackingNumbers));
    }

    dispatch(setIsSendingLoading(true));

    return ajax.postFormData(
      '/api/bulk-shipment-tracking',
      formData,
      (response) => {
        dispatch(setIsSendingLoading(false));
        enqueueSnackbar('Success! Tracking results will be sent to your user email.', {
          variant: 'success',
        });
        dispatch(reset('trackShipmentsForm'));
      },
      (response) => {
        dispatch(setIsSendingLoading(false));
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

export const trackShipmentsReducer = trackShipmentsSlice.reducer;
