'use strict';

import './ShippingLocations.scss';

import autobind from 'react-autobind';
import React from 'react';
import { connect } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { ic_add } from 'react-icons-kit/md/ic_add';

import * as ajax from 'common/helpers/ajax';
import Button from 'common/components/Button';
import Icon from 'common/components/Icon';
import * as actions from '../store/actions';
import ShippingLocationTable from './ShippingLocationTable';
import Box from '@mui/material/Box';
import Tips from 'common/components/Tips/Tips';

class ShippingLocations extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    this.props.dispatch(actions.fetchShippingLocations());
  }

  render() {
    return (
      <section className="ShippingLocations">
        <Box
          sx={{
            paddingBottom: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Tips>
            Shipping locations will be used to determine whether shipments are inbound or
            outbound.
          </Tips>

          <Button variant="contained" onClick={() => this.showAddShippingLocationModal()}>
            <Icon icon={ic_add} /> Add
          </Button>
        </Box>
        {this.props.shippingLocations?.length ? (
          <ShippingLocationTable
            shippingLocations={this.props.shippingLocations}
            showAddShippingLocationModal={this.showAddShippingLocationModal}
            removeShippingLocation={this.removeShippingLocation}
          />
        ) : (
          <EmptyPlaceholder showModal={() => this.showAddShippingLocationModal()} />
        )}
      </section>
    );
  }

  updateShippingLocations() {
    ajax.getJSON(
      '/api/shipping-locations',
      (response) => {
        this.setState({ shippingLocations: response });
      },
      (response) => {
        console.log(response);
      }
    );
  }

  showAddShippingLocationModal(shippingLocation) {
    this.props.dispatch({
      type: 'SETTINGS_SHIPPING_SETUP__ADD_SHIPPING_LOCATION_MODAL__SHOW',
      payload: shippingLocation,
    });
  }

  removeShippingLocation(shippingLocation) {
    if (confirm('Are you sure you want to remove this location?')) {
      this.props.dispatch(removeShippingLocation(shippingLocation));
    }
  }
}

/**
 * An empty placeholder to render when no payment information has been entered
 * yet.
 */
const EmptyPlaceholder = (props) => {
  return (
    <Button
      className="empty-placeholder_new"
      variant="outlined"
      onClick={props.showModal}
    >
      You haven&rsquo;t added any shipping locations yet! Click here to get started.
    </Button>
  );
};

export const removeShippingLocation = (shippingLocation) => {
  return (dispatch) => {
    ajax.deleteJSON(
      '/api/shipping-locations/' + shippingLocation.uuid,
      {},
      (response) => {
        enqueueSnackbar('This shipping location has been removed!', {
          variant: 'success',
        });
        dispatch(actions.fetchShippingLocations());
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

const mapStateToProps = (state) => {
  return {
    shippingLocations: state.shippingLocations,
  };
};

export default connect(mapStateToProps)(ShippingLocations);
