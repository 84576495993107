import classNames from 'classnames';
import React from 'react';

import * as utils from 'common/helpers/utils';

import Chart from './ChartViewModel';
import * as Constants from './Constants';
import {
  axisFontSizeChartPlugin,
  LABELS_BOX_WIDTH,
  legendMarginBottom,
  MAX_DATASETS_LENGTH_TO_SHOW,
  MIN_DATA_FOR_LEGEND,
} from './Constants';

/**
 * Renders a combined bar + line chart.
 *
 * Note that this chart expects 2 datasets. The first dataset will be used for
 * the bar chart, while the second dataset will be used to render the line
 * chart.
 */
class CombinedBarLineChart extends React.Component {
  render() {
    if (!this.props.data) {
      return null;
    }

    return (
      <Chart
        title={this.props.title}
        chartConfig={this.chartConfig}
        className={classNames(this.props.className, 'CombinedBarLineChart')}
        hideLegend={
          this.props.hideLegend ||
          this.chartConfig.data.datasets.length > MAX_DATASETS_LENGTH_TO_SHOW
        }
        height={this.props.height}
        shipperSubscriptions={this.props.shipperSubscriptions}
      />
    );
  }

  get chartConfig() {
    var lineConfig = {
      yAxisID: 'yAxisRight',
      backgroundColor: 'transparent',
      borderColor: Constants.MULTI_COLORS[1],
      pointBackgroundColor: Constants.MULTI_COLORS[1],
      pointHoverBackgroundColor: Constants.MULTI_COLORS[1],
      pointHoverBorderColor: utils.hexToRGBA(Constants.MULTI_COLORS[1], 0.3),
    };
    var barConfig = {
      yAxisID: 'yAxisLeft',
      backgroundColor: Constants.MULTI_COLORS[0],
      hoverBackgroundColor: Constants.MULTI_COLORS[0],
    };

    return {
      type: 'bar',
      data: {
        labels: this.props.data.labels || [],
        // Reverse the ordering of datasets. Chart.js orders datasets in
        // decreasing z-index ordering, but it's more intuitive for us to define
        // the datasets in increasing z-index order.
        datasets: this.props.data.datasets
          .map((dataset, i) => {
            if (i === 0) {
              return Object.assign({ type: 'bar' }, barConfig, dataset);
            } else {
              return Object.assign(
                { type: 'line' },
                lineConfig,
                Constants.POINT_CONFIG,
                dataset
              );
            }
          })
          .reverse(),
        categoryPercentage: 0.9,
        barPercentage: 1.0,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: !(
            this.props.hideLegend ||
            this.props.data.datasets.length > MAX_DATASETS_LENGTH_TO_SHOW ||
            this.props.data.datasets.length < MIN_DATA_FOR_LEGEND
          ),
          labels: {
            boxWidth: LABELS_BOX_WIDTH,
          },
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false,
        },
        plugins: {
          tooltip: {
            backgroundColor: Constants.TOOLTIP_BACKGROUND_COLOR,
            callbacks: {
              // Add a little more space between the label color swatch and the
              // label text.
              label: (context) => {
                let value = context.raw;
                const yAxisID = context.dataset.yAxisID;

                if (yAxisID === 'yAxisLeft' && this.props.yAxisLeftTooltipsFmt) {
                  value = this.props.yAxisLeftTooltipsFmt(value);
                }
                if (yAxisID === 'yAxisRight' && this.props.yAxisRightTooltipsFmt) {
                  value = this.props.yAxisRightTooltipsFmt(value);
                }

                return ` ${context.label}: ${value}`;
              },
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: this.props.xAxisLabel,
            },
            grid: {
              drawTicks: false,
              display: true,
              zeroLineColor: Constants.LIGHT_BLUE,
            },
            border: {
              dash: Constants.GRID_DASH,
              display: true,
            },
          },

          yAxisLeft: {
            position: 'left',
            title: {
              display: true,
              text: this.props.yAxisLeftLabel,
            },
            grid: {
              drawTicks: false,
              display: true,
              zeroLineColor: Constants.LIGHT_BLUE,
            },
            border: {
              dash: Constants.GRID_DASH,
              display: true,
            },
            ticks: {
              beginAtZero: true,
              callback: (value) => {
                if (this.props.yAxisLeftTicksFmt) {
                  value = this.props.yAxisLeftTicksFmt(value);
                }
                return value + '  ';
              },
            },
            max: this.props.yAxisLeftMax,
          },
          yAxisRight: {
            id: 'yAxisRight',
            position: 'right',
            title: {
              display: true,
              text: this.props.yAxisRightLabel,
            },
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
              callback: (value) => {
                if (this.props.yAxisRightTicksFmt) {
                  value = this.props.yAxisRightTicksFmt(value);
                }
                return value;
              },
              max: this.props.yAxisRightMax,
            },
          },
        },
      },
      plugins: [legendMarginBottom, axisFontSizeChartPlugin],
    };
  }
}

export default CombinedBarLineChart;
