import React, { memo, useEffect, useRef } from 'react';
import { DataGrid, GridPagination, useGridApiContext } from '@mui/x-data-grid';
import NoTableDataOverlay from './NoTableDataOverlay';
import { SpinnerContainer } from '../Spinner';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ic_file_download } from 'react-icons-kit/md/ic_file_download';
import Icon from '../Icon';
import Tooltip from '@mui/material/Tooltip';

export const LoadingOverlay = ({ sx = {} }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgb(255 255 255 / 0.05)',
        backdropFilter: 'blur(1.4px)',
        zIndex: 1,
        ...sx,
      }}
    >
      <SpinnerContainer isVisible={true} message="Working..." />
    </Box>
  );
};

const TableLoadingOverlayWrapper = ({ tableRef }) => {
  useEffect(() => {
    const overlayWrapper = tableRef.current.querySelector(
      '.MuiDataGrid-overlayWrapper'
    );
    if (overlayWrapper.querySelector('.SpinnerContainer')) {
      // add sticky position for cover LoadingOverlay on table with scroll
      // we need to show LoadingOverlay on the entire table
      overlayWrapper.classList.add('overlayWrapper_sticky')
    }
  }, []);

  return <LoadingOverlay />;
};

const CustomFooterStatusComponent = ({ slotProps }) => {
  const apiRef = useGridApiContext();

  const handleExport = () => {
    apiRef.current.exportDataAsCsv(slotProps?.toolbar?.csvOptions);
  };
  const isDisabled = slotProps?.toolbar?.csvOptions?._isDisabled;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Tooltip title="Please select rows!" disableHoverListener={!isDisabled}>
        <Box>
          <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={handleExport}
            disabled={isDisabled}
            startIcon={<Icon icon={ic_file_download} />}
            sx={{
              fontWeight: '500 !important',
              font: 'Outfit',
              margin: '0 10px',
              padding: '0 10px',
              lineHeight: '1.75em !Important',
              borderRadius: '30px',
              '.MuiButton-startIcon': {
                marginRight: '1px',
              },
            }}
          >
            CSV
          </Button>
        </Box>
      </Tooltip>
      <Box
        sx={{
          '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
            marginBottom: '0 !important',
          },
          '.MuiSelect-standard': { marginTop: '3px' },
        }}
      >
        <GridPagination />
      </Box>
    </Box>
  );
};

const DataGridCustom = ({
  rows,
  columns,
  sortModel,
  onSortModelChange,
  settings = {},
  customNoTableDataOverlay,
  slots = {},
  sx = {},
  slotProps = {},
}) => {
  const tableRef = useRef();

  return (
    <DataGrid
      ref={tableRef}
      sx={{
        '& .MuiDataGrid-main': {
          fontFamily: 'Outfit',
          color: '#0B2C4D',
        },
        '& .MuiDataGrid-iconButtonContainer': {
          padding: '0 !important',
          display: 'none',
        },
        '& .MuiIconButton-sizeSmall>.MuiDataGrid-sortIcon': {
          display: 'none',
        },
        '& .MuiIconButton-sizeSmall>.MuiDataGrid-menuIconButton': {
          display: 'none',
        },
        '.MuiDataGrid-cell--textCenter': {
          textAlign: 'center',
        },
        '.MuiDataGrid-overlayWrapper': {
          // for all overlay (without loading)
          position: 'absolute',
          zIndex: 1,
        },
        '.MuiDataGrid-overlayWrapper.overlayWrapper_sticky': {
          position: 'sticky !important',
        },
        '& .MuiDataGrid-virtualScroller': {
          // overflow: 'visible',
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: '#fff',
          borderRadius: '12px 12px 0px 0px',
        },
        '& .MuiDataGrid-columnHeader:hover': {
          padding: '0px !important',
          '.MuiDataGrid-columnHeaderTitle': {
            borderRadius: '16px',
            backgroundColor: '#E0EFFF',
            height: 'fit-content !important',
            lineHeight: 'normal',
            padding: '4px 10px',
          },
          '.MuiBox-root': {
            borderRadius: '16px',
            backgroundColor: '#E0EFFF',
            height: 'fit-content',
            padding: '4px 10px',
            lineHeight: 'normal',
            width: 'min-content',
          },
        },
        '.MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator': {
          visibility: 'hidden !important',
        },
        '.MuiDataGrid-columnHeader--sorted': {
          padding: '0px !important',

          '.MuiDataGrid-columnHeaderTitle': {
            fontWeight: '600 !important',
            borderRadius: '16px',
            backgroundColor: '#E0EFFF',
            height: 'fit-content !important',
            lineHeight: 'normal',
            padding: '4px 10px',
          },
          '.MuiBox-root': {
            fontWeight: '600 !important',
            borderRadius: '16px',
            backgroundColor: '#E0EFFF',
            height: 'fit-content',
            padding: '4px 10px',
            lineHeight: 'normal',
            width: 'min-content',
          },
        },
        '.MuiDataGrid-columnHeaderTitle, .MuiDataGrid-columnHeaderTitleContainer': {
          color: '#1175DB',
          fontFamily: 'Outfit',
          fontWeight: 300,
          letterSpacing: '0em',
        },
        '.MuiDataGrid-footerContainer p': {
          margin: 0,
        },
        '.MuiDataGrid-row.Mui-hovered': {
          backgroundColor: '#F5F8FD',
        },
        '.MuiDataGrid-row:hover': {
          backgroundColor: '#F5F8FD',
        },

        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within ':
          {
            outline: 'none',
          },
        '& .MuiDataGrid-withBorderColor': {
          borderColor: '#E0EFFFBA',
        },
        '.MuiDataGrid-virtualScroller': {
          // firefox cant use backdropFilter with static block. That we set loadingOverlay as absolute position and lock scroll
          ...(settings.loading ? { overflow: 'hidden' } : {}),
        },

        border: 'none',
        height: '100%',
        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '4px' },
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '8px' },
        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '12px' },
        ...sx,
      }}
      rows={rows}
      columns={columns}
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      // hideFooter
      disableRowSelectionOnClick
      rowSelection={false}
      disableColumnMenu
      slots={{
        noRowsOverlay:
          (customNoTableDataOverlay && (() => customNoTableDataOverlay)) ||
          NoTableDataOverlay,
        loadingOverlay: () => <TableLoadingOverlayWrapper tableRef={tableRef} />,
        footer: () => <CustomFooterStatusComponent slotProps={slotProps} />,
        ...slots,
      }}
      getRowHeight={() => 'auto'}
      {...settings}
    />
  );
};

export default memo(DataGridCustom);
