import React, { useEffect } from 'react';
import useChartTabs from 'common/components/Tabs/hooks/useChartTabs';
import PageContainer from '../containers/PageContainer';
import Box from '@mui/material/Box';
import TabsTitle from 'common/components/Tabs/TabsTitle';
import classNames from 'classnames';
import './ShippingSetup.scss';
import VendorAuth from '../settings/VendorAuth';
import VendorAccountNumberMappings from '../settings/VendorAccountNumberMappings';
import GLCodes from '../settings/GLCodes/GLCodes';
import UpdateVendorAccountNumberMappingModal from '../settings/UpdateVendorAccountNumberMappingModal';
import ShippingLocations from '../settings/ShippingLocations';
import AddShippingLocationModal from '../settings/AddShippingLocationModal';
import FinancialCalendar from '../settings/FinancialCalendar';
import { useDispatch, useSelector } from 'react-redux';
import { useCheckPermissions } from '../../common/helpers/hooks/useCheckPermissions';
import * as actions from '../store/actions';
import OperationVendorAuth from '../settings/OperationVendorAuth';

const ShippingSetup = () => {
  const dispatch = useDispatch();
  const checkPermissions = useCheckPermissions();
  const { updateVendorAccountNumberMappingModal, addShippingLocationModal } = useSelector(
    (state) => state.settingsShippingSetup
  );
  const vendorAccountNumberMappings = useSelector(
    (state) => state.vendorAccountNumberMappings
  );

  useEffect(() => {
    if (!vendorAccountNumberMappings || vendorAccountNumberMappings.length <= 0) {
      dispatch(actions.fetchVendorAccountNumberMappings());
    }
  }, []);

  const { currentTab, onChangeCurrentQueryNames } = useChartTabs(tabList, PAGE);

  const { isAllowTab } = checkPermissions([PERMISSION_PAGE_KEY, currentTab.path]);

  return (
    <PageContainer
      title="Shipment Setup"
      contentId="shipmentSetup"
      contentClassName={classNames('ShipmentSetup', 'PageContentWithTabs')}
      seconHeaderContent={
        <TabsTitle
          tabList={tabList}
          value={currentTab.label}
          pages={PAGE}
          permissionPageKey={PERMISSION_PAGE_KEY}
          setValue={onChangeCurrentQueryNames}
        />
      }
    >
      <Box>
        {isAllowTab && (
          <>
            {currentTab.path === 'carrier-credentials' && <VendorAuth />}
            {currentTab.path === 'operation-credentials' && <OperationVendorAuth />}
            {currentTab.path === 'account-number-mappings' && (
              <>
                <VendorAccountNumberMappings />
                <UpdateVendorAccountNumberMappingModal
                  show={updateVendorAccountNumberMappingModal.isVisible}
                />
              </>
            )}
            {currentTab.path === 'gl-coding-rules' && <GLCodes />}

            {currentTab.path === 'shipping-locations' && (
              <>
                <ShippingLocations />
                <AddShippingLocationModal show={addShippingLocationModal.isVisible} />
              </>
            )}

            {currentTab.path === 'update-financial-calendar' && <FinancialCalendar />}
          </>
        )}
      </Box>
    </PageContainer>
  );
};

const PAGE = 'shipping-setup';
const PERMISSION_PAGE_KEY = 'SHIPPING_SETUP';

const tabList = [
  {
    label: 'Carrier Credentials',
    path: 'carrier-credentials',
  },
  {
    label: 'Operation Credentials',
    path: 'operation-credentials',
  },
  {
    label: 'Account Number Mappings',
    path: 'account-number-mappings',
  },
  {
    label: 'GL Coding Rules',
    path: 'gl-coding-rules',
  },
  {
    label: 'Shipping Locations',
    path: 'shipping-locations',
  },
  {
    label: 'Financial Calendar',
    path: 'update-financial-calendar',
  },
];

export default ShippingSetup;
