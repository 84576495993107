import classNames from 'classnames';
import React, { memo } from 'react';
import * as utils from 'common/helpers/utils';
import * as Constants from './Constants';
import _merge from 'lodash/merge';
import Chart from './ChartViewModel';
import {
  axisFontSizeChartPlugin,
  LABELS_BOX_WIDTH,
  legendMarginBottom,
  MAX_DATASETS_LENGTH_TO_SHOW,
} from './Constants';

const MultiLineChart = (props) => {
  if (!props.data) {
    return <></>;
  }

  const hideLegend =
    props.hideLegend || props.data.datasets.length > MAX_DATASETS_LENGTH_TO_SHOW;

  const colorPalette = Constants.getColorPallete(props.data.datasets.length);

  const chartConfig = {
    type: 'line',
    data: {
      labels: props.data.labels || [],
      datasets: props.data.datasets.map((dataset, i) => {
        return Object.assign(
          {
            fill:
              props?.queryChartData?.chartType &&
              props?.queryChartData?.chartType === 'line-filled',
            lineTension: 0,
            // For fill charts
            backgroundColor: (context) => {
              if (!context.chart.chartArea) {
                return;
              }
              const { ctx, chartArea } = context.chart;
              const gradient = ctx.createLinearGradient(
                0,
                chartArea.top,
                0,
                chartArea.bottom
              );
              gradient.addColorStop(0, utils.hexToRGBA(colorPalette[i], 0.3));
              gradient.addColorStop(1, utils.hexToRGBA(colorPalette[i], 0.07));
              return gradient;
            },
            // Custom field for react select picker
            selectBackgroundColor: colorPalette[i],
            borderColor: colorPalette[i],
            pointBackgroundColor: colorPalette[i],
            pointHoverBackgroundColor: colorPalette[i],
            pointHoverBorderColor: utils.hexToRGBA(colorPalette[i], 0.3),
            spanGaps: false,
          },
          Constants.POINT_CONFIG,
          dataset
        );
      }),
    },
    options: {
      maintainAspectRatio: false,
      interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
      },
      layout: {
        padding: {
          left: 0,
        },
      },
      plugins: {
        tooltip: {
          backgroundColor: Constants.TOOLTIP_BACKGROUND_COLOR,
          callbacks: {
            // Add a little more space between the label color swatch and the
            // label text.
            label: (context) => {
              let itemValue = context.raw;
              let dataset = context.dataset;

              if (props.yAxisTooltipsFmt) {
                itemValue = props.yAxisTooltipsFmt(itemValue);
              }

              let tooltip = ' ' + dataset.label + ': ' + itemValue;

              if (props.yAxisTooltipsShowPerc) {
                let itemPerc =
                  context.raw /
                  context.chart._active.reduce(
                    (a, b) => a + b.element?.$context?.raw || 0,
                    0
                  );

                if (props.yAxisTooltipsPercFmt) {
                  const itemPercNew = props.yAxisTooltipsPercFmt(itemPerc);
                  itemPerc = itemPercNew;
                }

                return tooltip + ' (' + itemPerc + ')';
              }
            },
          },
          legend: {
            display: props.hideLegend,
          },
        },
        legend: {
          display: false,
          labels: {
            boxWidth: LABELS_BOX_WIDTH,
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: props.xAxisLabel,
          },
          border: {
            color: Constants.LIGHT_BLUE,
          },
          grid: {
            color: 'transparent',
            zeroLineColor: Constants.LIGHT_BLUE, //todo migrate,
          },
          ticks: {
            color: Constants.MED_BLUE,
          },
        },
        y: {
          title: {
            display: true,
            text: props.yAxisLabel,
          },
          border: {
            dash: Constants.GRID_DASH,
            display: true,
            color: Constants.LIGHT_BLUE,
          },
          // borderColor: Constants.LIGHT_BLUE,
          grid: {
            color: Constants.LIGHT_BLUE,
            zeroLineColor: Constants.LIGHT_BLUE,
            drawTicks: false,
            display: true,
          },
          beginAtZero: true,
          max: props.yAxisTicksMax,
          ticks: {
            callback: (value) => {
              if (props.yAxisTicksFmt) {
                value = props.yAxisTicksFmt(value);
              }
              return value + '  ';
            },
            stepSize: props.yAxisTicksStepSize,
            color: Constants.MED_BLUE,
          },
        },
      },
    },
    plugins: [legendMarginBottom, axisFontSizeChartPlugin],
  };

  return (
    <Chart
      title={props.title}
      helpText={props.helpText}
      chartConfig={_merge(chartConfig, props.chartConfig)}
      className={classNames(props.className, 'MultiLineChart')}
      hideLegend={hideLegend}
      height={props.height}
      shipperSubscriptions={props.shipperSubscriptions}
    />
  );
};

export default memo(MultiLineChart);
