'use strict';

import './VendorInvoiceDetails.scss';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageContainer from 'cloud/containers/PageContainer';
import { getVendorInvoiceDetails } from '../store/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { SpinnerContainer } from 'common/components/Spinner';
import DropdownButton from 'common/components/DropdownButton';
import * as utils from 'common/helpers/utils';
import HelpIcon from 'common/components/HelpIcon';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableHeaderStack from 'common/components/table/TableHeaderStack';
import { useVendorInvoiceDetailsColumns } from './hooks/useVendorInvoiceDetailsColumns';
import { useVendorInvoiceDetailsRows } from './hooks/useVendorInvoiceDetailsRows';
import DataGridWrapper from 'common/components/table/DataGridWrapper';
import DataGridCustom from 'common/components/table/DataGridCustom';
import Box from '@mui/material/Box';
import { useSearch } from 'common/components/table/TableHeaderSearch';
import { searchTableFn } from 'common/helpers/utils';
import { MenuItem, Container } from '@mui/material';
import Stack from '@mui/material/Stack';

const DOWNLOAD_SELECTED_FORMATS = {
  PREFER_PDF: {
    value: 'prefer_pdf',
    label: 'Prefer PDF',
  },
  PREFER_CSV: {
    value: 'prefer_csv',
    label: 'Prefer CSV',
  },
};

const EmptyPlaceholder = () => {
  return (
    <Box className="table-empty-placeholder">
      <p>Please wait and try again later.</p>
      <p>
        Invoice details are still processing or your invoice doesn't contain any
        shipments.
      </p>
    </Box>
  );
};

const SIZE_PER_PAGE = 10;

const VendorDetails = (props) => {
  if (!props.vendor_invoice) {
    return null;
  }

  return (
    <Table bordered={true}  className="VendorDetails detailsTable_table">
      <TableHead className={'detailsTable_thead'}>
        <tr>
          <td className="heading" colSpan={12}>
            Invoice Detail
          </td>
        </tr>
      </TableHead>
      <TableBody>
        <tr>
          <td className={'detailsTable_row-title'}>Invoice number</td>
          <td className={'detailsTable_row-text'}>
            {props.vendor_invoice.invoice_number}
          </td>
          <td className={'detailsTable_row-title'}>Invoice date</td>
          <td className={'detailsTable_row-text'}>{props.vendor_invoice.invoice_date}</td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Vendor</td>
          <td className={'detailsTable_row-text'}>{props.vendor_invoice.vendor}</td>
          <td className={'detailsTable_row-title'}>Vendor account number</td>
          <td className={'detailsTable_row-text'}>
            {props.vendor_invoice.vendor_account_number}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Due date</td>
          <td className={'detailsTable_row-text'}>
            {props.vendor_invoice.invoice_due_date}
          </td>
          <td className={'detailsTable_row-title'}>Payment status</td>
          <td className={'detailsTable_row-text'}>
            {props.vendor_invoice.vendor_payment_status}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Original amount</td>
          <td className={'detailsTable_row-text'}>
            {props.vendor_invoice.original_amount}
          </td>
          <td className={'detailsTable_row-title'}>Adjusted amount</td>
          <td className={'detailsTable_row-text'}>
            {props.vendor_invoice.adjusted_amount}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Final amount</td>
          <td className={'detailsTable_row-text'}>{props.vendor_invoice.final_amount}</td>
          <td className={'detailsTable_row-title'}>
            Num shipments
            <HelpIcon class="text-right">
              In case of shipment quantity discrepancy, please contact your admin.
            </HelpIcon>
          </td>
          <td className={'detailsTable_row-text'}>
            {props.vendor_invoice.num_shipments}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Currency</td>
          <td className={'detailsTable_row-text'}>
            {props.vendor_invoice.currency_iso_3}
          </td>
          <td className={'detailsTable_row-title'}>Detailed message</td>
          <td className={'detailsTable_row-text'}>
            {props.vendor_invoice.detailed_message}
          </td>
        </tr>
      </TableBody>
    </Table>
  );
};

const VendorInvoiceDetails = (props) => {
  const [sortModel, setSortModel] = useState([
    {
      field: 'trackingNumber',
      sort: 'asc',
    },
  ]);

  const { vendorInvoiceDetails, vendorInvoiceError } = useSelector(
    (state) => state.vendorInvoiceDetails
  );

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { searchQuery, onHandlerSearch } = useSearch('');

  useEffect(() => {
    if (location.state && location.state.vendor_invoice_id)
      dispatch(getVendorInvoiceDetails(location.state.vendor_invoice_id));
    else history.push('/invoices');
  }, [location, dispatch]);

  useEffect(() => {
    if (vendorInvoiceError) {
      history.push('/invoices');
    }
  }, [vendorInvoiceError, dispatch]);

  useEffect(() => {
    return function () {
      dispatch({
        type: 'VENDOR_INVOICE_DETAILS',
        payload: null,
      });
    };
  }, [dispatch]);

  const returnHandler = () => {
    history.push('/invoices');
  };

  const rows = useVendorInvoiceDetailsRows(vendorInvoiceDetails?.shipments);
  const columns = useVendorInvoiceDetailsColumns();

  const searchedRows = useMemo(() => {
    const keyList = [
      'trackingNumber',
      'carrierPickedUpDate',
      'chargeAmount',
      'adjustmentAmount',
      'discountAmount',
      'refundAmount',
      'totalAmount',
    ];
    return searchTableFn(searchQuery, rows, keyList);
  }, [searchQuery, rows]);

  return (
    <PageContainer
      title={
        <span onClick={returnHandler} style={{ cursor: 'pointer' }}>
          Carrier Invoices
        </span>
      }
    >
      {!vendorInvoiceDetails ? (
        <SpinnerContainer isVisible message="Working..." />
      ) : (
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <VendorDetails vendor_invoice={vendorInvoiceDetails.vendor_invoice} />
            <Box sx={{ width: '100%' }}>
              <TableHeaderStack
                searchQuery={searchQuery}
                onHandlerSearch={onHandlerSearch}
                searchStyle={{ maxWidth: '210px' }}
                actions={
                  <DropdownButton
                    key="DownloadSelected"
                    title="Download invoice"
                    id="DownloadSelected"
                    variant="contained"
                    menuAlign="right"
                  >
                    {Object.entries(DOWNLOAD_SELECTED_FORMATS).map(([key, one]) => (
                      <MenuItem
                        key={one.value}
                        onClick={() =>
                          downloadSelected(one.value, vendorInvoiceDetails.vendor_invoice)
                        }
                      >
                        {one.label}
                      </MenuItem>
                    ))}
                  </DropdownButton>
                }
              />

              <DataGridWrapper
                // customPudding={100}
                style={{
                  minHeight: '450px',
                }}
              >
                <DataGridCustom
                  rows={searchedRows}
                  columns={columns}
                  sortModel={sortModel}
                  onSortModelChange={setSortModel}
                  customNoTableDataOverlay={<EmptyPlaceholder />}
                  settings={{
                    initialState: {
                      pagination: { paginationModel: { pageSize: SIZE_PER_PAGE } },
                    },
                    pageSizeOptions: [SIZE_PER_PAGE, 30, 50, 100],
                    hideFooter: false,
                  }}
                />
              </DataGridWrapper>
            </Box>
          </Stack>
        </Container>
      )}
    </PageContainer>
  );
};

function downloadSelected(selectedFormatPreference, vendorInvoice) {
  if (!('has_content' in vendorInvoice)) {
    return null;
  }
  let downloadQueryString = '?hid=' + vendorInvoice.hid;
  if (selectedFormatPreference === DOWNLOAD_SELECTED_FORMATS.PREFER_PDF.value) {
    utils.triggerDownloadUrl(
      `/vendor-invoices${downloadQueryString}&format=${selectedFormatPreference}`
    );
  } else {
    utils.triggerDownload(
      `/vendor-invoices${downloadQueryString}&format=${selectedFormatPreference}`
    );
  }
}

export default VendorInvoiceDetails;
