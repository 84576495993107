import { createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { getJSON, postJSON } from 'common/helpers/ajax';
import {
  CALCULATED_PRICE,
  CALCULATED_EFFECTIVENESS,
  CALCULATION_COMPARE,
} from 'common/helpers/constants';
import {
  findMaxValue,
  findMinValue,
  findAbsMinValue,
  findAbsMaxValue,
} from 'common/helpers/utils';

const defaultFilter = {
  contractId: '',
  serviceId: '',
  earnedDiscount: '',
  ratesYear: '',
  accessorialId: [],
};

const initialState = {
  filters: [defaultFilter],
  calculations: [],
  calculationCompare: {},
  calculationAccessorials: {},
  calculationDIM: {},
  compareError: null,
  currency: '',
  displayType: CALCULATED_PRICE,
  selectedCalculation: null,
  selectedCalculationIndex: 0,
  loading: false,
  charges: {},
  ratesYears: {},
  csvData: '',
};

const analysisSlice = createSlice({
  name: 'analysis',
  initialState: initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    addFilter: (state) => {
      if (state.filters.length < 2) state.filters.push(defaultFilter);
    },
    changeFilter: (state, { payload }) => {
      // Clear filters if contract id was changed, clear earned discount
      // and accessorials fields id service id was changed

      if (payload.data.contractId) {
        state.filters[payload.index] = {
          ...defaultFilter,
          ...payload.data,
        };
      } else if (payload.data.serviceId) {
        state.filters[payload.index] = {
          ...state.filters[payload.index],
          earnedDiscount: '',
          accessorialId: [],

          ...payload.data,
        };
      } else if (payload.data.ratesYear) {
        state.filters[payload.index] = {
          ...state.filters[payload.index],
          accessorialId: [],

          ...payload.data,
        };
      } else {
        state.filters[payload.index] = {
          ...state.filters[payload.index],
          ...payload.data,
        };
      }
    },
    setCalculations: (state, { payload }) => {
      // Set the maximum value of each calculation to create a heatmap
      state.calculations = [...payload].map((calculation) => ({
        ...calculation,
        maxValues: {
          [CALCULATED_PRICE]: findMaxValue(calculation[CALCULATED_PRICE]),
          [CALCULATED_EFFECTIVENESS]: findMaxValue(calculation[CALCULATED_EFFECTIVENESS]),
        },
        minValues: {
          [CALCULATED_PRICE]: findMinValue(calculation[CALCULATED_PRICE]),
          [CALCULATED_EFFECTIVENESS]: findMinValue(calculation[CALCULATED_EFFECTIVENESS]),
        },
      }));
    },
    setCalculationCompare: (state, { payload }) => {
      state.calculationCompare = { ...payload.calculation };
      state.compareError = payload.compareError;

      // Set the maximum value of each calculation to create a heatmap
      if (Object.keys(payload.calculation).length > 0) {
        state.calculationCompare.maxValues = {
          [CALCULATED_PRICE]: findAbsMaxValue(
            { ...payload.calculation }[CALCULATED_PRICE]
          ),
          [CALCULATED_EFFECTIVENESS]: findAbsMaxValue(
            { ...payload.calculation }[CALCULATED_EFFECTIVENESS]
          ),
        };
        state.calculationCompare.minValues = {
          [CALCULATED_PRICE]: findAbsMinValue(
            { ...payload.calculation }[CALCULATED_PRICE]
          ),
          [CALCULATED_EFFECTIVENESS]: findAbsMinValue(
            { ...payload.calculation }[CALCULATED_EFFECTIVENESS]
          ),
        };
      }
    },
    setCalculationAccessorials: (state, { payload }) => {
      if (!state.calculationAccessorials[payload.contractId]) {
        state.calculationAccessorials[payload.contractId] = {};
      }

      state.calculationAccessorials[payload.contractId][payload.ratesYearId] =
        payload.data;
    },
    setCalculationDIM: (state, { payload }) => {
      if (!state.calculationDIM[payload.contractId]) {
        state.calculationDIM[payload.contractId] = {};
      }

      state.calculationDIM[payload.contractId][payload.ratesYearId] = payload.data;
    },
    selectDisplayType: (state, { payload }) => {
      state.displayType = payload;
    },
    setCharges: (state, { payload }) => {
      state.loading = false;
      state.charges[payload.contractId] = payload.data.servicesData;
      state.currency = payload.data.currency;
      state.ratesYears[payload.contractId] = payload.data.ratesEffectiveDates;
    },
    selectCalculation: (state, { payload }) => {
      state.selectedCalculationIndex = payload;

      if (payload === CALCULATION_COMPARE)
        state.selectedCalculation = { ...state.calculationCompare };
      else state.selectedCalculation = { ...state.calculations[payload] };
    },
    clearFilters: (state) => {
      state.filters = [...initialState.filters];
    },
    clearCalculations: (state) => {
      state.calculations = [...initialState.calculations];
      state.calculationCompare = { ...initialState.calculationCompare };
      state.selectedCalculation = initialState.selectedCalculation;
      state.selectedCalculationIndex = initialState.selectedCalculationIndex;
    },
    setCsvData: (state, { payload }) => {
      state.csvData = payload;
    },
  },
});

export const {
  setLoading,
  addFilter,
  setCalculations,
  setCalculationCompare,
  setCalculationAccessorials,
  setCalculationDIM,
  clearCalculations,
  clearFilters,
  selectCalculation,
  selectDisplayType,
  changeFilter,
  setCharges,
  setCsvData,
} = analysisSlice.actions;

export const calculateBasic = (filters) => {
  return async (dispatch) => {
    dispatch(clearCalculations());
    dispatch(setLoading(true));

    const body = filters.map((f) => {
      const obj = {
        contractId: Number(f.contractId),
        serviceId: f.serviceId,
        effectiveDateId: f.ratesYear,
        accessorials: f.accessorialId,
      };

      if (f.earnedDiscount) {
        obj.discountRangeId = f.earnedDiscount;
      }

      return obj;
    });

    await postJSON(
      '/contracts/calculation/',
      body,
      (response) => {
        dispatch(setCalculations(response.calculation));
        dispatch(
          setCalculationCompare({
            calculation: response.calculationCompare,
            compareError: response.errorMsg,
          })
        );
        dispatch(setLoading(false));
      },
      (response) => {
        dispatch(setLoading(false));
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

export const calculateAccessorials = (contractId, ratesYearId, callback) => {
  return async (dispatch) => {
    await getJSON(
      `/contracts/calculation/accessorials/${contractId}/${ratesYearId}/`,
      null,
      (response) => {
        dispatch(
          setCalculationAccessorials({
            data: response,
            contractId: contractId,
            ratesYearId: ratesYearId,
          })
        );
        if (callback) {
          callback();
        }
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
        if (callback) {
          callback();
        }
      }
    );
  };
};

export const calculateDIM = (contractId, ratesYearId, callback) => {
  return async (dispatch) => {
    await getJSON(
      `/contracts/calculation/dim_divisors/${contractId}/${ratesYearId}/`,
      null,
      (response) => {
        dispatch(
          setCalculationDIM({
            data: response,
            contractId: contractId,
            ratesYearId: ratesYearId,
          })
        );
        if (callback) {
          callback();
        }
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
        if (callback) {
          callback();
        }
      }
    );
  };
};

export const getCharges = (contractId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));

    await getJSON(
      `/contracts/${contractId}/data/`,
      null,
      (response) => dispatch(setCharges({ data: response, contractId })),
      (response) => {
        dispatch(setLoading(false));
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

export const analysisReducer = analysisSlice.reducer;
