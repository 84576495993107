import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import { tableFormatMap, useClassSubscriptionsForCharts } from './Constants';
import Box from '@mui/material/Box';
import HelpIcon from 'common/components/HelpIcon';
import React, { useMemo } from 'react';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { toCamelCaseString } from '../../helpers/utils';
import { Divider } from '@mui/material';

const VerticalTable = (props) => {
  const additionalClass = useClassSubscriptionsForCharts(props.shipperSubscriptions);

  const { chartData, tableFormats } = props.queryChartData;

  const sortedDataColumns = useMemo(() => {
    return [...tableFormats]?.sort((a, b) => a.index - b.index);
  }, [tableFormats]);

  const rows = useMemo(() => {
    return (
      sortedDataColumns?.map((tableFormat, index) => {
        const value = chartData[0][toCamelCaseString(tableFormat.column)];
        return {
          ...(tableFormat.format !== 'string'
            ? { value: tableFormatMap[tableFormat.format](value) }
            : { value }),
          headerName: (
            tableFormat.column.charAt(0).toUpperCase() + tableFormat.column.slice(1)
          ).replaceAll(/_/g, ' '),
        };
      }) || []
    );
  }, [sortedDataColumns, chartData]);

  return (
    <Box className="Chart">
      <Box className="chartHeader">
        <TitleBlock
          title={props.title}
          helpText={props.helpText}
          className={additionalClass}
        />
      </Box>
      <Divider className="divider" />
      <Table sx={{ minWidth: '100%' }} aria-label="simple table">
        <TableHead></TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.headerName}>
              <TableCell component="th" scope="row">
                {row.headerName}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
const TitleBlock = (props) => {
  return (
    <>
      {props.title && (
        <Box className="chartHeader_titleBlock">
          <h3 className={`chartTitle ${props.className}`}>{props.title}</h3>
          {props.helpText && (
            <Box className="helpIcon">
              <HelpIcon>{props.helpText}</HelpIcon>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
export default VerticalTable;
