'use strict';

import React from 'react';
import autobind from 'react-autobind';
import { connect } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

import NoSearchResults from './NoSearchResults';
import ShipmentSearchForm from './ShipmentSearchForm';
import ShipmentMultipleResults from './ShipmentMultipleResults';
import ShipmentDetails from './ShipmentDetails';
import { SpinnerContainer } from 'common/components/Spinner';

class ShipmentLookup extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      isLoading: false,
    };
  }

  /**
   * If we have a tracking number, then trigger an initial query for shipments
   * with that tracking number.
   */
  componentDidMount() {
    if (this.props.match.params.trackingNumber) {
      this.setState({ isLoading: true });

      this.props.dispatch(
        this.props.fetchShipments(
          this.props.match.params,
          () => {
            this.setState({ isLoading: false });
          },
          (response) => {
            enqueueSnackbar(response.message, { variant: 'error' });
            this.setState({ isLoading: false });
          }
        )
      );
    }
  }

  render() {
    return (
      <>
        <ShipmentSearchForm
          trackingNumber={this.props.match.params.trackingNumber}
          isLoading={this.state.isLoading}
          setIsLoading={(isLoading) => this.setState({ isLoading })}
        />
        {this.getBodyComponent()}
      </>
    );
  }

  getBodyComponent() {
    const { trackingNumber, hid } = this.props.match.params;

    //If there is a request to the server, we show the Loader
    if (this.state.isLoading) {
      return <SpinnerContainer isVisible={true} message="Working..." />;
    }

    // If there's not tracking number, then we should short-circuit immediately.
    if (!trackingNumber) {
      return null;
    }
    // If we didn't find any shipments, then show an empty placeholder.
    else if (!this.props.shipments || this.props.shipments.length === 0) {
      return <NoSearchResults />;
    }
    // If we have only one shipment, then we still need to check the tracking
    // numbers are equal. this ensures that we show the `SearchMultipleResults`
    // component if the tracking number is a prefix with only a single match (we
    // want to user to select the full tracking number).
    else if (
      this.props.shipments &&
      this.props.shipments.length === 1 &&
      this.props.shipments[0].trackingNumber === trackingNumber
    ) {
      return <ShipmentDetails shipment={this.props.shipments[0]} />;
    }
    // // If we have multiple shipments, then see if we have a hid. If so, then we
    // // should should try to match by that. Otherwise, just show everything.
    else {
      let shipment =
        this.props.shipments && this.props.shipments.find((s) => s.hid == hid);
      if (shipment) {
        return <ShipmentDetails shipment={shipment} />;
      } else {
        return <ShipmentMultipleResults shipments={this.props.shipments} />;
      }
    }

    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    shipments: state.shipments,
  };
};

export default connect(mapStateToProps)(ShipmentLookup);
