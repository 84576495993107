import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import Box from '@mui/material/Box';

const Container = styled(TableContainer)`
  max-height: 80vh;
`;

const Info = styled(Typography)`
  margin: 80px;
  text-align: center;
`;

export const DIMDivisorTable = ({ DIM }) => {
  return (
    <Container component={Box}>
      {DIM.length !== 0 ? (
        <Table bordered={true}  className={'detailsTable_table'}>
          <TableHead className={'detailsTable_thead'}>
            <TableRow>
              <TableCell className={`heading`}>Service</TableCell>
              <TableCell className={`heading`}>List DIM divisor</TableCell>
              <TableCell className={`heading`}>Change</TableCell>
              <TableCell className={`heading`}>Effective DIM divisor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {DIM.map((row) => (
              <TableRow
                key={
                  row.service + row.listDIMDivisor + row.change + row.effectiveDIMDivisor
                }
              >
                <TableCell className={'detailsTable_row-text'}>{row.service}</TableCell>
                <TableCell className={'detailsTable_row-text'}>
                  {row.listDIMDivisor || '-'}
                </TableCell>
                <TableCell className={'detailsTable_row-text'}>
                  {row.change || '-'}
                </TableCell>
                <TableCell className={'detailsTable_row-text'}>
                  {row.effectiveDIMDivisor || '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Info variant="subtitle1">No DIM divisors found</Info>
      )}
    </Container>
  );
};
