import { useMemo } from 'react';

export const useNetRatesTableRows = (netRatesList) =>
  useMemo(
    () =>
      (netRatesList || []).reduce((prevState, currentValue) => {
        const currentRow = {
          id: currentValue.id,
          number: currentValue.number,
          service_type: currentValue.service_type,
          carrier: currentValue.carrier,
          effective_date: currentValue.effective_date,
          actions: currentValue,
          currentValue,
        };
        prevState.push(currentRow);
        return prevState;
      }, []),
    [netRatesList]
  );
