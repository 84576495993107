import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import DashboardContainer from './DashboardContainer';
import TabsTitle from 'common/components/Tabs/TabsTitle';
import useChartTabs from 'common/components/Tabs/hooks/useChartTabs';
import Box from '@mui/material/Box';
import { toCamelCaseInArray } from 'common/helpers/utils';

const PAGE = 'dashboard/performance';

const Performance = (props) => {
  const tabList = props.tabsState?.tabsMap?.PERFORMANCE || [];

  const { currentTab, onChangeCurrentQueryNames, contentId } = useChartTabs(
    tabList,
    PAGE
  );

  const contentTabValue = useMemo(() => {
    return toCamelCaseInArray(currentTab.value || []);
  }, [currentTab.value]);

  return (
    <DashboardContainer
      seconHeaderContent={
        !props.tabsState.isLoading &&
          tabList.length >= 1  && (
          <Box className={'dashboardTabs'}>
            <TabsTitle
              setValue={onChangeCurrentQueryNames}
              value={currentTab.label}
              tabList={tabList}
              pageName={PAGE}
            />
          </Box>
        )
      }
      title="Performance"
      contentId={contentId}
      contentTab={contentTabValue}
      queryNames={currentTab.queryNames}
      getCharts={getCharts}
      contentClassName={'PageContentWithTabs'}
    />
  );
};

const getCharts = {};

const mapStateToProps = (state) => {
  return {
    tabsState: state.dashboard.tabsState,
  };
};

export default connect(mapStateToProps)(Performance);
