'use strict';

import React from 'react';
import autobind from 'react-autobind';
import { connect } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

import * as actions from '../store/actions';
import * as ajax from 'common/helpers/ajax';
import * as utils from 'common/helpers/utils';

import RunReportForm from './RunReportForm';
import { loactionMatchingToObject } from 'common/helpers/loactionMatchingToObject';
import { blobToBase64 } from 'common/helpers/utils';
import Box from '@mui/material/Box';

class RunReportFormController extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  render() {
    return (
      <Box className="RunReportsFormController">
        <RunReportForm
          onSubmit={this.onRunReportFormSubmit}
          reports={this.props.reports}
        />
      </Box>
    );
  }

  async onRunReportFormSubmit(data) {
    if (!data.reportCanName) {
      enqueueSnackbar('Please select a report.', { variant: 'error' });
      return null;
    }

    const copyData = { ...data };

    const {
      locationGrouping,
      locations_destination,
      locations_origin,
      upload_radio_value,
      files,
      trackingNumbers,
      invoiceNumbers,
    } = copyData;
    let isAnyLoactionDestinationEmpty, isAnyLoactionOriginEmpty;
    if (!locationGrouping || locationGrouping === 'CITY') {
      isAnyLoactionDestinationEmpty =
        locations_destination &&
        locations_destination.length > 0 &&
        locations_destination.some(
          (item) => item.city === '' || item.country === '' || item.state === ''
        );
      isAnyLoactionOriginEmpty =
        locations_origin &&
        locations_origin.length > 0 &&
        locations_origin.some(
          (item) => item.city === '' || item.country === '' || item.state === ''
        );
    }
    if (locationGrouping === 'STATE') {
      isAnyLoactionDestinationEmpty =
        locations_destination &&
        locations_destination.length > 0 &&
        locations_destination.some((item) => item.country === '' || item.state === '');
      isAnyLoactionOriginEmpty =
        locations_origin &&
        locations_origin.length > 0 &&
        locations_origin.some((item) => item.country === '' || item.state === '');
    }
    if (locationGrouping === 'COUNTRY') {
      isAnyLoactionDestinationEmpty =
        locations_destination &&
        locations_destination.length > 0 &&
        locations_destination.some((item) => item.country === '');
      isAnyLoactionOriginEmpty =
        locations_origin &&
        locations_origin.length > 0 &&
        locations_origin.some((item) => item.country === '');
    }
    if (isAnyLoactionDestinationEmpty || isAnyLoactionOriginEmpty) {
      enqueueSnackbar('Please select all locations', { variant: 'error' });
      return null;
    }

    if (upload_radio_value) {
      if (upload_radio_value === 'file') {
        const fileInBuffer = await blobToBase64(files[0]);
        copyData['files[]'] = fileInBuffer;
      } else {
        const toFormatNumbersFromInput = (numbers) => {
          return numbers.split('\n').filter((line) => line.trim() !== '');
        };

        if (trackingNumbers) {
          copyData['tracking_numbers'] = toFormatNumbersFromInput(trackingNumbers);
        }
        if (invoiceNumbers) {
          copyData['invoice_numbers'] = toFormatNumbersFromInput(invoiceNumbers);
        }
      }
    }

    const location_filtering = {
      locations: {
        destination: {},
        origin: {},
      },
    };

    if (
      locations_destination &&
      locations_destination.length > 0 &&
      locations_destination[0].country
    ) {
      location_filtering.locations.destination =
        loactionMatchingToObject(locations_destination);
    }

    if (locations_origin && locations_origin.length > 0 && locations_origin[0].country) {
      location_filtering.locations.origin = loactionMatchingToObject(locations_origin);
    }

    delete copyData.locations_destination;
    delete copyData.locations_origin;
    delete copyData.upload_radio_value;
    delete copyData.files;
    delete copyData.trackingNumbers;
    delete copyData.invoiceNumbers;

    const newData = {
      ...copyData,
      location_filtering: JSON.stringify(location_filtering),
    };

    return this.props.dispatch(
      submitReport(
        newData,
        // This component is a little weird in that once we finish submitting
        // the report run, we need to continuously poll the server until we
        // get confirmation that the report is done running.
        (response) => {
          this.pollReportStatus(response.uuid);
          this.props.dispatch(actions.fetchReportRuns());
          enqueueSnackbar(
            'Your report has been successfully submitted, and will ' +
              'automatically begin downloading when it finishes running.',
            { variant: 'success' }
          );
        },
        (response) => {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      )
    );
  }

  /**
   * Continuously checks the status of the given report.
   *
   * Executes the given callback once the report finishes processing.
   */
  pollReportStatus(reportRunUuid) {
    return ajax.getJSON(
      '/api/report-runs/' + reportRunUuid,
      (response) => {
        if (response.status !== 'COMPLETED') {
          setTimeout(() => {
            this.pollReportStatus(reportRunUuid);
          }, 2500);
        } else {
          this.handleReportCompleted(response);
        }
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  }

  /**
   * Downloads the given report.
   */
  handleReportCompleted(reportRun) {
    this.props.dispatch(actions.fetchReportRuns());

    // If the report finished with an error, then trigger a download and show
    // the download alert.
    if (!reportRun.error) {
      enqueueSnackbar(
        'Success! Your "' +
          reportRun.reportDispName +
          '" report will ' +
          'begin downloading shortly.',
        { variant: 'success' }
      );
      utils.triggerDownload(reportRun.downloadPath);
      return;
    }

    if (reportRun.error === 'LARGE_REPORT_IN_PROCESS') {
      enqueueSnackbar(
        `${reportRun.reportDispName} report is quite large and will take longer than usual to process. Please check back later for its status.`,
        { variant: 'warning' }
      );
      return;
    }

    if (reportRun.error === 'LARGE_REPORT_FAILED') {
      enqueueSnackbar(
        'Unfortunately there was an error running your large"' +
          reportRun.reportDispName +
          '" report. Please check the report status ' +
          'for more details.',
        { variant: 'error' }
      );
      return;
    }

    enqueueSnackbar(
      'Unfortunately there was an error running your "' +
        reportRun.reportDispName +
        '" report. Please check the report status ' +
        'for more details.',
      { variant: 'error' }
    );
  }
}

/**
 * Renders an error message container if an error exists.
 */
const renderErrorMessageCont = (errorMessage) => {
  if (!errorMessage) {
    return null;
  }

  return (
    <div className="alert alert-danger" role="alert">
      <strong>Error!</strong> {errorMessage}
    </div>
  );
};

/**
 * Thunk for submitting the shipper report with the given data.
 */
const submitReport = (data, successCallback, errorCallback) => {
  return (dispatch) => {
    return ajax.postJSON(
      '/api/report-runs',
      data,
      (response) => {
        if (successCallback) {
          successCallback(response);
        }
      },
      (response) => {
        if (errorCallback) {
          errorCallback(response);
        }
      }
    );
  };
};

export default connect()(RunReportFormController);
