import React from 'react';

/**
 * Displays an error message.
 */
const ErrorContainer = (props) => {
  return (
    <div className="ErrorContainer alert alert-danger">
      <strong>Well that's embarrassing...</strong> It looks like there was an error
      fetching this data. Please try again later.
    </div>
  );
};

export default ErrorContainer;
