import { createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { getJSON, postFormData } from 'common/helpers/ajax';

const carriersNetRatesSlice = createSlice({
  name: 'carriersNetRates',
  initialState: {
    loading: false,
    error: null,
    carriersList: null,
    serviceTypeList: null,
    netRatesList: null,
    fetchingSendingData: false,
  },
  reducers: {
    getNetRatesCarriersListStart: (state) => {
      state.loading = true;
    },
    getNetRatesCarriersListSuccess: (state, { payload }) => {
      state.loading = false;
      state.carriersList = payload;
    },
    getNetRatesCarriersListFailure: (state) => {
      state.loading = false;
    },
    setServiceTypeList: (state, { payload }) => {
      state.serviceTypeList = payload;
    },
    addNetRate: (state, { payload }) => {
      state.netRatesList = [...state.netRatesList, payload];
    },
    setNetRate: (state, { payload }) => {
      state.netRatesList = payload;
    },

    setNeteRateFailure: (state, { payload }) => {
      state.netRatesList = [];
    },
    setFetchingSendingData: (state, { payload }) => {
      state.fetchingSendingData = payload;
    },
  },
});

export const {
  getNetRatesCarriersListStart,
  getNetRatesCarriersListSuccess,
  getNetRatesCarriersListFailure,
  setServiceTypeList,
} = carriersNetRatesSlice.actions;

export const getNetRatesCarriersList = () => {
  return async (dispatch, getState) => {
    dispatch(getNetRatesCarriersListStart());

    await getJSON(
      '/api/net-rates-analysis/carriers',
      null,
      (response) => {
        if (response && Object.keys(response).length > 0) {
          const arraysOfValues = Object.entries(response);
          dispatch(getNetRatesCarriersListSuccess(arraysOfValues));
        }
      },
      (response) => {
        dispatch(getNetRatesCarriersListFailure());
        enqueueSnackbar(response.message, { variant: 'error' });
      },
      false
    );
  };
};

export const getNetRatesServiceTypeList = (carrier) => {
  return async (dispatch) => {
    dispatch(carriersNetRatesSlice.actions.setServiceTypeList([]));
    await getJSON(
      `/api/net-rates-analysis/services-types`,
      { carrier },
      (response) => {
        if (response && Object.keys(response).length > 0) {
          const arraysOfValues = Object.entries(response);
          dispatch(carriersNetRatesSlice.actions.setServiceTypeList(arraysOfValues));
        }
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      },
      false
    );
  };
};

export const addNetRate = (formData) => {
  return async (dispatch) => {
    dispatch(carriersNetRatesSlice.actions.setFetchingSendingData(true));
    await postFormData(
      '/api/net-rates-analysis/shipper-net-rate',
      formData,
      (response) => {
        dispatch(carriersNetRatesSlice.actions.addNetRate(response));
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      },
      false
    ).then(() => dispatch(carriersNetRatesSlice.actions.setFetchingSendingData(false)));
  };
};

export const getCarrierNetRates = () => {
  return async (dispatch) => {
    await getJSON(
      `/api/net-rates-analysis/carrier-net-rate`,
      null,
      (response) => {
        if (response) {
          dispatch(carriersNetRatesSlice.actions.setNetRate(response));
        }
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      },
      false
    );
  };
};

export const getCarrierNetRate = (carrier_net_rate_id, service_type, name) => {
  return async (dispatch) => {
    await getJSON(
      `/api/net-rates-analysis/download-carrier-net-rate`,
      { carrier_net_rate_id },
      (response) => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = url;
        const filename = name ? `${name} (${service_type}).csv` : `${service_type}.csv`;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      },
      false,
      'blob'
    );
  };
};

export const carriersNetRatesReducer = carriersNetRatesSlice.reducer;
