'use strict';

import autobind from 'react-autobind';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { FormGroup, FormLabel } from '@mui/material';
import isAfterDay from 'react-dates/lib/utils/isAfterDay';

import * as actions from '../store/actions';
import Button from 'common/components/Button';
import HelpIcon from 'common/components/HelpIcon';
import ReduxFormSelect from 'common/components/ReduxFormSelect';
import ReduxFormDateRangePicker from 'common/components/ReduxFormDateRangePicker';
import ReduxFormToggleButtonGroup from 'common/components/ReduxFormToggleButtonGroup';
import ReduxFormVendorAccountNumbersSelect from 'common/components/ReduxFormVendorAccountNumbersSelect';

/**
 * Mapping from known date range names to the number of weeks the correspond to.
 */
export const DATE_RANGES = [
  {
    label: 'Last 4 weeks',
    value: 'LAST_4_WEEKS',
    numWeeks: 4,
  },
  {
    label: 'Last 8 weeks',
    value: 'LAST_8_WEEKS',
    numWeeks: 8,
  },
  {
    label: 'Last 12 weeks',
    value: 'LAST_12_WEEKS',
    numWeeks: 12,
  },
];

export const DESTINATION_TYPES = [
  {
    label: 'Domestic & international',
    value: 'BOTH',
  },
  {
    label: 'Domestic',
    value: 'DOMESTIC',
  },
  {
    label: 'International',
    value: 'INTERNATIONAL',
  },
];

class DashboardFilterSidebarForm extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    if (!this.props.filters.initialized) {
      this.props.dispatch(actions.initializeDashboardFilters());
    }

    if (
      !this.props.vendorAccountNumberMappings ||
      this.props.vendorAccountNumberMappings.length <= 0
    ) {
      this.props.dispatch(actions.fetchVendorAccountNumberMappings());
    }
  }

  /**
   * If the sidebar is about to open, then make sure we reset it prior to
   * opening.
   */
  componentWillUpdate(nextProps, nextState) {
    if (!this.props.isSidebarOpen && nextProps.isSidebarOpen) {
      this.props.reset();
    }
  }

  render() {
    let dateRangeOptions = DATE_RANGES.map((obj) => ({
      label: obj.label,
      value: obj.value,
    }));
    // Also add a `Custom` option.
    dateRangeOptions.push({ label: 'Custom', value: 'CUSTOM' });

    return (
      <form
        className="DashboardFilterSidebarForm defaultColumnFormStyle"
        onSubmit={this.props.handleSubmit(this.saveFilters)}
      >
        <FormGroup className="vendorAccountNumbersFormGroup">
          <FormLabel>Account numbers</FormLabel>
          <ReduxFormVendorAccountNumbersSelect
            name="vendorAccountNumbers"
            placeholder="Type account numbers"
            vendorAccountNumberMappings={this.props.vendorAccountNumberMappings}
            onOpen={this.handleOpenDropdown}
            onClose={this.handleCloseDropdown}
          />
        </FormGroup>

        <FormGroup className="destinationTypeFormGroup">
          <FormLabel>Destination type</FormLabel>
          <ReduxFormSelect
            name="destinationType"
            options={DESTINATION_TYPES}
            placeholder="Select a destination type"
            clearable={false}
            onOpen={this.handleOpenDropdown}
            onClose={this.handleCloseDropdown}
          />
        </FormGroup>

        <FormGroup className="DateRangeFormGroup">
          <FormLabel>
            Dates
            <HelpIcon>
              These date ranges default to skipping the last full week since it generally
              takes carriers at least a week to invoice for shipments. You&rsquo;re still
              able to view data from the most recent week by selecting a Custom date
              range, but please keep in mind that this data may be incomplete until all
              shipments that were picked up that week are invoiced.
            </HelpIcon>
          </FormLabel>
          <ReduxFormToggleButtonGroup
            name="dateRangeName"
            options={dateRangeOptions}
            onChange={this.handleDateRangeNameChange}
            orientation="vertical"
          />
          <ReduxFormDateRangePicker
            names={['startDate', 'endDate']}
            disabled={this.props.dateRangeNameValue !== 'CUSTOM'}
            numberOfMonths={1}
            isOutsideRange={(day) => isAfterDay(day, moment())}
            onFocusChange={this.handleOpenDropdown}
            onClose={this.handleCloseDropdown}
            startDateId="Dashboard start date"
            endDateId="Dashboard end date"
          />
        </FormGroup>
        <FormGroup>
          <Button
            type="submit"
            size="large"
            variant="contained"
            disabled={this.props.pristine || this.props.submitting}
          >
            Apply
          </Button>
        </FormGroup>
      </form>
    );
  }

  /**
   * Whenever there's a change to the date range name, we should also update
   * the start / end date ranges.
   */
  handleDateRangeNameChange(e, value) {
    let dates = getStartEndDatesFromName(value);
    if (dates.startDate && dates.endDate) {
      this.props.change('startDate', dates.startDate);
      this.props.change('endDate', dates.endDate);
    }
  }

  saveFilters(data) {
    // A date range is required, so if either the start or end date is missing,
    // then short-circuit immediately.
    if (!data.startDate || !data.endDate) {
      return;
    }
    this.props.dispatch({
      type: 'DASHBOARD__FILTERS__UPDATE',
      payload: data,
    });
  }

  /**
   * Lets the parent know that a dropdown was opened.
   */
  handleOpenDropdown() {
    this.props.onDropdownOpen && this.props.onDropdownOpen();
  }

  /**
   * Lets the parent know that a dropdown was closed.
   *
   * Note that this function uses a massive hack by delaying calling the
   * callback by 200ms. We need to do this because we want to prevent clicks on
   * the filter menu's background overlay from closing the filter menu while the
   * dropdown is open. In order to do so, we need to make sure the click event
   * on the overlay is processed *before* we notify the parent that the dropdown
   * has closed (hence delaying the callback).
   */
  handleCloseDropdown() {
    setTimeout(() => {
      this.props.onDropdownClose && this.props.onDropdownClose();
    }, 200);
  }
}

/**
 * Returns the appropriate start and end dates based on the given date range
 * name.
 */
export const getStartEndDatesFromName = (dateRangeName) => {
  let startDate = null,
    endDate = null;

  let dateRange = Array.find(DATE_RANGES, (obj) => obj.value === dateRangeName);
  if (dateRange) {
    endDate = moment().subtract(2, 'weeks').endOf('week').format('YYYY-MM-DD');
    startDate = moment()
      .subtract(dateRange.numWeeks, 'weeks')
      .startOf('week')
      .format('YYYY-MM-DD');
  }

  return { startDate, endDate };
};

const selector = formValueSelector('Dashboard.FilterSidebarForm');
const mapStateToProps = (state) => {
  return {
    initialValues: state.dashboard.filters,
    dateRangeNameValue: selector(state, 'dateRangeName'),

    filters: state.dashboard.filters,
    vendorAccountNumberMappings: state.vendorAccountNumberMappings,
  };
};

DashboardFilterSidebarForm = reduxForm({
  form: 'Dashboard.FilterSidebarForm',
  enableReinitialize: true,
})(DashboardFilterSidebarForm);

export default connect(mapStateToProps)(DashboardFilterSidebarForm);
