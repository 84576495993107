import React from 'react';
import Box from '@mui/material/Box';

const SecondHeader = React.forwardRef(({ children, secondHeaderClass = '' }, ref) => {
  return (
    <Box ref={ref} className={`SecondHeader ${secondHeaderClass}`}>
      {children}
    </Box>
  );
});

export default SecondHeader;
