'use strict';

import React from 'react';
import Box from '@mui/material/Box';

/**
 * An empty placeholder to render when we didn't find any shipments.
 */
const NoSearchResults = (props) => {
  return (
    <Box
      className="empty-placeholder_new_without-Hover"
      sx={{
        marginTop: '30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <strong className="text-bold">No shipments found.</strong>
      <div>Please double-check your tracking number and try again.</div>
    </Box>
  );
};

export default NoSearchResults;
