import React from 'react';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Icon from '../Icon';
import CircularProgress from '@mui/material/CircularProgress';
import { ic_error_outline } from 'react-icons-kit/md/ic_error_outline';
import { ic_done_all } from 'react-icons-kit/md/ic_done_all';
import { connect, useSelector } from 'react-redux';
import { useCheckPermissions } from '../../helpers/hooks/useCheckPermissions';
import Tooltip from '@mui/material/Tooltip';
import { ic_lock_outline } from 'react-icons-kit/md/ic_lock_outline';

function CloneProps(props) {
  const { children, ...other } = props;
  return children(other);
}

const TabsTitle = ({
  value,
  setValue,
  tabList,
  pages,
  pageName,
  filters,
  permissionPageKey = '',
}) => {
  const checkPermissions = useCheckPermissions();
  const shipper = useSelector((state) => state.user)?.shipper;

  const handleChange = (event, newLabel) => {
    setValue(tabList.find((tab) => tab.label === newLabel));
  };

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: 'transparent !important',
        maxWidth: '100%',
        overflow: 'hidden',
        width: 'fit-content',
        borderRadius: 0,
        blockSize: 'fit-content',
        margin: 0,
        height: 'auto',
        '& .MuiTab-root': {
          fontSize: '1.1rem',
          letterSpacing: '-1px',
          fontWeight: 500,
          minHeight: 'auto',
          textWrap: 'nowrap',
        },
        '& .MuiTabs-root': {
          minHeight: 'auto !important',
          '.MuiTabs-flexContainer': {
            gap: '0px',
          },
        },
        '& .Mui-selected': {
          background: '#E0EFFF !important',
          color: '#1175DB !important',
        },
        '& .MuiTabs-indicator': {
          display: 'none',
        },
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs"
      >
        {tabList.map((tab) => {
          const contentId = `${pageName}_${tab?.path || ''}`;
          const isCanHasIcon = value !== tab.label;
          const { isLoading, isError, isSuccess, filterSetId } = pages[contentId] || {};

          let IconComponent = null;
          if (isCanHasIcon && filters.filterSetId === filterSetId) {
            if (isLoading) {
              IconComponent = <CircularProgress size={14} sx={{ color: '#0B2C4D' }} />;
            } else if (isError) {
              IconComponent = <Icon size={14} icon={ic_error_outline} />;
            } else if (isSuccess) {
              IconComponent = <Icon size={14} icon={ic_done_all} />;
            }
          }

          const { isAllowTab, needSubscription } = checkPermissions([
            permissionPageKey,
            tab.path,
          ]);

          if (!isAllowTab) {
            IconComponent = <Icon size={14} icon={ic_lock_outline} />;
          }

          // global account hasn't shipper
          const className = shipper?.subscription === 'DEMO' ? needSubscription : '';

          const disabled = !!(tab.disabled || (permissionPageKey && !isAllowTab));

          return (
            <CloneProps
              icon={IconComponent}
              iconPosition="end"
              key={`CustomTitle_Tabs_${tab.label}`}
              value={tab.label}
              label={tab.label}
              disabled={disabled}
              className={className}
              sx={{
                borderRadius: '16px 16px 0px 0px',
                background: 'transparent',

                padding: !IconComponent ? '12px 42px 12px 42px' : '12px 31px 12px 31px',
                '&:hover': {
                  color: '#061A2E',
                },
                color: '#0B2C4D',
                fontFamily: 'Outfit',
                textEdge: 'cap',
                fontSize: '20px',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0em',
              }}
            >
              {(tabProps) => (
                <Tooltip title={!isAllowTab && 'Contact us to upgrade plan'}>
                  <div>
                    <Tab {...tabProps} />
                  </div>
                </Tooltip>
              )}
            </CloneProps>
          );
        })}
      </Tabs>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  return {
    pages: state.dashboard.pages,
    filters: state.dashboard.filters,
  };
};

export default connect(mapStateToProps)(TabsTitle);
