'use strict';

import './Settings.scss';

import React from 'react';
import { connect } from 'react-redux';

import AddPaymentCardModal from './AddPaymentCardModal';
import PaymentMethods from './PaymentMethods';
import ShipperInvoices from './ShipperInvoices';
import ShipperOptimizationInvoices from './ShipperOptimizationInvoices';

const Billing = (props) => {
  let paymentMethodsEl = null;
  if (props.partner.isShipperBillingEnabled && !props.shipper.isVendorPaymentsEnabled) {
    paymentMethodsEl = <PaymentMethods />;
  }
  return (
    <>
      {paymentMethodsEl}
      <ShipperInvoices />
      {props.shipper.isOptimizationEnabled ? <ShipperOptimizationInvoices /> : null}
      <AddPaymentCardModal show={props.settingsBilling.addPaymentCardModal.isVisible} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    partner: state.partner,
    shipper: state.shipper,
    settingsBilling: state.settingsBilling,
  };
};

export default connect(mapStateToProps)(Billing);
