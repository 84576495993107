import React, { useState } from 'react';
import { useRows } from './hooks/useRows';
import { useColumns } from './hooks/useColumns';
import DataGridWrapper from '../table/DataGridWrapper';
import DataGridCustom from '../table/DataGridCustom';

import './ServicesSummaryContent.scss';

const ServicesSummaryContent = ({ carrierServiceSummaryStats, customPudding }) => {
  const [sortModel, setSortModel] = useState([
    {
      field: 'totalCost',
      sort: 'desc',
    },
  ]);

  const rows = useRows(carrierServiceSummaryStats);
  const columns = useColumns();

  return (
    <DataGridWrapper customPudding={customPudding}>
      <DataGridCustom
        rows={rows}
        columns={columns}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        slotProps={{ toolbar: { csvOptions: { fileName: 'Services Summary' } } }}
        settings={{
          rowHeight: 55,
          // getEstimatedRowHeight: () => 52,
          //https://github.com/mui/mui-x/issues/417#issuecomment-1183970233
          className: 'autoHeightWithDefaultHeight',
          getRowHeight: () => null,
        }}
      />
    </DataGridWrapper>
  );
};

export default ServicesSummaryContent;
