'use strict';

import './Account.scss';

import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@mui/material';

import AccountSettingsForm from './AccountSettingsForm';
import ChangePasswordForm from './ChangePasswordForm';
import EmailPreferencesForm from './EmailPreferencesForm';
import Stack from '@mui/material/Stack';
import SignOutIcon from 'common/components/icon-components/SignOutIcon';
import Box from '@mui/material/Box';

class Account extends React.Component {
  render() {
    return (
      <Box>
        <section
          className="sign-out"
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <a href="/sign-out">
            <Button variant="contained" color="error" startIcon={<SignOutIcon />}>
              Sign out
            </Button>
          </a>
        </section>

        <Stack spacing={4}>
          <section className="AccountSettings">
            <h3 className="heading">Account settings</h3>
            <AccountSettingsForm />
          </section>

          <section className="ChangePassword">
            <h3 className="heading">Change password</h3>
            <ChangePasswordForm />
          </section>

          <section className="EmailPreferences">
            <h3 className="heading">Email preferences</h3>
            <EmailPreferencesForm />
          </section>
        </Stack>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    shipper: state.shipper,
  };
};

export default connect(mapStateToProps)(Account);
