import React, { useMemo } from 'react';
import { gridDateUtils } from 'common/components/table/gridDateUtils';
import * as utils from 'common/helpers/utils';
import Button from 'common/components/Button';
import { filePdf } from 'react-icons-kit/oct/filePdf';
import { file } from 'react-icons-kit/oct/file';
import Icon from 'common/components/Icon';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { enqueueSnackbar } from 'notistack';
import Box from '@mui/material/Box';

const InvoiceNumberFormatter = ({ cell, row }) => {
  const errorCallback = () => {
    enqueueSnackbar('File not found', { variant: 'error' });
  };

  return (
    <Box sx={{ minWidth: '95px', textAlign: 'left' }}>
      {cell}
      <div className="downloadLinks">
        <Tooltip title="Download PDF">
          <IconButton
            onClick={() =>
              utils.triggerDownload(`/shipper-invoices/${cell}.pdf`, errorCallback)
            }
            sx={{ padding: '0 !important' }}
          >
            <Icon icon={filePdf} size={18} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Download CSV">
          <IconButton
            onClick={() =>
              utils.triggerDownload(`/shipper-invoices/${cell}.csv`, errorCallback)
            }
            sx={{ padding: '0 !important' }}
          >
            <Icon icon={file} size={18} />
          </IconButton>
        </Tooltip>
      </div>
    </Box>
  );
};

const StatusFormatter = ({ cell, row, payShipperInvoice, isWorkingList }) => {
  var status;
  if (cell === 'PAYMENT_NOT_NEEDED') {
    status = 'No payment needed';
  } else if (cell === 'CANCELLED') {
    status = 'Cancelled';
  } else if (cell === 'PAID') {
    status = 'Paid ' + utils.Fmt.date(row.paidDt);
  } else if (window.G.partner.isShipperBillingEnabled) {
    var dueDateStr = utils.Fmt.date(row.paymentDueDate);
    status = (
      <div className="payNowCont">
        <Button
          type="submit"
          variant="contained"
          loadingText="Please wait..."
          isLoading={isWorkingList.includes(row.invoiceNumber)}
          onClick={(e) => payShipperInvoice(row)}
        >
          Pay now
        </Button>
        <div className="help-block">Due {dueDateStr}</div>
      </div>
    );
  } else {
    status = 'Unpaid';
  }

  return <div>{status}</div>;
};

export const useVendorAccountNumberMappingsColumns = (payShipperInvoice, isWorkingList) =>
  useMemo(
    () => [
      {
        field: 'invoiceDate',
        headerName: 'Invoice date',
        width: 120,
        headerAlign: 'center',
        align: 'center',
        sortComparator: gridDateUtils,
        renderCell: (params) => (params.value ? utils.Fmt.date(params.value) : '\u2014'),
      },
      {
        field: 'invoiceNumber',
        headerName: 'Invoice number',
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <InvoiceNumberFormatter cell={params.value} row={params.row.currentValue} />
        ),
      },
      {
        field: 'amountRecovered',
        headerName: 'Refunds recovered',
        flex: 1,
        minWidth: 150,
        headerAlign: 'left',
        align: 'left',
        renderCell: (params) => utils.Fmt.dollars(params.value / 100),
      },
      {
        field: 'totalAmountDue',
        headerName: 'Amount due',
        flex: 1,
        minWidth: 150,
        headerAlign: 'left',
        align: 'left',
        renderCell: (params) => utils.Fmt.dollars(params.value / 100),
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 150,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <StatusFormatter
            cell={params.value}
            row={params.row.currentValue}
            payShipperInvoice={payShipperInvoice}
            isWorkingList={isWorkingList}
          />
        ),
      },
    ],
    [isWorkingList]
  );
