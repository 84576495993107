import { useMemo } from 'react';

export const useShippingLocationTableRows = (shippingLocations) =>
  useMemo(
    () =>
      shippingLocations?.reduce((prevState, currentValue) => {
        const currentRow = {
          id: currentValue.uuid,
          name: currentValue.name,
          formattedStr: currentValue.formattedStr,
          actions: currentValue,
          currentValue,
        };
        prevState.push(currentRow);
        return prevState;
      }, []),
    [shippingLocations]
  );
