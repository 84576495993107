'use strict';

import './ShipmentDetails.scss';

import React from 'react';
import _flatten from 'lodash/flatten';
import _groupBy from 'lodash/groupBy';
import { connect, useSelector } from 'react-redux';
import * as constants from 'common/helpers/constants';
import * as utils from 'common/helpers/utils';
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';

// We use this a lot in this file so let's just make a shortcut for it.
const mdashIfNull = utils.Fmt.mdashIfNull;

const ShipmentDetails = (props) => {
  if (!props.shipment) {
    return null;
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{ background: 'inherit' }}
      className="ShipmentDetails"
    >
      <Grid xs={6}>
        <OverviewTable shipment={props.shipment} />
      </Grid>

      <Grid xs={6}>
        <ParcelDetailsTable shipment={props.shipment} />
      </Grid>

      <Grid xs={6}>
        <AddressTable
          heading="Origin address"
          name={props.shipment.originName}
          company={props.shipment.originCompany}
          street1={props.shipment.originStreet1}
          street2={props.shipment.originStreet2}
          city={props.shipment.originCity}
          state={props.shipment.originState}
          postalCode={props.shipment.originPostalCode}
          countryCode={props.shipment.originCountryCode}
        />
      </Grid>
      <Grid xs={6}>
        <AddressTable
          heading="Destination address"
          name={props.shipment.destinationName}
          company={props.shipment.destinationCompany}
          street1={props.shipment.destinationStreet1}
          street2={props.shipment.destinationStreet2}
          city={props.shipment.destinationCity}
          state={props.shipment.destinationState}
          postalCode={props.shipment.destinationPostalCode}
          countryCode={props.shipment.destinationCountryCode}
        />
      </Grid>

      <Grid xs={12}>
        <BillingEntriesTable shipment={props.shipment} />
      </Grid>

      <Grid xs={12}>
        <ShipmentEventsTable shipment={props.shipment} />
      </Grid>
    </Grid>
  );
};

const OverviewTable = (props) => {
  const userInfo = useSelector((state) => state.user);

  return (
    <Table bordered={true} className={'detailsTable_table'}>
      <thead className={'detailsTable_thead'}>
        <tr>
          <td className="heading" colSpan={2}>
            Overview
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={'detailsTable_row-title'} style={{ width: '180px' }}>
            Tracking number
          </td>
          <td className={'detailsTable_row-text'}>{props.shipment.trackingNumber}</td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Status</td>
          <td>{mdashIfNull(constants.getDispValue(props.shipment.status))}</td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Carrier</td>
          <td className={'detailsTable_row-text'}>
            {mdashIfNull(constants.getDispValue(props.shipment.carrier))}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Vendor</td>
          <td className={'detailsTable_row-text'}>
            {mdashIfNull(constants.getDispValue(props.shipment.vendor))}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Account number</td>
          <td className={'detailsTable_row-text'}>
            {mdashIfNull(props.shipment.vendorAccountNumber)}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Service type</td>
          <td className={'detailsTable_row-text'}>
            {mdashIfNull(props.shipment.dispServiceType)}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Total cost</td>
          <td className={'detailsTable_row-text'}>
            {mdashIfNull(utils.Fmt.dollars(props.shipment.totalCost / 100))}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Net cost</td>
          <td className={'detailsTable_row-text'}>
            {mdashIfNull(utils.Fmt.dollars(props.shipment.netCost / 100))}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Label creation date</td>

          <td className={'detailsTable_row-text'}>
            {mdashIfNull(utils.Fmt.date(props.shipment.labelCreatedDate))}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Pickup date</td>
          <td className={'detailsTable_row-text'}>
            {mdashIfNull(utils.Fmt.date(props.shipment.carrierPickedUpDate))}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Delivery date</td>
          <td className={'detailsTable_row-text'}>
            {mdashIfNull(utils.Fmt.date(props.shipment.deliveredDate))}
          </td>
        </tr>
        {!!props.shipment.shipper && !!userInfo.isAdmin && (
          <>
            <tr>
              <td className={'detailsTable_row-title'}>Shipper</td>
              <td className={'detailsTable_row-text'}>
                {props.shipment.shipper.companyName} (ID {props.shipment.shipper.id})
              </td>
            </tr>

            <tr>
              <td className={'detailsTable_row-title'}>Shipper status</td>
              <td className={'detailsTable_row-text'}>
                {props.shipment.shipper.isActive ? 'Active' : 'Inactive'}
              </td>
            </tr>
          </>
        )}
      </tbody>
    </Table>
  );
};

const ParcelDetailsTable = (props) => {
  const isGlobal = useSelector((state) => state.user)._isGlobal;

  return (
    <Table bordered={true} className={'detailsTable_table'}>
      <thead className={'detailsTable_thead'}>
        <tr>
          <td className="heading" colSpan={2}>
            Parcel details
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={'detailsTable_row-title'} style={{ width: '180px' }}>
            Pricing zone
          </td>
          <td className={'detailsTable_row-text'}>
            {mdashIfNull(props.shipment.pricingZone)}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Scale weight</td>
          <td className={'detailsTable_row-text'}>
            {props.shipment.scaleWeight
              ? utils.Fmt.float1(props.shipment.scaleWeight) + ' lb'
              : constants.MDASH}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Rated weight</td>
          <td className={'detailsTable_row-text'}>
            {props.shipment.ratedWeight
              ? utils.Fmt.float1(props.shipment.ratedWeight) + ' lb'
              : constants.MDASH}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Num pieces</td>
          <td className={'detailsTable_row-text'}>
            {mdashIfNull(props.shipment.numPieces)}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Length</td>
          <td className={'detailsTable_row-text'}>
            {props.shipment.dimLength
              ? utils.Fmt.float1(props.shipment.dimLength) + ' in'
              : constants.MDASH}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Width</td>
          <td className={'detailsTable_row-text'}>
            {props.shipment.dimWidth
              ? utils.Fmt.float1(props.shipment.dimWidth) + ' in'
              : constants.MDASH}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Height</td>
          <td className={'detailsTable_row-text'}>
            {props.shipment.dimHeight
              ? utils.Fmt.float1(props.shipment.dimHeight) + ' in'
              : constants.MDASH}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Dim divisor</td>
          <td className={'detailsTable_row-text'}>
            {mdashIfNull(props.shipment.dimDivisor)}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Reference 1</td>
          <td className={'detailsTable_row-text'}>
            {mdashIfNull(props.shipment.reference1)}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Reference 2</td>
          <td className={'detailsTable_row-text'}>
            {mdashIfNull(props.shipment.reference2)}
          </td>
        </tr>
        <tr>
          <td className={'detailsTable_row-title'}>Reference 3</td>
          <td className={'detailsTable_row-text'}>
            {mdashIfNull(props.shipment.reference3)}
          </td>
        </tr>
        {isGlobal && (
          <>
            <tr>
              <td className={'detailsTable_row-title'}>Created</td>
              <td className={'detailsTable_row-text'}>
                {mdashIfNull(utils.Fmt.dateTime(props.shipment.createdDt))}
              </td>
            </tr>
            <tr>
              <td className={'detailsTable_row-title'}>Updated</td>
              <td className={'detailsTable_row-text'}>
                {mdashIfNull(utils.Fmt.dateTime(props.shipment.updatedDt))}
              </td>
            </tr>
          </>
        )}
      </tbody>
    </Table>
  );
};

/**
 * Returns a table for showing an address.
 */
const AddressTable = (props) => {
  const addressLines = utils.Fmt.addressLines({
    name: props.name,
    company: props.company,
    street1: props.street1,
    street2: props.street2,
    city: props.city,
    state: props.state,
    postalCode: props.postalCode,
    countryCode: props.countryCode,
  });
  const addressComponents = addressLines.map((line, i) => <div key={i}>{line}</div>);

  return (
    <Table bordered={true} className={'detailsTable_table'}>
      <thead className={'detailsTable_thead'}>
        <tr>
          <td className="heading">{props.heading}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{addressComponents}</td>
        </tr>
      </tbody>
    </Table>
  );
};

/**
 * Renders the table that shows the list of shipment events.
 */
const ShipmentEventsTable = (props) => {
  const eventComponents = props.shipment.events
    .map((event, i) => {
      let description = event.description;
      if (event.exceptionDescription) {
        description += ` (${event.exceptionDescription})`;
      }

      const addressLines = utils.Fmt.addressLines({
        city: event.city,
        state: event.state,
        postalCode: event.postalCode,
        countryCode: event.countryCode,
      });

      return (
        <tr key={i}>
          <td style={{ width: '180px' }}>
            {event.occurredDtFormatted}
          </td>
          <td>
            {description}
            <div className="text-muted">{addressLines.join(', ')}</div>
          </td>
        </tr>
      );
    })
    .reverse();

  return (
    <Table bordered={true} className={'detailsTable_table'}>
      <thead className={'detailsTable_thead'}>
        <tr>
          <td className="heading" colSpan={2}>
            Events
          </td>
        </tr>
      </thead>
      <tbody>
        {eventComponents.length > 0 ? (
          eventComponents
        ) : (
          <tr>
            <td colSpan={2} className="emptyPlaceholder">
              No events have been recorded on this shipment yet.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

/**
 * Renders the table that shows the list of billing entries for this shipment.
 */
const BillingEntriesTable = (props) => {
  // Create a temp data structure to make it easy to look up vendor invoices by
  // hid.
  const vendorInvoicesByHid = {};
  props.shipment.vendorInvoices.forEach((vi) => {
    vendorInvoicesByHid[vi.hid] = vi;
  });

  // First, we need to create the set of groupings for billing entries. The
  // group key should concatenate the issued date w/ the invoice hid. If an
  // invoice hid doesn't exist, use a Gilda character (~), which comes *after*
  // all alphabetic and numeric characters lexicographically. This lets us sort
  // the group keys lexicographically and get the ordering we want, which is
  // chronologically by issue date. Additionally, among billing entries issued
  // on the same day, billing entries w/ an invoice should come before billing
  // entries without and invoice.
  const groupedBillingEntries = _groupBy(
    props.shipment.billingEntries,
    (be) => `${be.issuedDate}_${be.vendorInvoiceHid || '~'}`
  );

  // For each group, generate a list of `<tr>` rows that can be inserted into
  // the table.
  let billingEntryRows = Object.keys(groupedBillingEntries)
    .sort()
    .map((groupKey, i) => {
      const [issuedDate, vendorInvoiceHid] = groupKey.split('_'),
        billingEntries = groupedBillingEntries[groupKey],
        vendorInvoice = vendorInvoicesByHid[vendorInvoiceHid];

      // There should be only one cell for the issued date and invoice number
      // per group. Initialize this separately for cleanliness.
      const issuedDateCell = (
        <td rowSpan={billingEntries.length}>
          <div>{utils.Fmt.date(issuedDate)}</div>
          {vendorInvoice && (
            <div className={'billingEntryRows_chargesInvoice'}>
              Invoice{' '}
              <span className={'billingEntryRows_chargesInvoice_number'}>
                {vendorInvoice.invoiceNumber}
              </span>
            </div>
          )}
        </td>
      );

      // Each group will have a single `<tr>` row per billing entry.
      return billingEntries.map((be, j) => (
        <tr key={groupKey + '-' + j}>
          {j === 0 && issuedDateCell}
          <td className={'detailsTable_row-title'}>
            {be.vendorDescription} ({utils.Fmt.toTitleCase(be.chargeType)}){' '}
            {be.currencyIso3}
          </td>
          <td className={'detailsTable_row-title'} style={{ fontWeight: 400 }}>
            {utils.Fmt.dollars(be.amount / 100)}
          </td>
        </tr>
      ));
    });

  billingEntryRows = _flatten(billingEntryRows);

  if (billingEntryRows.length > 0) {
    if (props.shipment.totalCost / 100 - props.shipment.netCost / 100 > 0) {
      billingEntryRows.push(
        <tr key="totalCost">
          <td rowSpan={3}></td>
          <td className={'billingEntryRows_title'}>Total cost</td>
          <td className={'billingEntryRows_value'}>
            {utils.Fmt.dollars(props.shipment.totalCost / 100)}
          </td>
        </tr>,
        <tr key="refund">
          <td className={'billingEntryRows_title'}>Refund amount</td>
          <td className={'billingEntryRows_value'}>
            {utils.Fmt.dollars(
              props.shipment.totalCost / 100 - props.shipment.netCost / 100
            )}
          </td>
        </tr>,
        <tr key="netCost">
          <td className={'billingEntryRows_title'}>Net cost (after audit refunds)</td>
          <td className={'billingEntryRows_value'}>
            {utils.Fmt.dollars(props.shipment.netCost / 100)}
          </td>
        </tr>
      );
    } else {
      billingEntryRows.push(
        <tr key="totalCost">
          <td rowSpan={3}></td>
          <td className={'billingEntryRows_title'}>Total cost</td>
          <td className={'billingEntryRows_value'}>
            {utils.Fmt.dollars(props.shipment.totalCost / 100)}
          </td>
        </tr>,
        <tr key="netCost">
          <td className={'billingEntryRows_title'}>Net cost (after audit refunds)</td>
          <td className={'billingEntryRows_value'}>
            {utils.Fmt.dollars(props.shipment.netCost / 100)}
          </td>
        </tr>
      );
    }
  }

  return (
    <Table bordered={true} className={'detailsTable_table'}>
      <thead className={'detailsTable_thead'}>
        <tr>
          <td className="heading" colSpan={3}>
            Charges
          </td>
        </tr>
      </thead>
      <tbody>
        {props.shipment.billingEntries.length > 0 ? (
          billingEntryRows
        ) : (
          <tr>
            <td colSpan={3} className="emptyPlaceholder">
              No charges have been recorded on this shipment yet.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default connect()(ShipmentDetails);
