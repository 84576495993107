'use strict';

import React from 'react';

import DashboardFilterSidebarForm from 'cloud/dashboard/DashboardFilterSidebarForm';

import createFilterSidebar from 'common/components/FilterSidebar/FilterSidebar';
import { matchPath } from 'react-router-dom';

const DashboardFilterSidebar = createFilterSidebar('Dashboard.FilterSidebar');

export default (props) => {
  const match = matchPath(location.pathname, {
    path: '/dashboard',
    exact: false,
  });

  if (match) {
    return <DashboardFilterSidebar Component={DashboardFilterSidebarForm} />;
  } else {
    return null;
  }
};
