export const legendList = [
  {
    value: 0,
    label: 'Without legend',
    color: 'warning',
  },
  {
    value: 16,
    label: 'Medium size',
    color: 'primary',
  },
  {
    value: 32,
    label: 'Large size',
    color: 'primary',
  },
];

export const titleMarks = [
  {
    value: 8,
    label: '8',
  },
  {
    value: 24,
    label: '24',
  },
  {
    value: 36,
    label: '36',
  },
  {
    value: 48,
    label: '48',
  },
  {
    value: 64,
    label: '64',
  },
];

export const CHART_SIZE_HORIZONTAL = 'horizontal';
export const CHART_SIZE_SQUARE = 'square';
export const CHART_SIZE_VERTICAL = 'vertical';

export const chartSizeMap = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export const chartSizeList = [
  {
    x: 1280,
    y: 720,
    key: 'horizontal_small',
    type: CHART_SIZE_HORIZONTAL,
    size: chartSizeMap.small,
  },
  {
    x: 1920,
    y: 1080,
    key: 'horizontal_medium',
    type: CHART_SIZE_HORIZONTAL,
    size: chartSizeMap.medium,
  },
  {
    x: 3840,
    y: 2160,
    key: 'horizontal_large',
    type: CHART_SIZE_HORIZONTAL,
    size: chartSizeMap.large,
  },

  {
    x: 1280,
    y: 1280,
    key: 'square_small',
    type: CHART_SIZE_SQUARE,
    size: chartSizeMap.small,
  },
  {
    x: 1920,
    y: 1920,
    key: 'square_medium',
    type: CHART_SIZE_SQUARE,
    size: chartSizeMap.medium,
  },
  {
    x: 3840,
    y: 3840,
    key: 'square_large',
    type: CHART_SIZE_SQUARE,
    size: chartSizeMap.large,
  },

  {
    x: 720,
    y: 1280,
    key: 'vertical_small',
    type: CHART_SIZE_VERTICAL,
    size: chartSizeMap.small,
  },
  {
    x: 1080,
    y: 1920,
    key: 'vertical_medium',
    type: CHART_SIZE_VERTICAL,
    size: chartSizeMap.medium,
  },
  {
    x: 2160,
    y: 3840,
    key: 'vertical_large',
    type: CHART_SIZE_VERTICAL,
    size: chartSizeMap.large,
  },
];
