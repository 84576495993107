import { useMemo } from 'react';

export const useRows = (reportRuns) =>
  useMemo(
    () =>
      reportRuns?.reduce((prevState, currentValue) => {
        const currentRow = {
          id: currentValue.uuid,
          createdDt: currentValue.createdDt,
          reportDispName: currentValue.reportDispName,
          startDate: currentValue.startDate,
          endDate: currentValue.endDate,
          status: currentValue.status,
          currentValue: currentValue,
          // username: currentValue.username,
          // companyName: currentValue.companyName,
          // source: currentValue.source,
        };
        prevState.push(currentRow);
        return prevState;
      }, []),
    [reportRuns]
  );
