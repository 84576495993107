import React from 'react';
import classNames from 'classnames';

import Chart from './ChartViewModel';
import * as Constants from './Constants';
import {
  axisFontSizeChartPlugin,
  legendMarginBottom,
  PADDING_BUBBLE_CHART,
  recalculateBubbleRadiusPlugin,
} from './Constants';

const BubbleChart = (props) => {
  const { data, yAxisTooltipsFmt, colors, title } = props;
  if (!data) {
    return null;
  }

  let minValue = Infinity;
  let maxValue = -Infinity;
  for (let i = 0; i < data.datasets.length; i++) {
    minValue = Math.min(minValue, ...data.datasets[i].data);
    maxValue = Math.max(maxValue, ...data.datasets[i].data);
  }

  const mapRange = (x, inMin = minValue, inMax = maxValue, outMin = 2.5, outMax = 15) => {
    return ((x - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  };

  const bubbleChartData = {
    animation: {
      duration: 10,
    },

    datasets: data.datasets.map((dataset) => ({
      label: dataset.label,
      fill: false,
      lineTension: 0,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: Constants.BLUE_10[Constants.BLUE_10.length - 1],
      pointBackgroundColor: Constants.BLUE_10[Constants.BLUE_10.length - 8],
      pointBorderWidth: 1,
      pointHoverRadius: 2,
      pointHoverBackgroundColor: Constants.RED_10[Constants.RED_10.length - 8],
      pointHoverBorderColor: Constants.RED_10[Constants.RED_10.length - 1],
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: dataset.data.map((datasetData, index) => {
        const initRadius = mapRange(datasetData);
        return {
          x: data.labels[index],
          y: dataset.label,
          r: initRadius,
          initRadius,
          clearValue: datasetData,
        };
      }),
    })),
  };

  const bubble = {
    type: 'bubble',
    data: bubbleChartData,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: 'Weekly activity',
      },
      scales: {
        y: {
          type: 'category',
          labels: bubbleChartData.datasets.map((dataset) => dataset.label),
          border: {
            dash: Constants.GRID_DASH,
            display: true,
          },
          grid: {
            drawTicks: false,
            display: true,
          },
          ticks: {
            padding: PADDING_BUBBLE_CHART,
          },
        },
        x: {
          type: 'category',
          labels: data.labels,
          border: {
            dash: Constants.GRID_DASH,
            display: true,
          },
          grid: {
            drawTicks: false,
            display: true,
          },
          ticks: {
            padding: PADDING_BUBBLE_CHART,
          },
        },
      },
      plugins: {
        title: {
          display: false,
          padding: {
            right: 0,
            top: 0,
            bottom: 0,
            left: 0,
          },
        },
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: Constants.TOOLTIP_BACKGROUND_COLOR,
          callbacks: {
            enabled: false,
            title: (context) => {
              return context[0].raw.x;
            },
            label: (context) => {
              const value = yAxisTooltipsFmt
                ? yAxisTooltipsFmt(context.raw.clearValue)
                : context.raw.clearValue;
              return `${context.raw.y}: ${value}`;
            },
          },
        },
      },
    },
    plugins: [legendMarginBottom, recalculateBubbleRadiusPlugin, axisFontSizeChartPlugin],
  };

  return (
    <Chart
      title={title}
      chartConfig={bubble}
      className={classNames(props.className)}
      hideLegend={false}
      isHideDatasetsSelect={true}
      height={props.height}
      shipperSubscriptions={props.shipperSubscriptions}
    />
  );
};

export default BubbleChart;
