import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import HelpIcon from 'common/components/HelpIcon';
import DataGridCustom from '../table/DataGridCustom';
import humps from 'humps';
import { gridDateUtils } from '../table/gridDateUtils';
import './Chart.scss';
import { Divider } from '@mui/material';
import Icon from '../Icon';
import { ic_fullscreen } from 'react-icons-kit/md/ic_fullscreen';
import TooltipIcon from '../TooltipIcon';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DataGridWrapper from '../table/DataGridWrapper';
import { ic_keyboard_arrow_left } from 'react-icons-kit/md/ic_keyboard_arrow_left';
import { tableFormatMap, useClassSubscriptionsForCharts } from './Constants';

const Table = (props) => {
  const [isFullscreenOpen, setIsFullscreenOpen] = useState(false);
  const additionalClass = useClassSubscriptionsForCharts(props.shipperSubscriptions);

  const { chartData, tableFormats } = props.queryChartData;

  const sortedDataColumns = useMemo(() => {
    return tableFormats?.map((d) => d)?.sort((a, b) => a.index - b.index);
  }, [tableFormats]);

  // const [sortModel, setSortModel] = useState([
  //   {
  //     field: 'totalCost',
  //     sort: 'desc',
  //   },
  // ]);

  const rows = useMemo(() => {
    return chartData
      ?.map((d) => ({ ...d }))
      ?.map((dataRow, index) => {
        if (dataRow.id === null || dataRow.id === undefined) {
          dataRow.id = `Table_Chart_${props.title}_${index}`;
        }
        return dataRow;
      });
  }, [chartData]);

  const columns = useMemo(() => {
    return (
      sortedDataColumns?.map((tableFormat, index) => {
        return {
          field: humps.camelize(tableFormat.column),
          headerName: (
            tableFormat.column.charAt(0).toUpperCase() + tableFormat.column.slice(1)
          ).replaceAll(/_/g, ' '),
          flex: 1,
          minWidth: tableFormat.minWidth ?? 130,
          align: tableFormat.columnAlign ?? 'center',
          headerAlign: tableFormat.headerAlign ?? 'center',
          ...(tableFormat.format !== 'string'
            ? { renderCell: (params) => tableFormatMap[tableFormat.format](params.value) }
            : {}),
          ...(tableFormat.format === 'date' || tableFormat.format === 'date-month'
            ? { sortComparator: gridDateUtils }
            : {}),
        };
      }) || []
    );
  }, [sortedDataColumns]);

  const handleCloseFullScreen = () => {
    setIsFullscreenOpen(false);
  };

  const handleOpenFullScreen = () => {
    setIsFullscreenOpen(true);
  };

  return (
    <Box className="Chart">
      <div className="buttons">
        <TooltipIcon
          className="FileDownloadIcon"
          icon={ic_fullscreen}
          onClick={handleOpenFullScreen}
        >
          Open in full screen
        </TooltipIcon>
      </div>
      <Box className="chartHeader">
        <TitleBlock
          title={props.title}
          helpText={props.helpText}
          className={additionalClass}
        />
      </Box>
      <Divider className="divider" />

      <Box sx={{ height: (props.height || 400) + 51, width: '100%' }}>
        <DataGridCustom
          rows={rows}
          columns={columns}
          // sortModel={sortModel}
          // onSortModelChange={setSortModel}
          slotProps={{ toolbar: { csvOptions: { fileName: props.title } } }}
          settings={{
            initialState: {
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            },
            pageSizeOptions: [25, 50, 100],
          }}
        />
      </Box>

      <Dialog
        fullScreen={true}
        open={isFullscreenOpen}
        onClose={handleCloseFullScreen}
        maxWidth="xl"
        PaperProps={{
          style: {
            width: '100%',
            height: '100vh',
          },
        }}
      >
        <Box>
          <Button
            variant="text"
            startIcon={<Icon icon={ic_keyboard_arrow_left} size={24} />}
            onClick={handleCloseFullScreen}
            // fullWidth={false}
            sx={{
              fontWeight: '600 !important',
            }}
          >
            BACK
          </Button>

          <Box className="chartHeader">
            <TitleBlock title={props.title} helpText={props.helpText} />
          </Box>
          <Divider className="divider" />
        </Box>

        <Box sx={{ height: '100%', position: 'relative' }}>
          <DataGridWrapper customPudding={0}>
            <DataGridCustom
              rows={rows}
              columns={columns}
              slotProps={{ toolbar: { csvOptions: { fileName: props.title } } }}
              sx={{
                '.MuiDataGrid-columnHeadersInner': {
                  backgroundColor: '#F5F8FD',
                  borderRadius: '12px 12px 0px 0px',
                },
              }}
            />
          </DataGridWrapper>
        </Box>
      </Dialog>
    </Box>
  );
};

const TitleBlock = (props) => {
  return (
    <>
      {props.title && (
        <Box className="chartHeader_titleBlock">
          <h3 className={`chartTitle ${props.className}`}>{props.title}</h3>
          {props.helpText && (
            <Box className="helpIcon">
              <HelpIcon>{props.helpText}</HelpIcon>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default Table;
