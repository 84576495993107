import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { Confirmation } from 'common/components/Confirmation';
import { deleteNetRate, getShipperNetRate } from '../../../store/slices/NetRatesSlices';
import IconButton from '@mui/material/IconButton';
import Icon from 'common/components/Icon';
import { ic_vertical_align_bottom } from 'react-icons-kit/md/ic_vertical_align_bottom';

export const useNetRatesTableColumns = (dispatch) =>
  useMemo(
    () => [
      {
        field: 'number',
        headerName: 'Number',
        flex: 1,
        minWidth: 150,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'service_type',
        headerName: 'Service type',
        flex: 1,
        minWidth: 150,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'carrier',
        headerName: 'Carrier',
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'effective_date',
        headerName: 'Effective date',
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },

      {
        field: 'actions',
        headerName: 'Actions',
        width: 120,
        type: 'actions',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <Stack spacing={1} direction="row">
            <Confirmation
              onSubmit={() => {
                dispatch(deleteNetRate(params.value.id));
              }}
              btn={
                <IconButton size="small">
                  <Icon icon={ic_delete} size={20} />
                </IconButton>
              }
            >
              Do you really want to delete this contract?
            </Confirmation>

            <IconButton
              size="small"
              onClick={() =>
                dispatch(getShipperNetRate(params.value.id, params.value.service_type, params.value.number))
              }
            >
              <Icon icon={ic_vertical_align_bottom} size={20} />
            </IconButton>
          </Stack>
        ),
      },
    ],
    [dispatch]
  );
