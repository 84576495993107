import { useMemo } from 'react';

export const useVendorInvoicesTableRows = (vendorInvoices) =>
  useMemo(
    () =>
      vendorInvoices?.reduce((prevState, currentValue) => {
        const currentRow = {
          id: currentValue.hid,
          operationVendor: currentValue.operationVendor,
          operationVendorAccountNumber: currentValue.operationVendorAccountNumber,
          invoiceNumber: currentValue.invoiceNumber,
          invoiceDate: currentValue.invoiceDate,
          invoiceDueDate: currentValue.invoiceDueDate,
          hasContentPdf: currentValue.hasContentPdf,
          hasContent: currentValue.hasContent,
          originalAmount: currentValue.originalAmount,
          finalAmount: currentValue.finalAmount,
          adjustedAmount: currentValue.adjustedAmount,
          detailedMessage: currentValue.detailedMessage,
          operationInvoiceId: currentValue.operationInvoiceId,
          currentValue,
        };
        prevState.push(currentRow);
        return prevState;
      }, []),
    [vendorInvoices]
  );
