import React from 'react';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { useCalculation } from '../helpers/hooks';

const heatMapWidth = 420;

const Wrapper = styled.div`
  display: flex;
  /* margin-bottom: 16px; */
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
`;

const Description = styled.div`
  display: flex;
  width: ${heatMapWidth}px;
  margin: 0 auto;
  justify-content: space-between;
`;

const BlockContainer = styled.div`
  display: flex;
  width: ${heatMapWidth}px;
  margin: 0 auto;
  flex-direction: ${({ selectedCalculationIndex }) =>
    selectedCalculationIndex === 0 ? 'row-reverse' : 'row'};
`;

const Block = styled.div`
  height: 15px;
  flex-grow: 1;
  background-color: ${({ type }) =>
    type === 'cold' ? 'rgb(55, 105, 186)' : 'rgb(193, 73, 24)'};
  opacity: ${({ opacity }) => opacity};
`;

export const HeatmapKey = ({ selectedCalculationIndex }) => {
  const { isA, isB, isCompare } = useCalculation(selectedCalculationIndex);

  let scale = [];

  if (isCompare) {
    for (let i = -1; i <= 1; i += 0.1) {
      scale.push(i);
    }
  } else if (isA) {
    for (let i = -1; i <= -0.1; i += 0.1) {
      scale.push(i);
    }
  } else if (isB) {
    for (let i = 0.1; i <= 1; i += 0.1) {
      scale.push(i);
    }
  }

  return (
    <Wrapper>
      <Description>
        <Typography variant="body1">{isCompare ? 'A is cheaper' : 'Cheaper'}</Typography>
        <Typography variant="body1">
          {isCompare ? 'B is cheaper' : 'More expensive'}
        </Typography>
      </Description>

      <BlockContainer selectedCalculationIndex={selectedCalculationIndex}>
        {scale.map((o) => (
          <Block key={o} opacity={Math.abs(o)} type={o >= 0 ? 'cold' : 'hot'} />
        ))}
      </BlockContainer>
    </Wrapper>
  );
};
