import React, { useEffect, useMemo, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { ic_close } from 'react-icons-kit/md/ic_close';
import Icon from 'common/components/Icon';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import {
  getArticlesList,
  getArticlesReactions,
  getUnreadArticles,
  markArticlesAsRead,
  setFeedbackModalOpen,
} from '../../store/actions';
import Article from './Article';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const searchArticle = (data, query) => {
  if (query && data) {
    return data.filter((item) => item.header.toLowerCase().includes(query.toLowerCase()));
  } else return data;
};

const NewsWidget = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const dispatch = useDispatch();
  const { articlesList, unreadArticlesCount, articlesReactionsList, unreadArticlesCountInitialized } = useSelector(
    (state) => state.articles
  );
  const [searchQuery, setSearchQuery] = useState('');

  const foundArticle = useMemo(
    () => searchArticle(articlesList, searchQuery),
    [articlesList, searchQuery]
  );
  useEffect(() => {
    if (openSidebar) {
      dispatch(getArticlesList());
      dispatch(getArticlesReactions());
    }
  }, [openSidebar]);

  useEffect(() => {
    if (!articlesList?.length && !unreadArticlesCountInitialized) {
      dispatch(getUnreadArticles());
    }
  }, []);

  useEffect(() => {
    if (openSidebar && articlesList && typeof articlesList !== 'string') {
      const allArticleIds = articlesList.map((i) => i.id);
      dispatch(markArticlesAsRead({ articles_ids: allArticleIds }));
    }
  }, [openSidebar, articlesList]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenSidebar(open);
  };

  const handleOpenModal = () => {
    dispatch(setFeedbackModalOpen(true));
  };
  const isHaveArticle =
    articlesList && typeof articlesList !== 'string' && articlesList.length > 0;

  return (
    <div className="SidebarNotificationWidget">
      <Badge badgeContent={unreadArticlesCount} color="primary">
        <Box component={'strong'} sx={{ cursor: 'pointer' }} onClick={toggleDrawer(true)}>
          News and Updates &nbsp;&nbsp;
        </Box>
      </Badge>
      <Drawer
        anchor={'right'}
        open={openSidebar}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            margin: '20px',
            borderRadius: '16px',
            height: 'calc(100% - 40px)',
          },
        }}
      >
        <Box sx={{ width: '500px', overflow: 'hidden' }}>
          <Box
            sx={{
              background: 'var(--galleon-blue)',
              color: '#f7fafc',
              display: 'flex',
              justifyContent: 'space-between',
              fontWeight: 'bold',
              padding: '0.75rem 1rem',
            }}
          >
            <span>News and Updates</span>
            <div onClick={toggleDrawer(false)}>
              <Icon icon={ic_close} size={24} />
            </div>
          </Box>
          <Box sx={{ padding: '0.75rem 1rem', height: '100%', overflow: 'hidden' }}>
            {isHaveArticle && (
              <div>
                <TextField
                  sx={{
                    '& .MuiOutlinedInput-root ': {
                      borderRadius: '2px',
                    },
                  }}
                  variant="outlined"
                  size="small"
                  placeholder="Search..."
                  fullWidth
                  className="form-control searchInputMui"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            )}

            <Box
              sx={{
                height: 'calc(100% - 75px)',
                'overflow-y': 'scroll',
              }}
            >
              {isHaveArticle ? (
                foundArticle.map((article) => (
                  <Article
                    key={article.id}
                    article={article}
                    articlesReactionsList={articlesReactionsList}
                  />
                ))
              ) : (
                <Typography
                  component="h5"
                  sx={{ marginTop: '30px', textAlign: 'center' }}
                >
                  Ooops! Newsfeed is empty
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
};

export default NewsWidget;
