import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Badge } from '@mui/material';

const PREFIX = 'Thumb';

const classes = {
  badge: `${PREFIX}-badge`,
};

const StyledBadge = styled(Badge)(() => ({
  [`&.${classes.badge}`]: {
    cursor: 'pointer',
    '& .MuiBadge-badge': {
      maxWidth: 20,
      fontSize: '0.9rem',
    },
  },
}));

const Thumb = ({ file, removeFile }) => {
  const [img, setImg] = useState(null);
  useEffect(() => {
    let reader = new FileReader();

    reader.onloadend = () => {
      setImg(reader.result);
    };

    reader.readAsDataURL(file);
  }, [file]);
  return (
    <StyledBadge
      color="secondary"
      badgeContent={<span className="cross">&#10005;</span>}
      onClick={removeFile}
      className={classes.badge}
    >
      <img src={img} alt={file.name} height={100} width={100} />
    </StyledBadge>
  );
};

export default Thumb;
