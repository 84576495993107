'use strict';

import React from 'react';

import ShipmentDetailsFilterSidebarForm from 'cloud/shipment-details/ShipmentDetailsFilterSidebarForm';

import createFilterSidebar from 'common/components/FilterSidebar/FilterSidebar';
import { matchPath } from 'react-router-dom';

const ShipmentDetailsFilterSidebar = createFilterSidebar('ShipmentDetails.FilterSidebar');

export default (props) => {
  const match = matchPath(location.pathname, {
    path: '/shipment-details',
    exact: true,
  });

  if (match) {
    return <ShipmentDetailsFilterSidebar Component={ShipmentDetailsFilterSidebarForm} />;
  } else {
    return null;
  }
};
