import './Spinner.scss';

import autobind from 'react-autobind';
import classNames from 'classnames';
import events from 'events';
import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

// Global event router we'll use to allow the outside world to communicate with
// the spinner container.
var EVENT_EMITTER = new events.EventEmitter();

class GlobalSpinnerContainer extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      isVisible: false,
      message: '',
    };
  }

  componentDidMount() {
    EVENT_EMITTER.on('show', this.handleShow);
    EVENT_EMITTER.on('hide', this.handleHide);
  }

  componentWillUnmount() {
    EVENT_EMITTER.removeListener('show', this.handleShow);
    EVENT_EMITTER.removeListener('hide', this.handleShow);
  }

  render() {
    return (
      <SpinnerContainer
        className="GlobalSpinnerContainer"
        isVisible={this.state.isVisible}
        message={this.state.message}
      />
    );
  }

  handleShow(message) {
    this.setState({
      isVisible: true,
      message: message,
    });
  }

  handleHide() {
    this.setState({ isVisible: false });
  }
}

/**
 * Implements a component that shows / hides a throbber overlay.
 */
const SpinnerContainer = (props) => {
  var content = null;
  if (props.isVisible) {
    content = (
      <CSSTransition classNames="transition" timeout={{ enter: 150, exit: 150 }}>
        <div className={classNames('SpinnerContainer', props.className)}>
          <div className="valign-outer">
            <div className="valign-inner">
              <div className="spinner"></div>
              <h2 className="message">{props.message}</h2>
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }

  return <TransitionGroup>{content}</TransitionGroup>;
};

function show(message) {
  EVENT_EMITTER.emit('show', message || 'Working...');
}

function hide() {
  EVENT_EMITTER.emit('hide');
}

export { GlobalSpinnerContainer, SpinnerContainer, show, hide };
