import React from 'react';
import autobind from 'react-autobind';

import './VendorInvoicesPaymentModal.scss';
import Button from 'common/components/Button';
import { Modal } from 'common/components/Modal';

import VendorInvoicesPaymentForm from './VendorInvoicesPaymentForm';
import Box from '@mui/material/Box';
import * as utils from 'common/helpers/utils';
import { enqueueSnackbar } from 'notistack';

class VendorInvoicesPaymentModal extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      selectedInvoices: [],
      filteredInvoicesCount: 0,
      totalAmount: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.selectedVendorInvoices) !==
      JSON.stringify(this.props.selectedVendorInvoices)
    ) {
      let totalAmount = 0;
      let filteredInvoices = this.props.selectedVendorInvoices.filter((invoice) => {
        if (
          !invoice.isScheduledForPayment &&
          invoice.finalAmount &&
          invoice.vendorPaymentStatus &&
          invoice.vendorPaymentStatus.toLowerCase().indexOf('unpaid') !== -1
        ) {
          totalAmount += invoice.finalAmount ? invoice.finalAmount : 0;

          return true;
        } else {
          return false;
        }
      });

      this.setState({
        totalAmount: totalAmount,
        selectedInvoices: filteredInvoices,
        filteredInvoicesCount:
          this.props.selectedVendorInvoices.length - filteredInvoices.length,
      });
    }
  }

  onRemoveInvoice(id) {
    let selectedInvoices = this.state.selectedInvoices.filter(
      (invoice) => invoice.hid != id
    );
    this.setState({ selectedInvoices });
  }

  render() {
    return (
      <div>
        <Button
          onClick={() => this.props.onShow && this.props.onShow()}
          className="GreenButton new_button_middle"
          variant="contained"
        >
          Pay selected
        </Button>

        {this.props.enrolledForPayments ? (
          <Modal
            open={this.props.show}
            onClose={this.close}
            className="VendorInvoicesPaymentModal"
            maxWidth="lg"
            title="Pay open invoices"
          >
            <VendorInvoicesPaymentForm
              selectedInvoices={this.state.selectedInvoices}
              filteredInvoicesCount={this.state.filteredInvoicesCount}
              totalAmount={this.state.totalAmount}
              onRemove={this.onRemoveInvoice}
              onClose={this.close}
            />
          </Modal>
        ) : (
          <Modal
            open={this.props.show}
            onClose={this.close}
            className="VendorInvoicesPaymentModal"
            maxWidth="sm"
            fullWidth={false}
            title="Enroll in Payments"
            actions={
              <>
                <Button type="submit" onClick={this.close}>
                  Go back
                </Button>
              </>
            }
          >
            <>
              <p>
                Did you know you can pay your carrier invoices directly through Galleon?
              </p>
              <p>This can be a great way for your A/P team to save time and money.</p>
              <p>If you're interested in learning more, let's talk!</p>

              <Box
                component="span"
                sx={{ cursor: 'pointer', fontWeight: '14px', color: '#1175db' }}
                onClick={() => {
                  if (utils.copyToClipboard('support@galleonlogistics.com')) {
                    enqueueSnackbar('Successfully copied text to clipboard.', {
                      variant: 'success',
                    });
                  }
                }}
              >
                support@galleonlogistics.com
              </Box>
            </>
          </Modal>
        )}
      </div>
    );
  }

  close(e) {
    this.props.onClose && this.props.onClose();
  }
}

export default VendorInvoicesPaymentModal;
