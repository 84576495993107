import React from 'react';

const ArrowTriangle = ({ pathFill = '#1175DB' }) => {
  return (
    <svg
      width="8"
      height="7"
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.54771 0.224732C7.96096 0.151806 8.11956 0.383236 7.90336 0.739324L4.39288 6.52129C4.17606 6.87842 3.82388 6.87738 3.60768 6.52129L0.0972037 0.739324C-0.119623 0.382197 0.0381036 0.151541 0.452854 0.224732L0.517638 0.236164C2.44105 0.57559 5.56024 0.575463 7.48293 0.236164L7.54771 0.224732Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default ArrowTriangle;
