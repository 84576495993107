import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'common/components/Modal';
import {
  downloadTemplate,
  getNetRatesCarriersList,
  setServiceTypeList,
} from '../../store/slices/NetRatesSlices';
import { DownloadTemplateForm } from './DownloadTemplateForm';

export const DownloadTemplateModal = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { carriersList, serviceTypeList, fetchingSendingData } = useSelector(
    (state) => state.netRates
  );

  // Open the modal
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  // Close the modal
  const handleClose = useCallback(() => {
    setOpen(false);
    dispatch(setServiceTypeList(null));
  }, [setOpen]);

  const downloadTemplateHandler = useCallback(
    async ({ carrier, serviceType }) => {
      await dispatch(downloadTemplate(carrier, serviceType)).then(() => handleClose());
    },
    [dispatch]
  );

  // Load list of carriers
  useEffect(() => {
    if (open) dispatch(getNetRatesCarriersList());
  }, [dispatch, open]);

  return (
    <>
      <a
        href="javascript:void(0)"
        style={{ cursor: 'pointer', fontWeight: '14px', color: '#1175db' }}
        onClick={handleOpen}
      >
        Download Template
      </a>
      <Modal
        open={open}
        onClose={handleClose}
        contentClassName="withOverflow"
        className="withOverflow"
        title="Select which carrier issued the contract"
        isDefaultCloseButton
      >
        <DownloadTemplateForm
          carriers={carriersList}
          serviceTypeList={serviceTypeList}
          onSubmit={downloadTemplateHandler}
          fetchingSendingData={fetchingSendingData}
        />
      </Modal>
    </>
  );
};
