import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import styled from 'styled-components';

const StyledFormControl = styled(FormControl)`
  width: 100%;
  .MuiFormLabel-root {
    line-height: 14px;
  }
`;

export const SelectFormik = ({
  field,
  form,
  label,
  variant,
  children,
  ...otherProps
}) => {
  const hasError = !!form.errors[field.name];
  const hasTouched = !!form.touched[field.name];

  return (
    <StyledFormControl variant={variant} error={hasError && hasTouched}>
      <InputLabel>{label}</InputLabel>
      <Select autoWidth {...field} {...otherProps} label={label}>
        {children}
      </Select>
      {hasError && hasTouched && (
        <FormHelperText>{form.errors[field.name]}</FormHelperText>
      )}
    </StyledFormControl>
  );
};
