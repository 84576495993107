import React from 'react';

import TooltipIcon from './TooltipIcon';
import { iosHelpOutline } from 'react-icons-kit/ionicons/iosHelpOutline';
import './HelpIcon.scss';

/**
 * Renders a help icon and tooltip.
 *
 * The text for the tooltip will be determined by the text inside the
 * `<HelpIcon></HelpIcon>` tags.
 */
function HelpIcon(props) {
  return (
    <TooltipIcon className="HelpIcon icon" icon={iosHelpOutline} {...props}></TooltipIcon>
  );
}

export default HelpIcon;
