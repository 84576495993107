import { createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import * as ajax from 'common/helpers/ajax';
import { convertToJSON } from 'common/components/UploadTrackingField/utils';

const multiplyTrackShipmentsSlice = createSlice({
  name: 'multiplyTrackShipmentsSlice',
  initialState: {
    error: null,
    isSendingLoading: null, //if null ===  is init state
    shipments: [],
  },
  reducers: {
    setError: (state, { payload }) => {
      state.error = payload;
    },
    setIsSendingLoading: (state, { payload }) => {
      state.isSendingLoading = payload;
    },
    setShipments: (state, { payload }) => {
      state.shipments = payload;
    },
  },
});

const { setShipments, setError, setIsSendingLoading } =
  multiplyTrackShipmentsSlice.actions;

export const uploadShipmentTrackingList = ({
  vendorAuthId,
  trackingNumbers,
  files,
  upload_radio_value,
}) => {
  return async (dispatch) => {
    let formData = new FormData();
    formData.append('vendor_auth_id', vendorAuthId);
    if (upload_radio_value === 'file') {
      Array.from(files).forEach((file) => {
        formData.append('files[]', file);
      });
    } else {
      formData.append('tracking_numbers', convertToJSON(trackingNumbers));
    }

    dispatch(setIsSendingLoading(true));

    return ajax.postFormData(
      '/api/multiple-lookup',
      formData,
      (response) => {
        dispatch(setIsSendingLoading(false));
        dispatch(setShipments(response));
      },
      (response) => {
        dispatch(setIsSendingLoading(false));
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

export const multiplyTrackShipmentsReducer = multiplyTrackShipmentsSlice.reducer;
