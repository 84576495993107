import React from 'react';
import { Chip } from '@mui/material';
import Box from '@mui/material/Box';
import Icon from '../../Icon';
import { ic_clear } from 'react-icons-kit/md/ic_clear';

const ChartTagLegend = (props) => {
  return (
    <Box className="tags-wrapper">
      {props.selectedDatasets.map((dataset) => (
        <Chip
          onClick={(e) => e.stopPropagation()}
          key={dataset.label + dataset.value.value}
          className="tag"
          avatar={
            <Box
              className="tag_color"
              sx={{ background: dataset.value.selectBackgroundColor }}
            />
          }
          label={dataset.label}
          deleteIcon={
            props.onRemoveDataSet && <Icon icon={ic_clear} className="tag_clear" />
          }
          onDelete={props.onRemoveDataSet && props.onRemoveDataSet(dataset.value)}
        />
      ))}
    </Box>
  );
};

export default ChartTagLegend;
