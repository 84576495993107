import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

const useChartTabs = (tabList, pageName) => {
  const listenRef = useRef(null);
  const history = useHistory();
  const match = useRouteMatch(`/${pageName}/:slug`);

  const findCurrentTab = useCallback(
    (slug) => {
      return tabList.find((tab) => slug === tab.path) || tabList[0];
    },
    [tabList]
  );

  //if we have tabList in mounting initialState will work.
  //else useEffect will work after we receive tabList
  const [currentTab, setCurrentTab] = useState(findCurrentTab(match?.params?.slug) || {});

  // init currentTab state in first loading if it is necessary (if tabList was undefined)
  useEffect(() => {
    const slug = match?.params?.slug;
    if (!Object.keys(currentTab).length && !!tabList.length && slug) {
      setCurrentTab(findCurrentTab(slug));
    }
  }, [tabList, currentTab, findCurrentTab]);

  useLayoutEffect(() => {
    if (!!listenRef.current) {
      listenRef.current();
    }

    listenRef.current = history.listen((location, action) => {
      const urlParts = location.pathname.replace(new RegExp(`/${pageName}/?`), '').split('/');
      const slug = urlParts[0];

      setCurrentTab(findCurrentTab(slug));
    });

    return () => {
      listenRef.current();
    };
  }, [history, findCurrentTab]);

  const changePath = useCallback((label) => {
    history.push(`/${pageName}/${label}`);
  }, []);

  // init path after tabList was loaded
  useEffect(() => {
    if (!match?.params?.slug && !!tabList.length) {
      changePath(tabList[0].path);
    }
  }, [tabList]);

  const onChangeCurrentQueryNames = useCallback((newTab) => {
    changePath(newTab.path);
  }, []);

  const contentId = useMemo(() => {
    return `${pageName}_${currentTab?.path || ''}`;
  }, [pageName, currentTab]);

  return { currentTab: currentTab, onChangeCurrentQueryNames, contentId };
};

export default useChartTabs;
