import classNames from 'classnames';
import React from 'react';

import * as utils from 'common/helpers/utils';

import Chart from './ChartViewModel';
import * as Constants from './Constants';
import {
  axisFontSizeChartPlugin,
  legendMarginBottom,
  MAX_DATASETS_LENGTH_TO_SHOW,
} from './Constants';

const BarChart = (props) => {
  if (!props.data) {
    return null;
  }

  const colorPalette = Constants.getColorPallete(props.data.datasets.length);

  const isHideLegend =
    props.hideLegend || props.data.datasets.length > MAX_DATASETS_LENGTH_TO_SHOW;

  const isStacked = props.queryChartData?.chartType === 'stacked-bar';

  const chartConfig = {
    type: 'bar',
    data: {
      labels: props.data.labels || [],
      datasets: props.data.datasets.map((dataset, i) => {
        return Object.assign(
          {
            // Custom field for react select picker
            selectBackgroundColor: colorPalette[i],
            backgroundColor: utils.hexToRGBA(colorPalette[i], 0.9),
            borderColor: colorPalette[i],
            hoverBackgroundColor: utils.pSBC(-0.3, colorPalette[i]),
          },
          dataset
        );
      }),
      categoryPercentage: 0.9,
      barPercentage: 1.0,
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          ...(isStacked ? { mode: 'index' } : {}),
          callbacks: {
            label: (context) => {
              // const value =isStacked?:
              let yLabel = props.yAxisTooltipsFmt
                ? props.yAxisTooltipsFmt(context.raw)
                : context.raw;
              return context.dataset.label + ': ' + yLabel;
            },
          },
        },
      },

      scales: {
        x: {
          stacked: isStacked,
          title: {
            display: true,
            text: props.xAxisLabel,
          },
          border: {
            color: Constants.LIGHT_BLUE,
          },
          grid: {
            color: 'transparent',
            zeroLineColor: Constants.LIGHT_BLUE,
          },
          ticks: {
            color: Constants.MED_BLUE,
          },
        },
        y: {
          stacked: isStacked,
          title: {
            display: true,
            text: props.yAxisLabel,
          },
          border: {
            dash: Constants.GRID_DASH,
            display: true,
            color: Constants.LIGHT_BLUE,
          },
          grid: {
            color: Constants.LIGHT_BLUE,
            zeroLineColor: Constants.LIGHT_BLUE,
          },
          beginAtZero: true,
          max: props.yAxisTicksMax,
          ticks: {
            callback: (value) => {
              if (props.yAxisTicksFmt) {
                value = props.yAxisTicksFmt(value);
              }
              return value + '  ';
            },
            stepSize: props.yAxisTicksStepSize,
            color: Constants.MED_BLUE,
          },
        },
      },
    },
    plugins: [legendMarginBottom, axisFontSizeChartPlugin],
  };

  return (
    <Chart
      title={props.title}
      chartConfig={chartConfig}
      className={classNames(props.className, 'BarChart')}
      hideLegend={isHideLegend}
      height={props.height}
      shipperSubscriptions={props.shipperSubscriptions}
    />
  );
};

export default BarChart;
