import { useMemo } from 'react';

export const useShipmentDetailsTableRows = (shipments) =>
  useMemo(
    () =>
      shipments?.reduce((prevState, currentValue) => {
        const currentRow = {
          id: currentValue.hid,
          ...currentValue,
        };
        prevState.push(currentRow);
        return prevState;
      }, []),
    [shipments]
  );
