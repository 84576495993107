'use strict';

import React from 'react';

import VendorInvoicesFilterSidebarForm from 'cloud/vendor-invoices/VendorInvoicesFilterSidebarForm';

import createFilterSidebar from 'common/components/FilterSidebar/FilterSidebar';
import { matchPath } from 'react-router-dom';

const VendorInvoicesFilterSidebar = createFilterSidebar('VendorInvoices.FilterSidebar');

export default (props) => {
  const match = matchPath(location.pathname, {
    path: '/invoices',
    exact: true,
  });

  if (match) {
    return <VendorInvoicesFilterSidebar Component={VendorInvoicesFilterSidebarForm} />;
  } else {
    return null;
  }
};
