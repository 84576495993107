import { generateRandomHexColor } from 'common/helpers/utils';
import humps from 'humps';
import * as utils from '../../helpers/utils';
import { useSelector } from 'react-redux';

export const FONT_FAMILY = 'Outfit, sans-serif';

export const LIGHTER_GRAY = '#CCC';
export const LIGHT_BLUE = '#B0CEEC';
export const MED_BLUE = '#526982';

export const MED_GRAY = '#999';
export const DARK_GRAY = '#777';
export const DARKER_GRAY = '#526982';

export const TOOLTIP_BACKGROUND_COLOR = 'rgba(11, 44, 77, 0.75)';

// Merge custom options with global Chart JS config.
export const defaultChartJSConf = {
  defaultFontFamily: FONT_FAMILY,
  defaultColor: DARKER_GRAY,
  defaultFontColor: DARKER_GRAY,
  color: DARKER_GRAY,
  responsive: true,
  // maintainAspectRatio: true,
  animation: {
    duration: 300,
  },
  hover: {
    animationDuration: 200,
  },
  elements: {
    line: {
      // borderWidth: 2,
    },
  },
  layout: {
    padding: {
      left: 0,
    },
  },
  legend: {
    labels: {
      fontFamily: FONT_FAMILY,
      color: DARKER_GRAY,
      // Reduce the size of the legend boxes.
      // boxWidth: 25,

      // Increase padding between legend boxes.
      padding: 20,
    },
  },
  plugins: {
    tooltip: {
      backgroundColor: TOOLTIP_BACKGROUND_COLOR,
      titleFontFamily: FONT_FAMILY,
      // titleFont:
      titleMarginBottom: 11,

      titleFont: {
        fontFamily: FONT_FAMILY,
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 600,
      },

      bodyFont: {
        size: 12,
        fontFamily: FONT_FAMILY,
      },
      bodyFontFamily: FONT_FAMILY,
      bodySpacing: 4,
      // xPadding: 10,
      cornerRadius: 8,
      padding: 14,

      // HACK: By default, ChartJS renders very little padding at the bottom of
      // the tooltip. Here, we add some blank text to the footer (with a font
      // size of 1) just to make sure that there's a little more padding.
      footerFontSize: 1,
      callbacks: {
        // footer: function (tooltipItem, data) {
        //   return ' ';
        // },
      },
    },
  },
};

export const POINT_CONFIG = {
  pointRadius: 3,
  pointHoverRadius: 6,
  pointHoverBorderWidth: 16,

  // Give all points on graphs a significantly larger hit-target for triggering
  // hover events.
  pointHitRadius: 20,
};

export const BLUE_YELLOW_12 = [
  '#00C2FF',
  '#00C5F2',
  '#00CAE2',
  '#23CFCB',
  '#3CD4B8',
  '#3CD4B8',
  '#71DE8C',
  '#88E377',
  '#9EE763',
  '#B3EB50',
  '#CBF03C',
  '#D9F331',
];

export const MULTI_COLORS_LIGHT = [
  '#3772ff50',
  '#ff6a9650',
  '#ffa26a50',
  '#a26aff50',
  '#E2D35650',
];

export const MULTI_COLORS = [
  '#3772ff',
  '#ff6a96',
  '#ffa26a',
  '#6affa2',
  '#ff6af9',
  '#a26aff',
  '#FEB640',
  '#D271CE',
  '#63BAF9',
  '#41E79E',
  '#F39A95',
  '#E2D356',
  '#52A7AB',
  '#18ffff',
  '#6d4c41',
  '#ffea00',
  '#00695c',
  '#aa00ff',
  '#9e9d24',
  '#bf360c',
  '#1b5e20',
  '#0091ea',
  '#ff1744',
  '#41ff41',
  '#3f51b5',
  '#c8e6c9',
  '#bcaaa4',
  '#ff6f00',
  '#90a4ae',
  '#c2185b',
  '#80cbc4',
  '#ffccbc',
  '#b3e5fc',
  '#F4A460',
  '#2ECC71 ',
  '#9370DB',
  '#A0522D',
  '#e9967a',
  '#66cdaa',
  '#d2b48c',
  '#0055ff',
  '#ff99cc',
  '#ffff99',
  '#63BAF9',
  '#41E79E',
  '#FEB640',
  '#F39A95',
  '#E2D356',
  '#D271CE',
  '#52A7AB',
  '#18ffff',
  '#6d4c41',
  '#ffea00',
  '#00695c',
  '#aa00ff',
  '#9e9d24',
  '#bf360c',
  '#1b5e20',
  '#0091ea',
  '#ff1744',
  '#3f51b5',
  '#c8e6c9',
  '#bcaaa4',
  '#ff6f00',
  '#90a4ae',
  '#c2185b',
  '#80cbc4',
  '#ffccbc',
  '#b3e5fc',
  '#F4A460',
  '#2ECC71 ',
  '#9370DB',
  '#A0522D',
  '#e9967a',
  '#66cdaa',
  '#d2b48c',
  '#0055ff',
  '#ff99cc',
  '#ffff99',
];

export const RED_10 = [
  '#FDF2F2',
  '#FAD2D0',
  '#F6B7B4',
  '#F4A39F',
  '#F39A95',
  '#EE726B',
  '#ED6B63',
  '#EC5E56',
  '#EB564D',
  '#E94238',
];

export const GREEN_10 = [
  '#CCEDDA',
  '#B5E5CA',
  '#9FDEBA',
  '#89D7AA',
  '#72CF9A',
  '#5CC88B',
  '#46C17B',
  '#2FB96B',
  '#19B25B',
  '#03AB4C',
];

export const BLUE_10 = [
  '#D7EAF7',
  '#BFE0F7',
  '#A7D6F7',
  '#8FCCF7',
  '#77C2F7',
  '#5FB8F7',
  '#47AEF7',
  '#2FA4F7',
  '#179AF7',
  '#0090F7',
];

export const BLUE_PURPLE_10 = [
  '#59D8FF',
  '#69C1F0',
  '#7AAAE1',
  '#8A93D2',
  '#9B7CC3',
  '#AB65B4',
  '#BC4EA5',
  '#CC3796',
  '#DD2087',
  '#EE0979',
];

export const useClassSubscriptionsForCharts = (shipperSubscriptions) => {
  const isDemo = useSelector((state) => state.user)?.shipper?.subscription === 'DEMO';

  if (!isDemo) {
    return '';
  }

  return (shipperSubscriptions || []).map((subscription) => subscription).join(' ');
};

export const getColorPallete = (length = MULTI_COLORS.length) => {
  const colorPallete = [...MULTI_COLORS];
  if (length <= colorPallete.length) return colorPallete.splice(0, length);

  const missingColorsLength = length - MULTI_COLORS.length;
  for (let i = 0; i < missingColorsLength; i++) {
    colorPallete.push(generateRandomHexColor());
  }

  return colorPallete;
};

export const GRID_DASH = [4, 4];

export const MIN_DATA_FOR_LEGEND = 2;

export const LABELS_BOX_WIDTH = 14;

export const legendMarginBottom = {
  id: 'legend-margin-bottom',
  afterInit(chart, args, options) {
    const fitValue = chart.legend.fit;
    chart.legend.fit = function fit() {
      fitValue.call(chart.legend);
      return (this.height += 10);
    };
  },
};

export const PADDING_BUBBLE_CHART = 20;

export const recalculateBubbleRadiusPlugin = {
  id: 'recalculate-bubble-radius-plugin',
  afterInit(chart, args, options) {
    const width = (Math.min(chart.width, chart.height) || 400) / 400;
    chart.config.data.datasets.forEach((dataset) =>
      dataset.data.forEach((bubbleItemData) => {
        bubbleItemData.r = bubbleItemData.initRadius * width;
      })
    );

    chart.config.options.plugins.title.padding = {
      right: PADDING_BUBBLE_CHART,
      top: 0,
      bottom: PADDING_BUBBLE_CHART * width,
      left: PADDING_BUBBLE_CHART,
    };

    chart.config.options.scales.y.ticks.padding = PADDING_BUBBLE_CHART * width;

    chart.config.options.scales.x.ticks.padding = PADDING_BUBBLE_CHART * width;
  },
};

export const AXIS_FONT_SIZE_CHART = 12;

export const axisFontSizeChartPlugin = {
  id: 'axis-font-size-chart-plugin',
  afterInit(chart, args, options) {
    const width = (Math.min(chart.width, chart.height) || 400) / 400;

    const scalesFontSize = Math.max(width / 2, 1) * AXIS_FONT_SIZE_CHART;

    Object.entries(chart.config.options.scales).forEach(([scalesKey, scalesValue]) => {
      chart.config.options.scales[scalesKey] = {
        ...scalesValue,
        ticks: {
          ...(scalesValue.ticks || {}),
          font: {
            ...(scalesValue.ticks.font || {}),
            size: scalesFontSize,
          },
        },
      };
    });
  },
};

// export const xAxisMargin = {
//   id: 'xAxisMargin',
//   beforeDatasetsDraw(chart, args, options) {
//     const {
//       ctx,
//       data,
//       scales: { x, y },
//     } = chart;
//
//     x._labelItems.forEach((label, index) => {
//       label.textBaseline = 'top';
//       label.textOffset = 10;
//     });
//   },
// };

export const polarAreaArcAngleChartPlugin = {
  id: 'polar-area-arc-angle-chart-plugin',
  beforeUpdate: function (chart) {
    const labelsAngleMap = chart.config.data.datasets[0]._extraAngle || {};

    const labels = chart.config.data.labels.map((label) => humps.decamelize(label));
    const sumCurrentData = Object.values(labelsAngleMap).reduce(
      (prevState, currentValue) => {
        return currentValue + prevState;
      },
      0
    );

    chart.config.options.elements.arc.angle = labels.map(
      (currentLabel) => (labelsAngleMap[currentLabel] / sumCurrentData) * 360
    );
  },
};

export const MAX_DATASETS_LENGTH_TO_SHOW = 5;

export const ONE_ELEMENT_IN_CHART = 1;

export const CIRCLE_CHARTS = ['polarArea', 'pie'];

export const tableFormatMap = {
  date: utils.Fmt.dateCustomFormat('MM/DD/YYYY'),
  'date-month': utils.Fmt.dateCustomFormat('MM/YYYY'),
  dollar: utils.Fmt.dollars,
  int: utils.Fmt.int,
  'float-1': utils.Fmt.float1,
  'float-2': utils.Fmt.float2,
  'percent-int': utils.Fmt.percInt,
  'percent-float-2': utils.Fmt.percFloat2,
};
