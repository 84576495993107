'use strict';

import React from 'react';

import ReduxFormSelect from 'common/components/ReduxFormSelect';

/**
 * Helper for constructor a multi-select for a Salesforce field.
 *
 * This component is helpful since Salesforce encodes multiselect values as a
 * semicolon-delimited string.
 */
const ReduxFormSFMultiSelect = (props) => {
  return (
    <ReduxFormSelect
      {...props}
      format={(val, name) => (val ? val.split(';') : null)}
      parse={(val, name) => (val ? val.join(';') : null)}
      multi={true}
    />
  );
};

export default ReduxFormSFMultiSelect;
