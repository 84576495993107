import { useCallback, useEffect, useState } from 'react';

const dbName = 'ShipmentDetailsDB';
const storeName = 'settings';
const storeID = 'columnVisibility';

const openDB = async () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);

    request.onerror = (event) => reject(event.target.errorCode);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore(storeName, { keyPath: 'id' });
    };

    request.onsuccess = (event) => resolve(event.target.result);
  });
};

const saveToDB = async (data) => {
  const db = await openDB();
  const transaction = db.transaction([storeName], 'readwrite');
  const store = transaction.objectStore(storeName);
  store.put({ id: storeID, value: data });
};

const loadFromDB = async () => {
  const db = await openDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], 'readonly');
    const store = transaction.objectStore(storeName);
    const request = store.get(storeID);

    request.onerror = (event) => reject(event.target.errorCode);
    request.onsuccess = (event) => {
      resolve(event.target.result ? event.target.result.value : null);
    };
  });
};

const useColumnVisibility = (columns) => {
  const [columnVisibility, setColumnVisibility] = useState({});

  useEffect(() => {
    const initializeColumnVisibility = async () => {
      try {
        const savedVisibility = await loadFromDB();
        if (savedVisibility) {
          setColumnVisibility(savedVisibility);
        } else {
          const initialState = columns.reduce((prevState, column) => {
            prevState[column.field] = true;
            return prevState;
          }, {});
          setColumnVisibility(initialState);
        }
      } catch (error) {
        console.error('Error loading column visibility from IndexedDB:', error);
      }
    };
    if (!columns.length || !Object.keys(columnVisibility).length) {
      initializeColumnVisibility();
    }
  }, [columns]);

  const handleColumnVisibilityChange = useCallback(
    (event) => {
      const newModel = columns.reduce((prevState, column) => {
        prevState[column.field] = event.target.value.includes(column.field);

        return prevState;
      }, {});
      setColumnVisibility(newModel);

      saveToDB(newModel).catch((error) => {
        console.error('Error saving column visibility to IndexedDB:', error);
      });
    },
    [columns]
  );

  return {
    columnVisibility,
    handleColumnVisibilityChange,
  };
};

export default useColumnVisibility;
