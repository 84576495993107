import React, { useState, Fragment } from 'react';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { Modal } from './Modal';
import Button from "./Button";

const BtnWrapper = styled.div`
  display: inline-block;
`;

export const Confirmation = ({ children, onSubmit, btn, disabled }) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const openConfirmation = () => {
    setConfirmationOpen(true);
  };

  const closeConfirmation = () => {
    setConfirmationOpen(false);
  };
  return (
    <Fragment>
      <BtnWrapper onClick={disabled ? undefined : openConfirmation}>{btn}</BtnWrapper>
      <Modal
        title="Are you sure?"
        actions={
          <Fragment>
            <Button color="primary" variant="outlined" onClick={closeConfirmation}>
              No
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={(event) => {
                onSubmit(event);
                closeConfirmation();
              }}
            >
              Yes
            </Button>
          </Fragment>
        }
        open={confirmationOpen}
        onClose={closeConfirmation}
        maxWidth="xs"
      >
        <Typography variant="body1">{children}</Typography>
      </Modal>
    </Fragment>
  );
};
