import React, { memo, useState } from 'react';
import TooltipIcon from '../../TooltipIcon';
import ChartPopover from './ChartPopover';
import { ic_more_vert } from 'react-icons-kit/md/ic_more_vert';
import Slide from '@mui/material/Slide';
import {
  Button,
  Dialog,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import Icon from '../../Icon';
import { ic_fullscreen } from 'react-icons-kit/md/ic_fullscreen';
import Chart from '../Chart';
import { ic_keyboard_arrow_left } from 'react-icons-kit/md/ic_keyboard_arrow_left';
import Box from '@mui/material/Box';

const ChartPopoverWrapper = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TooltipIcon className="FileDownloadIcon" icon={ic_more_vert} onClick={onOpen}>
        Options
      </TooltipIcon>
      <ChartPopover
        {...props}
        anchorEl={anchorEl}
        onClose={onClose}
        additionalMenu={
          !props.modalCloseButton && <FullPageChart {...props} onClose={onClose} />
        }
      />
    </>
  );
};

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullPageChart = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.rebuildChartCallback();
    props.onClose();
  };

  return (
    <>
      <MenuItem onClick={handleClickOpen}>
        <ListItemIcon>
          <Icon icon={ic_fullscreen} size={24} />
        </ListItemIcon>
        <ListItemText>Open in full screen</ListItemText>
      </MenuItem>
      <Divider />

      <Dialog
        fullScreen
        open={open}
        // onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box
          sx={{
            padding: '10px',
            width: '100%',
            height: '100%',
          }}
        >
          <Chart
            {...props}
            modalCloseButton={
              <Button
                variant="text"
                startIcon={<Icon icon={ic_keyboard_arrow_left} size={24} />}
                onClick={handleClose}
                // fullWidth={false}
                sx={{
                  fontWeight: '600 !important',
                }}
              >
                BACK
              </Button>
            }
          />
        </Box>
      </Dialog>
    </>
  );
};

export default memo(ChartPopoverWrapper);
