import React, { useEffect, useMemo } from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import { change, formValueSelector, reduxForm } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Button from 'common/components/Button';
import ReduxFormSelect from 'common/components/ReduxFormSelect';
import {
  getVendorAuthListList,
  uploadBulkShipmentTracking,
} from '../store/slices/TrackShipmentsSlice';
import { required } from 'common/components/UploadTrackingField/utils';
import ErrorContainer from 'sudo/GlobalAccount/Dashboard/components/ErrorContainer';
import { SpinnerContainer } from 'common/components/Spinner';
import UploadBlock from 'common/components/UploadTrackingField/UploadBlock';
import Tips from 'common/components/Tips/Tips';

let TrackShipments = (props) => {
  const { vendorAuthList, isLoading, error, isSendingLoading } = props.trackShipments;

  const dispatch = useDispatch();

  const onChangeRadioValue = (value) => {
    props.changeField('upload_radio_value', value);
  };

  useEffect(() => {
    dispatch(getVendorAuthListList());
  }, []);

  const save = (data) => {
    dispatch(uploadBulkShipmentTracking(data));
  };

  const vendorAuthOptions = useMemo(() => {
    return vendorAuthList.map((vendorAuth) => ({
      label: `${vendorAuth.vendor} / ${vendorAuth.webCredentials?.username || ''}`,
      value: vendorAuth.id,
    }));
  }, [vendorAuthList]);

  return (
    <>
      {isLoading ? (
        <SpinnerContainer isVisible message="Working..." />
      ) : error ? (
        <ErrorContainer />
      ) : (
        <Box
          component={'form'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '25px',
            maxWidth: '640px',
          }}
          onSubmit={props.handleSubmit(save)}
        >
          <Tips>
            After submitting the process, your api tracking report will be sent to a
            related account email.
          </Tips>
          <FormGroup>
            <FormLabel>Vendor auth</FormLabel>
            <ReduxFormSelect
              name="vendorAuthId"
              placeholder="Select a vendor auth"
              options={vendorAuthOptions}
              clearable={false}
              validate={[required]}
            />
          </FormGroup>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
            className={'upload'}
          >
            <UploadBlock
              radioValue={props.upload_radio_value}
              onChangeRadioValue={onChangeRadioValue}
              type="button"
            />
          </Box>
          <FormGroup>
            <Button
              type="submit"
              size="large"
              variant="contained"
              style={{ height: '49px', width: 'fit-content' }}
              isLoading={isSendingLoading}
              disabled={props.pristine || props.submitting || props.invalid}
            >
              Submit
            </Button>
          </FormGroup>
        </Box>
      )}
    </>
  );
};

TrackShipments = reduxForm({
  form: 'trackShipmentsForm',
})(TrackShipments);

const selector = formValueSelector('trackShipmentsForm');

const mapStateToProps = (state) => {
  return {
    trackShipments: state.trackShipments,
    upload_radio_value: selector(state, 'upload_radio_value'),
    trackingNumbers: selector(state, 'trackingNumbers'),
    files: selector(state, 'files'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeField: function (field, value) {
      dispatch(change('trackShipmentsForm', field, value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackShipments);
