'use strict';

import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { ic_menu } from 'react-icons-kit/md/ic_menu';

import Icon from 'common/components/Icon';
import Box from '@mui/material/Box';

const PageHeader = React.forwardRef((props, ref) => {
  const className = classNames('PageHeader', {
    'text-center': !props.sidebar.isDocked,
    [props.customClassName]: !!props.customClassName,
  });

  return (
    <div ref={ref} className={className}>
      {!props.sidebar.isDocked && (
        <Icon
          className="MenuIcon"
          icon={ic_menu}
          size={24}
          onClick={() => props.dispatch({ type: 'SIDEBAR__TOGGLE' })}
        />
      )}
      <h2>{props.title}</h2>
      <Box className="PageHeader_titleWrapper">
        {props.rightIcons && props.rightIcons}
      </Box>
    </div>
  );
});

const mapStateToProps = (state) => {
  return {
    sidebar: state.sidebar,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(PageHeader);
