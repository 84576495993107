import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Field, FormikProvider, useFormik } from 'formik';
import React from 'react';
import { TextFieldFormik } from 'common/components/formik/TextFieldFormik';
import { validationSchema } from './NewsWidget/validationSchema';
import { useDispatch } from 'react-redux';
import { createFeedback } from '../store/actions';
import Uploader from './NewsWidget/Uploader';
import { enqueueSnackbar } from 'notistack';
import Button from 'common/components/Button';
import MuiButton from '@mui/material/Button';

const PREFIX = 'FeedbackModal';

const classes = {
  marginToNone: `${PREFIX}-marginToNone`,
  actions: `${PREFIX}-actions`,
  imagesBlock: `${PREFIX}-imagesBlock`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.marginToNone}`]: {
    marginTop: 0,
  },

  [`& .${classes.actions}`]: {
    paddingRight: 24,
    paddingBottom: 16,
  },

  [`& .${classes.imagesBlock}`]: {
    marginTop: 10,
  },
}));

const FeedbackModal = ({
  open,
  handleCloseModal,
  headerText = 'Share your feedback!',
}) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      feedbackText: '',
      name: '',
      email: '',
      files: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const { name, email, feedbackText, files } = values;

      let formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('feedback_text', feedbackText);

      if (files.length > 0) {
        files.forEach((file) => {
          formData.append('files[]', file);
        });
      }

      const successCallback = (response) => {
        handleCloseModal();
        enqueueSnackbar(
          'Your feedback has been submitted. We will contact you for the additional details asap.',
          { variant: 'success' }
        );
        resetForm({
          feedbackText: '',
          name: '',
          email: '',
          files: [],
        });
      };
      const errorCallback = () => {
        enqueueSnackbar('Error! Feedback not saved!', { variant: 'error' });
      };
      dispatch(createFeedback(formData, successCallback, errorCallback));
    },
  });

  const { handleSubmit } = formik;

  return (
    <StyledDialog
      onClose={handleCloseModal}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle onClose={handleCloseModal}>{headerText}</DialogTitle>
      <DialogContent sx={{ paddingTop: '10px !important' }}>
        <FormikProvider value={formik}>
          <Field
            component={TextFieldFormik}
            className={classes.marginToNone}
            size="small"
            margin="dense"
            label="How may I address you?"
            variant="outlined"
            fullWidth
            name="name"
            shrink={false}
          />
          <Field
            component={TextFieldFormik}
            size="small"
            margin="dense"
            label="Your contact email"
            type="email"
            variant="outlined"
            fullWidth
            name="email"
            shrink={false}
          />
          <Field
            component={TextFieldFormik}
            size="small"
            margin="dense"
            label="Feedback text"
            multiline
            rows={6}
            variant="outlined"
            fullWidth
            name="feedbackText"
            shrink={false}
          />
          <div className={classes.imagesBlock}>
            <Uploader formik={formik} />
          </div>
        </FormikProvider>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <MuiButton
          onClick={handleCloseModal}
          color="primary"
          variant="outlined"
          sx={{ borderRadius: '25px', lineHeight: 'normal' }}
          className={'new_button_middle'}
        >
          Cancel
        </MuiButton>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Send
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default FeedbackModal;
