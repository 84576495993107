'use strict';

import autobind from 'react-autobind';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { enqueueSnackbar } from 'notistack';
import { FormGroup, FormLabel } from '@mui/material';

import './ShipmentDetailsFilterSidebarForm.scss';

import Button from 'common/components/Button';
import ReduxFormSelect from 'common/components/ReduxFormSelect';
import ReduxFormDateRangePicker from 'common/components/ReduxFormDateRangePicker';
import ReduxFormSFMultiSelect from '../../broadside/components/ReduxFormSFMultiSelect';
import ReduxFormToggleButton from 'common/components/reduxFormToggleButton/ReduxFormToggleButton';
import isAfterDay from 'react-dates/lib/utils/isAfterDay';
import Box from '@mui/material/Box';
import { DESTINATION_TYPES } from '../dashboard/DashboardFilterSidebarForm';
import ReduxFormVendorAccountNumbersSelect from '../../common/components/ReduxFormVendorAccountNumbersSelect';
import { getVendors } from '../../common/helpers/utils';
import ReduxFormFloatingLabelFormGroup from '../../common/components/ReduxFormFloatingLabelFormGroup';
import * as actions from '../store/actions';
import TooltipIcon from '../../common/components/TooltipIcon';
import { ic_help_outline } from 'react-icons-kit/md/ic_help_outline';

// Format the current date as YYYY-MM-DD
let formattedCurrentDate = moment().format('YYYY-MM-DD');

// Get the date four weeks ago and format it
let formattedFourWeeksAgo = moment().subtract(28, 'days').format('YYYY-MM-DD');

const REDUX_FORM_NAME = 'ShipmentDetails.FilterSidebarForm';

export const DATE_RANGES = [
  {
    label: 'Last 4 weeks',
    value: 'LAST_4_WEEKS',
    numWeeks: 4,
  },
];

export const DATE_RANGE_OPTIONS = [
  {
    label: 'Picked up date',
    value: 'PICKED_UP_DATE',
  },
  {
    label: 'Invoice date',
    value: 'INVOICE_DATE',
  },
  {
    label: 'Label creation date',
    value: 'LABEL_CREATION_DATE',
  },
];

export const SHIPMENT_COST_OPTIONS = [
  {
    label: 'More than',
    value: 'MORE_THAN',
  },
  {
    label: 'Less than',
    value: 'LESS_THAN',
  },
];

export const SHIPMENT_STATUS_OPTIONS = [
  {
    label: 'Unknown',
    value: 'UNKNOWN',
  },
  {
    label: 'Label Created',
    value: 'LABEL_CREATED',
  },
  {
    label: 'In Transit',
    value: 'IN_TRANSIT',
  },
  {
    label: 'Delivered',
    value: 'DELIVERED',
  },
  {
    label: 'Terminated Without Delivery',
    value: 'TERMINATED_WITHOUT_DELIVERY',
  },
  {
    label: 'Tracking Updates Ended',
    value: 'TRACKING_UPDATES_ENDED',
  },
  {
    label: 'Never Shipped',
    value: 'NEVER_SHIPPED',
  },
  {
    label: 'Tracking Updates Unretrievable',
    value: 'TRACKING_UPDATES_UNRETRIEVABLE',
  },
];

class ShipmentDetailsFilterSidebarForm extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  componentDidMount() {
    if (
      !this.props.vendorAccountNumberMappings ||
      this.props.vendorAccountNumberMappings.length <= 0
    ) {
      this.props.dispatch(actions.fetchVendorAccountNumberMappings());
    }
  }

  render() {
    return (
      <form
        className="ShipmentDetailsSidebarForm defaultColumnFormStyle"
        onSubmit={this.props.handleSubmit(this.saveFilters)}
      >
        <FormGroup className="DateRangeFormGroup">
          <FormLabel>Shipment dates</FormLabel>

          <ReduxFormToggleButton
            optionList={DATE_RANGE_OPTIONS}
            value={this.props.dateRangeOption}
            fieldName={'dateRangeOption'}
            change={this.props.change}
          />

          <Box className="pickedUpDatePicker">
            <ReduxFormDateRangePicker
              names={['startDate', 'endDate']}
              numberOfMonths={1}
              isOutsideRange={(day) =>
                this.props.dateRangeOption !== 'PICKED_UP_DATE' &&
                isAfterDay(day, moment())
              }
              onFocusChange={this.handleOpenDropdown}
              onClose={this.handleCloseDropdown}
              startDateId="Shipment start date"
              endDateId="Shipment end date"
              required={true}
            />
          </Box>

          {this.props.errors?.shipmentDates && (
            <div className="DateRangeFormGroup-error">
              {this.props.errors.shipmentDates.message}
            </div>
          )}
        </FormGroup>

        <FormGroup className="destinationTypeFormGroup">
          <FormLabel>Destination type</FormLabel>
          <ReduxFormSelect
            name="destinationType"
            options={DESTINATION_TYPES}
            placeholder="Select a destination type"
            clearable={false}
            onOpen={this.handleOpenDropdown}
            onClose={this.handleCloseDropdown}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>Vendor</FormLabel>
          <ReduxFormSFMultiSelect
            name="vendor"
            options={getVendors(this.props.vendorAccountNumberMappings)}
            placeholder="Select vendor"
            clearable={false}
            onOpen={this.handleOpenDropdown}
            onClose={this.handleCloseDropdown}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>Service types</FormLabel>
          <ReduxFormSFMultiSelect
            name="selectedServices"
            options={this.props.services}
            disabled={!this.props.services || this.props.services.length === 0}
            placeholder="Select a service type"
            onOpen={this.handleOpenDropdown}
            onClose={this.handleCloseDropdown}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>
            Billing Entries
            <TooltipIcon icon={ic_help_outline} size="14">
              Applying this filter might slow down the search
            </TooltipIcon>
          </FormLabel>
          <ReduxFormSFMultiSelect
            name="selectedBillingEntries"
            options={this.props.billingEntries}
            disabled={
              !this.props.billingEntries || this.props.billingEntries.length === 0
            }
            placeholder="Select a billing entry"
            onOpen={this.handleOpenDropdown}
            onClose={this.handleCloseDropdown}
          />
        </FormGroup>

        <FormGroup>
          <FormLabel>Shipment Status</FormLabel>
          <ReduxFormSFMultiSelect
            name="selectedStatuses"
            placeholder="Select a shipment status"
            options={SHIPMENT_STATUS_OPTIONS}
            onOpen={this.handleOpenDropdown}
            onClose={this.handleCloseDropdown}
          />
        </FormGroup>

        <FormGroup className="vendorAccountNumbersFormGroup">
          <FormLabel>Account numbers</FormLabel>
          <ReduxFormVendorAccountNumbersSelect
            name="vendorAccountNumbers"
            placeholder="Type account numbers"
            vendorAccountNumberMappings={this.props.vendorAccountNumberMappings}
            onOpen={this.handleOpenDropdown}
            onClose={this.handleCloseDropdown}
          />
        </FormGroup>

        <ReduxFormFloatingLabelFormGroup
          type="text"
          name="trackingNumbers"
          label="Tracking numbers"
          placeholder="Enter trackings, sep. by commas"
          formGroupClasses="searchInput"
        />

        <ReduxFormFloatingLabelFormGroup
          type="text"
          name="invoiceNumbers"
          label="Invoice numbers"
          placeholder="Enter invoices, sep. by commas"
          formGroupClasses="searchInput"
        />

        <ReduxFormFloatingLabelFormGroup
          type="text"
          name="originPostalCodes"
          label="Origin postal code"
          placeholder="Enter post-codes, sep. by commas"
          formGroupClasses="searchInput"
        />

        <ReduxFormFloatingLabelFormGroup
          type="text"
          name="destinationPostalCodes"
          label="Destination postal code"
          placeholder="Enter post-codes, sep. by commas"
          formGroupClasses="searchInput"
        />

        <FormGroup className="searchInput">
          <FormLabel>Shipment cost</FormLabel>
          <ReduxFormToggleButton
            optionList={SHIPMENT_COST_OPTIONS}
            value={this.props.shipmentCostOption}
            fieldName={'shipmentCostOption'}
            change={this.props.change}
          />
          <div style={{ width: '100%' }}>
            <Field
              className="form-control pickedUpDatePicker"
              name="shipmentCost"
              component="input"
              placeholder="Enter amount"
              type="number"
              style={{ width: '100%' }}
            />
          </div>
        </FormGroup>

        <FormGroup>
          <Button
            type="submit"
            size="large"
            variant="contained"
            disabled={this.props.submitting || this.props.pristine}
            isLoading={this.props.isLoading}
          >
            Apply
          </Button>
        </FormGroup>
        <FormGroup>
          <Button
            size="small"
            variant="outlined"
            onClick={(e) => {
              e.preventDefault();
              this.resetForm();
            }}
          >
            Reset form
          </Button>
        </FormGroup>
      </form>
    );
  }

  saveFilters(data) {
    this.props.dispatch(clearErrors());

    if ((data.startDate && !data.endDate) || (!data.startDate && data.endDate)) {
      enqueueSnackbar(
        'Please enter 8 digits separated with “/” signs, in such a format: MM/DD/YYYY.',
        { variant: 'error' }
      );
      return;
    }

    this.props.dispatch({
      type: 'SHIPMENT_DETAILS__FILTERS__UPDATE',
      payload: {
        ...data,
      },
    });
  }

  /**
   * Lets the parent know that a dropdown was opened.
   */
  handleOpenDropdown() {
    this.props.onDropdownOpen && this.props.onDropdownOpen();
  }

  /**
   * Lets the parent know that a dropdown was closed.
   *
   * Note that this function uses a massive hack by delaying calling the
   * callback by 200ms. We need to do this because we want to prevent clicks on
   * the filter menu's background overlay from closing the filter menu while the
   * dropdown is open. In order to do so, we need to make sure the click event
   * on the overlay is processed *before* we notify the parent that the dropdown
   * has closed (hence delaying the callback).
   */
  handleCloseDropdown() {
    setTimeout(() => {
      this.props.onDropdownClose && this.props.onDropdownClose();
    }, 200);
  }

  resetForm() {
    const payload = {
      vendorAccountNumbers: [],
      dateRangeOption: DATE_RANGE_OPTIONS[0].value,
      vendor: null,
      invoiceNumbers: null,
      startDate: formattedFourWeeksAgo,
      endDate: formattedCurrentDate,
      destinationType: null,
      selectedServices: null,
      selectedBillingEntries: null,
      trackingNumbers: null,
      destinationPostalCodes: null,
      originPostalCodes: null,
      shipmentCost: null,
      selectedStatuses: null,
      shipmentCostOption: SHIPMENT_COST_OPTIONS[0].value,
    };
    Object.entries(payload).forEach(([fieldKey, fieldValue]) => {
      this.props.dispatch(this.props.change(fieldKey, fieldValue));
    });
    this.props.dispatch({
      type: 'SHIPMENT_DETAILS__FILTERS__UPDATE',
      payload,
    });
  }
}

const clearErrors = () => {
  return (dispatch) => {
    dispatch({
      type: 'SHIPMENT_DETAILS__FILTERS_ERRORS__UPDATE',
      payload: {},
    });
  };
};

const selector = formValueSelector('ShipmentDetails.FilterSidebarForm');

const mapStateToProps = (state) => {
  return {
    initialValues: {
      ...(state.ShipmentDetails.filters || {}),

      startDate: formattedFourWeeksAgo,
      endDate: formattedCurrentDate,
    },
    shipmentCostOption: selector(state, 'shipmentCostOption'),
    errors: state.ShipmentDetails.errors,
    dateRangeOption: selector(state, 'dateRangeOption'),
    vendorAccountNumberMappings: state.vendorAccountNumberMappings,
    services: state.Services.services,
    billingEntries: state.Services.billingEntries,
    isLoading: state.ShipmentDetails.isLoading,
  };
};

ShipmentDetailsFilterSidebarForm = reduxForm({
  form: REDUX_FORM_NAME,
  enableReinitialize: false,
})(ShipmentDetailsFilterSidebarForm);

export default connect(mapStateToProps)(ShipmentDetailsFilterSidebarForm);
