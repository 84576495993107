import React, { useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import _cloneDeep from 'lodash/cloneDeep';
import { CIRCLE_CHARTS, MIN_DATA_FOR_LEGEND } from '../Constants';
import ChartJS from 'chart.js/auto';
import {
  Button,
  Checkbox,
  Chip,
  Divider,
  Slider,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ic_format_align_left } from 'react-icons-kit/md/ic_format_align_left';
import { ic_format_align_center } from 'react-icons-kit/md/ic_format_align_center';
import { ic_format_align_right } from 'react-icons-kit/md/ic_format_align_right';
import { ic_format_italic } from 'react-icons-kit/md/ic_format_italic';
import { ic_format_bold } from 'react-icons-kit/md/ic_format_bold';
import { ic_clear } from 'react-icons-kit/md/ic_clear';
import Icon from '../../Icon';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import {
  CHART_SIZE_HORIZONTAL,
  CHART_SIZE_SQUARE,
  CHART_SIZE_VERTICAL,
  chartSizeList,
  legendList,
  titleMarks,
} from './constants';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const SaveChart = (props) => {
  const randomKeyRef = useRef(Math.random());
  const virtualChartCanvasRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [typeLegend, setTypeLegend] = useState(props.initSaveChartState.typeLegend);
  const [titleSize, setTitleSize] = useState(props.initSaveChartState.titleSize);
  const [titleAlignment, setTitleAlignment] = useState(
    props.initSaveChartState.titleAlignment
  );
  const [titleFormats, setTitleFormats] = useState(props.initSaveChartState.titleFormats);
  const [chartAspectRatio, setChartAspectRatio] = useState(
    props.initSaveChartState.chartAspectRatio
  );

  const handleTitleFormat = (event, newFormats) => {
    setTitleFormats(newFormats);
  };

  const handleTitleAlignmentAlignment = (event, newAlignment) => {
    setTitleAlignment(newAlignment || 'center');
  };

  const saveChart = () => {
    if (props.onSaveChart) {
      props.onSaveChart({
        typeLegend,
        titleSize,
        titleAlignment,
        titleFormats,
        chartAspectRatio,
      });
    }
  };

  const saveVirtualChartCanvas = () => {
    setIsLoading(true);

    saveChart();

    const virtualCanvas = virtualChartCanvasRef.current;
    const ctx = virtualCanvas.getContext('2d');

    const cloneConfig = _cloneDeep(props.config);

    //title
    cloneConfig.options.plugins.title = {
      text: titleFormats.includes('uppercase') ? props.title.toUpperCase() : props.title,
      display: titleAlignment !== 'none',
      align: titleAlignment !== 'none' ? titleAlignment : 'center',
      font: {
        size: titleSize,
        ...(titleFormats.includes('italic') ? { style: 'italic' } : {}),
        weight: titleFormats.includes('bold') ? 'bold' : 'normal',
      },
    };

    //legend
    cloneConfig.options.plugins.legend.display = !!typeLegend;
    if (!!typeLegend) {
      cloneConfig.options.plugins.legend.labels = {
        ...cloneConfig.options.plugins.legend.labels,
        boxWidth: typeLegend - 4,
        boxHeight: typeLegend - 4,
        font: {
          size: typeLegend,
          lineHeight: 1,
        },
      };
    }

    //save after render
    cloneConfig.options.animation = {
      onComplete: () => {
        onSave();
      },
    };

    const chartJSClone = new ChartJS(ctx, cloneConfig);

    function onSave() {
      const image = virtualCanvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = `${props.title}.png`;
      link.click();
      link.remove();
      chartJSClone.destroy();
      setIsLoading(false);
    }
  };

  const onTitleChange = (event, newValue) => {
    setTitleSize(newValue);
  };

  return (
    <Stack spacing={2}>
      <Stack
        spacing={1}
        sx={{ borderRadius: '6px', padding: '6px 6px 24px', backgroundColor: '#f4f6f8' }}
      >
        <Typography variant="button" display="block" sx={{ fontWeight: 600 }}>
          Title
        </Typography>
        <Paper
          elevation={0}
          sx={{
            display: 'flex',
            border: (theme) => `1px solid ${theme.palette.divider}`,
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <StyledToggleButtonGroup
            size="small"
            value={titleAlignment}
            exclusive
            onChange={handleTitleAlignmentAlignment}
            aria-label="text formatting"
          >
            <ToggleButton value="none" aria-label="left aligned" color="warning">
              <Icon icon={ic_clear} size={20} />
            </ToggleButton>
          </StyledToggleButtonGroup>
          <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
          <StyledToggleButtonGroup
            size="small"
            value={titleAlignment}
            exclusive
            onChange={handleTitleAlignmentAlignment}
            aria-label="text alignment"
          >
            <ToggleButton value="start" aria-label="left aligned">
              <Icon icon={ic_format_align_left} size={20} />
            </ToggleButton>
            <ToggleButton value="center" aria-label="centered">
              <Icon icon={ic_format_align_center} size={20} />
            </ToggleButton>
            <ToggleButton value="end" aria-label="right aligned">
              <Icon icon={ic_format_align_right} size={20} />
            </ToggleButton>
          </StyledToggleButtonGroup>
          <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
          <StyledToggleButtonGroup
            size="small"
            value={titleFormats}
            onChange={handleTitleFormat}
            aria-label="text formatting"
          >
            <ToggleButton
              value="bold"
              aria-label="bold"
              disabled={titleAlignment === 'none'}
              sx={{
                ...(titleAlignment === 'none' ? { color: '#bdbdbd !important' } : {}),
              }}
            >
              <Icon icon={ic_format_bold} size={20} />
            </ToggleButton>
            <ToggleButton
              value="italic"
              aria-label="italic"
              disabled={titleAlignment === 'none'}
              sx={{
                ...(titleAlignment === 'none' ? { color: '#bdbdbd !important' } : {}),
              }}
            >
              <Icon icon={ic_format_italic} size={20} />
            </ToggleButton>
            <ToggleButton
              value="uppercase"
              aria-label="uppercase"
              disabled={titleAlignment === 'none'}
              sx={{
                ...(titleAlignment === 'none' ? { color: '#bdbdbd !important' } : {}),
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>AA</Typography>
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Paper>
        <Stack spacing={2} direction="row">
          <Typography
            variant="button"
            display="block"
            sx={{
              fontWeight: 400,
              textWrap: 'nowrap',
              ...(titleAlignment === 'none' ? { color: '#bdbdbd' } : {}),
            }}
          >
            Size (px)
          </Typography>
          <Slider
            step={2}
            marks={titleMarks}
            min={8}
            max={64}
            onChange={onTitleChange}
            value={titleSize}
            size="small"
            valueLabelDisplay="auto"
            color="primary"
            disabled={titleAlignment === 'none'}
          />
        </Stack>
      </Stack>

      <Stack
        spacing={1}
        sx={{
          borderRadius: '6px',
          padding: '6px',
          backgroundColor: '#f4f6f8',
        }}
      >
        <Typography variant="button" display="block" sx={{ fontWeight: 600 }}>
          Legend
        </Typography>
        <Stack spacing={1} direction="row" alignItems="center">
          {legendList.map((legendEl) => (
            <Chip
              sx={{
                borderRadius: '6px',
                fontWeight: '500 !important',
                flexGrow: '1',
              }}
              key={`${randomKeyRef}_SaveChart_title_${props.title}_${legendEl.label}`}
              label={legendEl.label}
              variant={typeLegend === legendEl.value ? 'filled' : 'outlined'}
              onClick={() => setTypeLegend(legendEl.value)}
              color={typeLegend === legendEl.value ? legendEl.color : 'primary'}
            />
          ))}
        </Stack>
      </Stack>
      <Stack
        spacing={1}
        sx={{ borderRadius: '6px', padding: '6px', backgroundColor: '#f4f6f8' }}
      >
        <Typography variant="button" display="block" sx={{ fontWeight: 600 }}>
          Chart size
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridTemplateRows: 'repeat(3, 1fr)',
            gridColumnGap: '0px',
            gridRowGap: '0px',
            alignItems: 'center',
          }}
        >
          {[CHART_SIZE_HORIZONTAL, CHART_SIZE_SQUARE, CHART_SIZE_VERTICAL].map(
            (chartTypeSize) => (
              <React.Fragment
                key={`${randomKeyRef}_ToggleButtonGroup_chartAspectRatio_${props.title}_${chartTypeSize}`}
              >
                <Typography
                  variant="button"
                  display="block"
                  sx={{
                    textTransform: 'capitalize',
                    fontWeight: 400,
                    textWrap: 'nowrap',
                  }}
                >
                  {chartTypeSize}
                </Typography>

                {chartSizeList
                  .filter((chartSize) => chartSize.type === chartTypeSize)
                  .map((chartAspectRatioItem) => (
                    <Button
                      key={`${randomKeyRef}_ToggleButtonGroup_chartAspectRatioItem_${props.title}_${chartAspectRatioItem.key}`}
                      variant="contained"
                      size="small"
                      sx={{
                        ...(chartAspectRatio.key !== chartAspectRatioItem.key
                          ? {
                              background: 'transparent !important',
                              color: '#4691da !important',
                              '&:hover': {
                                color: '#fff !important',
                                background: 'var(--galleon-blue-dark-1) !important',
                              },
                            }
                          : {}),
                        fontWeight: '500 !important',
                        padding: '4px 6px',
                      }}
                      color={'primary'}
                      onClick={() => setChartAspectRatio(chartAspectRatioItem)}
                    >
                      {chartAspectRatioItem.x} × {chartAspectRatioItem.y}
                    </Button>
                  ))}
              </React.Fragment>
            )
          )}
        </Box>
      </Stack>

      {props.saveChecboxes}

      <LoadingButton
        sx={{ marginTop: '30px' }}
        loading={isLoading}
        variant="contained"
        onClick={saveVirtualChartCanvas}
        // fullWidth
      >
        Download
      </LoadingButton>

      {/* virtualChartCanvas for render as img */}
      <Box
        sx={{
          width: `${chartAspectRatio.x}px`,
          height: `${chartAspectRatio.y}px`,
          position: 'absolute',
          top: '-9999px',
        }}
      >
        <canvas ref={virtualChartCanvasRef} />
      </Box>
    </Stack>
  );
};

const GLOBAL_KEY_SESSION = 'global_SaveChartSettings';

const SaveChartStorageHoc = (props) => {
  const localStorageKeySettingsLocalChart = useMemo(() => {
    return `${props.title}_SaveChartSettings`;
  }, [props.title]);

  const initSaveChartState = useMemo(() => {
    if (localStorage.getItem(localStorageKeySettingsLocalChart)) {
      return JSON.parse(localStorage.getItem(localStorageKeySettingsLocalChart));
    }
    if (localStorage.getItem(GLOBAL_KEY_SESSION)) {
      return JSON.parse(localStorage.getItem(GLOBAL_KEY_SESSION));
    }

    return {
      isSaveSettingsThisChart: true,
      isSaveSettingsAllChart: false,
      typeLegend:
        props.chartConfig.data.datasets >= MIN_DATA_FOR_LEGEND ||
        CIRCLE_CHARTS.includes(props.chartConfig.type)
          ? legendList[1].value
          : 0,
      titleSize: 16,
      titleAlignment: 'center',
      titleFormats: ['bold'],
      chartAspectRatio: chartSizeList.find(
        (chartSizeEl) => chartSizeEl.key === 'horizontal_medium'
      ),
    };
  }, [localStorageKeySettingsLocalChart]);

  const [isSaveSettingsThisChart, setIsSaveSettingsThisChart] = useState(
    initSaveChartState.isSaveSettingsThisChart || false
  );
  const [isSaveSettingsAllChart, setIsSaveSettingsAllChart] = useState(
    initSaveChartState.isSaveSettingsAllChart || false
  );

  const handleChangeCheckbox = (fn) => (event) => {
    fn(event.target.checked);
  };

  const onSaveChart = (callbackData) => {
    if (isSaveSettingsAllChart) {
      callbackData.isSaveSettingsAllChart = isSaveSettingsAllChart;
      localStorage.setItem(GLOBAL_KEY_SESSION, JSON.stringify(callbackData));
    } else if (isSaveSettingsThisChart) {
      callbackData.isSaveSettingsThisChart = isSaveSettingsThisChart;
      localStorage.setItem(
        localStorageKeySettingsLocalChart,
        JSON.stringify(callbackData)
      );
    }

    if (localStorage.getItem(GLOBAL_KEY_SESSION) && !isSaveSettingsAllChart) {
      localStorage.removeItem(GLOBAL_KEY_SESSION);
    }
    if (
      localStorage.getItem(localStorageKeySettingsLocalChart) &&
      !isSaveSettingsThisChart
    ) {
      localStorage.removeItem(localStorageKeySettingsLocalChart);
    }

    if (props.onSaveChart) {
      props.onSaveChart();
    }
  };

  return (
    <SaveChart
      initSaveChartState={initSaveChartState}
      {...props}
      onSaveChart={onSaveChart}
      saveChecboxes={
        <FormGroup
          sx={{
            label: {
              marginBottom: 0,
            },
            '& .MuiCheckbox-root': {
              padding: '4px 9px',
            },
          }}
        >
          <Stack spacing={1}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={isSaveSettingsAllChart}
                  checked={isSaveSettingsThisChart}
                  onChange={handleChangeCheckbox(setIsSaveSettingsThisChart)}
                />
              }
              label={
                <span>
                  Save the settings for <b>this</b> chart
                </span>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSaveSettingsAllChart}
                  onChange={handleChangeCheckbox(setIsSaveSettingsAllChart)}
                />
              }
              label={
                <span>
                  Save the settings for <b>all</b> charts
                </span>
              }
            />
          </Stack>
        </FormGroup>
      }
    />
  );
};

export default SaveChartStorageHoc;
