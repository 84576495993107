import { useMemo } from 'react';

const statusFormatter = (row) => {
  if (row.isOwner) {
    return 'Owner';
  } else if (row.isRegistered) {
    return 'Registered';
  } else {
    return 'Invitation sent';
  }
};

export const useUsersTableRows = (users) =>
  useMemo(
    () =>
      users?.reduce((prevState, currentValue) => {
        const currentRow = {
          id: currentValue.uuid,
          name: currentValue.name,
          email: currentValue.email,
          status: statusFormatter(currentValue),
          actions: currentValue,
          isOwner: currentValue.isOwner,
          currentValue,
        };
        prevState.push(currentRow);
        return prevState;
      }, []),
    [users]
  );
