import React, { memo } from 'react';
import Box from '@mui/material/Box';

const MultiLineHeader = ({ list = [], sx = {} }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      span: { lineHeight: 'normal' },
      ...sx,
    }}
  >
    {list.map((el) => (
      <span key={`MultiLineHeader_${el}`}>{el}</span>
    ))}
  </Box>
);

export default memo(MultiLineHeader);
