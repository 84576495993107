import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { ic_vertical_align_bottom } from 'react-icons-kit/md/ic_vertical_align_bottom';
import { ACCESSORIALS, DIM, getDispValue } from 'common/helpers/constants';
import { AccessorialsTable } from './AccessorialsTable';
import { DIMDivisorTable } from './DIMDivisorTable';
import Icon from 'common/components/Icon';
import { calculateAccessorials, calculateDIM } from '../../store/slices/AnalysisSlice';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Button from 'common/components/Button';
import { setFeedbackModalOpen } from '../../store/actions';
import { Typography } from '@mui/material';

const toMoment = (val) => moment(val);

const defaultFilters = {
  additionalFee: '',
  ratesYearId: '',
};

const Wrapper = styled.div`
  .subtitle {
    margin-bottom: 20px;
  }

  .navigation {
    margin-top: 50px;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #c4c4c4;
    border-width: 1px;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #c4c4c4;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #757575;
  }

  .MuiFormControlLabel-label.Mui-disabled {
    color: #333;
  }

  .MuiCheckbox-root.MuiIconButton-root.Mui-disabled {
    color: #757575;
  }
`;

const InfoContainer = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 50px;
`;

const ServicesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 50px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContractInfo = ({
  contract,
  contractId,
  services,
  ratesYears,
  calculationAccessorials,
  calculationDIM,
}) => {
  const [filters, setFilters] = useState(defaultFilters);
  const [isCalculated, setIsCalculated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const updateFilters = useCallback(
    (event) =>
      setFilters((filters) => {
        return { ...filters, [event.target.name]: event.target.value };
      }),
    []
  );

  const calculateFee = useCallback(() => {
    const callback = () => {
      setIsLoading(false);
    };

    if (filters.additionalFee === ACCESSORIALS) {
      setIsLoading(true);
      dispatch(calculateAccessorials(contractId, filters.ratesYearId, callback));
    } else if (filters.additionalFee === DIM) {
      setIsLoading(true);
      dispatch(calculateDIM(contractId, filters.ratesYearId, callback));
    }
    setIsCalculated(true);
  }, [dispatch, filters.ratesYearId, filters.additionalFee, contractId]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, [contractId]);

  useEffect(() => {
    setIsCalculated(false);
  }, [filters]);

  return (
    <Wrapper>
      {contract && (
        <Box
          sx={{
            padding: '27px',
            background: 'var(--galleon-blue-1)',
            position: 'relative',
            borderRadius: '22px',
            maxWidth: '1110px',
          }}
        >
          <Grid container spacing={4}>
            <Grid xs={6}>
              <Table bordered={true}  className={'detailsTable_table'}>
                <thead className={'detailsTable_thead'}>
                  <tr>
                    <td className={`heading`} colSpan={2}>
                      Contract details
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={'detailsTable_row-title'} style={{ width: '180px' }}>
                      Contract number
                    </td>
                    <td className={'detailsTable_row-text'}>{contract.number || ''}</td>
                  </tr>
                  <tr>
                    <td className={'detailsTable_row-title'} style={{ width: '180px' }}>
                      Carrier
                    </td>
                    <td className={'detailsTable_row-text'}>
                      {getDispValue(contract.carrier.name) || ''}
                    </td>
                  </tr>
                  <tr>
                    <td className={'detailsTable_row-title'} style={{ width: '180px' }}>
                      Effective date
                    </td>
                    <td className={'detailsTable_row-text'}>
                      {contract.contractDate ? toMoment(contract.contractDate) : null}
                    </td>
                  </tr>

                  <tr>
                    <td className={'detailsTable_row-title'} style={{ width: '180px' }}>
                      Available services
                    </td>
                    <td
                      className={'detailsTable_row-text'}
                      style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}
                    >
                      {services &&
                        contract.services
                          .filter((service) => services.find((s) => service.id === s.id))
                          .map((s) => <Box key={s.name}>{s.name}</Box>)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Grid>
            <Grid xs={6}>
              <Stack spacing={2}>
                <h3 className="heading">Information on additional fees</h3>

                <TextField
                  className="filters-input"
                  name="additionalFee"
                  value={filters.additionalFee}
                  onChange={updateFilters}
                  label="Additional fee"
                  size="small"
                  select
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                >
                  <MenuItem value={ACCESSORIALS}>Accessorials</MenuItem>
                  <MenuItem value={DIM}>DIM divisor</MenuItem>
                </TextField>

                <TextField
                  className="filters-input"
                  name="ratesYearId"
                  value={filters.ratesYearId}
                  onChange={updateFilters}
                  label="Rates base year"
                  size="small"
                  select
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                >
                  {ratesYears && ratesYears.length > 0 ? (
                    ratesYears.map((year) => (
                      <MenuItem key={year.id} value={year.id}>
                        {year.effectiveDate}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No rates found</MenuItem>
                  )}
                </TextField>

                <Button
                  color="primary"
                  variant="contained"
                  disabled={!filters.additionalFee || !filters.ratesYearId}
                  isLoading={isLoading}
                  onClick={calculateFee}
                >
                  Calculate
                </Button>
              </Stack>
            </Grid>

            <Grid xs={12}>
              {isCalculated &&
                filters.additionalFee === ACCESSORIALS &&
                calculationAccessorials &&
                calculationAccessorials[contractId] &&
                calculationAccessorials[contractId][filters.ratesYearId] && (
                  <Stack spacing={1}>
                    <TitleContainer>
                      <h3 className="heading">Accessorials discounts</h3>
                      <div>
                        <Button
                          className="new_button_icon"
                          component={Link}
                          to={`/contracts/calculation/accessorials/${contractId}/${filters.ratesYearId}/download/`}
                          disabled={
                            calculationAccessorials[contractId][filters.ratesYearId]
                              .length === 0
                          }
                          size="small"
                        >
                          <Icon
                            size={20}
                            icon={ic_vertical_align_bottom}
                            style={{
                              color: 'var(--galleon-blue-1)',
                            }}
                          />
                        </Button>
                      </div>
                    </TitleContainer>

                    <AccessorialsTable
                      accessorials={
                        calculationAccessorials[contractId][filters.ratesYearId]
                      }
                    />
                  </Stack>
                )}

              {isCalculated &&
                filters.additionalFee === DIM &&
                calculationDIM &&
                calculationDIM[contractId] &&
                calculationDIM[contractId][filters.ratesYearId] && (
                  <Stack spacing={1}>
                    <TitleContainer>
                      <h3 className="heading">DIM divisors</h3>
                      <div>
                        <Button
                          className="new_button_icon"
                          component={Link}
                          to={`/contracts/calculation/dim_divisors/${contractId}/${filters.ratesYearId}/download/`}
                          disabled={
                            calculationDIM[contractId][filters.ratesYearId].length === 0
                          }
                          target="_blank"
                          size="small"
                        >
                          <Icon
                            size={20}
                            icon={ic_vertical_align_bottom}
                            style={{
                              color: 'var(--galleon-blue-1)',
                            }}
                          />
                        </Button>
                      </div>
                    </TitleContainer>

                    <DIMDivisorTable
                      DIM={calculationDIM[contractId][filters.ratesYearId]}
                    />
                  </Stack>
                )}
            </Grid>
          </Grid>

          <Typography className="navigation" variant="subtitle2" align="center">
            Need more services?{' '}
            <Box
              component="span"
              sx={{
                cursor: 'pointer',
                fontWeight: '14px',
                color: 'var(--galleon-blue-dark-1)',
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
              onClick={() => {
                dispatch(setFeedbackModalOpen(true));
              }}
            >
              Feel free to contact us
            </Box>
          </Typography>
        </Box>
      )}
    </Wrapper>
  );
};
