'use strict';

import _omit from 'lodash/omit';
import autobind from 'react-autobind';
import moment from 'moment';
import React from 'react';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import { START_DATE, END_DATE } from 'react-dates/constants';
import { Fields } from 'redux-form';
import 'react-dates/initialize';

import * as utils from 'common/helpers/utils';
import cn from 'classnames';
import Tooltip from '@mui/material/Tooltip';

class DateRangePickerWrapper extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      focusedInput: null,
    };
  }

  handleDatesChange(dates) {
    this.getStartDateInput().onChange(dates.startDate);
    this.getEndDateInput().onChange(dates.endDate);
  }

  changePlaceholderTextOnFocus(inputName) {
    if (this.state.focusedInput === inputName) {
      return 'MM/DD/YYYY';
    }
    return null;
  }

  handleFocusChange(focusedInput) {
    this.setState({ focusedInput });
    if (focusedInput === START_DATE) {
      this.getStartDateInput().onFocus();
    } else if (focusedInput === END_DATE) {
      this.getEndDateInput().onFocus();
    }
  }

  initialVisibleMonth() {
    if (this.state.focusedInput === START_DATE) {
      return this.getStartDateValue() || moment();
    } else {
      return this.getEndDateValue() || moment();
    }
  }

  render() {
    let {
      // These refer to props that were passed in from a parent. We should throw
      // them away.
      format,
      normalize,

      // Pulls out the version of onDatesChange that might get called in addition
      // to our custom actions.
      onDatesChange,
      onFocusChange,

      startDateFieldName,
      endDateFieldName,
      ...props
    } = this.props;

    // <Field /> adds separate top-level props for the field names, which we
    // shouldn't pass to `DateRangePicker`.
    props = _omit(props, [startDateFieldName, endDateFieldName]);

    return (
      <Tooltip
        disableHoverListener
        title={
          'Please enter 8 digits separated with “/” signs, in such a format: MM/DD/YYYY'
        }
        placement="top"
      >
        <div
          className={cn(
            { isDateRangePickerFull: this.getStartDateValue() && this.getEndDateValue() },
            props.className || ''
          )}
        >
          <DateRangePicker
            startDateId="Start date range"
            endDateId="End date range"
            startDate={this.getStartDateValue()}
            endDate={this.getEndDateValue()}
            startDatePlaceholderText={
              this.changePlaceholderTextOnFocus(startDateFieldName) || 'Start date'
            }
            endDatePlaceholderText={
              this.changePlaceholderTextOnFocus(endDateFieldName) || 'End date'
            }
            focusedInput={this.state.focusedInput || null}
            onDatesChange={(dates) => {
              this.handleDatesChange(dates);
              onDatesChange && onDatesChange(dates);
            }}
            onFocusChange={(focusedInput) => {
              this.handleFocusChange(focusedInput);
            }}
            initialVisibleMonth={this.initialVisibleMonth}
            hideKeyboardShortcutsPanel={true}
            minimumNights={0}
            {...props}
          />
        </div>
      </Tooltip>
    );
  }

  getStartDateValue() {
    return this.getStartDateInput().value || null;
  }

  getEndDateValue() {
    return this.getEndDateInput().value || null;
  }

  getStartDateInput() {
    return utils.getByPath(this.props, this.props.startDateFieldName).input;
  }

  getEndDateInput() {
    return utils.getByPath(this.props, this.props.endDateFieldName).input;
  }
}

/**
 * Formatter function to convert values to `moment` objects prior to passing
 * them them to the `DateRangePicker`.
 */
export const formatDates = (value) => {
  return value ? moment(value) : value;
};

/**
 * Normalizer function for converting dates in ISO string format prior to
 * storing them in redux.
 */
export const normalizeDates = (value) => {
  return value ? value.format('YYYY-MM-DD') : value;
};

const renderDates = (props) => {
  var { names, ...props } = props,
    [startDateFieldName, endDateFieldName] = names;

  return (
    <DateRangePickerWrapper
      {...props}
      startDateFieldName={startDateFieldName}
      endDateFieldName={endDateFieldName}
    />
  );
};

const ReduxFormDateRangePicker = (props) => {
  return (
    <Fields
      {...props}
      component={renderDates}
      parse={normalizeDates}
      format={formatDates}
    />
  );
};

export default ReduxFormDateRangePicker;
