import React, { useMemo, useState } from 'react';
import { useGLCodesTableColumns } from './hooks/useGLCodesTableColumns';
import { useGLCodesTableRows } from './hooks/useGLCodesTableRows';
import DataGridWrapper from 'common/components/table/DataGridWrapper';
import DataGridCustom from 'common/components/table/DataGridCustom';

const SIZE_PER_PAGE = 30;

export const GLCodesTable = (props) => {
  const [sortModel, setSortModel] = useState([
    {
      field: 'code',
      sort: 'asc',
    },
  ]);

  const rows = useGLCodesTableRows(props.data);
  const codeName = rows.length === 0 ? 'GL Code' : rows[0].codeName;
  const columns = useGLCodesTableColumns(props.onDelete, props.onEdit, codeName);

  const countRowsPeerPage = useMemo(() => {
    return rows.length ? (rows.length > 30 ? 30 : rows.length) : null;
  }, [rows]);

  return (
    <DataGridWrapper
      // customPudding={100}
      countRowsPeerPage={countRowsPeerPage}
    >
      <DataGridCustom
        rows={rows}
        columns={columns}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        settings={{
          initialState: {
            pagination: { paginationModel: { pageSize: SIZE_PER_PAGE } },
          },
          pageSizeOptions: [SIZE_PER_PAGE],
          hideFooter: false,
        }}
      />
    </DataGridWrapper>
  );
};
