import React, { useCallback, Fragment } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import cn from 'classnames';
import { useCalculation } from 'common/helpers/hooks';

const Container = styled(TableContainer)`
  max-height: 80vh;
`;

const Info = styled(Typography)`
  margin: 100px;
  text-align: center;
`;

const ColoredTableCell = styled(TableCell)`
  &.blue-cell {
    background-color: ${({ opacity }) =>
      `rgba(55, 105, 186, ${Math.ceil(opacity * 10) / 10})`};
    color: ${({ opacity }) => (Math.ceil(opacity * 10) / 10) > 0.5 && 'white'};
  }

  &.red-cell {
    background-color: ${({ opacity }) =>
      `rgba(193, 73, 24, ${Math.ceil(opacity * 10) / 10})`};
    color: ${({ opacity }) => (Math.ceil(opacity * 10) / 10) > 0.7 && 'white'};
  }
`;

export const CalculationTable = ({
  data,
  zones,
  weight,
  maxValues,
  minValues,
  compareError = null,
  selectedCalculationIndex,
}) => {
  const { isCompare, isA, isB } = useCalculation(selectedCalculationIndex);

  const setOpacity = useCallback(
    (num) => {
      const mainDifference = maxValues - minValues;
      const itemDifference = maxValues - num;

      if (num === minValues || num === 0) {
        return 0.1;
      }

      return Math.abs((100 - (itemDifference * 100) / mainDifference) / 100);
    },
    [maxValues, minValues]
  );

  return (
    <div>
      <Container component={Paper}>
        {data ? (
          <Fragment>
            {data.length !== 0 ? (
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {[...zones]
                      .map((zone) => (
                        <TableCell align="center" key={zone}>
                          {zone}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{weight[index]}</TableCell>
                      {row.map((item, index) => {
                        return (
                          <ColoredTableCell
                            align="center"
                            key={index}
                            value={item}
                            opacity={setOpacity(Number(item))}
                            className={cn(
                              isCompare
                                ? {
                                    'blue-cell': item > 0,
                                    'red-cell': item < 0,
                                  }
                                : {
                                    'blue-cell': isB,
                                    'red-cell': isA,
                                  }
                            )}
                          >
                            <span>${item}</span>
                          </ColoredTableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Info variant="h6">No rates found...</Info>
            )}
          </Fragment>
        ) : (
          <Info variant="h6">{compareError || 'Waiting for your rates...'}</Info>
        )}
      </Container>
    </div>
  );
};
