import React, { useMemo } from 'react';
import HelpIcon from 'common/components/HelpIcon';

import DashboardContainer from './DashboardContainer';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { yAxisFormatMap } from 'sudo/common/chartConstants';
import LastPeriodComponentWrapper from '../components/LastPeriodComponentWrapper';
import { Divider } from '@mui/material';
import { connect, useSelector } from 'react-redux';
import { toCamelCaseInArray } from 'common/helpers/utils';

const Overview = (props) => {
  const shipper = useSelector((state) => state.user)?.shipper;

  const queryNames = useMemo(
    () => props.tabList?.[0]?.queryNames,
    [props.tabList.length]
  );

  const content = useMemo(() => {
    return props.tabList.reduce(
      (prevState, tab) => {
        prevState[tab.path] = toCamelCaseInArray(tab.value);
        return prevState;
      },
      { modal: [], header: [], chip: [] }
    );
  }, [props.tabList.length]);

  return (
    <DashboardContainer
      title="Overview"
      contentId="Overview"
      seconHeaderContent={
        shipper.subscription === 'DEMO' &&
        'Metrics percentage change feature (% from last period) is available only in PRO plan.'
      }
      secondHeaderClass={'secondHeaderWithoutAfter'}
      queryNames={queryNames}
      renderGrid={RenderGrid}
      contentTab={content}
      chartsPerRow={4}
      isRenderOldGrid
    />
  );
};

const RenderGrid = ({ metrics, contentTab }) => {
  const sorter = (contentTabList) => {
    return contentTabList.sort((firstItemKey, secondItemKey) => {
      const firstMetric = metrics[firstItemKey];
      const secondMetric = metrics[secondItemKey];
      if (!firstMetric || !secondMetric) {
        return 0;
      }

      return firstMetric.index - secondMetric.index;
    });
  };

  return (
    <Box className="overviewWrapper">
      {!!contentTab.header.length && (
        <Paper
          elevation={0}
          className="ChartsPaper headerChartsPaper"
          sx={{ 'grid-column': `span ${contentTab.header.length}` }}
        >
          {sorter(contentTab.header).map((headerItemKey) => {
            const currentMetric = metrics[headerItemKey];
            if (!currentMetric) {
              return null;
            }

            return (
              <OverviewMetric
                key={headerItemKey}
                data={currentMetric}
                name={currentMetric.title}
                helpText={currentMetric.helpText}
                viewMetricComponent={HeaderViewMetricComponent}
                isShowTooltip
                isWithoutPaper
              />
            );
          })}
        </Paper>
      )}
      {!!contentTab.modal.length && (
        <Paper
          elevation={0}
          className="ChartsPaper sideChartPaper"
          sx={{ 'grid-row': `span ${contentTab.modal.length / 2}` }}
        >
          {sorter(contentTab.modal).map((modalItemKey, index) => {
            const currentMetric = metrics[modalItemKey];
            if (!currentMetric) {
              return null;
            }
            const numberInArray = index + 1;
            return (
              <>
                <Box className="sideViewMetric" key={modalItemKey}>
                  <OverviewMetric
                    data={currentMetric}
                    name={currentMetric.title}
                    helpText={currentMetric.helpText}
                    viewMetricComponent={SideGroupPaperMetric}
                    isShowTooltip
                    className="transparentChartsPaper"
                  />
                </Box>
                {numberInArray % 2 === 0 && contentTab.modal.length !== numberInArray && (
                  <Divider className="sideViewMetricDivider" />
                )}
              </>
            );
          })}
        </Paper>
      )}

      {sorter(contentTab.chip).map((chipItemKey) => {
        const currentMetric = metrics[chipItemKey];
        if (!currentMetric) {
          return null;
        }
        return (
          <OverviewMetric
            key={chipItemKey}
            data={currentMetric}
            name={currentMetric.title}
            helpText={currentMetric.helpText}
            viewMetricComponent={StandartViewMetricComponent}
            isShowTooltip
            className="ChartsPaper standartChartsPaper"
          />
        );
      })}
    </Box>
  );
};

const OverviewMetric = (props) => {
  if (!props.data?.value) {
    return null;
  }

  let helpIcon = null;
  if (props.helpText) {
    helpIcon = <HelpIcon>{props.helpText}</HelpIcon>;
  }

  let prevValue = null;
  let value;
  if (props.data.value === null) {
    value = '\u2014';
  } else if (props.data.format) {
    value = yAxisFormatMap[props.data.format](props.data.value);
    if (props.data.oldValue) {
      prevValue = yAxisFormatMap[props.data.format](props.data.oldValue);
    }
  } else {
    value = props.data.value;
  }
  const Component = useMemo(() => {
    return props.viewMetricComponent;
  }, []);

  if (props.isWithoutPaper) {
    return (
      <Component
        name={props.name}
        helpIcon={helpIcon}
        value={value}
        data={props.data}
        prevValue={prevValue}
        isShowTooltip={props.isShowTooltip}
        icon={props.icon}
        width={props.width}
      />
    );
  }

  return (
    <Paper
      elevation={0}
      className={props.className}
      sx={{ width: props.width ? `${props.width}px` : 'auto' }}
    >
      <Component
        name={props.name}
        helpIcon={helpIcon}
        value={value}
        data={props.data}
        prevValue={prevValue}
        isShowTooltip={props.isShowTooltip}
        icon={props.icon}
      />
    </Paper>
  );
};

const StandartViewMetricComponent = (props) => {
  return (
    <Box className="standartViewMetricComponent">
      <div className="name">
        {props.name}
        {props.helpIcon}
      </div>
      <div className="value_wrapper">
        <div className="value">{props.value}</div>
        {props.data.oldValue && (
          <LastPeriodComponentWrapper
            className="lastPeriod"
            tooltipValue={props.prevValue}
            oldValue={props.data.oldValue}
            value={props.data.value}
            isShowTooltip={props.isShowTooltip}
          />
        )}
      </div>
    </Box>
  );
};

const HeaderViewMetricComponent = (props) => {
  return (
    <Box
      className="headerViewMetricComponent"
      sx={{ width: props.width ? `${props.width}px` : 'auto' }}
    >
      <div className="value">{props.value}</div>

      <div className="name">{props.name}</div>

      {props.data.oldValue && (
        <LastPeriodComponentWrapper
          className="lastPeriod"
          tooltipValue={props.prevValue}
          oldValue={props.data.oldValue}
          value={props.data.value}
          isShowTooltip={props.isShowTooltip}
        />
      )}
    </Box>
  );
};

const SideGroupPaperMetric = (props) => {
  return (
    <Box className="sideViewMetricComponent">
      <div className="value">
        <div>{props.icon}</div>
        <div>{props.value}</div>
      </div>

      <div className="name">{props.name}</div>

      {props.data.oldValue && (
        <LastPeriodComponentWrapper
          className="lastPeriod"
          tooltipValue={props.prevValue}
          oldValue={props.data.oldValue}
          value={props.data.value}
          isShowTooltip={props.isShowTooltip}
        />
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    tabList: state.dashboard.tabsState?.tabsMap?.OVERVIEW || [],
  };
};

export default connect(mapStateToProps)(Overview);
