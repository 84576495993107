import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import DashboardContainer from './DashboardContainer';
import TabsTitle from 'common/components/Tabs/TabsTitle';
import useChartTabs from 'common/components/Tabs/hooks/useChartTabs';
import { toCamelCaseInArray } from 'common/helpers/utils';

const PAGE = 'dashboard/audit';

const Audit = (props) => {
  const tabList = props.tabsState?.tabsMap?.AUDIT || [];

  const { currentTab, onChangeCurrentQueryNames, contentId } = useChartTabs(
    tabList,
    PAGE
  );

  const contentTabValue = useMemo(() => {
    return toCamelCaseInArray(currentTab.value || []);
  }, [currentTab.value]);

  return (
    <DashboardContainer
      seconHeaderContent={
        !props.tabsState.isLoading &&
          tabList.length >= 1  && (
          <TabsTitle
            setValue={onChangeCurrentQueryNames}
            value={currentTab.label}
            tabList={tabList}
            pageName={PAGE}
          />
        )
      }
      title="Audit"
      contentId={contentId}
      contentTab={contentTabValue}
      queryNames={currentTab.queryNames}
      getCharts={getCharts}
      contentClassName={'PageContentWithTabs'}
    />
  );
};

// outdated logic, with a complete transfer to the cloud - to review its necessity
const getCharts = {};

const mapStateToProps = (state) => {
  return {
    tabsState: state.dashboard.tabsState,
  };
};

export default connect(mapStateToProps)(Audit);
