'use strict';

import autobind from 'react-autobind';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { enqueueSnackbar } from 'notistack';

import * as ajax from 'common/helpers/ajax';
import Button from 'common/components/Button';
import ReduxFormFloatingLabelFormGroup from 'common/components/ReduxFormFloatingLabelFormGroup';
import Grid from '@mui/material/Unstable_Grid2';

class AccountSettingsForm extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.saveUser)}>
        <Grid container spacing={2}>
          <Grid xs={6}>
            <ReduxFormFloatingLabelFormGroup type="text" name="name" label="Full name" />
          </Grid>
          <Grid xs={6}>
            <ReduxFormFloatingLabelFormGroup type="email" name="email" label="Email" />
          </Grid>

          <Grid xs={12}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              isLoading={this.props.submitting}
              disabled={this.props.pristine || this.props.submitting}
            >
              Save changes
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }

  saveUser(data) {
    return this.props.dispatch(saveUser(data));
  }
}

export const fetchUser = () => {
  return (dispatch) => {
    ajax.getJSON(
      '/api/user/current-user',
      (response) => {
        dispatch({
          type: 'USER__UPDATE',
          payload: response,
        });
      },
      (response) => {
        console.log(response);
      }
    );
  };
};

const saveUser = (data) => {
  return (dispatch) => {
    return ajax.putJSON(
      '/api/user/current-user',
      data,
      (response) => {
        dispatch({
          type: 'USER__UPDATE',
          payload: response,
        });

        enqueueSnackbar('Success! Your account settings have been updated.', {
          variant: 'success',
        });
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

export const fetchPermissions = () => {
  return (dispatch) => {
    return ajax.getJSON(
      '/api/cloudweb-views-restrictions-map',
      {},
      (response) => {
        dispatch({
          type: 'CLOUD_WEB_VIEWS_RESTRICTIONS_MAP',
          payload: response,
        });
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      },
      false
    );
  };
};

const mapStateToProps = (state) => {
  return {
    initialValues: {
      name: state.user.name,
      email: state.user.email,
    },
  };
};

AccountSettingsForm = reduxForm({
  form: 'Account.AccountSettingsForm',
})(AccountSettingsForm);

export default connect(mapStateToProps)(AccountSettingsForm);
