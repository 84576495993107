import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
// import { ContractCardsList } from '../components/contracts/ContractCardsList';
import PageContainer from '../../containers/PageContainer';
import { getCharges } from '../../store/slices/AnalysisSlice';
import { getContracts } from '../../store/slices/ContractsSlice';
import { ContractInfo } from './ContractInfo';
import { ContractCardsList } from './ContractsCardList';
import { SpinnerContainer } from 'common/components/Spinner';
import Tips from 'common/components/Tips/Tips';
import Box from '@mui/material/Box';

const Container = styled.div`
  margin-bottom: 10px;

  button:not(:last-of-type) {
    margin-right: 20px;
  }
`;

export const ContractsList = () => {
  const dispatch = useDispatch();
  const contracts = useSelector((state) => state.contracts);
  const { contractId } = useParams();
  const { carriersList } = useSelector((state) => state.carriers);
  const { ratesYears, calculationAccessorials, calculationDIM, loading } = useSelector(
    (state) => state.analysis
  );

  const selectedContract = contracts
    ? contracts.list.find((contract) => {
        return contract.id === parseInt(contractId);
      })
    : null;

  const selectedCarrier = selectedContract
    ? carriersList.find((carrier) => carrier.name === selectedContract.carrier.name)
    : null;

  useEffect(() => {
    dispatch(getContracts());
  }, [dispatch, contracts.length]);

  useEffect(() => {
    if (contractId) {
      dispatch(getCharges(contractId));
    }
  }, [dispatch, contractId]);

  return (
    <PageContainer title="Your Contracts">
      {loading || contracts.loading ? (
        <SpinnerContainer isVisible={true} message="Working..." />
      ) : (
        <Container>
          <Box
            sx={{
              marginBottom: '10px',
            }}
          >
            <Tips>
              <Box>
                The uploaded contracts will be used for a custom analysis depending on
                your request.
              </Box>
              <Box>For all the details, please contact us via “feedback form”.</Box>
            </Tips>
          </Box>
          <ContractCardsList loading={contracts.loading} contractsList={contracts.list} />
          {!!contractId && (
            <ContractInfo
              contract={selectedContract}
              contractId={contractId}
              services={!!selectedCarrier && selectedCarrier.services}
              ratesYears={!!ratesYears && ratesYears[contractId]}
              calculationDIM={calculationDIM}
              calculationAccessorials={calculationAccessorials}
            />
          )}
        </Container>
      )}
    </PageContainer>
  );
};
