import './ShipmentMultipleResults.scss';

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useShipmentMultipleResultsRows } from './hooks/useShipmentMultipleResultsRows';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import { Collapse } from '@mui/material';
import Box from '@mui/material/Box';
import { ic_keyboard_arrow_up } from 'react-icons-kit/md/ic_keyboard_arrow_up';
import { ic_keyboard_arrow_down } from 'react-icons-kit/md/ic_keyboard_arrow_down';
import Icon from 'common/components/Icon';
import ShipmentDetails from './ShipmentDetails';
import * as utils from 'common/helpers/utils';
import * as constants from 'common/helpers/constants';

const ShipmentMultipleResults = (props) => {
  const rows = useShipmentMultipleResultsRows(props.shipments);

  return (
    <TableContainer sx={{ border: 'none' }}>
      <Table
        sx={{
          borderSpacing: 0,
          borderCollapse: 'separate',
          borderRadius: '16px',
          border: '1px',
          overflow: 'hidden',
        }}
        aria-label="collapsible table"
      >
        <TableHead sx={{ backgroundColor: '#f5f8fd' }}>
          <TableRow>
            <TableCell sx={{ width: '77px' }} />
            <TableCell sx={{ width: '198px' }} align="left">
              Tracking number
            </TableCell>
            <TableCell sx={{ width: '130px' }} align="left">
              Pickup date
            </TableCell>
            <TableCell sx={{ width: '100px' }} align="left">
              Status
            </TableCell>
            <TableCell sx={{ width: '256px' }} align="left">
              Service type
            </TableCell>
            <TableCell sx={{ width: '153px' }} align="left">
              Origin
            </TableCell>
            <TableCell align="left">Destination</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Row = ({ row }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          backgroundColor: '#FFFFFF',
          borderSpacing: 0,
          borderCollapse: 'separate',
          borderRadius: '16px',
          border: '1px',
          overflow: 'hidden',
        }}
      >
        <TableCell sx={{ width: '77px', borderBottom: 0 }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <Icon size={20} icon={ic_keyboard_arrow_up} />
            ) : (
              <Icon size={20} icon={ic_keyboard_arrow_down} />
            )}
          </IconButton>
        </TableCell>
        <TableCell sx={{ width: '198px', borderBottom: 0 }}>
          {row.trackingNumber}
        </TableCell>
        <TableCell sx={{ width: '130px', borderBottom: 0 }} align="left">
          {utils.Fmt.date(row.carrierPickedUpDate)}
        </TableCell>
        <TableCell sx={{ width: '100px', borderBottom: 0 }} align="left">
          {constants.getDispValue(row.status)}
        </TableCell>
        <TableCell sx={{ width: '256px', borderBottom: 0 }} align="left">
          {row.serviceType}
        </TableCell>
        <TableCell sx={{ width: '153px', borderBottom: 0 }} align="left">
          {row.origin}
        </TableCell>
        <TableCell sx={{ borderBottom: 0 }} align="left">
          {row.dispServiceType}
        </TableCell>
      </TableRow>
      <TableRow sx={{ ...(open ? { backgroundColor: '#e0efff' } : {}) }}>
        <TableCell style={{ padding: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: '12px 0 48px 0' }}>
              <ShipmentDetails shipment={row.currentValue} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(connect()(ShipmentMultipleResults));
