import React, { useMemo, useState } from 'react';
import Chart from './Chart';

const GeoChartViewModel = (props) => {
  const [chart, setChart] = useState(null);

  const config = useMemo(() => {
    return props.chartConfig;
  }, []);

  return <Chart chart={chart} setChart={setChart} config={config} {...props} />;
};

export default GeoChartViewModel;
