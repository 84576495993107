'use strict';

import React from 'react';
import moment from 'moment';
import autobind from 'react-autobind';
import SingleDatePicker from 'react-dates/lib/components/SingleDatePicker';
import { Field } from 'redux-form';

import { formatDates, normalizeDates } from './ReduxFormDateRangePicker';

class SingleDatePickerWrapper extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = { isFocused: false, date: null };
  }

  render() {
    const { input, meta, ...props } = this.props;

    return (
      <SingleDatePicker
        date={this.state.date || input.value || null}
        initialVisibleMonth={() => input.value || moment()}
        hideKeyboardShortcutsPanel={true}
        focused={this.state.isFocused}
        numberOfMonths={1}
        onDateChange={(date) => {
          input.onChange(date);
          this.setState({ date });
        }}
        onFocusChange={({ focused }) => this.setState({ isFocused: focused })}
        {...props}
      />
    );
  }
}

const ReduxFormSingleDatePicker = (props) => {
  return (
    <Field
      {...props}
      component={SingleDatePickerWrapper}
      normalize={normalizeDates}
      format={formatDates}
    />
  );
};

export default ReduxFormSingleDatePicker;
