import React, { useMemo } from 'react';
import TooltipIcon from 'common/components/TooltipIcon';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { ic_edit } from 'react-icons-kit/md/ic_edit';

const ActionsFormatter = ({
  row,
  showAddShippingLocationModal,
  removeShippingLocation,
}) => {
  return (
    <div>
      <TooltipIcon
        className="EditIcon"
        icon={ic_edit}
        onClick={() => showAddShippingLocationModal(row)}
      >
        Edit shipping location
      </TooltipIcon>
      <TooltipIcon
        className="RemoveIcon"
        icon={ic_delete}
        onClick={() => removeShippingLocation(row)}
      >
        Remove shipping location
      </TooltipIcon>
    </div>
  );
};

export const useShippingLocationTableColumns = (
  showAddShippingLocationModal,
  removeShippingLocation
) =>
  useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        width: 280,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'formattedStr',
        headerName: 'Address',
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'actions',
        headerName: '',
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: 'actions',
        renderCell: (params) => (
          <ActionsFormatter
            row={params.value}
            showAddShippingLocationModal={showAddShippingLocationModal}
            removeShippingLocation={removeShippingLocation}
          />
        ),
      },
    ],
    [showAddShippingLocationModal, removeShippingLocation]
  );
