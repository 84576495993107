import React, { Fragment } from 'react';
import styledComponents from 'styled-components';
import * as yup from 'yup';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Field, Form, Formik } from 'formik';
import { SelectFormik } from 'common/components/formik/SelectFormik';
import { CheckboxFormik } from 'common/components/formik/CheckboxFormik';
import { getDispValue } from 'common/helpers/constants';
import { ContractUploadFormik } from 'common/components/formik/ContractUplaodFormik';
import Button from "common/components/Button";

const FormRow = styledComponents.div`
  margin: 0 0 20px;

  h6 {
    margin: 0 0 6px;
  }

  .MuiSelect-select:focus {
    background-color: inherit;
  }

  .selectFileBtn {
    margin-right: 10px;
  }
`;

const ValidationError = styledComponents.div`
  color: #f44336;
`;

const FormNavigation = styledComponents.div`
  display: flex;
  justify-content: flex-end;
`;

const validationSchema = yup.object().shape({
  carrier_id: yup.number().required('Please choose a carrier!'),
  services: yup.array(yup.number()).required('Please select at least one service!'),
  file: yup.mixed().required('Please select the file!'),
});

export const AddContractForm = ({ carriers, onSubmit }) => {
  return (
    <Formik
      initialValues={{ carrier_id: '', services: [], file: '' }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, touched }) => (
        <Form>
          <FormRow>
            <Field
              name="carrier_id"
              component={SelectFormik}
              label="Carrier"
              variant="outlined"
            >
              {carriers.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {getDispValue(c.name)}
                </MenuItem>
              ))}
            </Field>
          </FormRow>

          <FormRow>
            <Typography variant="subtitle1">
              Upload a PDF version of the contract
            </Typography>

            <Field name="file" component={ContractUploadFormik} />
            {touched.file && errors.file && (
              <ValidationError>{errors.file}</ValidationError>
            )}
          </FormRow>

          <Field>
            {({ field }) => {
              const selectedCarrier = carriers.find(
                (c) => c.id === field.value.carrier_id
              );

              return selectedCarrier ? (
                <Fragment>
                  <Typography variant="subtitle1">
                    Select which services you want to see (up to 3).
                  </Typography>
                  {selectedCarrier.services.map((s) => (
                    <FormGroup row key={s.id}>
                      <Field
                        name="services"
                        label={s.name}
                        value={Number(s.id)}
                        component={CheckboxFormik}
                      />
                    </FormGroup>
                  ))}
                  {touched.services && errors.services && (
                    <ValidationError>{errors.services}</ValidationError>
                  )}
                </Fragment>
              ) : (
                ''
              );
            }}
          </Field>
          <FormNavigation>
            <Button type="submit" variant="contained" color="primary">
              Finish upload
            </Button>
          </FormNavigation>
        </Form>
      )}
    </Formik>
  );
};
