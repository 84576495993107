import { useMemo } from 'react';

export const useGLCodesTableRows = (users) =>
  useMemo(
    () =>
      (users || []).reduce((prevState, currentValue) => {
        const currentRow = {
          id: currentValue.hid,
          code: currentValue.code,
          updatedDt: currentValue.updated_dt,
          codeName: currentValue.code_name,
          vendorAccountNumber: currentValue.vendor_account_number,
          glType: currentValue.gl_type,
          startingFromDate: currentValue.starting_from_date ?? '-',
          actions: currentValue,
          currentValue,
        };
        prevState.push(currentRow);
        return prevState;
      }, []),
    [users]
  );
