import React from 'react';
import Button from 'common/components/Button';
import { ic_add } from 'react-icons-kit/md/ic_add';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import Icon from 'common/components/Icon';
import { FormGroup, FormLabel } from '@mui/material';
import ReduxFormSelect from 'common/components/ReduxFormSelect';
import { useState } from 'react';
import { useEffect } from 'react';
import { FieldArray } from 'redux-form';
import './LocationsSelect.scss';
import Stack from '@mui/material/Stack';

const FieldsRow = ({
  item,
  index,
  fields,
  locationType,
  optionsData,
  changeField,
  locationLevel,
}) => {
  const [optionCountry, setOptionCountry] = useState([]);
  const [optionState, setOptionState] = useState([]);
  const [optionCity, setOptionCity] = useState([]);

  useEffect(() => {
    if (optionsData) {
      const isArrayData = Array.isArray(optionsData);
      let countrys;
      if (!isArrayData)
        countrys = Object.keys(optionsData).map((country) => ({
          value: country,
          label: country,
        }));
      else
        countrys = optionsData.map((country) => ({
          value: country,
          label: country,
        }));
      setOptionCountry(countrys);
      if (countrys.length === 0) {
        setOptionState([]);
        setOptionCity([]);

        changeField(`${item}`, {
          city: '',
          country: '',
          state: '',
        });
      }
    } else {
      setOptionCountry([]);
      if (optionState.length > 0) setOptionState([]);
      if (optionCity.length > 0) setOptionCity([]);
    }
  }, [optionsData, item]);

  // useEffect(() => {
  //   const isArrayData = Array.isArray(optionsData);
  //   if (locationLevel !== 'COUNTRY' && !isArrayData) {
  //     console.log('optionsData', optionsData);
  //     if (optionsData && Object.keys(optionsData).length > 0) {
  //       const slectedCountry = fields.get(index).country;
  //       if (slectedCountry) {
  //         const statesArray = Object.keys(optionsData[slectedCountry]);
  //         console.log('optionState', optionState);
  //         if (optionState.length === 0 || statesArray[0] !== optionState[0].value) {
  //           const states = Object.keys(optionsData[slectedCountry]).map((item) => ({
  //             value: item,
  //             label: item,
  //           }));
  //           setOptionState(states);
  //         }

  //         const slectedState = fields.get(index).state;
  //         const cityArray =
  //           optionsData[slectedCountry] && optionsData[slectedCountry][slectedState];
  //         if (cityArray) {
  //           if (optionsData[slectedCountry][slectedState][0] !== optionCity[0].value) {
  //             const cities = cityArray.map((city) => ({
  //               value: city,
  //               label: city,
  //             }));
  //             if (cities) setOptionCity(cities);
  //           }
  //         }
  //       }
  //     }
  //   } else {
  //     if (optionState.length > 0) setOptionState([]);
  //     if (optionCity.length > 0) setOptionCity([]);
  //   }
  // }, [index, fields, optionsData, locationLevel]);

  const onSelectCountry = (value) => {
    if (optionCountry.length > 0 && locationLevel !== 'COUNTRY') {
      let states;
      if (!locationLevel || locationLevel === 'CITY') {
        states = Object.keys(optionsData[value]).map((item) => ({
          value: item,
          label: item,
        }));
      } else if (locationLevel === 'STATE') {
        states = optionsData[value].map((item) => ({
          value: item,
          label: item,
        }));
      }
      setOptionState(states);
    }
  };

  const onSelectState = (value) => {
    if (locationLevel !== 'STATE') {
      // if (!locationLevel || (locationLevel && locationLevel === 'CITY')) {
      const slectedCountry = fields.get(index).country;
      const cities =
        optionsData[slectedCountry][value] &&
        optionsData[slectedCountry][value].map((city) => ({
          value: city,
          label: city,
        }));
      if (cities) setOptionCity(cities);
      // }
    }
  };

  const locationName = locationType[0].toUpperCase() + locationType.slice(1);

  const isShowBorder =
    fields.length > 1 &&
    index < fields.length - 1 &&
    (!locationLevel || (locationLevel && locationLevel === 'CITY'));

  return (
    <Stack spacing={1} direction="row">
      <Stack spacing={1} sx={{ width: '100%' }}>
        <Stack spacing={1} direction="row" sx={{ width: '100%' }}>
          <FormGroup sx={{ width: '50%' }}>
            <FormLabel>{locationName} Country</FormLabel>
            <ReduxFormSelect
              name={`${item}.country`}
              placeholder="Select"
              options={optionCountry}
              clearable={false}
              onChange={onSelectCountry}
              multi={locationLevel === 'COUNTRY'}
            />
          </FormGroup>

          {(!locationLevel ||
            (locationLevel &&
              (locationLevel === 'CITY' || locationLevel === 'STATE'))) && (
            <FormGroup sx={{ width: '50%' }}>
              <FormLabel>{locationName} State</FormLabel>
              <ReduxFormSelect
                name={`${item}.state`}
                placeholder="Select"
                options={optionState}
                clearable={false}
                onChange={onSelectState}
                multi={locationLevel === 'STATE'}
              />
            </FormGroup>
          )}
        </Stack>
        {(!locationLevel || (locationLevel && locationLevel === 'CITY')) && (
          <FormGroup>
            <FormLabel>{locationName} City</FormLabel>
            <ReduxFormSelect
              name={`${item}.city`}
              placeholder="Select"
              options={optionCity}
              clearable={false}
              multi={true}
              className="multiSelectReport"
            />
          </FormGroup>
        )}
      </Stack>

      {fields.length > 1 && (
        <Icon
          icon={ic_delete}
          size={25}
          style={{ color: 'red', marginTop: '30px' }}
          onClick={() => fields.remove(index)}
        />
      )}
    </Stack>
  );
};

const RenderLocations = ({
  fields,
  locationType,
  optionsData,
  changeField,
  locationLevel,
  meta: { error },
}) => {
  return (
    <Stack spacing={2}>
      <Stack spacing={3}>
        {fields.map((item, index) => {
          return (
            <FieldsRow
              key={index}
              item={item}
              index={index}
              fields={fields}
              optionsData={optionsData}
              locationType={locationType}
              changeField={changeField}
              locationLevel={locationLevel}
            />
          );
        })}
      </Stack>

      <Button
        variant="contained"
        className="addButton"
        sx={{
          width: 'fit-content',
        }}
        onClick={() => fields.push({ city: '', country: '', state: '' })}
      >
        <Icon icon={ic_add} />
        Add
      </Button>
    </Stack>
  );
};

const LocationsSelect = ({ locationType, optionsData, changeField, locationLevel }) => {
  return (
    <FieldArray
      name={`locations_${locationType}`}
      component={RenderLocations}
      props={{ locationType, optionsData, changeField, locationLevel }}
    />
  );
};

export default LocationsSelect;
