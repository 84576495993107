'use strict';

import './ShipperInvoices.scss';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as ajax from 'common/helpers/ajax';
import { useShipperOptimizationInvoicesRows } from './hooks/useShipperOptimizationInvoicesRows';
import { useShipperOptimizationInvoicesColumns } from './hooks/useShipperOptimizationInvoicesColumns';
import DataGridWrapper from 'common/components/table/DataGridWrapper';
import DataGridCustom from 'common/components/table/DataGridCustom';

const SIZE_PER_PAGE = 10;

const ShipperOptimizationInvoices = (props) => {
  const [sortModel, setSortModel] = useState([
    {
      field: 'invoiceDate',
      sort: 'desc',
    },
  ]);

  useEffect(() => {
    props.dispatch(fetchShipperOptimizationInvoices());
  }, []);

  const rows = useShipperOptimizationInvoicesRows(props.shipperOptimizationInvoices);
  const columns = useShipperOptimizationInvoicesColumns();

  return (
    <section className="ShipperOptimizationInvoices">
      <h3 className="heading">Optimization invoice history</h3>
      {!rows.length ? (
        <EmptyPlaceholder />
      ) : (
        <DataGridWrapper style={{ minHeight: '250px' }}>
          <DataGridCustom
            rows={rows}
            columns={columns}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            settings={{
              initialState: {
                pagination: { paginationModel: { pageSize: SIZE_PER_PAGE } },
              },
              pageSizeOptions: [SIZE_PER_PAGE],
              hideFooter: false,
            }}
          />
        </DataGridWrapper>
      )}
    </section>
  );
};

/**
 * An empty placeholder to render when we don't have any invoices to show.
 */
const EmptyPlaceholder = (props) => {
  return (
    <div className="empty-placeholder">
      You don&rsquo;t have any optimization invoices yet.
    </div>
  );
};

/**
 * Updates the list of shipper invoices.
 */
const fetchShipperOptimizationInvoices = () => {
  return (dispatch) => {
    return ajax.getJSON(
      '/api/shipper-optimization-invoices',
      (response) => {
        dispatch({
          type: 'SHIPPER_OPTIMIZATION_INVOICES__UPDATE',
          payload: response,
        });
      },
      (response) => {
        console.log(response);
      }
    );
  };
};

const mapStateToProps = (state) => {
  return {
    shipperOptimizationInvoices: state.shipperOptimizationInvoices,
  };
};

export default connect(mapStateToProps)(ShipperOptimizationInvoices);
