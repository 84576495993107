import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import DashboardContainer from './DashboardContainer';
import { toCamelCaseInArray } from 'common/helpers/utils';
import ServicesSummaryContent from 'common/components/ServicesSummaryContent/ServicesSummaryContent';

const ServicesSummary = (props) => {
  const { queryNames, value } = props.tabsState?.tabsMap?.SERVICES_SUMMARY?.[0] || {};

  const content = useMemo(() => {
    return toCamelCaseInArray(value);
  }, [value]);

  return (
    <DashboardContainer
      title="Services Summary"
      contentId="ServicesSummary"
      queryNames={queryNames}
      renderGrid={RenderGrid}
      contentTab={content}
    />
  );
};

const RenderGrid = ({ metrics, contentKey }) => {
  if (!!contentKey && !metrics[contentKey]) {
    return <></>;
  }

  return (
    <ServicesSummaryContent
      carrierServiceSummaryStats={metrics.carrierServiceSummaryStats}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    tabsState: state.dashboard.tabsState,
  };
};

export default connect(mapStateToProps)(ServicesSummary);
