'use strict';

import React from 'react';
import { connect } from 'react-redux';
import PendingImportPlaceholder from 'cloud/components/PendingImportPlaceholder';
import ScheduledReportForm from './ScheduledReportForm';
import ScheduledReportsList from './ScheduledReportsList';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import './Reporting.scss';

const ScheduledReports = (props) => {
  return (
    <>
      {props.shipper.hasAxShipments ? (
        <Stack spacing={4}>
          <Box className="ScheduledReportsList">
            <ScheduledReportsList />
          </Box>

          <ScheduledReportForm />
        </Stack>
      ) : (
        /**
         * Renders the pending import placeholder if the given shipper doesn't have any
         * ax shipments.
         */
        <Box className="ScheduledReportsList">
          <PendingImportPlaceholder />
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    shipper: state.shipper,
  };
};

export default connect(mapStateToProps)(ScheduledReports);
