import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import { Field, Form, Formik } from 'formik';
import { SelectFormik } from 'common/components/formik/SelectFormik';
import { useDispatch } from 'react-redux';
import { getNetRatesServiceTypeList } from '../../store/slices/NetRatesSlices';
import Button from "common/components/Button";

const FormRow = styled.div`
  margin: 0 0 20px;

  h6 {
    margin: 0 0 6px;
  }

  .MuiSelect-select:focus {
    background-color: inherit;
  }

  .selectFileBtn {
    margin-right: 10px;
  }
  &.formRow {
    display: flex;
    div.column {
      display: flex;
      width: 50%;
      height: 100%;
      :first-child {
        margin-right: 10px;
      }
      :last-child {
        margin-left: 10px;
      }
    }
  }
`;

const FormNavigation = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const validationSchema = yup.object().shape({
  carrier: yup.string().required('Please choose a carrier!'),
  serviceType: yup.string().required('Please choose a service!'),
});

export const DownloadTemplateForm = ({
  carriers,
  serviceTypeList,
  onSubmit,
  fetchingSendingData,
}) => {
  const dispatch = useDispatch();

  const handleChangeCarrier = (event, setFieldValue) => {
    setFieldValue('carrier', event.target.value);
    dispatch(getNetRatesServiceTypeList(event.target.value));
  };

  return (
    <Formik
      initialValues={{ carrier: '', serviceType: '' }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, touched, values, setFieldValue }) => {
        return (
          <Form>
            <FormRow>
              <Field
                name="carrier"
                component={SelectFormik}
                label="Carrier*"
                variant="outlined"
                onChange={(e) => handleChangeCarrier(e, setFieldValue)}
                value={values.carrier}
              >
                {carriers &&
                  carriers.map((item) => (
                    <MenuItem key={item[0]} value={item[0]}>
                      {item[1]}
                    </MenuItem>
                  ))}
              </Field>
            </FormRow>
            {serviceTypeList && (
              <FormRow>
                <Field
                  name="serviceType"
                  component={SelectFormik}
                  label="What services do you want to add*"
                  variant="outlined"
                >
                  {serviceTypeList.map((item) => (
                    <MenuItem key={item[0]} value={item[0]}>
                      {item[1]}
                    </MenuItem>
                  ))}
                </Field>
              </FormRow>
            )}
            <FormNavigation>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={fetchingSendingData}
              >
                {fetchingSendingData ? 'Working...' : 'Create and Download Template'}
              </Button>
            </FormNavigation>
          </Form>
        );
      }}
    </Formik>
  );
};
