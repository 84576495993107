import React, { useEffect, useState } from 'react';
import './MultiplyTrackShipments.scss';
import FormGroup from '@mui/material/FormGroup';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Button from 'common/components/Button';
import ErrorContainer from 'common/components/ErrorContainer';

import { TextFieldWrapper } from 'common/components/UploadTrackingField/UploadBlock';
import { counterLines } from 'common/helpers/utils';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ShipmentMultipleResults from '../lookup/ShipmentMultipleResults';
import { useHistory, useRouteMatch } from 'react-router-dom';
import NoSearchResults from '../lookup/NoSearchResults';
import { insertValidate, required } from '../UploadTrackingField/utils';
import Tips from '../Tips/Tips';

const FORM_NAME = 'multiplyTrackShipmentsForm';

const parseCsvToString = ({ file, string, callbackFn }) => {
  const parseCsv = (csvString) => {
    const lines = csvString.trim().split('\n');
    return lines.map((line) => {
      return line.split('-');
    });
  };

  if (!!string) {
    callbackFn(parseCsv(string));
  } else {
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      callbackFn(parseCsv(text));
    };
    reader.readAsText(file);
  }
};

const MS_TO_ONE_ROW = 3500;
const ONE_HUNDRED_PERCENT = 100;

const CircularProgressWithLabel = ({ rowsCount }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const totalTime = rowsCount * MS_TO_ONE_ROW;
    const incrementTime = MS_TO_ONE_ROW;

    const increment = (incrementTime / totalTime) * ONE_HUNDRED_PERCENT;

    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const newProgress = oldProgress + increment;
        if (newProgress >= ONE_HUNDRED_PERCENT) {
          clearInterval(timer);
          return ONE_HUNDRED_PERCENT;
        }
        return newProgress;
      });
    }, incrementTime);

    return () => {
      clearInterval(timer);
    };
  }, [rowsCount]);

  return (
    <Box
      sx={{
        position: 'relative',
        margin: '0 auto',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress
        variant={progress === ONE_HUNDRED_PERCENT ? 'indeterminate' : 'determinate'}
        value={progress}
        size={120}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {progress === ONE_HUNDRED_PERCENT ? 'Almost Done' : `${Math.round(progress)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

let MultiplyTrackShipments = (props) => {
  const { error, isSendingLoading, shipments } = props.trackShipments;
  const history = useHistory();
  const match = useRouteMatch(`/${props.pages}/:slug?/:trackingNumber?/:hid?`);

  const dispatch = useDispatch();

  const saveToUrl = (data) => {
    const callbackFn = (text) => {
      let newPath = `/${props.pages}/${match.params.slug}/${text}`;

      if (match.params.hid) {
        newPath += `/${match.params.hid}`;
      }
      history.push(newPath);
    };

    parseCsvToString({ string: data.trackingNumbers, callbackFn });
  };

  const save = (data) => {
    saveToUrl(data);

    dispatch(props.uploadShipmentTrackingList(data));
  };
  useEffect(() => {
    if (props.initialValues?.trackingNumbers?.length) {
      props.uploadShipmentTrackingList(props.initialValues.trackingNumbers);
    }
  }, []);

  const rowsCount = isSendingLoading
    ? counterLines.countStringLines(props.trackingNumbers)
    : 0;

  return (
    <>
      {error ? (
        <ErrorContainer />
      ) : (
        <Box>
          <Box
            component={'form'}
            className={'upload_form'}
            onSubmit={props.handleSubmit(save)}
          >
            <Box className={'upload'}>
              <Tips>
                {'Insert tracking numbers without comma. ' +
                  'Each TN should start from the new line. ' +
                  'Maximum number of TN-25!'}
              </Tips>

              <Field
                name={'trackingNumbers'}
                component={TextFieldWrapper}
                label={' '}
                validate={[required, insertValidate]}
              />
            </Box>
            <FormGroup>
              <Button
                type="submit"
                size="lg"
                variant="contained"
                color="primary"
                style={{ height: '49px', width: 'fit-content' }}
                isLoading={isSendingLoading}
                disabled={props.submitting || props.invalid}
              >
                Get Shipments
              </Button>
            </FormGroup>
          </Box>
          {/* if null ===  is init state*/}
          {isSendingLoading !== null && !isSendingLoading && !shipments.length && (
            <NoSearchResults />
          )}
          {isSendingLoading && !!rowsCount && (
            <CircularProgressWithLabel rowsCount={rowsCount} />
          )}
          {!isSendingLoading && !!shipments.length && (
            <Box sx={{ marginTop: '30px' }}>
              <ShipmentMultipleResults shipments={shipments} />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

MultiplyTrackShipments = reduxForm({
  form: FORM_NAME,
})(MultiplyTrackShipments);

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state) => {
  return {
    trackShipments: state.multiplyTrackShipments,
    trackingNumbers: selector(state, 'trackingNumbers'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeField: function (field, value) {
      dispatch(change(FORM_NAME, field, value));
    },
  };
};

const ConnectedMultiplyTrackShipments = connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiplyTrackShipments);

export default ConnectedMultiplyTrackShipments;
