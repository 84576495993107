import React from 'react';

const CarrierInvoicesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3 7C3 5.89543 3.89543 5 5 5H19C20.1046 5 21 5.89543 21 7V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V7Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7 14H10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 10L17 10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default CarrierInvoicesIcon;
