import React, {useEffect} from 'react';
import PageContainer from 'cloud/containers/PageContainer';
import { NetRatesCardList } from './NetRatesList/NetRatesCardList';

import Stack from '@mui/material/Stack';
import useChartTabs from 'common/components/Tabs/hooks/useChartTabs';
import TabsTitle from 'common/components/Tabs/TabsTitle';
import { CarrierNetRatesCardList } from './NetRatesList/CarrierNetRatesCardList';
import { CarrierNetRatesAnalysis, ShipperNetRatesAnalysis } from './NetRatesAnalysis';
import {getNetRates} from "../store/slices/NetRatesSlices";
import {useDispatch} from "react-redux";

const NetRates = () => {
  const dispatch = useDispatch();
  const { currentTab, onChangeCurrentQueryNames } = useChartTabs(tabList, PAGE);


  useEffect(() => {
    dispatch(getNetRates());
  }, []);

  return (
    <PageContainer
      title="Net Rates"
      contentClassName={'PageContentWithTabs'}
      secondHeaderClass={'secondHeaderClass'}
      seconHeaderContent={
        <TabsTitle
          tabList={tabList}
          value={currentTab.label}
          pages={PAGE}
          setValue={onChangeCurrentQueryNames}
        />
      }
    >
      <Stack spacing={5}>
        {currentTab.path === 'your-net-rate' && (
          <>
            <NetRatesCardList />
            <ShipperNetRatesAnalysis />
          </>
        )}
        {currentTab.path === 'carrier-net-rate' && (
          <>
            <CarrierNetRatesCardList />
            <CarrierNetRatesAnalysis />
          </>
        )}
      </Stack>
    </PageContainer>
  );
};

const PAGE = 'net-rates';

const tabList = [
  {
    label: 'Carrier Net Rate',
    path: 'carrier-net-rate',
  },

  {
    label: 'Your Net Rates',
    path: 'your-net-rate',
  },
];

export default NetRates;
