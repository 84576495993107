'use strict';

import autobind from 'react-autobind';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
import { enqueueSnackbar } from 'notistack';

import * as ajax from 'common/helpers/ajax';
import Button from 'common/components/Button';
import ReduxFormFloatingLabelFormGroup from 'common/components/ReduxFormFloatingLabelFormGroup';
import Grid from '@mui/material/Unstable_Grid2';
import Tips from 'common/components/Tips/Tips';

class ChangePasswordForm extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.savePassword)}>
        <Grid container spacing={2}>
          <Grid md={4}>
            <ReduxFormFloatingLabelFormGroup
              type="password"
              name="curPassword"
              label="Current password"
            />
          </Grid>
          <Grid md={4}>
            <ReduxFormFloatingLabelFormGroup
              type="password"
              name="newPassword"
              label="New password"
            />
          </Grid>
          <Grid md={4}>
            <ReduxFormFloatingLabelFormGroup
              type="password"
              name="confPassword"
              label="Confirm password"
            />
          </Grid>
          <Grid md={12}>
            <Tips>New passwords must be at least 6 characters long.</Tips>
          </Grid>

          <Grid md={12}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              isLoading={this.props.submitting}
              disabled={this.props.pristine || this.props.submitting}
            >
              Save password
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }

  savePassword(data) {
    // Validate new password is long enough, and that the conf password matches.
    if (!data.newPassword || data.newPassword.length < 6) {
      enqueueSnackbar('Your password must be at least 6 characters long.', {
        variant: 'error',
      });
      return null;
    } else if (data.newPassword !== data.confPassword) {
      enqueueSnackbar("The new passwords you enter don't match. Please try again.", {
        variant: 'error',
      });
      return null;
    }

    return this.props.dispatch(savePassword(data));
  }

  onSubmit(e) {
    e.preventDefault();

    // Validate new password is long enough, and that the conf password matches.
    if (!this.state.newPassword || this.state.newPassword.length < 6) {
      enqueueSnackbar('Your password must be at least 6 characters long.', {
        variant: 'error',
      });
      return;
    } else if (this.state.newPassword !== this.state.confPassword) {
      enqueueSnackbar("The new passwords you enter don't match. Please try again.", {
        variant: 'error',
      });
      return;
    }

    ajax.putJSON(
      '/api/user/current-user/change-password',
      {
        curPassword: this.state.curPassword,
        newPassword: this.state.newPassword,
      },
      (response) => {
        enqueueSnackbar('Success! Your password has been changed.', {
          variant: 'success',
        });
        this.props.onHide();
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  }
}

const savePassword = (data) => {
  return (dispatch) => {
    return ajax.putJSON(
      '/api/user/current-user/change-password',
      data,
      (response) => {
        enqueueSnackbar('Success! Your password has been changed.', {
          variant: 'success',
        });
        dispatch(reset('Account.ChangePasswordForm'));
      },
      (response) => {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    );
  };
};

ChangePasswordForm = reduxForm({
  form: 'Account.ChangePasswordForm',
})(ChangePasswordForm);

export default connect()(ChangePasswordForm);
