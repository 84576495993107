import React, { useMemo } from 'react';
import * as constants from 'common/helpers/constants';
import * as utils from 'common/helpers/utils';
import TooltipIcon from 'common/components/TooltipIcon';
import { NavLink } from 'react-router-dom';
import { ic_help_outline } from 'react-icons-kit/md/ic_help_outline';
import moment from 'moment/moment';
import { ic_schedule } from 'react-icons-kit/md/ic_schedule';
import { ic_help } from 'react-icons-kit/md/ic_help';
import VendorPaymentStatusIcon from 'common/components/VendorPaymentStatusIcon';

const StillProcessing = () => {
  return <TooltipIcon icon={ic_schedule}>Still processing...</TooltipIcon>;
};

const DollarFormatter = ({ val }) => {
  if (val === null) {
    return <StillProcessing />;
  }
  return utils.Fmt.dollars(val / 100);
};

const InvoiceNumber = ({ cell, row }) => {
  const link = (
    <NavLink
      to={{
        pathname: `/invoices/${cell}`,
        state: { vendor_invoice_id: row.vendorInvoiceId },
      }}
    >
      {cell}
    </NavLink>
  );
  if (row['detailedMessage'] != null) {
    return (
      <div>
        {link}
        <TooltipIcon icon={ic_help_outline} size="14" className="text-danger">
          {row['detailedMessage']}
        </TooltipIcon>
      </div>
    );
  } else {
    return link;
  }
};

const InvoiceDateFormatter = ({ val, row }) => {
  if (val === null) {
    return constants.MDASH;
  } else {
    const formatedDueDate = utils.Fmt.date(row.invoiceDueDate);
    const formatedInvoiceDate = utils.Fmt.date(val);
    if (moment(formatedInvoiceDate) > moment(formatedDueDate)) {
      return <span className="red">{formatedInvoiceDate}</span>;
    } else {
      return formatedInvoiceDate;
    }
  }
};

const VendorPaymentStatusHeader = ({ enrolledForPayments }) => {
  return (
    <>
      Status{' '}
      {!enrolledForPayments && (
        <TooltipIcon
          className="tooltip-not-enrolled"
          trigger={['hover', 'focus']}
          placement="left"
          icon={ic_help}
        >
          Want to pay invoices using Galleon? Contact us to know how!
        </TooltipIcon>
      )}
    </>
  );
};
const VendorPaymentStatusCell = ({ cell, row, enrolledForPayments }) => {
  if (!cell) return null;

  return (
    <div className="VendorPaymentStatusCol">
      <VendorPaymentStatusIcon
        disabled={!enrolledForPayments}
        vendorPaymentStatus={cell}
        invoiceDueDate={row.invoiceDueDate}
      />
    </div>
  );
};

export const useVendorInvoicesTableColumns = (enrolledForPayments) =>
  useMemo(
    () => [
      {
        field: 'vendor',
        headerName: 'Vendor',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center ',
        renderCell: (params) => constants.getDispValue(params.value),
      },
      {
        field: 'vendorAccountNumber',
        headerName: 'Account number',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'invoiceNumber',
        headerName: 'Invoice number',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <InvoiceNumber cell={params.value} row={params.row.currentValue} />
        ),
      },
      {
        field: 'invoiceDate',
        headerName: 'Invoice date',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <InvoiceDateFormatter val={params.value} row={params.row.currentValue} />
        ),
      },
      {
        field: 'invoiceDueDate',
        headerName: 'Due date',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => utils.Fmt.mdashIfNullFunc(utils.Fmt.date)(params.value),
      },
      {
        field: 'originalAmount',
        headerName: 'Original amount',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => <DollarFormatter val={params.value} />,
      },
      {
        field: 'finalAmount',
        headerName: 'Final amount',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => <DollarFormatter val={params.value} />,
      },
      {
        field: 'numShipments',
        headerName: 'Num Shipments',
        flex: 1,
        minWidth: 90,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          if (params.value === null && !params.row.currentValue.detailedMessage) {
            return <StillProcessing />;
          } else if (params.value === null) {
            return '-';
          } else {
            return params.value.toLocaleString('en');
          }
        },
      },
      {
        field: 'vendorPaymentStatus',
        headerName: 'Status',
        minWidth: 80,
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => (
          <VendorPaymentStatusHeader enrolledForPayments={enrolledForPayments} />
        ),
        renderCell: (params) => (
          <VendorPaymentStatusCell
            cell={params.value}
            row={params.row.currentValue}
            enrolledForPayments={enrolledForPayments}
          />
        ),
      },
    ],
    [enrolledForPayments]
  );
