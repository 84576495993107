import React, { useState } from 'react';
import { useShippingLocationTableRows } from './hooks/useShippingLocationTableRows';
import { useShippingLocationTableColumns } from './hooks/useShippingLocationTableColumns';
import DataGridWrapper from 'common/components/table/DataGridWrapper';
import DataGridCustom from 'common/components/table/DataGridCustom';

const SIZE_PER_PAGE = 20;

const ShippingLocationTable = (props) => {
  const [sortModel, setSortModel] = useState([
    {
      field: 'name',
      sort: 'asc',
    },
  ]);

  const rows = useShippingLocationTableRows(props.shippingLocations);
  const columns = useShippingLocationTableColumns(
    props.showAddShippingLocationModal,
    props.removeShippingLocation
  );

  return (
    <DataGridWrapper
      style={{
        minHeight: rows.length > 8 ? '650px' : '420px',
      }}
    >
      <DataGridCustom
        rows={rows}
        columns={columns}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        settings={{
          initialState: {
            pagination: { paginationModel: { pageSize: SIZE_PER_PAGE } },
          },
          pageSizeOptions: [SIZE_PER_PAGE],
          hideFooter: false,
        }}
      />
    </DataGridWrapper>
  );
};

export default ShippingLocationTable;
