import React from 'react';
const EditIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_598_6452)">
        <circle cx="8" cy="8" r="8" fill="#1175DB" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.7972 3.37894C9.7034 3.2851 9.57615 3.2324 9.44347 3.23242C9.3108 3.23245 9.18357 3.28521 9.08981 3.37908L4.30339 8.17128C4.20975 8.26503 4.15716 8.39212 4.15716 8.52462V10.2084C4.15716 10.4845 4.38101 10.7084 4.65716 10.7084H6.34946C6.48211 10.7084 6.60932 10.6557 6.70309 10.5618L11.4867 5.77612C11.6819 5.58087 11.6819 5.26441 11.4867 5.06916L9.7972 3.37894ZM5.15716 8.73155L9.44372 4.43981L10.4261 5.42265L6.14229 9.70837H5.15716V8.73155ZM3.73193 11.5594C3.45579 11.5594 3.23193 11.7833 3.23193 12.0594C3.23193 12.3356 3.45579 12.5594 3.73193 12.5594H12.0581C12.3343 12.5594 12.5581 12.3356 12.5581 12.0594C12.5581 11.7833 12.3343 11.5594 12.0581 11.5594H3.73193Z"
          fill="#F8F8F8"
        />
      </g>
      <defs>
        <clipPath id="clip0_598_6452">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditIcon;
