import React, { useMemo } from 'react';
import * as constants from 'common/helpers/constants';
import * as utils from 'common/helpers/utils';
import { dollarFormatter } from '../VendorInvoices';
import TooltipIcon from 'common/components/TooltipIcon';
import { ic_delete } from 'react-icons-kit/md/ic_delete';

export const useVendorInvoicesPaymentFormColumns = (onRemove) =>
  useMemo(
    () => [
      {
        field: 'vendor',
        headerName: 'Vendor',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center ',
        renderCell: (params) => constants.getDispValue(params.value),
      },
      {
        field: 'invoiceNumber',
        headerName: 'Invoice number',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'invoiceDueDate',
        headerName: 'Due date',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => utils.Fmt.mdashIfNullFunc(utils.Fmt.date)(params.value),
      },
      {
        field: 'finalAmount',
        headerName: 'Amount',
        width: 95,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => dollarFormatter(params.value),
      },
      {
        field: 'actions',
        headerName: 'Discount',
        flex: 1,
        minWidth: 130,
        headerAlign: 'center',
        align: 'center',
        type: 'actions',
        renderCell: (params) => (
          <TooltipIcon
            icon={ic_delete}
            onClick={() => onRemove && onRemove(params.row.currentValue.hid)}
          >
            Remove selected invoice
          </TooltipIcon>
        ),
      },
    ],
    [onRemove]
  );
