import React, { useCallback, useMemo, useState } from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import Chart from './Chart';

const PieChartViewModel = (props) => {
  const [chart, setChart] = useState(null);
  const builderSelectDataSet = (label, index) => {
    const dataset = props.chartConfig.data.datasets[0];
    return {
      label: label,
      value: {
        data: dataset.data[index],
        selectBackgroundColor: dataset.backgroundColor[index],
      },
    };
  };

  const [hiddenDatasets, setHiddenDatasets] = useState(
    props.hideLegend
      ? props.chartConfig.data.labels
          .slice(5)
          .reduce((prevState, currentValue, index) => {
            prevState.push(builderSelectDataSet(currentValue, index));

            return prevState;
          }, [])
      : []
  );

  const config = useMemo(() => {
    const cloneConfig = _cloneDeep(props.chartConfig);
    if (props.hideLegend) {
      cloneConfig.data.labels = props.chartConfig.data.labels.slice(0, 5);
      cloneConfig.data.datasets[0].data = props.chartConfig.data.datasets[0].data?.slice(
        0,
        5
      );
      cloneConfig.data.datasets[0].backgroundColor =
        props.chartConfig.data.datasets[0].backgroundColor?.slice(0, 5);

      cloneConfig.options = {
        ...cloneConfig.options,
        legend: { display: false },
      };
    }

    return cloneConfig;
  }, [props.chartConfig]);

  const [selectedDatasets, setSelectedDatasets] = useState(
    config.data.labels.map(builderSelectDataSet)
  );
  const onSetSelectedDatasets = useCallback((newDatasets) => {
    // hot fix unknown bug ReactSelect returns an overly nested structure
    setSelectedDatasets(
      newDatasets.map((newDataset) =>
        newDataset.value.label ? newDataset.value : newDataset
      )
    );
  }, []);

  const addDataToChart = useCallback(
    (newDatasets) => {
      const newDatasetLabels = newDatasets.map((dataset) => dataset.label);

      const { newHidden, newVisible } = props.chartConfig.data.labels.reduce(
        (prevState, label, index) => {
          const selectDataSet = builderSelectDataSet(label, index);

          if (!newDatasetLabels.includes(label)) {
            prevState.newHidden.push(selectDataSet);
          } else {
            prevState.newVisible.push(selectDataSet);
          }

          return prevState;
        },
        { newHidden: [], newVisible: [] }
      );

      setHiddenDatasets(newHidden);

      chart.data.labels = newVisible.map((one) => one.label);
      chart.data.datasets[0].data = newVisible.map((one) => one.value.data);
      chart.data.datasets[0].backgroundColor = newVisible.map(
        (one) => one.value.selectBackgroundColor
      );
      chart.update();
    },
    [chart, props]
  );

  return (
    <Chart
      selectedDatasets={selectedDatasets}
      setSelectedDatasets={onSetSelectedDatasets}
      hiddenDatasets={hiddenDatasets}
      setHiddenDatasets={setHiddenDatasets}
      addDataToChart={addDataToChart}
      chart={chart}
      setChart={setChart}
      config={config}
      {...props}
    />
  );
};

export default PieChartViewModel;
