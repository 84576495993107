import { useMemo } from 'react';
import * as utils from 'common/helpers/utils';
import * as constants from 'common/helpers/constants';

const cityStateFmt = (city, state) => {
  return utils.Fmt.addressLines({ city, state })[0];
};

export const useShipmentMultipleResultsRows = (shipments) =>
  useMemo(
    () =>
      (shipments || [])?.reduce((prevState, currentValue) => {
        const currentRow = {
          id: currentValue.hid,
          trackingNumber: currentValue.trackingNumber,
          carrierPickedUpDate: currentValue.carrierPickedUpDate,
          status: currentValue.status,
          serviceType: [
            constants.getDispValue(currentValue.carrier),
            currentValue.dispServiceType,
          ].join(' '),
          origin: cityStateFmt(currentValue.originCity, currentValue.originState),
          dispServiceType: cityStateFmt(
            currentValue.destinationCity,
            currentValue.destinationState
          ),
          currentValue,
        };
        prevState.push(currentRow);
        return prevState;
      }, []),
    [shipments]
  );
