import React, { useMemo } from 'react';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { ic_edit } from 'react-icons-kit/md/ic_edit';
import TooltipIcon from 'common/components/TooltipIcon';

const ActionsFormatter = ({ row, onDelete, onEdit }) => (
  <div>
    <TooltipIcon className="EditIcon" icon={ic_edit} onClick={() => onEdit(row)}>
      Edit GL code
    </TooltipIcon>
    <TooltipIcon
      className="RemoveIcon"
      icon={ic_delete}
      onClick={() => onDelete(row.hid)}
    >
      Remove GL code
    </TooltipIcon>
  </div>
);

export const useGLCodesTableColumns = (onDelete, onEdit, codeName) =>
  useMemo(
    () => [
      {
        field: 'code',
        headerName: codeName,
        width: 280,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'vendorAccountNumber',
        headerName: 'Account number',
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'glType',
        headerName: 'GL Type',
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'startingFromDate',
        headerName: 'Starting from date',
        flex: 1,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'actions',
        headerName: '',
        width: 80,
        headerAlign: 'center',
        align: 'center',
        type: 'actions',
        renderCell: (params) => (
          <ActionsFormatter row={params.value} onDelete={onDelete} onEdit={onEdit} />
        ),
      },
    ],
    [onDelete, onEdit]
  );
