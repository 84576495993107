import { useMemo } from 'react';

export const useRows = (carrierServiceSummaryStats) =>
  useMemo(() => {
    const { totalCostSum, numShipmentsSum } =
      carrierServiceSummaryStats?.reduce(
        (state, currentValue) => {
          state.totalCostSum += currentValue.totalCost;
          state.numShipmentsSum += currentValue.numShipments;
          return state;
        },
        { totalCostSum: 0, numShipmentsSum: 0 }
      ) || {};

    return carrierServiceSummaryStats?.reduce((prevState, currentValue) => {
      const currentRow = {
        id:
          currentValue.carrier +
          currentValue.iconUrl +
          currentValue.serviceType +
          currentValue.numShipments +
          currentValue.totalCost +
          currentValue.unitTransportationCost +
          currentValue.unitCost +
          currentValue.avgWeight +
          currentValue.avgPricingZone +
          currentValue.busDaysLabelCreatedToDelivered +
          currentValue.busDaysCarrierPickedUpToDelivered +
          Math.random(),
        carrier: currentValue.carrier,
        iconUrl: currentValue.iconUrl,
        serviceType: currentValue.serviceType,
        numShipments: currentValue.numShipments,
        totalCost: currentValue.totalCost,
        unitTransportationCost: currentValue.unitTransportationCost,
        unitCost: currentValue.unitCost,
        avgWeight: currentValue.avgWeight,
        avgPricingZone: currentValue.avgPricingZone,
        busDaysLabelCreatedToDelivered: currentValue.busDaysLabelCreatedToDelivered,
        busDaysCarrierPickedUpToDelivered: currentValue.busDaysCarrierPickedUpToDelivered,
        currentValue: currentValue,
        totalCostPerc: currentValue.totalCost / totalCostSum,
        numShipmentsPerc: currentValue.numShipments / numShipmentsSum,
      };
      prevState.push(currentRow);
      return prevState;
    }, []);
  }, [carrierServiceSummaryStats]);
