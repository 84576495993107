import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { gridDateUtils } from 'common/components/table/gridDateUtils';

const revertSortFunc = (a, b) => a.slice(1) - b.slice(1);

export const useVendorInvoiceDetailsColumns = () =>
  useMemo(
    () => [
      {
        field: 'trackingNumber',
        headerName: 'Tracking number',
        width: 210,
        headerAlign: 'center',
        align: 'center ',
        renderCell: (params) => (
          <NavLink
            to={{
              pathname: `/shipments/single-tracking/${params.value}`,
            }}
            style={{
              textOverflow: 'ellipsis',
              display: 'block',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            {params.value}
          </NavLink>
        ),
      },
      {
        field: 'serviceType',
        headerName: 'Service Type',
        minWidth: 160,
        headerAlign: 'center',
        align: 'center',
        sortComparator: gridDateUtils,
      },
      {
        field: 'carrierPickedUpDate',
        headerName: 'Picked up date',
        flex: 1,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
        sortComparator: gridDateUtils,
      },
      {
        field: 'chargeAmount',
        headerName: 'Charge',
        flex: 1,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
        sortComparator: revertSortFunc,
      },
      {
        field: 'adjustmentAmount',
        headerName: 'Adjustment',
        flex: 1,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
        sortComparator: revertSortFunc,
      },
      {
        field: 'discountAmount',
        headerName: 'Discount',
        flex: 1,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
        sortComparator: revertSortFunc,
      },
      {
        field: 'refundAmount',
        headerName: 'Refund',
        flex: 1,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
        sortComparator: revertSortFunc,
      },
      {
        field: 'totalAmount',
        headerName: 'Total',
        flex: 1,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
        sortComparator: revertSortFunc,
      },
    ],
    []
  );
